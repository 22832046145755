/* eslint-disable */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
// import { Input, Select } from "antd";
import { getAutoSetting, updateAutoSetting } from "../../services";
import { useAuth } from "../../hooks/useAuth";
import { Card, InputNumber, Input, Checkbox } from "antd";

const { TextArea } = Input;

const AutoTab = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        initFunc,
    }));

    const initStoreAccount = {
        sellername: "",
        sellerid: "",
        marketplaceid: "",
        sp_app_client_id: "",
        sp_app_client_secret: "",
        refresh_token: "",
        aws_sp_role: "",
        googlesheetid: "",
        googlesheet_range: "",
        updatedAt: ""
    }
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [autoList, setAutoList] = useState();

    const initFunc = () => {
        getAutoSettingList();
    };

    useEffect(() => {
        initFunc();
    }, [])

    const getAutoSettingList = async () => {
        try {
            setLoading(true);
            const result = await getAutoSetting({ user });
            console.log(555, result);
            setAutoList(result.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    const handleChangeValue = (key, value) => {
        var currentData = { ...autoList };
        currentData[key] = value;
        setAutoList(currentData);
    }

    const handleSaveData = async () => {
        setLoading(true);
        await updateAutoSetting({...autoList, user});
        initFunc();
        setLoading(false);
    }

    const handleCheckBox = async (key, value) => {
        var currentData = {...autoList};
        currentData[key] = value;
        setAutoList(currentData);
        setLoading(true);
        await updateAutoSetting({...currentData, user});
        initFunc();
        setLoading(false);
    }

    return (
        <>
            {loading && (
                <div id="global-loader">
                    <h2>データ処理中...</h2>
                </div>
            )}
            <div style={{
                height: "52px",
                background: "#d9edf7",
                color: "#3a87ad",
                border: "1px solid #bce8f1",
                padding: "0 20px",
                width: "100%", display: "flex",
                justifyContent: "space-between", alignItems: "center"
            }}>
                <span>オートモードの設定情報を入力してください。</span>
            </div>
            <div style={{ width: "100%" }}>
                <Card
                    title={<span style={{ color: "#6778e8" }}>自動設定</span>}
                    bordered
                    style={{ marginTop: "20px" }}
                >
                    <div className="row">
                        <div className="col-sm-3" />
                        <div className="col-sm-9 d-flex flex-column">
                            <Checkbox
                                checked={autoList && autoList.auto_price_update_chk == 0 ? false : true}
                                onChange={(e) => handleCheckBox("auto_price_update_chk", e.target.checked ? 1 : 0)}
                            >
                                自動価格改定
                            </Checkbox>
                            <Checkbox className="mt-3"
                                checked={autoList && autoList.auto_fba_scraping_start == 0 ? false : true}
                                onChange={(e) => handleCheckBox("auto_fba_scraping_start", e.target.checked ? 1 : 0)}
                            >
                                FBA商品 Scraping 自動調査
                            </Checkbox>
                        </div>
                    </div>
                </Card>
                {/* <Card
                    title={<span style={{ color: "#6778e8" }}>ギフト券</span>}
                    bordered
                    style={{ marginTop: "20px" }}
                >
                    <div className="row d-flex align-items-center">
                        <div className="col-sm-2 text-end fw-bold">ギフト券率</div>
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                            <InputNumber suffix="%"
                                style={{ width: "100%" }}
                                value={autoList && autoList.amazon_promotion} size="large"
                                onChange={(e) => handleChangeValue("amazon_promotion", e)}
                            />
                        </div>
                        <div className="col-sm-6">
                            <button type="button" className="btn btn-dark me-2" onClick={() => handleSaveData()} >
                                保存
                            </button>
                        </div>
                    </div>
                </Card> */}
                {/* <Card
                    title={<span style={{ color: "#6778e8" }}>出荷日数</span>}
                    bordered
                    style={{ marginTop: "20px" }}
                >
                    <div className="row d-flex align-items-center">
                        <div className="col-sm-2 text-end fw-bold">ハンドリングタイム</div>
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                            <InputNumber suffix="日" 
                                value={autoList && autoList.auto_handling_time} style={{ width: "100%" }} size="large" 
                                onChange={(e) => handleChangeValue("auto_handling_time", e)}
                            />
                        </div>
                        <div className="col-sm-6">
                            <button type="button" className="btn btn-dark me-2"  onClick={() => handleSaveData()}>
                                保存
                            </button>
                        </div>
                    </div>
                </Card> */}
                {/* <Card
                    title={<span style={{ color: "#6778e8" }}>価格制御</span>}
                    bordered
                    style={{ marginTop: "20px" }}
                >
                    <div className="row d-flex align-items-center">
                        <div className="col-sm-2 text-end fw-bold">最安値との価格差</div>
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                            <InputNumber suffix="$" 
                                style={{ width: "100%" }} size="large" 
                                value={autoList && autoList.auto_diff_price_control_val}
                                onChange={(e) => handleChangeValue("auto_diff_price_control_val", e)}
                            />
                        </div>
                        <div className="col-sm-6">
                            <button type="button" className="btn btn-dark me-2"  onClick={() => handleSaveData()}>
                                保存
                            </button>
                        </div>
                    </div>
                </Card> */}
                {/* <Card
                    title={<span style={{ color: "#6778e8" }}>日本Prime商品価格設定</span>}
                    bordered
                    style={{ marginTop: "20px" }}
                >
                    <div className="row d-flex align-items-center">
                        <div className="col-sm-2 text-end fw-bold">日本Prime商品価格</div>
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                            <InputNumber suffix="¥" style={{ width: "100%" }} size="large"
                                value={autoList && autoList.auto_prime_price_control_val}
                                onChange={(e) => handleChangeValue("auto_prime_price_control_val", e)}
                            />
                        </div>
                        <div className="col-sm-6">
                            <button type="button" className="btn btn-dark me-2"  onClick={() => handleSaveData()}>
                                保存
                            </button>
                        </div>
                    </div>
                </Card> */}
                {/* <Card
                    title={<span style={{ color: "#6778e8" }}>出品者のない商品利益率設定</span>}
                    bordered
                    style={{ marginTop: "20px" }}
                >
                    <div className="row d-flex align-items-center">
                        <div className="col-sm-2 text-end fw-bold">利益率</div>
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                            <InputNumber suffix="%" 
                                style={{ width: "100%" }} size="large" 
                                value={autoList && autoList.no_seller_profit_percent}
                                onChange={(e) => handleChangeValue("no_seller_profit_percent", e)}
                            />
                        </div>
                        <div className="col-sm-6">
                            <button type="button" className="btn btn-dark me-2"  onClick={() => handleSaveData()}>
                                保存
                            </button>
                        </div>
                    </div>
                </Card> */}
                <Card
                    title={<span style={{ color: "#6778e8" }}>UPS1 kg単価の設定</span>}
                    bordered
                    style={{ marginTop: "20px" }}
                >
                    <div className="row d-flex align-items-center">
                        <div className="col-sm-2 text-end fw-bold">UPS1 kg単価</div>
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                            <InputNumber suffix="¥"
                                style={{ width: "100%" }} size="large" 
                                value={autoList && autoList.ups1_price_per_kg_control} 
                                onChange={(e) => handleChangeValue("ups1_price_per_kg_control", e)}
                            />
                        </div>
                        <div className="col-sm-6">
                            <button type="button" className="btn btn-dark me-2"  onClick={() => handleSaveData()}>
                                保存
                            </button>
                        </div>
                    </div>
                </Card>
                <Card
                    title={<span style={{ color: "#6778e8" }}>UPS2 kg単価の設定</span>}
                    bordered
                    style={{ marginTop: "20px" }}
                >
                    <div className="row d-flex align-items-center">
                        <div className="col-sm-2 text-end fw-bold">UPS2 kg単価</div>
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                            <InputNumber suffix="¥" 
                                style={{ width: "100%" }} size="large" 
                                value={autoList && autoList.ups2_price_per_kg_control} 
                                onChange={(e) => handleChangeValue("ups2_price_per_kg_control", e)}
                            />
                        </div>
                        <div className="col-sm-6">
                            <button type="button" className="btn btn-dark me-2"  onClick={() => handleSaveData()}>
                                保存
                            </button>
                        </div>
                    </div>
                </Card>
                <Card
                    title={<span style={{ color: "#6778e8" }}>UGX kg単価の設定</span>}
                    bordered
                    style={{ marginTop: "20px" }}
                >
                    <div className="row d-flex align-items-center">
                        <div className="col-sm-2 text-end fw-bold">UGX kg単価</div>
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                            <InputNumber suffix="¥"
                                style={{ width: "100%" }} size="large" 
                                value={autoList && autoList.ugx_price_per_kg_control}
                                onChange={(e) => handleChangeValue("ugx_price_per_kg_control", e)}
                            />
                        </div>
                        <div className="col-sm-6">
                            <button type="button" className="btn btn-dark me-2"  onClick={() => handleSaveData()}>
                                保存
                            </button>
                        </div>
                    </div>
                </Card>
                <Card
                    title={<span style={{ color: "#6778e8" }}>FBA商品メッセージ</span>}
                    bordered
                    style={{ marginTop: "20px" }}
                >
                    <div className="row">
                        <div className="col-sm-2 text-end fw-bold">FBA 新品</div>
                        <div className="col-sm-1" />
                        <div className="col-sm-8">
                            <TextArea style={{ width: "100%" }} size="large" 
                                value={autoList && autoList.fba_new_note} rows={4} 
                                onChange={(e) => handleChangeValue("fba_new_note", e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-sm-2 text-end fw-bold">FBA 中古</div>
                        <div className="col-sm-1" />
                        <div className="col-sm-8">
                            <TextArea style={{ width: "100%" }} size="large"
                                value={autoList && autoList.fba_used_note} rows={4} 
                                onChange={(e) => handleChangeValue("fba_used_note", e.target.value)}
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                        <button type="button" className="btn btn-dark" style={{ width: "150px" }}  onClick={() => handleSaveData()}>
                            保存
                        </button>
                    </div>
                </Card>
                <Card
                    title={<span style={{ color: "#6778e8" }}>商品購入のため販売者条件</span>}
                    bordered
                    style={{ marginTop: "20px" }}
                >
                    <div className="row">
                        <div className="col-md-2 d-flex flex-column align-items-center">
                            <label className="fw-bold mb-2">ユーザー評価数</label>
                            <InputNumber suffix="以上" size="large" style={{ width: "100%" }} 
                                value={autoList && autoList.auto_feedback_count} 
                                onChange={(e) => handleChangeValue("auto_feedback_count", e)}
                            />
                        </div>
                        <div className="col-md-2 d-flex flex-column align-items-center">
                            <label className="fw-bold mb-2">ユーザー評価数</label>
                            <InputNumber suffix="以上" size="large" 
                                style={{ width: "100%" }} 
                                value={autoList && autoList.auto_feedback_rates} 
                                onChange={(e) => handleChangeValue("auto_feedback_rates", e)}
                            />
                        </div>
                        <div className="col-md-2 d-flex flex-column align-items-center">
                            <label className="fw-bold mb-2">ユーザー評価数</label>
                            <InputNumber suffix="以上" size="large" style={{ width: "100%" }} 
                                value={autoList && autoList.jp_seller_count_condition} 
                                onChange={(e) => handleChangeValue("jp_seller_count_condition", e)}
                            />
                        </div>
                        <div className="col-md-2 d-flex flex-column align-items-center">
                            <label className="fw-bold mb-2">ユーザー評価数</label>
                            <InputNumber suffix="以上" size="large" style={{ width: "100%" }} 
                                value={autoList && autoList.jp_shipping_time} 
                                onChange={(e) => handleChangeValue("jp_shipping_time", e)}
                            />
                        </div>
                        <div className="col-md-3 d-flex justify-content-center">
                            <button type="button" className="btn btn-dark" style={{ width: "150px", marginTop: "30px" }}  onClick={() => handleSaveData()}>
                                保存
                            </button>
                        </div>
                    </div>
                </Card>
            </div>
        </>
    )
});

AutoTab.displayName = "AutoTab";

export default AutoTab;