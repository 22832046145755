/* eslint-disable */
import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { FiActivity } from "react-icons/fi";
import { FaCalculator, FaPencil, FaTrashCan, FaMagnifyingGlass, FaArrowRotateRight } from 'react-icons/fa6';
import dayjs from "dayjs";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import {
  InputNumber,
  Table,
  Select,
  Pagination,
  Popover,
} from "antd";
import {
  CreateJob,
  getAllLog,
  deleteAllLog,
  updateAllLog,
  getEmployee,
  getCategoryData,
  isTokenErrorResponse,
} from "../../../services";
import { useAuth } from "../../../hooks/useAuth";
import { USAFlag, JAPANFlag } from "../../../core/components/Flags";
import * as utils from "../../../utils/util";
import * as constants from "../../../utils/constants";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { FilterDrawer } from "../../../core/components/FilterDrawer";

const initialData = {
  topFilter: "fba_purchase_ready_all",
  topAction: "none",
  order_by_value: "",
  order_by_way: 0, // 0 or 1
  filter_multi_asins: "",
  filter_us_min_rank: "",
  filter_us_max_rank: "",
  filter_us_min_weight: "",
  filter_us_max_weight: "",
  filter_us_min_price: "",
  filter_us_max_price: "",
  filter_jp_purchase_min_price: "",
  filter_jp_purchase_max_price: "",
  filter_jp_seller_min_price: "",
  filter_jp_seller_max_price: "",
  filter_min_profit_amount: "",
  filter_max_profit_amount: "",
  filter_min_profit_percent: "",
  filter_max_profit_percent: "",
  filter_product_type1_list: "",
  filter_us_category_list: "",
  filter_purchase_place: "",
  filter_ship_place: "",
  filter_employee_name: "",
  sales_count_not0: false,
  filter_keyword: "",
};

const topActionOptions = [
  { value: "none", label: "-- なし --" },
  { value: "calculate_profit_bulk_listing_page", label: "自動利益額計算" },
]

const ItemsAllLog = forwardRef((props, ref) => {

  useImperativeHandle(ref, () => ({
    initFunc,
  }));

  const { user, logout } = useAuth();
  const tableRef = useRef(null);

  const active_seller = useSelector((state) => state.active_seller);
  const store_list = useSelector((state) => state.store_list);

  const [categoryList, setCategoryList] = useState();
  const [employeeList, setEmployeeList] = useState();

  const route = all_routes;

  const [loading, setLoading] = useState(false);

  const [keyword, setKeyword] = useState("");
  const [filterValue, setFilterValue] = useState(initialData);

  const [isDrawer, setIsDrawer] = useState(false);

  const [dataSource, setDataSource] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 15 });


  const handleRefresh = async () => {
    setKeyword("");
    setFilterValue(initialData);
    getResearchList(1, true);
  }

  const handleSearch = (e) => {
    if (e == null || e.key === "Enter") {
      if (keyword == "") {
        filterValue.filter_keyword = "";
      }
      setPagination({ current: 1, pageSize: pagination.pageSize });
      getResearchList();
      setKeyword("");
    }
  };

  const getResearchList = async (page = 1, refresh = false) => {
    try {
      let seller = "";
      if (active_seller != "すべてのアカウント") seller = active_seller;
      setLoading(true);

      const result = await getAllLog({
        page: page - 1,
        count: pagination.pageSize,
        user: user,
        seller: seller,
        keyword: refresh ? '' : filterValue.filter_keyword,
        filterValue: refresh ? initialData : filterValue
      });
      if (isTokenErrorResponse(result)) {
        return logout();
      }

      if (result.data.data && result.data.data.length > 0) {
        setDataSource(result.data.data);
        let pages = Math.ceil(parseInt(result.data.recordsTotal) / pagination.pageSize);
        setPageCount(pages);
        setTotalItems(result.data.recordsTotal);
      } else {
        setDataSource([]);
        setPageCount(0);
        setTotalItems(0);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleTableChange = (page, pageSize) => {
    setPagination({
      current: page,
      pageSize: pageSize
    });
    getResearchList(page);
  };

  const handlePageSizeChange = (pagesize) => {
    pagination.pageSize = pagesize;
    getResearchList();
  };

  const initFunc = async () => {
    document.title = "納品総合ログ";

    const result = await getCategoryData({ user });
    setCategoryList(result?.data);

    const employees = await getEmployee({ user });
    setEmployeeList(employees?.data);

    getResearchList();
  }

  useEffect(() => {
    initFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_seller]);

  const checkProductStatus = async (asin) => {
    window.open(`${route.productdetails}?asin=${asin}`, "_blank", "noreferrer");
  };

  const onInputChange = (key, index) => (value) => {
    const newData = [...dataSource];
    newData[index][key] = value;

    setDataSource(newData);
  };


  const handleUpdateProduct = (index) => async () => {
    try {
      setLoading(true);
      const props = dataSource[index];
      props.user = user;
      await updateAllLog(props);
      getResearchList(pagination.current);
      setEdit_modal_visible(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleTopAction = async () => {
    try {
      
      utils.showConfirmBox("アクションの適用", "それを適用しますか？", async () => {
        setLoading(true);
        await CreateJob({
          jobname: filterValue.topAction,
          jobvalue: {
            name: "",
            value: "",
            condition: "",
            sellername: active_seller
          },
          user: user,
        });
        setLoading(false);
        getResearchList();
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getShipPlanName = (createdat, planname) => {
    return "FBA (" + dayjs(createdat).format("YY/MM/DD HH:mm") + ") " + planname;
  }

  const columns = [
    {
      title: <span>商品画像</span>,
      dataIndex: "image",
      width: 100,
      render: (text, record, index) => {
        return (
          <span className="inventoryimgname">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Link
                to={`http://amazon.com/dp/${record.asin}`}
                className="product-img stock-img"
                target="_blank"
              >
                <ImageWithBasePath
                  alt=""
                  src={text ?? "assets/img/products/product1.jpg"}
                  isProduct={text ? true : false}
                  width={80}
                  height={80}
                />
              </Link><br />
              <Link
                to={`http://us.so-bank.jp/detail/?code=${record.asin}&month=1`}
                className="product-img stock-img link-title"
                target="_blank"
              >
                プライスター
              </Link>
              <Link
                to={`http://camelcamelcamel.com/Sanyo-ABC-FAH941-cleaner-replacement-filters/product/${record.asin}`}
                className="product-img stock-img link-title"
                target="_blank"
              >
                camel camel camel
              </Link><br />
            </div>
          </span>
        );
      },
    },
    {
      title: <span>プラン名, 発注先, 発注者, 梱包担当</span>,
      dataIndex: "productname",
      width: 350,
      render: (text, record, index) => {
        return (
          <div>
            <table className="table1">
              {
                record.all_ship_plan_id.map(item => {
                  var url = `${route.fbaloglist}?ship_plan_id=${item.ship_plan_id}&asin=${record.asin}`;
                  var url1 = `${route.loglist}?ship_plan_id=${item.ship_plan_id}`;

                  let name = item.name;
                  if (!name?.startsWith("FBA")) {
                    let info = item.info ? JSON.parse(item.info) : null;
                    name = info ? getShipPlanName(info?.CreatedAt, info?.PlanName) : item.name;
                  }

                  let detail = <div>
                    <div><span className="d-inline-block text-end" style={{ width: 70 }}>プラン名 :</span> <strong>{name}</strong></div>
                    <div><span className="d-inline-block text-end" style={{ width: 70 }}>アカ :</span> <strong>{item.sellername}</strong></div>
                    <div><span className="d-inline-block text-end" style={{ width: 70 }}>発注先 :</span> <strong>{item.purchase_place}</strong></div>
                    <div><span className="d-inline-block text-end" style={{ width: 70 }}>発注者 :</span> <strong>{item.ship_place}</strong></div>
                    <div><span className="d-inline-block text-end" style={{ width: 70 }}>梱包担当 :</span> <strong>{item.employee_name}</strong></div>
                  </div>;

                  return <tr>
                    <td>
                      <Popover content={detail}>
                        <a href={url1} target="_blank">{name}</a>
                      </Popover>
                    </td>
                    <td>
                      <Popover content={detail}>
                        <a href={url1} target="_blank" className={`sellermark ${item.sellername} d-block`}>
                          {utils.getSellerMark(item.sellername, store_list)}
                        </a>
                      </Popover>
                    </td>
                    <td>
                      <Popover content={detail}>
                        <a href={url1} target="_blank">{item.purchase_place}&nbsp;</a>
                      </Popover>
                    </td>
                    <td>
                      <Popover content={detail}>
                        <a href={url1} target="_blank">{item.ship_place}&nbsp;</a>
                      </Popover>
                    </td>
                    <td>
                      <Popover content={detail}>
                        <a href={url1} target="_blank">{item.employee_name}&nbsp;</a>
                      </Popover>
                    </td>
                  </tr>
                })
              }
            </table>
          </div>
        );
      },
    },
    {
      title: (
        <span>
          ASIN<br />
          JAN<br />
          Amazonポイント
        </span>
      ),
      dataIndex: "asin",
      width: 100,
      render: (text, record) => {
        // var url_fba = "https://sellercentral.amazon.com/skucentral?mSku=" + record.sku + "&ref=myi_skuc";
        var site = "http://www.amazon.co.jp";
        var url = site + "/gp/offer-listing/" + record.asin + "/ref=olp_tab_new?ie=UTF8&condition=new";
        if (record.itemcondition != "New") {
          url = site + "/gp/offer-listing/" + record.asin + "/ref=olp_tab_used?ie=UTF8&condition=used";
        }
        return (
          <span>
            <Link to={url} className="product-title" target="_blank">
              {record.asin}
            </Link>
            <br /><br />
            <span className="product-title">{record.jan}</span> <br /><br />
            <span className="product-title">{record.us_category ? record.us_category : "-"}</span> <br /><br />
            <span className="product-title">{record.amazon_point}</span> <br />
          </span>
        );
      },
    },
    {
      title: (
        <span>
          コンディション<br />
          納品日
        </span>
      ),
      dataIndex: "itemcondition",
      width: 80,
      render: (text, record) => {

        let listDate = Number(record.list_date) > 3000 ? utils.getDateTimeFormat2(Number(record.list_date)) : record.list_date;
        listDate = listDate ? listDate.split(" ")[0] : "";
        
        return (
          <span>
            {record.itemcondition == "New" ? "新品" : "中古"}
            <br /><br />
            {listDate}
            <br /><br />
            {record.is_own == 1 ? "問屋商品" : "-"}
            <br /><br />
            {record.fba_is_sh == 1 && "SL"}
          </span>
        );
      },
    },
    {
      title: (
        <span>
          <JAPANFlag /><br />
          仕入価格<br />
          仕入数量<br />
          内容品
        </span>
      ),
      dataIndex: "jp_sell_price",
      width: 80,
      render: (text, record, index) => (
        <span>
          <div style={{ marginTop: "10px" }} className="editable-cell">
            ¥ {record.purchase_price}
          </div>
          <div style={{ marginTop: "10px" }} className="editable-cell">
            {record.purchase_stock}
          </div>
          <div style={{ marginTop: "10px" }} className="editable-cell">
            <InputNumber
              value={record.memo}
              onPressEnter={handleUpdateProduct(index)}
              onChange={onInputChange("memo", index)}
            />
          </div>
        </span>
      ),
    },
    {
      title: (
        <span>
          <USAFlag /><br />
          最低価格<br />
          ランク
        </span>
      ),
      dataIndex: "us_sell_price",
      width: 60,
      render: (text, record, index) => {
        var url = 'http://www.amazon.com/gp/offer-listing/' + record.asin.trim() + '/ref=olp_tab_all?ie=UTF8&sort=price&condition=new';
        var url_fba = 'http://www.amazon.com/gp/offer-listing/' + record.asin.trim() + '/ref=olp_tab_all?ie=UTF8&shipPromoFilter=1&sort=price&condition=new';
        var url1 = 'http://www.amazon.com/dp/' + record.asin.trim();
        if (record.itemcondition != "New") {
          url_fba = 'http://www.amazon.com/gp/offer-listing/' + record.asin.trim() + '/ref=olp_tab_all?ie=UTF8&shipPromoFilter=1&sort=price&condition=used';
          url = 'http://www.amazon.com/gp/offer-listing/' + record.asin.trim() + '/ref=olp_tab_all?ie=UTF8&sort=price&condition=used';
        }
        return (
          <span>
            <Link
              to={url}
              target="_blank"
            >
              <span className="text-blue">
                $ {record.us_lowest_price}
              </span>
            </Link>
            <br /> <br />
            <div>{record.us_rank ? record.us_rank : "-"}</div>
          </span>
        );
      },
    },

    {
      title: (
        <span>
          <USAFlag />
          <br />
          販売価格<br />
          数量
        </span>
      ),
      dataIndex: "package_size",
      width: 80,
      render: (text, record, index) => {

        return (
          <span>
            <div className="editable-cell">
              <InputNumber
                prefix="$"
                value={record.us_price}
                onPressEnter={handleUpdateProduct(index)}
                onChange={onInputChange("us_price", index)}
              />
            </div><br />
            <div>{record.us_stock}</div>
          </span>
        );
      },
    },
    {
      title: (
        <span>
          <USAFlag /><br />
          米国送料<br />
          包装数量
        </span>
      ),
      width: 60,
      dataIndex: "profit",
      render: (text, data) => {
        return (
          <span>
            <div>¥ {data.us_ship_price}</div><br />
            <div>{data.package_count}</div>
          </span>
        );
      },
    },
    {
      title: (
        <span>
          <USAFlag />
          <br />
          手数料<br />
          FBA手数料<br />
          合計手数料
        </span>
      ),
      width: 60,
      dataIndex: "profit",
      render: (text, data) => {
        var fba_fee = "";
        if (data.fba_is_sh == 0) {
          fba_fee = data.fba_fee_auto;
        } else {
          fba_fee = data.fba_sh_fee;
        }
        return (
          <span>
            <span>{data.us_amazon_fee} %</span><br /><br />
            <span>$ {data.fba_subtotal_fee}</span><br /><br />
            <span>$ {fba_fee} (計算)</span><br /><br />
            <span>{data.sell_percent} %</span>
          </span>
        );
      },
    },
    {
      title: (
        <span>
          利益額<br />
          利益率<br />
          容積重量<br />
          FBA重量<br />
          販売数量
        </span>
      ),
      dataIndex: "sales_history",
      width: 70,
      render: (text, data) => {

        return (
          <span>
            <span>¥ {data.us_profit_amount}</span><br /><br />
            <span>{data.us_profit_percent} %</span><br /><br />
            <span>{Math.round(data.weight)} g</span><br /><br />
            <span>{Math.round(data.fba_weight)} g</span><br /><br />
            <span>{data.all_sold_count}</span>
          </span>
        );
      },
    },
    {
      title: "アクション",
      dataIndex: "action",
      width: 60,
      render: (text, record, index) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <a
              className="me-1 p-1"
              onClick={() => checkProductStatus(record.asin)}
              title="詳細"
            >
              <FiActivity className="feather-eye" />
            </a>
          </div>
        </div>
      ),
    },
  ];


  const onDrawerClose = () => {
    setIsDrawer(false);
  };


  return (
    <>
      {loading && (
        <div id="global-loader">
          <h2>データ処理中...</h2>
        </div>
      )}
      {/* /product list */}
      <div className="card table-list-card">
        <div className="card-body">
          <div className="table-top">
            <div className="search-set">
              <a
                type="button"
                onClick={() => handleRefresh()}
                style={{ paddingRight: 10 }}
              >
                <FaArrowRotateRight />
              </a>
              <div className="search-input">
                <input
                  value={keyword}
                  type="text"
                  placeholder="Search"
                  className="form-control form-control-sm formsearch"
                  style={{ width: 200 }}
                  onChange={(e) => { 
                    setKeyword(e.target.value);
                    setFilterValue((prev) => ({
                      ...prev,
                      filter_keyword: e.target.value,
                    }))
                  }}
                  onKeyDown={handleSearch}
                // suffix={<FaMagnifyingGlass />}
                />
                <button
                  type="button"
                  onClick={() => handleSearch(null)}
                  className="btn btn-searchset"
                >
                  <FaMagnifyingGlass />
                </button>
              </div>

              <div
                className="search-input"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "20px",
                }}
              >
                <label style={{ marginRight: "10px" }}>アクション</label>
                <Select
                  className="select"
                  options={topActionOptions}
                  size="large"
                  style={{ width: "200px" }}
                  value={filterValue.topAction}
                  onChange={(e) =>
                    setFilterValue((prev) => ({
                      ...prev,
                      topAction: e,
                    }))
                  }
                />
                <button
                  type="button"
                  className="btn btn-primary me-2"
                  style={{ marginLeft: "10px" }}
                  onClick={handleTopAction}
                >
                  適用
                </button>
              </div>

              <button
                type="button"
                className="btn btn-primary me-2"
                onClick={() => {
                  setIsDrawer(true);
                }}
              >
                Filter Set
              </button>
            </div>

            <div className="d-flex align-items-center">
              <Pagination current={pagination.current} pageSize={pagination.pageSize} showSizeChanger={false} total={totalItems} onChange={handleTableChange} />

              <Select
                className="select ms-3"
                options={[
                  { value: 15, label: "15" },
                  { value: 30, label: "30" },
                  { value: 50, label: "50" },
                  { value: 100, label: "100" },
                ]}
                placeholder="15"
                onChange={handlePageSizeChange}
              />
              <span className="ms-2">/ page</span>
            </div>
          </div>
          <div className="table-responsive">

            <div ref={tableRef}>
              <Table bordered
                className="table datanew dataTable no-footer"
                // rowSelection={rowSelection}
                columns={columns}
                dataSource={dataSource}
                rowKey={(record) => record.id}
                pagination={false}
                onChange={handleTableChange}
                scroll={{ y: "calc(100vh - 400px)" }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* /product list */}

      <FilterDrawer
        open={isDrawer}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        categoryList={categoryList}
        employeeList={employeeList}
        onOk={() => {
          setPagination({ current: 1, pageSize: pagination.pageSize });
          onDrawerClose();
          getResearchList();
        }}
        onCancel={onDrawerClose}
      />

    </>
  );
});

export default ItemsAllLog;
