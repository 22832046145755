
const active_seller = "すべてのアカウント";
const storelist = [];

const initialState = {
  active_seller: active_seller,
  store_list: storelist,
  sidebar_visible: true,
};

export default initialState;
