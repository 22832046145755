/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Checkbox, Drawer, Table } from 'antd';
import { convertStringToJSON, getDateTimeFormat2 } from '../../utils/util';
import { useAuth } from '../../hooks/useAuth';
import { getProductDetails } from '../../services';
import StockLineChart from '../../core/components/StockLineChart';
import moment from 'moment';

export function FBASellerInfoDrawer({ open, asin, onClose, onClickSeller }) {

  const { user } = useAuth();

  const [fbaOnly, setFbaOnly] = useState(true);
  const [showCount, setShowCount] = useState(false);
  const [listingCountLoaded, setListingCountLoaded] = useState(false);

  const [loading, setLoading] = useState(false);
  const [sellerInfos, setSellerInfos] = useState([]);
  const [filteredSellerInfos, setFilteredSellerInfos] = useState([]);

  useEffect(() => {
    if (asin != null) {
      setSellerInfos([]);
      productDetails(asin, false);
      setShowCount(false);
      setListingCountLoaded(false);
    }
  }, [asin]);

  useEffect(() => {
    if (!listingCountLoaded && showCount) {
      productDetails(asin, true);
      setListingCountLoaded(true);
    }
  }, [showCount]);

  useEffect(() => {
    if (!fbaOnly) {
      setFilteredSellerInfos(sellerInfos);
    } else {
      setFilteredSellerInfos(sellerInfos.filter(item => item.is_fba));
    }
  }, [sellerInfos, fbaOnly]);

  const productDetails = async (asin, listingCount = false) => {
    try {
      setLoading(true);
      const result = await getProductDetails({ asin, user, listingCount: listingCount });
      const data = [];
      if (result.data.length > 0) {
        for (let i = 0; i < result.data.length; i++) {
          const item = {
            name: "",
            sellerid: "",
            is_live: false,
            is_fba: false,
            last_price_time: 0,
            last_price: 0,
            last_stock_time: 0,
            last_stock: 0,
            stock_series: [],
            lastseen: result.data[i]["lastseen"],
            us_profit_amount: result.data[i]["us_profit_amount"],
            us_profit_amount_sl: result.data[i]["us_profit_amount_sl"],
            us_profit_percent: result.data[i]["us_profit_percent"],
            us_profit_percent_sl: result.data[i]["us_profit_percent_sl"],
          };
          // if (result.data[i]["sellername"]) {
          item.name = result.data[i]["sellername"];
          //} else {
          item.sellerid = result.data[i]["sellerid"];
          //}
          item.is_live = result.data[i]["is_live"] == 1;
          item.is_fba = result.data[i]["isfba"] == 'true';
          item.listing_count = result.data[i]["listing_count"];

          if (!item.is_live) {
            continue;
          }

          if (result.data[i]["offercsv"]) {
            const offers = convertStringToJSON(result.data[i]["offercsv"]);
            if (offers.length >= 3) {
              item.last_price_time = (offers[offers.length - 3] + 21564000) * 60000;
              item.last_price = (offers[offers.length - 2] + offers[offers.length - 1]) / 100;

              if (item.is_live && result.data[i]["lastseen"]) {
                item.last_price_time = (Number(result.data[i]["lastseen"]) + 21564000) * 60000;
              }
            }
          }
          if (result.data[i]["stockcsv"]) {
            const stocks = convertStringToJSON(result.data[i]["stockcsv"]);
            if (stocks.length >= 2) {
              item.last_stock_time = (stocks[stocks.length - 2] + 21564000) * 60000;
              item.last_stock = stocks[stocks.length - 1];

              if (item.is_live && result.data[i]["lastseen"]) {
                item.last_stock_time = (Number(result.data[i]["lastseen"]) + 21564000) * 60000;
              }
            }

            for (let i = 0; i < stocks.length; i += 2) {
              const tempStamp1 = new Date((stocks[i] + 21564000) * 60000);
              const month1 = tempStamp1.getMonth() + 1;
              const day1 = tempStamp1.getDate();
              const year1 = tempStamp1.getFullYear().toString().slice(-2);

              const date1 = `${month1}/${day1}/${year1}`;
              item.stock_series.push({ date: date1, stock: stocks[i + 1] });
            }
            if (item.stock_series.length > 0 && (item.lastseen > 0 || item.is_live)) {
              const tsLastseen = (item.lastseen + 21564000) * 60000;
              let dtLastseen = moment(tsLastseen);
              let dtLaststock = moment(item.stock_series[item.stock_series.length - 1].date);
              let nLaststock = item.stock_series[item.stock_series.length - 1]['stock'];
              if (item.is_live) {
                dtLastseen = moment();
              }
              if (dtLastseen.isAfter(dtLaststock, 'day')) {
                const date1 = dtLastseen.format('M/D/YY');
                item.stock_series.push({ date: date1, stock: nLaststock });
              }
            }
          }

          data.push(item);
        }
      }
      data.sort((a, b) => (a.last_price - b.last_price));
      setSellerInfos(data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };


  const columns = [
    {
      title: "販売者名",
      dataIndex: "name",
      width: 100,
      render: (text, record) => {
        return (
          <div style={{ maxWidth: 180 }}>
            <div className='fw-bold'>{text}</div>
            <div
              className={`fw-bold`}
              style={{ cursor: onClickSeller ? 'pointer' : "inherit" }}
              onClick={() => onClickSeller && onClickSeller(record.sellerid)}>
              {record.sellerid}
            </div>
            {record.is_fba &&
              <div style={{ color: "#333" }}>出荷元 &nbsp;&nbsp;&nbsp; アマゾン</div>
            }
            {(record.listing_count && showCount) &&
              <div>{record.listing_count}</div>
            }
          </div>
        );
      },
    },
    {
      title: "価格",
      dataIndex: "last_price",
      width: 100,
      render: (text, record) => {
        return (
          <div className='text-start'>
            <div className='text-primary fw-bold'>$ {text}</div>
            <div>{getDateTimeFormat2(record.last_price_time)}</div>
          </div>
        );
      },
    },
    {
      title: <span>利益額<br />利益率</span>,
      dataIndex: "profit",
      width: 60,
      render: (text, record) => {
        let profit = record.last_price < 10 ? record.us_profit_amount_sl : record.us_profit_amount;
        let profitPercent = record.last_price < 10 ? record.us_profit_percent_sl : record.us_profit_percent;

        let color = "";
        let weight = "";
        if (profitPercent < 0) {
          color = "red";
          weight = "bold";
        } else if (profitPercent <= 4) {
          color = "green";
          weight = "bold";
        } else {
          color = "black";
          weight = "normal";
        }

        return (
          <div className='text-start'>
            <div style={{ color: color, fontWeight: weight }}>¥ {Math.round(profit)}</div>
            <div style={{ color: color, fontWeight: weight }}>{profitPercent} %</div>
          </div>
        );
      },
    },
    {
      title: "在庫",
      dataIndex: "last_stock",
      width: 100,
      render: (text, record) => {
        return (
          <div className='text-start'>
            <div className='fw-bold'>{text}</div>
            <div>{getDateTimeFormat2(record.last_stock_time)}</div>
          </div>
        );
      },
    },
    {
      title: "グラフ",
      dataIndex: "stock_series",
      width: 250,
      render: (text, record) => {
        return (
          <div className='' style={{ width: 250 }}>
            <StockLineChart
              data={record.stock_series}
              duration={90}
            />
          </div>
        );
      },
    },
  ];

  return (
    <Drawer
      title="FBA販売者情報"
      placement={"left"}
      closable={true}
      onClose={onClose}
      open={open}
      width={750}
    >
      <div>
        {loading && (
          <div className="page-loader">
            <h2>データ処理中...</h2>
          </div>
        )}

        <div className='text-end mb-3'>
          <Checkbox checked={showCount} onChange={(e) => setShowCount(e.target.checked)}>
            Counts
          </Checkbox>

          <Checkbox className='ms-4' checked={fbaOnly} onChange={(e) => setFbaOnly(e.target.checked)}>
            FBA Offers Only
          </Checkbox>
        </div>

        <Table
          className="table dataTable"
          columns={columns}
          dataSource={filteredSellerInfos}
          rowKey={(record) => record.sellerid}
          pagination={false}
        />

      </div>
    </Drawer>
  );
}
