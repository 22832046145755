/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import {
  Tabs
} from "antd";

import Research from "./research";
import FbaManageList from "./fba-manage/FbaManageList";
import PrepareDeliveryList from "./delivery-product/deliveryProduct";
import DeliveryLog from "./delivery-log/deliveryLog";
import AllLog from "./delivery-all-log";
import { useSearchParams } from "react-router-dom";

const { TabPane } = Tabs;

const DeliveryManagement = () => {
  // const [searchParams, setSearchParams] = useSearchParams();

  const researchRef = useRef();
  const fbaManageRef = useRef();
  const deliveryRef = useRef();
  const deliveryLogRef = useRef();
  const allLogRef = useRef();

  const [activeTab, setActiveTab] = useState(1);

  // useEffect(() => {
  //   let tab = searchParams.get("tab");
  //   if (!tab) { 
  //     tab = 1;
  //   }
  //   setActiveTab(tab);
  // }, [searchParams]);

  const handleChangeTab = (key) => {
    setActiveTab(key);
  }

  const handleClickTab = (tab) => {
    if (tab == 1) {
      if (researchRef.current)
        researchRef.current.initFunc();
    } else if (tab == 2) {
      if (fbaManageRef.current)
        fbaManageRef.current.initFunc();
    } else if (tab == 3) {
      if (deliveryRef.current)
        deliveryRef.current.initFunc();
    } else if (tab == 4) {
      if (deliveryLogRef.current)
        deliveryLogRef.current.initFunc();
    } if (tab == 5) {
      if (allLogRef.current)
        allLogRef.current.initFunc();
    }
    // setSearchParams({"tab": tab});
  }

  // useEffect(() => {
  //   if (activeTab == 1) {
  //     if (researchRef.current)
  //       researchRef.current.initFunc();
  //   } else if (activeTab == 2) {
  //     if (fbaManageRef.current)
  //       fbaManageRef.current.initFunc();
  //   } else if (activeTab == 3) {
  //     if (deliveryRef.current)
  //       deliveryRef.current.initFunc();
  //   } else if (activeTab == 4) {
  //     if (deliveryLogRef.current)
  //       deliveryLogRef.current.initFunc();
  //   }  if (activeTab == 5) {
  //     if (allLogRef.current)
  //       allLogRef.current.initFunc();
  //   }
  //   setSearchParams({"tab": activeTab});
  // }, [activeTab])

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="card table-list-card">
          <div className="card-body" style={{ padding: "10px" }}>
            <Tabs defaultActiveKey="1" type="card" onChange={handleChangeTab} onTabClick={handleClickTab} destroyInactiveTabPane={true}>
              <TabPane tab={<span>情報調査</span>} key="1">
                <Research ref={researchRef} />
              </TabPane>
              <TabPane tab={<span>納品準備</span>} key="2">
                <FbaManageList ref={fbaManageRef} />
              </TabPane>
              <TabPane tab={<span>プラン作成</span>} key="3">
                <PrepareDeliveryList ref={deliveryRef} />
              </TabPane>
              <TabPane tab={<span>納品ログ</span>} key="4">
                <DeliveryLog ref={deliveryLogRef} />
              </TabPane>
              <TabPane tab={<span>納品総合ログ</span>} key="5">
                <AllLog ref={allLogRef} />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryManagement;
