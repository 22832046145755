/* eslint-disable */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { createPackageWay, deletePackageWay, getFnskuList, getPackageWay, updatePackageWay } from "../../services";
import { useAuth } from "../../hooks/useAuth";
import { Button, Input, Modal, Table } from "antd";
import { FaCheck, FaPlus } from "react-icons/fa6";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import * as utils from "../../utils/util";
import { useSelector } from "react-redux";

const FnskuTab = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        initFunc,
    }));

    const active_seller = useSelector((state) => state.active_seller);

    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [contentData, setContentData] = useState();
    const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });
    const [totalItems, setTotalItems] = useState(0);
    const [searchText, setSearchText] = useState("");

    const columns = [
        {
            title: 'asin',
            dataIndex: 'asin',
            key: 'asin',
        },
        {
            title: 'sellername',
            dataIndex: 'sellername',
            key: 'sellername',
        },
        {
            title: 'condition',
            dataIndex: 'itemcondition',
            key: 'condition',
        },
        {
            title: 'fnsku',
            dataIndex: 'fnsku',
            key: 'fnsku',
        },
    ]

    const initFunc = () => {
        getData();
    }

    useEffect(() => {
        getData(1);
    }, [active_seller]);

    useEffect(() => {
        getData(1);
    }, [pagination.pageSize])

    const getData = async (page = 1) => {
        setLoading(true);
        const result = await getFnskuList({
            page: page - 1,
            count: pagination.pageSize,
            keyword: searchText,
            sellername: (active_seller != "すべてのアカウント") ? active_seller : "",
            user
        });
        if (result.data && result.data.length > 0) {
            let pages = Math.ceil(result.data[0]["full_count"] / pagination.pageSize);
            setTotalItems(result.data[0]["full_count"]);
            setContentData(result.data);
            setPagination(prev => ({
                ...prev,
                current: page
            }))
        } else {
            setContentData([]);
            setTotalItems(0);
            setPagination(prev => ({
                ...prev,
                current: 1
            }))
        }
        setLoading(false);
    }

    const handleTableChange = (pagination) => {
        getData(pagination.current);
        setPagination(pagination);
    };

    return (
        <>
            {loading && (
                <div id="global-loader">
                    <h2>データ処理中...</h2>
                </div>
            )}
            <div style={{ width: "100%" }}>
                <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", marginBottom: "5px" }}>
                    <h4>FNSKU 表</h4>

                    <input
                        value={searchText}
                        type="text"
                        placeholder="Search"
                        className="form-control form-control-sm formsearch"
                        style={{ width: 200 }}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                getData(1);
                            }
                        }}
                    />
                </div>
                <Table bordered
                    size="small"
                    dataSource={contentData}
                    columns={columns}
                    onChange={handleTableChange}
                    pagination={{
                        ...pagination,
                        total: totalItems,
                    }}
                />
            </div>
        </>
    )
});

FnskuTab.displayName = "FnskuTab";

export default FnskuTab;