import React, { useEffect, useRef, useState } from "react";
import { Tabs } from "antd";
import ItemsSale from "./sale/SalesList";
import PreparePurchase from "./purchase/PurchaseList";

const { TabPane } = Tabs;

const ItemsSaleManagement = () => {
    const itemSaleRef = useRef();
    const purchaseRef = useRef();

    const [activeTab, setActiveTab] = useState("");
    const handleChangeTab = (key) => {
        setActiveTab(key);
    }

    useEffect(() => {
        if (activeTab == 1) {
            if (itemSaleRef.current)
                itemSaleRef.current.initFunc();
        } else if (activeTab == 2) {
            if (purchaseRef.current)
                purchaseRef.current.initFunc();
        }
    }, [activeTab])

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="card table-list-card">
                    <div className="card-body" style={{ padding: "10px" }}>
                        <Tabs defaultActiveKey="1" type="card" onChange={handleChangeTab}>
                            <TabPane tab={<span>出品中商品</span>} key="1">
                                <ItemsSale ref={itemSaleRef} />
                            </TabPane>
                            <TabPane tab={<span>仕入準備</span>} key="2">
                                <PreparePurchase ref={purchaseRef} />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ItemsSaleManagement;