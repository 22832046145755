import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
// import { Input, Select } from "antd";
import { getExchangeData } from "../../services";
import { useAuth } from "../../hooks/useAuth";
import { Input } from "antd";

const ExchangeTab = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        initFunc,
    }));

    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [updatedAt, setUpdatedDate] = useState('');

    const initFunc = () => {
        getData();
    }

    useEffect(() => {
        initFunc();
    }, [])

    const getData = async () => {
        try {
            setLoading(true);
            const result = await getExchangeData({ user });
            setData(result.data);
            if (result.updatedat) {
                const updateDate = new Date(Number(result.updatedat));
                const year = updateDate.getFullYear();
                const month = String(updateDate.getMonth() + 1).padStart(2, '0');
                const day = String(updateDate.getDate()).padStart(2, '0');
                const hours = String(updateDate.getHours()).padStart(2, '0');
                const minutes = String(updateDate.getMinutes()).padStart(2, '0');
                const seconds = String(updateDate.getSeconds()).padStart(2, '0');
                setUpdatedDate(`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`);
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            {loading && (
                <div id="global-loader">
                    <h2>データ処理中...</h2>
                </div>
            )}
            <div style={{
                height: "52px",
                background: "#d9edf7",
                color: "#3a87ad",
                border: "1px solid #bce8f1",
                padding: "0 20px",
                width: "100%", display: "flex",
                justifyContent: "space-between", alignItems: "center"
            }}>
                <span>現在の為替レート情報が表示されます。</span>
                <span>更新時間: {updatedAt}</span>
            </div>
            <div style={{ width: "500px", margin: "0 auto" }}>
                {
                    data && data.map((item, key) => {
                        const price = Number(item.rate_price).toFixed(4)
                        if (item.name == "USDJPY")
                            return (
                                <div key={key} className="row align-items-center mt-3">
                                    <label className="col-md-2">{item.name}</label>
                                    <Input className="col-md-10" suffix="円" readOnly value={price} size="large" />
                                </div>
                            )
                    })
                }
            </div>
        </>
    )
});

ExchangeTab.displayName = "exchangeTab";

export default ExchangeTab;