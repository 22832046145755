/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { FaMagnifyingGlass } from 'react-icons/fa6';

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Table, Input, Select,
  Pagination,
} from "antd";
import {
  getHistoryList,
  isTokenErrorResponse,
} from "../../services";
import { useAuth } from "../../hooks/useAuth";
import * as constants from "../../utils/constants";

let curKeyword = null;

const HistoryList = () => {
  const { user, logout } = useAuth();

  const active_seller = useSelector((state) => state.active_seller);

  const [loading, setLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [keyword, setKeyword] = useState(searchParams.get("search"));

  const [dataSource, setDataSource] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });
  const [jumprow, setJumpRow] = useState("");


  const handleSearch = (e) => {
    if (e == null || e.key === "Enter") {
      if (keyword == "") {
        filterValue.filter_keyword = "";
      }
      setJumpRow(0);
      setPagination({ current: 1, pageSize: pagination.pageSize });
      getResearchList();
      setKeyword("");
    }
  };

  const handleJumpRow = (e) => {
    if (e.key === "Enter") {
      setPagination({
        current: parseInt(jumprow),
        pageSize: pagination.pageSize,
      });
      setJumpRow("");
      getResearchList(parseInt(jumprow));
    }
  };


  const getResearchList = async (page = 1) => {
    try {
      let seller = "";
      if (active_seller != "すべてのアカウント") seller = active_seller;
      // setLoading(true);

      let search = curKeyword;
      if (curKeyword == null) {
        search = keyword || "";
      }

      const result = await getHistoryList({
        page: page - 1,
        count: pagination.pageSize,
        user: user,
        keyword: search,
        jobname: (searchParams.get("jobname") ?? "")
      });
      if (isTokenErrorResponse(result)) {
        return logout();
      }

      if (result.data && result.data.length > 0) {
        setDataSource(result.data);
        let pages = Math.ceil(parseInt(result.data[0]["full_count"]) / pagination.pageSize);
        setPageCount(pages);
        setTotalItems(result.data[0]["full_count"]);
      } else {
        setDataSource([]);
        setTotalItems(0);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handlePageChange = (page, pageSize) => {
    setPagination({
      current: page,
      pageSize: pageSize
    });
    getResearchList(page);
  };

  const handlePageSizeChange = (pagesize) => {
    pagination.pageSize = pagesize;
    getResearchList();
  };

  useEffect(() => {
    document.title = "記録管理";
    getResearchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_seller]);


  useEffect(() => {
    const loadData = () => {
      getResearchList(pagination.current);
    };

    const interval = setInterval(() => {
      loadData();
    }, 3000);

    // Cleanup function to clear the interval
    return () => clearInterval(interval);

  }, [pagination])

  const columns = [
    {
      title: <span>アクション名</span>,
      dataIndex: "jobname",
      render: (text, record, index) => {
        var jobname = "";
        if (record.jobname == "calculate_profit_research_asin") jobname = "利益計算";
        else if (record.jobname == "calculate_profit_asin") jobname = "利益計算";
        else if (record.jobname == "calculate_profit_bulk_research_page") jobname = "一括利益計算";
        else if (record.jobname == "calculate_profit_bulk_listing_page") jobname = "一括利益計算";
        else if (record.jobname == "calculate_profit_purchase_asin") jobname = "利益計算";
        else if (record.jobname == "calculate_profit_shipplan") jobname = "利益計算";
        else if (record.jobname == "calculate_profit_listing_log_bulk_asin") jobname = "一括利益計算";
        else if (record.jobname == "calculate_profit_listing_log_asin") jobname = "利益計算";
        else if (record.jobname == "update_price_stock_asin") jobname = "価格更新";
        else if (record.jobname == "listing_research_asin") jobname = "個別出品";
        else if (record.jobname == "auto_active_listing_inventory_report") jobname = "Active Inventory Report";
        else if (record.jobname == "auto_fba_inventory_report") jobname = "FBA Inventory Report";
        else if (record.jobname == "auto_restock_inventory_recommendation_report") jobname = "Restock Inventory Recommendation Report";
        else if (record.jobname == "upload_lowestprice_research_asin") jobname = "最低価格をアップロード";
        else if (record.jobname == "upload_file_fba_stock_wait_asin") jobname = "入庫待ちASINアップロード";
        else if (record.jobname.startsWith("get_restock_inventory_data_from_google")) jobname = "在庫補充を取得";
        else if (record.jobname == "fba_shipping_plan_output_weeea2") jobname = "①WE Shipping Plan出力";
        else if (record.jobname == "fba_shipping_plan_output_kt") jobname = "②KT Shipping Plan出力";
        else if (record.jobname == "fba_shipping_plan_output_njoy") jobname = "③NJ Shipping Plan出力";
        else if (record.jobname == "fba_shipping_plan_output_fh") jobname = "④FH Shipping Plan出力";
        else if (record.jobname == "fba_label_print") jobname = "ラベル印刷";
        else if (record.jobname == "upload_sellerprice_research_asin") jobname = "問屋価格をアップロードする";
        else if (record.jobname == "auto_update_price_stock_bulk_asin") jobname = "自動価格改定";
        else if (record.jobname == "fba_ship_plan_log_product_excel_download") jobname = "納品ログ";
        else if (record.jobname == "auto_order_report") jobname = "FBA 注文 Report";
        else if (record.jobname == "bulk_listing_research_asin") jobname = "一括FBA商品出品";

        return (
          <span>
            {jobname}
          </span>
        );
      },
    },
    {
      title: <span>適用対象</span>,
      dataIndex: "jobvalue",
      render: (text, record, index) => {
        const jsonData = JSON.parse(record.jobvalue);
        const { name, value, condition, sellername } = jsonData;
        var jobname = "";
        if (record.jobname == "calculate_profit_research_asin") jobname = `FBA商品情報調査(${value}, ${condition})`;
        else if (record.jobname == "calculate_profit_bulk_research_page") jobname = `FBA商品情報調査(${sellername})`;
        else if (record.jobname == "calculate_profit_bulk_listing_page") jobname = `出品中商品(${sellername})`;
        else if (record.jobname == "calculate_profit_asin") jobname = `出品中商品(${value}, ${condition}, ${sellername})`;
        else if (record.jobname == "calculate_profit_purchase_asin") jobname = `製品準備(${value}, ${condition}, ${sellername})`;
        else if (record.jobname == "calculate_profit_shipplan") jobname = `納品ログ(${value}, ${jsonData.shipplanname ? jsonData.shipplanname : ""})`;
        else if (record.jobname == "calculate_profit_listing_log_bulk_asin") jobname = `FBA納品ログ(${value}, ${jsonData.shipplanid})`;
        else if (record.jobname == "calculate_profit_listing_log_asin") jobname = `FBA納品ログ(${value}, ${condition}, ${sellername}, ${jsonData.shipplanid})`;
        else if (record.jobname == "update_price_stock_asin") jobname = `出品中商品(${value}, ${condition ? condition + "," : ""} ${sellername})`;
        else if (record.jobname == "listing_research_asin") jobname = `FBA商品情報調査(${value}, ${condition}, ${sellername})`;
        else if (record.jobname == "auto_active_listing_inventory_report") jobname = `アクティブ在庫同期(${sellername})`;
        else if (record.jobname == "auto_fba_inventory_report") jobname = `FBA在庫同期(${sellername || ''})`;
        else if (record.jobname == "auto_restock_inventory_recommendation_report") jobname = `在庫補充推奨同期(${sellername})`;
        else if (record.jobname == "upload_lowestprice_research_asin") jobname = `FBA商品情報調査(${(value == 'com' || value == 'us') ? 'amazon.com' : value == 'jp' ? 'amazon.co.jp' : ''})`;
        else if (record.jobname == "upload_file_fba_stock_wait_asin") jobname = `入庫待ち(${value}, 数える:${jsonData.count})`;
        else if (record.jobname.startsWith("get_restock_inventory_data_from_google")) jobname = `Google スプレッドシートから(${sellername})`;
        else if (record.jobname == "fba_shipping_plan_output_weeea2") jobname = `FBA商品納品準備(weeea2)`;
        else if (record.jobname == "fba_shipping_plan_output_kt") jobname = `FBA商品納品準備(kt.japan)`;
        else if (record.jobname == "fba_shipping_plan_output_njoy") jobname = `FBA商品納品準備(njoy)`;
        else if (record.jobname == "fba_shipping_plan_output_fh") jobname = `FBA商品納品準備(fhjiyama)`;
        else if (record.jobname == "fba_label_print") jobname = `FBA 製品準備(fnsku:${jsonData.fnsku}, asin:${jsonData.asin}, ラベル枚数:${jsonData.stock})`;
        else if (record.jobname == "upload_sellerprice_research_asin") jobname = `FBA商品情報調査`;
        else if (record.jobname == "auto_update_price_stock_bulk_asin") jobname = `FBA商品出品(${sellername})`;
        else if (record.jobname == "fba_ship_plan_log_product_excel_download") jobname = `Excelダウンロード(${value})`;
        else if (record.jobname == "auto_order_report") jobname = `注文表示(${sellername})`;
        else if (record.jobname == "bulk_listing_research_asin") jobname = `FBA商品情報調査(${value})(${sellername})`;

        return (
          <span style={{ whiteSpace: "normal", wordBreak: "break-all" }}>
            {jobname}
          </span>
        );
      },
    },
    {
      title: (
        <span>要求登録時間</span>
      ),
      dataIndex: "createdat",
      width: 150,
      render: (text, record) => {
        let originalDate = new Date(record.createdAt);
        if (originalDate) {
          originalDate.setTime(originalDate.getTime() + 9 * 3600 * 1000);
        }
        const formattedDate = originalDate.toISOString().replace(/T/, ' ').replace(/\..+/, '');
        return (
          <span>
            {formattedDate}
          </span>
        );
      },
    },
    {
      title: (
        <span>メッセージ</span>
      ),
      dataIndex: "progress",
      width: 120,
      render: (text, record) => {
        return (
          <span>
            {record.progress}
          </span>
        );
      },
    },
    {
      title: (
        <span>進捗状況</span>
      ),
      dataIndex: "percent",
      width: 120,
      render: (text, record, index) => (
        <span>
          {record.percent}
        </span>
      ),
    },
  ];

  return (
    <>
      {loading && (
        <div id="global-loader">
          <h2>データ処理中...</h2>
        </div>
      )}
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>記録管理</h4>
              </div>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      value={keyword}
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      onChange={(e) => { setKeyword(e.target.value); curKeyword = e.target.value; }}
                      onKeyDown={handleSearch}
                    />
                    <button
                      type="button"
                      onClick={() => handleSearch(null)}
                      className="btn btn-searchset"
                    >
                      <FaMagnifyingGlass />
                    </button>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="form-sort">
                    <Select
                      className="select"
                      options={constants.PageSizeOptions}
                      placeholder="100"
                      onChange={handlePageSizeChange}
                    />
                  </div>
                  <span style={{ whiteSpace: "nowrap", marginLeft: "10px" }}>
                    件ずつ表示
                  </span>
                </div>
              </div>
              <div className="table-responsive">
                <div className="d-flex justify-content-between gap-3">
                  <div className="search-set">
                    <div className="search-input" style={{ display: "flex" }}>
                      <input
                        value={jumprow}
                        type="number"
                        placeholder="に行く..."
                        className="form-control form-control-sm formsearch"
                        onChange={(e) => setJumpRow(e.target.value)}
                        onKeyDown={handleJumpRow}
                      />
                      <span style={{ margin: "10px", width: "60%" }}>
                        {" "}
                        of {pageCount} pages
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-end flex-grow-1">
                    <Pagination current={pagination.current} pageSize={pagination.pageSize} showSizeChanger={false} total={totalItems} onChange={handlePageChange} />
                  </div>
                  <div className="text-end mt-2">
                    検索結果 : {totalItems} 件中 {(pagination.current - 1) * pagination.pageSize + 1}-
                    {Math.min(pagination.current * pagination.pageSize, totalItems)} 件
                  </div>
                </div>
                <br />
                <div>
                  <Table bordered
                    className="table datanew dataTable no-footer"
                    // rowSelection={rowSelection}
                    columns={columns}
                    dataSource={dataSource}
                    rowKey={(record) => record.id}
                    pagination={false}
                    onChange={handlePageChange}
                    scroll={{ y: "calc(100vh - 400px)" }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>
    </>
  );
};

export default HistoryList;
