/* eslint-disable */
import React, { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import RankingGraph from "../../core/components/RankingGraph";

const RankingGraphPopover = ({ asin, data }) => {

  const { user } = useAuth();
  const [loading, setLoading] = useState(false);

  return (
    <div className="row" style={{ width: 1200 }}>
      {loading && (
        <div className="page-loader">
          <h2>データ処理中...</h2>
        </div>
      )}
      <div className="col-sm-12">
        <RankingGraph 
          user={user}
          asin={asin}
          data={data}
          height={500}
          setLoading={setLoading}
        />
      </div>
    </div>
  );
};
export default RankingGraphPopover;
