/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { FiActivity } from "react-icons/fi";
import { FaCalculator, FaMagnifyingGlass, FaPencil, FaTrashCan } from "react-icons/fa6";
import { useSelector } from "react-redux";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { all_routes } from "../../../Router/all_routes";
import {
  InputNumber,
  Table,
  Select,
  Popover,
} from "antd";
import {
  sendJobStatus,
  timeout,
  CreateJob,
  downloadShipPlanLogList,
  getfbaLog,
  updateAllLog,
  deleteAllLog,
  updateAllLogUsPriceLogToSale,
  updateAllLogUsPriceSaleToLog,
  isTokenErrorResponse,
} from "../../../services";
import { useAuth } from "../../../hooks/useAuth";
import * as utils from "../../../utils/util";
import * as constants from "../../../utils/constants";


const initialData = {
  topFilter: "fba_package_ready_all",
  topAction: "none",
  order_by_value: "",
  order_by_way: 0, // 0 or 1
  filter_multi_asins: "",
  filter_us_min_rank: "",
  filter_us_max_rank: "",
  filter_us_min_weight: "",
  filter_us_max_weight: "",
  filter_us_min_price: "",
  filter_us_max_price: "",
  filter_jp_purchase_min_price: "",
  filter_jp_purchase_max_price: "",
  filter_jp_seller_min_price: "",
  filter_jp_seller_max_price: "",
  filter_min_profit_amount: "",
  filter_max_profit_amount: "",
  filter_min_profit_percent: "",
  filter_max_profit_percent: "",
  filter_product_type1_list: "",
  filter_us_category_list: "",
  filter_purchase_place: "",
  filter_ship_place: "",
  filter_employee_name: "",
  sales_count_not0: false,
  ship_plan_id: "",

  filter_start_order_date: "",
  filter_end_order_date: "",
  filter_start_purchase_date: "",
  filter_end_purchase_date: "",
  filter_keyword: "",
};

const topFilterOptions = [
  { value: "fba_package_ready_all", label: "全体" },
  { value: "is_new", label: "新品" },
  { value: "is_used", label: "中古" },
  { value: "FBA_is_SH", label: "FBA Small and Light商品" },
];

const topActionOptions = [
  { value: "none", label: "-- なし --" },
  { value: "calculate_profit_listing_log_bulk_asin", label: "自動利益額計算" },
  { value: "fba_ship_plan_log_product_excel_download", label: "Excelダウンロード", },

  // { value: "change_all_usprice_to_lowest", label: "米国最安価格に変更", },
  { value: "update_all_usprice_sale_to_log", label: "出品中商品 => 納品ログ 価格更新", },
  { value: "update_all_usprice_log_to_sale", label: "納品ログ => 出品中商品 価格更新", },
];

const FBA_Log = () => {
  const location = useLocation();
  const { user, logout } = useAuth();
  const tableRef = useRef(null);

  const queryParams = new URLSearchParams(location.search);

  const [headerWidths, setHeaderWidths] = useState([]);
  const [isHeaderFixed, setHeaderFixed] = useState(false);

  const active_seller = useSelector((state) => state.active_seller);
  const store_list = useSelector((state) => state.store_list);

  const route = all_routes;

  const [loading, setLoading] = useState(false);

  const [keyword, setKeyword] = useState("");
  const [filterValue, setFilterValue] = useState(initialData);

  const [title_ship_plan_id, setTitleShipPlanId] = useState("");

  const [dataSource, setDataSource] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });
  const [jumprow, setJumpRow] = useState("");

  const [editASINIndex, setEditASINIndex] = useState(-1);

  const [name, setName] = useState("");
  const [fba_amazon_ship_id, setfba_amazon_ship_id] = useState("");
  const [fba_amazon_reference_id, setfba_amazon_reference_id] = useState("");
  const [tracking_id, settracking_id] = useState("");
  const [fedex_tracking_id, setfedex_tracking_id] = useState("");
  const [ups_track_number, setups_track_number] = useState("");
  const [promotion_percent, setpromotion_percent] = useState("");
  const [purchase_price, setpurchase_price] = useState("");
  const [tariff_price, settariff_price] = useState("");
  const [ship_price, setship_price] = useState("");
  const [internal_ship_price, setinternal_ship_price] = useState("");
  const [weight_ship_price, setweight_ship_price] = useState("");
  const [package_ship_price, setpackage_ship_price] = useState("");
  const [package_fee_price, setpackage_fee_price] = useState("");
  const [amazon_fee_price, setamazon_fee_price] = useState("");
  const [created_date, setcreated_date] = useState("");
  const [package_weight, setpackage_weight] = useState("");
  const [all_weight, setall_weight] = useState("");


  const handleSearch = (e) => {
    if (e == null || e.key === "Enter") {
      if (keyword == "") {
        filterValue.filter_keyword = "";
      }
      setJumpRow(0);
      setPagination({ current: 1, pageSize: pagination.pageSize });
      getResearchList();
      setKeyword("");
    }
  };

  const handleJumpRow = (e) => {
    if (e.key === "Enter") {
      setPagination({
        current: parseInt(jumprow),
        pageSize: pagination.pageSize,
      });
      setJumpRow("");
    }
  };
  const getResearchList = async () => {
    try {
      let seller = "";
      if (active_seller != "すべてのアカウント") seller = active_seller;
      setLoading(true);

      let filter = queryParams.get("ship_plan_id") ? { ...filterValue, ship_plan_id: queryParams.get("ship_plan_id") } : { ...filterValue };
      filter = queryParams.get("asin") ? { ...filter, asin: queryParams.get("asin") } : { ...filter };

      const result = await getfbaLog({
        page: pagination.current - 1,
        count: pagination.pageSize,
        user: user,
        keyword: filterValue.filter_keyword,
        seller: seller,
        filterValue: filter,
      });
      if (isTokenErrorResponse(result)) {
        return logout();
      }

      if (result.data && result.data.length > 0) {
        setDataSource(result.data);
        let pages =
          parseInt(result.data[0]["full_count"]) / pagination.pageSize + 1;
        setPageCount(pages.toFixed(0));
        setTotalItems(result.data[0]["full_count"]);
      } else {
        setDataSource([]);
        setTotalItems(0);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const onInputChange = (key, index) => (value) => {
    const newData = [...dataSource];

    if (key == "memo" || key == "memo1") {
      newData[index][key] = value.target.value;
    } else {
      newData[index][key] = value;
    }

    setDataSource(newData);
  };

  const handlePageSizeChange = (pagesize) => {
    pagination.pageSize = pagesize;
    getResearchList();
  };

  const checkProductStatus = async (asin) => {
    window.open(`${route.productdetails}?asin=${asin}`, "_blank", "noreferrer");
  };

  useEffect(() => {
    document.title = "納品ログ";
    setTitleShipPlanId(queryParams.get('ship_plan_name'));
    getResearchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, active_seller]);

  const onEditModalInputChange = (key) => (value) => {
    const newData = [...dataSource];
    let index = editASINIndex;
    switch (key) {
      case "us_sell_price":
        setEditus_sell_price(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "name":
        setname(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "fba_amazon_ship_id":
        setfba_amazon_ship_id(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "fba_amazon_reference_id":
        setfba_amazon_reference_id(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "tracking_id":
        settracking_id(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "fedex_tracking_id":
        setfedex_tracking_id(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "ups_track_number":
        setups_track_number(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "promotion_percent":
        setpromotion_percent(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "purchase_price":
        setpurchase_price(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "tariff_price":
        settariff_price(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "ship_price":
        setship_price(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "internal_ship_price":
        setinternal_ship_price(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "weight_ship_price":
        setweight_ship_price(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "package_ship_price":
        setpackage_ship_price(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "package_fee_price":
        setpackage_fee_price(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "amazon_fee_price":
        setamazon_fee_price(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "created_date":
        setcreated_date(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "package_weight":
        setpackage_weight(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "all_weight":
        setall_weight(value.target.value);
        newData[index][key] = value.target.value;
        break;
    }
    setDataSource(newData);
  }

  const handleUpdateProduct = (index) => async () => {
    try {
      setLoading(true);
      const props = dataSource[index];
      props.user = user;

      await updateAllLog(props);
      getResearchList();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  async function handleJobStatus(id) {
    const result = await sendJobStatus({ id: id, user: user });
    console.log(result);
    if (parseInt(result.data.status) > 0) {
      return true;
    }
    return false;
  }
  const handleCalculate = (index) => async () => {
    try {
      setLoading(true);
      const product = dataSource[index];
      console.log("product", product);
      const result = await CreateJob({
        jobname: "calculate_profit_listing_log_asin",
        jobvalue: {
          name: "asin",
          value: product.asin,
          condition: product.itemcondition,
          sellername: product.sellername,
          shipplanid: queryParams.get("ship_plan_id")
        },
        user: user,
      });
      console.log("calculate response", result);
      if (result.data.id) {
        let id = result.data.id;
        let res = await handleJobStatus(id);
        while (!res) {
          timeout(1000);
          res = await handleJobStatus(id);
        }
        getResearchList();
      }

      //result.data == 1 :  success
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleProductEdit = (index) => async () => {
    try {
      var product = dataSource[index];
      setEditASINIndex(index);
      setName(product.name)
      setfba_amazon_ship_id(product.fba_amazon_ship_id)
      setfba_amazon_reference_id(product.fba_amazon_reference_id)
      settracking_id(product.tracking_id)
      setfedex_tracking_id(product.fedex_tracking_id)
      setups_track_number(product.ups_track_number)
      setpromotion_percent(product.promotion_percent)
      setpurchase_price(product.purchase_price)
      settariff_price(product.tariff_price)
      setship_price(product.ship_price)
      setinternal_ship_price(product.internal_ship_price)
      setweight_ship_price(product.weight_ship_price)
      setpackage_ship_price(product.package_ship_price)
      setpackage_fee_price(product.package_fee_price)
      setcreated_date(product.created_date)
      setpackage_weight(product.package_weight)
      setall_weight(product.all_weight)
      setamazon_fee_price(product.amazon_fee_price);
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      title: (
        <span>商品画像</span>
      ),
      dataIndex: "image",
      width: 100,
      render: (text, record, index) => {

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Link
              to={utils.getAmazonUrl(record.asin)}
              className="product-img stock-img"
              target="_blank"
            >
              <ImageWithBasePath
                alt=""
                src={text ?? "assets/img/products/product1.jpg"}
                isProduct={text ? true : false}
                width={80}
                height={80}
              />
            </Link>
            <div>{record.jan3}</div>
          </div>
        );
      },
    },
    {
      title: "商品名",
      dataIndex: "productname",
      width: 200,
      render: (text, record, index) => {
        return (
          <span className="inventoryimgname">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Link
                to={utils.getAmazonUrl(record.asin)}
                className="product-title"
                target="_blank"
              >
                {text}
              </Link>
              <Link
                to={utils.getAmazonJpUrl(record.asin)}
                className="product-title"
                target="_blank"
              >
                {record.jp_productname}
              </Link>
            </div>
          </span>
        );
      },
    },
    {
      title: <span>ASIN</span>,
      dataIndex: "asin",
      width: 100,
      render: (text, record) => {
        return (
          <span>
            <Link to={utils.getAmazonJpOfferListingUrl(record.asin, record.itemcondition)} className="product-title" target="_blank">
              {record.asin}
            </Link>
          </span>
        );
      },
    },
    {
      title: <span>FNSKU</span>,
      dataIndex: "fnsku",
      width: 100,
      render: (text, record) => {
        let color = utils.getSellerColor(record.sellername);
        let fnsku = utils.getSellerFNSKU(record, record.sellername);
        return (
          <span style={{ color: color }}>{record.fulfillment_sku}</span>
        );
      },
    },
    {
      title: <span>アカ</span>,
      dataIndex: "seller_name",
      width: 80,
      render: (text, record) => {
        return (
          <div className={`sellermark ${record.sellername}`}>
            {utils.getSellerMark(record.sellername, store_list)}
          </div>
        );
      },
    },
    {
      title: <span>JAN</span>,
      dataIndex: "jan",
      width: 100,
      render: (text, record) => {
        let jansArr = record.jan?.split(",");
        let jans = <span>
          {jansArr?.map((j, idx) => <span key={idx}>{j}<br /></span>)}
        </span>;

        return (
          <div><Popover content={jans}>{jansArr?.[0]}</Popover></div>
        );
      },
    },
    {
      title: <span>数量</span>,
      dataIndex: "purchase_stock",
      width: 100,
      render: (text, record, index) => {
        return (
          <div>
            <InputNumber
              value={record.purchase_stock}
              onPressEnter={handleUpdateProduct(index)}
              onChange={onInputChange("purchase_stock", index)}
            />
          </div>
        );
      },
    },
    {
      title: <span>日本価格</span>,
      dataIndex: "purchase_price",
      width: 100,
      render: (text, record, index) => {
        return (
          <div>
            <InputNumber
              prefix="¥"
              value={record.purchase_price}
              onPressEnter={handleUpdateProduct(index)}
              onChange={onInputChange("purchase_price", index)}
              controls={false}
            /><br />
            <a className="lowest-price d-flex justify-content-between p-2"
              href={utils.getAmazonJpOfferListingUrl(record.asin, record.itemcondition)} target="_blank">
              <span>最安</span><span>¥ {record.jp_price}</span>
            </a>
          </div>
        );
      },
    },
    {
      title: <span>米価格</span>,
      dataIndex: "us_price",
      width: 100,
      render: (text, record, index) => {
        return (
          <div>
            <InputNumber
              prefix="$"
              value={record.us_price}
              onPressEnter={handleUpdateProduct(index)}
              onChange={onInputChange("us_price", index)}
              controls={false}
            /><br />
            <a className="lowest-price d-flex justify-content-between p-2"
              href={utils.getAmazonOfferListingUrl(record.asin, record.itemcondition)} target="_blank">
              <span>最安</span><span>$ {record.us_lowest_price}</span>
            </a>
          </div>
        );
      },
    },
    {
      title: <span>サイズ</span>,
      dataIndex: "product_size",
      width: 80,
      render: (text, record) => {
        var product_type = ((record.product_type == "") || (typeof record.product_type === "undefined")) ? "-" : record.product_type;
        return (
          <span>{product_type}</span>
        );
      },
    },
    {
      title: <span>重量</span>,
      dataIndex: "product_weight",
      width: 100,
      render: (text, record) => {
        var weight = Number(record.weight);
        return (
          <span>{weight}g</span>
        );
      },
    },
    {
      title: <span>利益額</span>,
      dataIndex: "profit",
      width: 100,
      render: (text, record) => {
        return (
          <span>¥ {record.us_prev_profit}</span>
        );
      },
    },
    {
      title: <span>利益率</span>,
      dataIndex: "profit_percent",
      width: 100,
      render: (text, record) => {
        return (
          <span>{record.us_prev_profit_percent} %</span>
        );
      },
    },
    {
      title: "アクション",
      dataIndex: "action",
      width: 100,
      render: (text, record, index) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <a
              className="me-1 p-1"
              onClick={handleCalculate(index)}
              title="利益計算"
            >
              <FaCalculator className="feather-view" />
            </a>
            <a
              className="confirm-text p-1"
              onClick={handleProductEdit(index)}
              data-bs-toggle="modal"
              data-bs-target="#product-edit-modal"
              title="編集"
            >
              <FaPencil className="feather-edit" />
            </a>
          </div>
          <div className="edit-delete-action">
            <a
              className="me-1 p-1"
              onClick={() => checkProductStatus(record.asin)}
              title="詳細"
            >
              <FiActivity className="feather-eye" />
            </a>
            <a
              className="me-1 p-1"
              onClick={() => showConfirmationAlertASINDelete(index)}
              title="削除"
            >
              <FaTrashCan className="feather-trash-2" />
            </a>
          </div>
        </div>
      ),
    },
  ];

  const MySwal = withReactContent(Swal);
  const showConfirmationAlertASINDelete = async (index) => {
    MySwal.fire({
      title: "削除しますか？",
      text: "これを元に戻すことはできません。",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "はい、",
      cancelButtonText: "キャンセル",
      cancelButtonColor: "#ff0000",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const product = dataSource[index];
        setLoading(true);
        const result = await deleteAllLog({
          id: product.id,
          user: user,
        });
        setLoading(false);
        MySwal.fire({
          title: "削除されました！",
          text: "ASINは削除されました。",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
        getResearchList();
      } else {
        MySwal.close();
      }
    });
  };

  const handleTopAction = async () => {
    try {
      
      if (filterValue.topAction == "fba_ship_plan_log_product_excel_download") {
        const shipPlanId = queryParams.get("ship_plan_id");

        let res = await downloadShipPlanLogList({
          user: user,
          ship_plan_id: shipPlanId,
          ship_plan_name: title_ship_plan_id
        });
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        const fileName = `shipplan_${shipPlanId}.xlsx`;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();

        await CreateJob({
          jobname: filterValue.topAction,
          jobvalue: {
            name: "PlanName",
            value: title_ship_plan_id,
            shipplanid: shipPlanId
          },
          user: user,
        });

      } else if (filterValue.topAction == "change_all_usprice_to_lowest") {

        const newData = [...dataSource];
        for (const item of newData) {
          item.us_price = item.us_lowest_price;
        }
        setDataSource(newData);

      } else if (filterValue.topAction == "update_all_usprice_sale_to_log") {

        setLoading(true);

        await updateAllLogUsPriceSaleToLog(queryParams.get("ship_plan_id"), user);
        getResearchList();

        setLoading(false);

      } else if (filterValue.topAction == "update_all_usprice_log_to_sale") {

        setLoading(true);

        await updateAllLogUsPriceLogToSale(queryParams.get("ship_plan_id"), user);
        getResearchList();

        setLoading(false);

      } else if (filterValue.topAction == "calculate_profit_listing_log_bulk_asin") {

        setLoading(true);

        await CreateJob({
          jobname: filterValue.topAction,
          jobvalue: {
            value: title_ship_plan_id,
            shipplanid: queryParams.get("ship_plan_id")
          },
          user: user,
        });

        setLoading(false);

      }


    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };


  useEffect(() => {
    const handleScroll = () => {
      const tableHeader = tableRef.current.querySelector(".ant-table-thead");
      const bodyRows = tableRef.current.querySelectorAll(
        ".ant-table-tbody > tr"
      );
      const tableRect = tableRef.current.getBoundingClientRect();
      const headerRect = tableHeader.getBoundingClientRect();

      const widths = [];
      columns.forEach((col, index) => {
        const bodyCell = bodyRows[0].querySelector(
          `.ant-table-cell:nth-child(${index + 1})`
        );
        widths.push(bodyCell && bodyCell.offsetWidth);
      });

      setHeaderWidths(widths);

      if (tableRect.top < headerRect.height) {
        setHeaderFixed(true);
      } else {
        tableHeader.style.position = "static";
        setHeaderFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {loading && (
        <div id="global-loader">
          <h2>データ処理中...</h2>
        </div>
      )}
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>FBA納品ログ &nbsp;&nbsp;&nbsp;<span style={{ color: "blue", fontSize: "15px" }}>{title_ship_plan_id}</span></h4>
              </div>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      value={keyword}
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      style={{ width: 200 }}
                      onChange={(e) => { 
                        setKeyword(e.target.value);
                        setFilterValue((prev) => ({
                          ...prev,
                          filter_keyword: e.target.value,
                        }))
                      }}
                      onKeyDown={handleSearch}
                    />
                    <button
                      type="button"
                      onClick={() => handleSearch(null)}
                      className="btn btn-searchset"
                    >
                      <FaMagnifyingGlass />
                    </button>
                  </div>
                  <div
                    className="search-input"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    <label style={{ marginRight: "10px" }}>フィルタ</label>
                    <Select
                      className="select"
                      options={topFilterOptions}
                      size="large"
                      style={{ width: "200px" }}
                      value={filterValue.topFilter}
                      onChange={(e) =>
                        setFilterValue((prev) => ({
                          ...prev,
                          topFilter: e,
                        }))
                      }
                    />
                    <button
                      type="button"
                      className="btn btn-primary me-2"
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        filterValue.filter_keyword = "";
                        getResearchList()
                      }}
                    >
                      検索
                    </button>
                  </div>
                  <div
                    className="search-input"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    <label style={{ marginRight: "10px" }}>アクション</label>
                    <Select
                      className="select"
                      options={topActionOptions}
                      size="large"
                      style={{ width: "200px" }}
                      value={filterValue.topAction}
                      onChange={(e) =>
                        setFilterValue((prev) => ({
                          ...prev,
                          topAction: e,
                        }))
                      }
                      dropdownStyle={{ width: 250 }}
                    />
                    <button
                      type="button"
                      className="btn btn-primary me-2"
                      style={{ marginLeft: "10px" }}
                      onClick={handleTopAction}
                    >
                      適用
                    </button>
                  </div>
                </div>
                <div className="search-path">
                  <div className="search-set">
                    <div className="search-input" style={{ display: "flex" }}>
                      <input
                        value={jumprow}
                        type="number"
                        placeholder="に行く..."
                        className="form-control form-control-sm formsearch"
                        onChange={(e) => setJumpRow(e.target.value)}
                        onKeyDown={handleJumpRow}
                      />
                      <span style={{ margin: "10px", width: "60%" }}>
                        {" "}
                        of {pageCount} pages
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <div style={{ display: "flex" }}>
                  <div className="form-sort">
                    <Select
                      className="select"
                      options={constants.PageSizeOptions}
                      placeholder="100"
                      onChange={handlePageSizeChange}
                    />
                  </div>
                  <span
                    style={{
                      width: "15%",
                      marginTop: "10px",
                      marginLeft: "5px",
                    }}
                  >
                    件ずつ表示
                  </span>
                  <div
                    style={{
                      textAlign: "end",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    検索結果 : {totalItems} 件中 {(pagination.current - 1) * pagination.pageSize + 1}-
                    {Math.min(pagination.current * pagination.pageSize, totalItems)} 件
                  </div>
                </div>
                <br />
                {isHeaderFixed && (
                  <div
                    style={{
                      zIndex: 1000,
                      background: "#fff",
                      position: "fixed",
                      top: 0,
                    }}
                  >
                    <table>
                      <thead>
                        <tr>
                          {columns.map((column, index) => (
                            <th
                              key={index}
                              style={{
                                width: headerWidths[index],
                                textAlign: "center",
                                border: "2px solid #f0f0f0",
                                height: 65
                              }}
                            >
                              {column.title}
                            </th>
                          ))}
                        </tr>
                      </thead>
                    </table>
                  </div>
                )}
                <div ref={tableRef}>
                  <Table
                    className="table datanew dataTable no-footer"
                    // rowSelection={rowSelection}
                    columns={columns}
                    dataSource={dataSource}
                    rowKey={(record) => record.id}
                    pagination={{
                      ...pagination,
                      // position: ['topRight', 'bottomRight'],
                      position: ["bottomRight"],
                      total: totalItems,
                      pageSizeOptions: [
                        "100",
                        "150",
                        "200",
                        "300",
                        "500",
                        "1000",
                      ],
                    }}
                    onChange={handleTableChange}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /product list */}

          {/* Product edit modal  */}
          <div
            id="product-edit-modal"
            className="modal fade"
            tabIndex={-1}
            role="dialog"
            aria-hidden="true"
            style={{ display: "none" }}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">プロパティ編集ダイアログ</h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body p-4">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="field-3" className="form-label">
                          Shipping Plan Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="field-3"
                          value={name}
                          onChange={onEditModalInputChange("name")}
                          placeholder="Shipping Plan Name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="field-3" className="form-label">
                          Shipping Plan Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="field-3"
                          placeholder="Shipping Plan Name"
                          value={fba_amazon_ship_id}
                          onChange={onEditModalInputChange("fba_amazon_ship_id")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="field-3" className="form-label">
                          Amazon Referencer ID
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="field-3"
                          placeholder="Amazon Referencer ID"
                          value={fba_amazon_reference_id}
                          onChange={onEditModalInputChange("fba_amazon_reference_id")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="field-3" className="form-label">
                          UGX追跡番号
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="field-3"
                          placeholder="UGX追跡番号"
                          value={tracking_id}
                          onChange={onEditModalInputChange("tracking_id")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="field-3" className="form-label">
                          Fedex追跡番号
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="field-3"
                          placeholder="Fedex追跡番号"
                          value={fedex_tracking_id}
                          onChange={onEditModalInputChange("fedex_tracking_id")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="field-3" className="form-label">
                          UPS追跡番号
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="field-3"
                          placeholder="UPS追跡番号"
                          value={ups_track_number}
                          onChange={onEditModalInputChange("ups_track_number")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="field-3" className="form-label">
                          ギフト券(%)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="field-3"
                          placeholder="ギフト券"
                          value={promotion_percent}
                          onChange={onEditModalInputChange("promotion_percent")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="field-3" className="form-label">
                          仕入れ金額の合計(¥)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="field-3"
                          disabled
                          placeholder="仕入れ金額の合計"
                          value={purchase_price}
                          onChange={onEditModalInputChange("purchase_price")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="field-3" className="form-label">
                          関税(¥)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="field-3"
                          placeholder="関税"
                          value={tariff_price}
                          onChange={onEditModalInputChange("tariff_price")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="field-3" className="form-label">
                          国内送料(¥)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="field-3"
                          placeholder="国内送料"
                          value={ship_price}
                          onChange={onEditModalInputChange("ship_price")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="field-3" className="form-label">
                          国外送料(¥)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="field-3"
                          placeholder="国外送料"
                          value={internal_ship_price}
                          onChange={onEditModalInputChange("internal_ship_price")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="field-3" className="form-label">
                          商品全体重量における送料(¥)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="field-3"
                          placeholder="商品全体重量における送料"
                          value={weight_ship_price}
                          onChange={onEditModalInputChange("weight_ship_price")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="field-3" className="form-label">
                          梱包材の送料(¥)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="field-3"
                          placeholder="梱包材の送料"
                          value={package_ship_price}
                          onChange={onEditModalInputChange("package_ship_price")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="field-3" className="form-label">
                          梱包経費(¥)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="field-3"
                          placeholder="梱包経費"
                          value={package_fee_price}
                          onChange={onEditModalInputChange("package_fee_price")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="field-3" className="form-label">
                          Amazon手数料($)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="field-3"
                          disabled
                          placeholder="Amazon手数料"
                          value={amazon_fee_price}
                          onChange={onEditModalInputChange("amazon_fee_price")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="field-3" className="form-label">
                          販売開始日
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="field-3"
                          placeholder="販売開始日"
                          value={created_date}
                          onChange={onEditModalInputChange("created_date")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="field-3" className="form-label">
                          Shipplan 梱包重量(kg)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="field-3"
                          placeholder="Shipplan 梱包重量"
                          value={package_weight}
                          onChange={onEditModalInputChange("package_weight")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="field-3" className="form-label">
                          Shipplan 全体重量(kg)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="field-3"
                          placeholder="Shipplan 全体重量"
                          value={all_weight}
                          onChange={onEditModalInputChange("all_weight")}
                        />
                      </div>
                    </div>
                  </div>

                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-cancel waves-effect"
                    data-bs-dismiss="modal"
                  >
                    閉じる
                  </button>
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={handleUpdateProduct(editASINIndex)}
                  >
                    保存
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default FBA_Log;
