
export const PageSizeOptions = [
    { value: 100, label: "100" },
    { value: 150, label: "150" },
    { value: 200, label: "200" },
    { value: 300, label: "300" },
    { value: 500, label: "500" },
];


export const OrderTypes = [
    { value: "rank", label: "ランク" }, 
    { value: "pre_profit_amount", label: "利益額" }, 
    { value: "pre_profit_percent", label: "利益率" }, 
    { value: "jp_fba_lowest_price", label: "FBA最低価格" }, 
    { value: "jp_lowest_price", label: "日本最低価格" }, 
    { value: "category_rank_percent", label: "ランク％" }, 
    { value: "purchase_price", label: "手動価格" }, 
    { value: "us_lowest_price", label: "販売価格" }, 

    { value: "sales_count_7", label: "Sales 7日" }, 
    { value: "sales_count_15", label: "Sales 15日" }, 
    { value: "sales_count_30", label: "Sales 30日" }, 
    { value: "sales_count_60", label: "Sales 60日" }, 
    { value: "sales_count_90", label: "Sales 90日" }, 

    { value: "rank_drop_count_30", label: "ランキング降下回数 30日" }, 
    { value: "rank_drop_count_90", label: "ランキング降下回数 90日" }, 
    { value: "rank_drop_count_180", label: "ランキング降下回数 180日" }, 
    { value: "rank_drop_count_365", label: "ランキング降下回数 365日" }, 
];

export const ProductTypes = [
    { value: "", label: "-- なし --" }, 
    { value: "std", label: "standard size" }, 
    { value: "obv", label: "over size" }, 
    { value: "Flats1", label: "Flats1" }, 
    { value: "Parcels", label: "Parcels" }, 
];

export const PurchasePlaces = [
    { value: "", label: "" }, 
    { value: "amazon", label: "amazon" }, 
    { value: "楽天", label: "楽天" }, 
    { value: "Yahoo", label: "Yahoo" }, 
    { value: "丸三", label: "丸三" }, 
    { value: "ワース", label: "ワース" }, 
    { value: "板山", label: "板山" }, 
    { value: "SD", label: "SD" }, 
    { value: "出版", label: "出版" }, 
    { value: "ナスコム", label: "ナスコム" }, 
    { value: "ブンカ", label: "ブンカ" }, 
    { value: "NETSEA", label: "NETSEA" }, 
    { value: "カワダ", label: "カワダ" }, 
    { value: "マッチング", label: "マッチング" }, 
    { value: "無印", label: "無印" }, 
    { value: "サウンドハウス", label: "サウンドハウス" }, 
    { value: "松村商店", label: "松村商店" }, 
    { value: "アケボノ", label: "アケボノ" }, 
    { value: "在庫", label: "在庫" }, 
    { value: "-", label: "-" }, 
];

export const ShipPlaces = [
    { value: "", label: "" }, 
    { value: "浩司アカ", label: "浩司アカ" }, 
    { value: "永野", label: "永野" }, 
    { value: "勝男アカ", label: "勝男アカ" }, 
    { value: "山下", label: "山下" }, 
    { value: "孝子アカ", label: "孝子アカ" }, 
    { value: "メイン", label: "メイン" }, 
    { value: "戸越", label: "戸越" }, 
];

export const ProductTypeOptions = [
    { value: "Default", label: "Default" },
    { value: "STD", label: "STD" },
    { value: "SL", label: "SL" },
];
export const ConditionOptions = [
    { value: "New", label: "New" },
    { value: "Used", label: "Used" },
];
export const RegionOptions = [
    { value: "us", label: "COM" },
    { value: "jp", label: "JP" },
];


export const BoxSizeOptions = [
    { value: "139", width: 49, height: 40, length: 50 },
];

export const MediaTypes = [
    "Books", "DVD", "Music", "Video Game Consoles", "Video Game Accessories categories", "Software & Computer/Video Games", "CDs & Vinyl", "Video Games"
];
export const IgnoreMediaAsins = [
    "B00IJY2Q1K"
];