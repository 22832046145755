import axios from "axios";

// const BASE_URL = "https://amazeengine.com:3000"; // for https server
const BASE_URL = "http://amazeengine.com";       // for http server
// const BASE_URL = "http://localhost:3001";        // for local test server


const isTokenErrorResponse = (response) => {
  if (!response.data) {
    return false;
  }
  if (!response.data.message) {
    return false;
  }
  let msg = response.data.message.toLowerCase();
  if (msg == "authentication token missing" ||
    msg == "wrong authentication token"
  ) {
    return true;
  }
  return false;
}



const loginUser = async (props) => {
  try {
    var result = await axios.post(`${BASE_URL}/login/`, props, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return result;
  } catch (error) {
    return error.response;
  }
};

const signUpUser = async (props) => {
  try {
    var result = await axios.post(`${BASE_URL}/signup/`, props, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const researchASINList = async (props) => {
  const params = {
    page: props.page, count: props.count, sellername: props.seller, search: props.keyword,
    filter: props.filterValue.topFilter, ...props.filterValue,
    filter_multi_asins: props.filterValue?.filter_multi_asins?.trim().replace(/(\s)+/g, ",")
  }
  try {
    var result = await axios.get(
      `${BASE_URL}/fbainventory/get-research-asin-list`,
      {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const fbaProductASINList = async (props) => {
  const params = {
    page: props.page, count: props.count, sellername: props.seller, search: props.keyword,
    filter: props.filterValue.topFilter, ...props.filterValue,
    filter_multi_asins: props.filterValue?.filter_multi_asins?.trim().replace(/(\s)+/g, ",")
  }
  try {
    var result = await axios.get(
      `${BASE_URL}/fbainventory/get-fbainventory-list`,
      {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};


const fbaPurchaseASINList = async (props) => {
  const params = {
    page: props.page, count: props.count, sellername: props.seller, search: props.keyword,
    product_status: props.product_status,
    filter: props.filterValue.topFilter, ...props.filterValue,
    filter_multi_asins: props?.filterValue?.filter_multi_asins?.trim().replace(/(\s)+/g, ",")
  }
  try {
    var result = await axios.get(
      `${BASE_URL}/fbaorder/get_fba_purchase_list`,
      {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};
const addFbaPurchaseProduct = async (sellerName, productStatus, props, user) => {
  try {
    var result = await axios.post(`${BASE_URL}/fbaorder/add_fba_purchase_item?sellername=${sellerName}&product_status=${productStatus}`, props, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response;
  }
}
const copyFbaPurchaseProduct = async (sellerName, productStatus, props, user) => {
  try {
    var result = await axios.post(`${BASE_URL}/fbaorder/copy_fba_purchase_item?sellername=${sellerName}&product_status=${productStatus}`, props, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response;
  }
}
const updateFbaPurchaseProduct = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/fbaorder/update_fba_purchase_item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};
const updateBulkFbaPurchaseProduct = async (data, user) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/fbaorder/update_bulk_fba_purchase_item`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};
const deleteFbaPurchaseProduct = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/fbaorder/delete_fba_purchase_item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}
const deleteBulkFbaPurchaseProduct = async (data, user) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/fbaorder/delete_bulk_fba_purchase_item`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const getProductDetails = async (props) => {
  try {
    var result = await axios.get(
      `${BASE_URL}/fbainventory/get-fbainventory-item-offers?asin=${props.asin}&listingCount=${props.listingCount ? 1 : 0}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const getBulkProductDetails = async (asins, user) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/fbainventory/get-fbainventory-bulk-item-offers`,
      asins,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const getStores = async (props) => {
  try {
    var result = await axios.get(`${BASE_URL}/users/getStores`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.user.token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const updateStores = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/users/update-store`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      });
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const deleteStore = async (props) => {
  try {
    var result = await axios.get(
      `${BASE_URL}/users/delete-store?id=${props.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const updateResearchProduct = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/fbainventory/update-research-asin`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};
const addProduct = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/fbainventory/create-research-asin`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const deleteResearchASIN = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/fbainventory/delete-research-asin`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const deleteBulkResearchASIN = async (data, user) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/fbainventory/delete-bulk-research-asin`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const deleteFBAProductASIN = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/fbainventory/delete-fbainventory`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const CreateJob = async (props) => {
  try {
    var result = await axios.post(`${BASE_URL}/utils/create-job`, props, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.user.token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response;
  }
};
const sendJobStatus = async (props) => {
  try {
    console.log(props);
    var result = await axios.get(
      `${BASE_URL}/utils/get-job_status?id=${props.id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const addFBAProductPreparation = async (sellerName, props, user) => {
  try {
    var result = await axios.post(`${BASE_URL}/fbaorder/add_fba_purchase_ready_item?sellername=${sellerName}`, props, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    });
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const updateFBAProduct = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/fbainventory/update-fbainventory`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const uploadCSVFile = async (file, user) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    var result = await axios.post(
      `${BASE_URL}/fbainventory/add-upload-file-research-asin`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const downloadASINList = async (props) => {

  try {
    var result = await axios.get(
      `${BASE_URL}/fbainventory/download-research-asin-list`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const downloadShipPlanLogList = async (props) => {

  try {
    var result = await axios.post(
      `${BASE_URL}/utils/download-shipplan-log-item`,
      {
        shipplanid: props.ship_plan_id,
        shipplanname: props.ship_plan_name
      },
      {
        responseType: 'arraybuffer',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const uploadCSVFileForLowestPrice = async ({ file, region, user }) => {
  try {
    console.log(file, region, user);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('region', region)
    var result = await axios.post(
      `${BASE_URL}/fbainventory/add-upload-file-lowest-price-asin`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}


const uploadCSVFileForSellerPrice = async ({ file, user }) => {
  try {
    console.log(file, user);
    const formData = new FormData();
    formData.append('file', file);
    var result = await axios.post(
      `${BASE_URL}/fbainventory/add-upload-file-seller-price-asin`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}


const uploadCSVFileForEditAsin = async ({ file, user }) => {
  try {
    console.log(file, user);
    const formData = new FormData();
    formData.append('file', file);
    var result = await axios.post(
      `${BASE_URL}/fbainventory/add-upload-file-edit-asin`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const uploadCSVFileForFbaStockWaitData = async ({ file, user }) => {
  try {
    console.log(file, user);
    const formData = new FormData();
    formData.append('file', file);
    var result = await axios.post(
      `${BASE_URL}/fbaorder/add-upload-file-fba-stock-wait-data`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const getHistoryList = async (props) => {
  try {
    var result = await axios.get(
      // /utils/get-job-list?page=0&count=10&search=480
      `${BASE_URL}/utils/get-job-list?&page=${props.page}&count=${props.count}&search=${props.keyword}&jobname=${props.jobname}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};


const getDeliveryProductList = async (props) => {
  const params = {
    page: props.page, count: props.count, sellername: props.seller, search: props.keyword,
    filter: props.filterValue.topFilter, ...props.filterValue
  };
  try {
    var result = await axios.get(
      `${BASE_URL}/fbaorder/get_fba_list_ready_list`,
      {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const addDeliveryProduct = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/fbaorder/add_fba_list_ready_item?sellername=${props.sellername}`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    // var result = await axios.post(
    //   // `${BASE_URL}/fbaorder/delete_fba_package_complete_item`,
    //   `${BASE_URL}/fbaorder/delete_fba_purchase_item`,
    //   { id: props.id },
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${props.user.token}`,
    //     },
    //   }
    // );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const updateDeliveryProduct = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/fbaorder/update_fba_list_ready_item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const deleteDeliveryProduct = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/fbaorder/delete_fba_list_ready_item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const returnDeliveryProduct = async (props) => {
  try {
    await axios.post(
      `${BASE_URL}/fbaorder/delete_fba_list_ready_item`,
      { id: props.id },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    var result = await axios.post(
      // `${BASE_URL}/fbaorder/add_fba_package_complete_item?sellername=${props.sellername}`,
      // `${BASE_URL}/fbaorder/add_fba_purchase_item?sellername=${props.sellername}&product_status=2`,
      `${BASE_URL}/fbaorder/add_fba_purchase_item_from_list_ready?sellername=${props.sellername}`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );

    return result.data;
  } catch (error) {
    return error.response;
  }
};

const printLabel = async (props) => {
  const { id, fba_sku, labelNumber, product_status, title } = props;
  const label_print_modal_type = product_status;
  const sku = fba_sku;
  const count = labelNumber;

  var url = "/labelprint/barcode128b-complete.php";
  url += "?label_print_modal_type=" + label_print_modal_type;
  url += "&id=" + id;
  url += "&sku=" + sku;
  url += "&count=" + count;
  url += "&title=" + title;
  window.open(url, "printlabel");

  /*
  // Create a form element
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = 'http://150.66.7.227/labelprint/barcode128b-complete.php';
  form.target = '_blank';

  // Append form data
  const formData = {
    label_print_modal_type: label_print_modal_type,
    sku: sku,
    count: count,
    title: title
  };

  Object.keys(formData).forEach(key => {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = formData[key];
    form.appendChild(input);
  });

  // Append form to the body and submit it
  document.body.appendChild(form);
  form.submit();

  // Clean up
  document.body.removeChild(form);
  */
}

const getDeliveryLog = async (props) => {
  const params = {
    page: props.page, count: props.count, sellername: props.seller, search: props.keyword,
    filter: props.filterValue.topFilter, ...props.filterValue
  };
  try {
    var result = await axios.get(
      `${BASE_URL}/fbaorder/get_ship_plan_list`,
      {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    console.log("Delivery Log: ", result.data);
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const addDeliveryLog = async (props) => {
  try {
    await axios.post(
      `${BASE_URL}/fbaorder/add_ship_plan_item?sellername=${props.sellername}`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    var result = await axios.post(
      `${BASE_URL}/fbaorder/delete_fba_list_ready_item`,
      {
        id: props.id,
        user: props.user.token,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const updateDeliveryLog = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/fbaorder/update_ship_plan_item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const deleteDeliveryLog = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/fbaorder/delete_ship_plan_item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const getRestockInventory = async (props) => {
  const params = {
    page: props.page, count: props.count, sellername: props.seller, search: props.keyword,
    filter: props.filterValue.topFilter, ...props.filterValue
  };
  try {
    var result = await axios.get(
      `${BASE_URL}/fbaorder/get_fba_restock_inventory_list`,
      {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const updateRestockInventory = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/fbaorder/update_fba_restock_inventory_item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const deleteRestockInventory = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/fbaorder/delete_fba_restock_inventory_item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const getAllLog = async (props) => {
  const params = {
    page: props.page, count: props.count, sellername: props.seller, search: props.keyword,
    filter: props.filterValue.topFilter, ...props.filterValue
  };
  try {
    var result = await axios.get(
      `${BASE_URL}/fbainventory/get_fba_list_log_list?`,
      {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const addAllLog = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/fbainventory/add_fba_list_log_item?sellername=${props.sellername}`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const updateAllLog = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/fbainventory/update_fba_list_log_item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const updateAllLogUsPriceSaleToLog = async (shipplanid, user) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/utils/update-us-price-shipplan-log-item-from-listing`,
      { shipplanid: shipplanid },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};
const updateAllLogUsPriceLogToSale = async (shipplanid, user) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/utils/update-us-price-listing-from-shipplan-log-item`,
      { shipplanid: shipplanid },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const deleteAllLog = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/fbainventory/delete_fba_list_log_item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const getfbaLog = async (props) => {
  const params = {
    page: props.page, count: props.count, sellername: props.seller, search: props.keyword,
    filter: props.filterValue.topFilter, ...props.filterValue
  };
  try {
    var result = await axios.get(
      `${BASE_URL}/fbainventory/get_fba_asin_list_log_list`,
      {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const getfbaShipplaninfo = async (props) => {
  try {
    var result = await axios.get(
      `${BASE_URL}/utils/get-fba-shipplan-info`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const updatefbaShipplaninfo = async (props) => {
  try {
    await axios.post(
      `${BASE_URL}/utils/update-fba-shipplan-info`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );

    const result = await axios.post(
      `${BASE_URL}/utils/download-shipplan-output`,
      {
        pagetype: "fba_list_ready_all",
        sellername: props.AccountId
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const getCategoryData = async (props) => {
  try {
    var result = await axios.get(
      `${BASE_URL}/utils/get-category-list`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const addCategoryData = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/utils/create-category-item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const updateCategoryData = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/utils/update-category-item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const getExchangeData = async (props) => {
  try {
    var result = await axios.get(
      `${BASE_URL}/utils/get-setting-item?name=JPYRATE`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const updateExchangeData = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/utils/update-setting-item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const listingFbaProductFromShipplanready = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/utils/listing-fba-product-from-shipplanready`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const getEmployee = async (props) => {
  try {
    var result = await axios.get(
      `${BASE_URL}/utils/get-employee-list`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const createEmployee = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/utils/create-employee-item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const updateEmployee = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/utils/update-employee-item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const deleteEmployee = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/utils/delete-employee-item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const getContent = async (props) => {
  try {
    var result = await axios.get(
      `${BASE_URL}/utils/get-content-list`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const createContent = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/utils/create-content-item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const updateContent = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/utils/update-content-item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const deleteContent = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/utils/delete-content-item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const getAutoSetting = async (props) => {
  try {
    var result = await axios.get(
      `${BASE_URL}/utils/get-auto-settings`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const updateAutoSetting = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/utils/update-auto-settings`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const getBanSetting = async (props) => {
  try {
    var result = await axios.get(
      `${BASE_URL}/utils/get-ban-asin-list?page=${props.page}&count=${props.count}&search=${props.keyword}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const createBanSetting = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/utils/create-ban-asin-item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const updateBanSetting = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/utils/update-ban-asin-item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const deleteBanSetting = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/utils/delete-ban-asin-item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const getBanTitleSetting = async (props) => {
  try {
    var result = await axios.get(
      `${BASE_URL}/utils/get-ban-title-list?page=${props.page}&count=${props.count}&search=${props.keyword}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const createBanTitleSetting = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/utils/create-ban-title-item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const updateBanTitleSetting = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/utils/update-ban-title-item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const deleteBanTitleSetting = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/utils/delete-ban-title-item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const getBanManufactureSetting = async (props) => {
  try {
    var result = await axios.get(
      `${BASE_URL}/utils/get-ban-manufacture-list?page=${props.page}&count=${props.count}&search=${props.keyword}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const createBanManufactureSetting = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/utils/create-ban-manufacture-item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const updateBanManufactureSetting = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/utils/update-ban-manufacture-item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const deleteBanManufactureSetting = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/utils/delete-ban-manufacture-item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}



const getPackageWay = async (props) => {
  try {
    var result = await axios.get(
      `${BASE_URL}/utils/get-packageway-list?page=${props.page}&count=${props.count}&search=${props.keyword}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const createPackageWay = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/utils/create-packageway-item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const updatePackageWay = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/utils/update-packageway-item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}

const deletePackageWay = async (props) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/utils/delete-packageway-item`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}


const getFnskuList = async (props) => {
  try {
    var result = await axios.get(
      `${BASE_URL}/utils/get-fnsku-list?page=${props.page}&count=${props.count}&search=${props.keyword}&sellername=${props.sellername}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
}


const getFbaOrderList = async (props) => {
  let orderDateFilter = (props.filterValue.order_date_filter == "fba_custom_day_range") ? "" : props.filterValue.order_date_filter;
  const params = {
    page: props.page, count: props.count, sellername: props.seller, search: props.keyword,
    ...props.filterValue, filter: props.filterValue.topFilter, order_date_filter: orderDateFilter
  };
  try {
    var result = await axios.get(
      `${BASE_URL}/fbaorder/get_fba_order_list`,
      {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};




const getAsinSalesRanksHistory = async (props) => {
  const params = {
    asin: props.asin
  };
  try {
    var result = await axios.get(
      `${BASE_URL}/fbainventory/get-fbainventory-keepa-item`,
      {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};

const getBulkAsinSalesRanksHistory = async (asins, user) => {
  try {
    var result = await axios.post(
      `${BASE_URL}/fbainventory/get-fbainventory-bulk-keepa-item`,
      asins,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    return error.response;
  }
};


const timeout = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export {
  BASE_URL,

  isTokenErrorResponse,

  loginUser,
  signUpUser,
  getProductDetails,
  getBulkProductDetails,
  getStores,
  CreateJob,
  sendJobStatus,
  addProduct,
  researchASINList,
  updateResearchProduct,
  deleteResearchASIN,
  deleteBulkResearchASIN,
  timeout,
  addFBAProductPreparation,
  downloadASINList,
  downloadShipPlanLogList,
  uploadCSVFile,
  uploadCSVFileForLowestPrice,
  uploadCSVFileForFbaStockWaitData,
  uploadCSVFileForSellerPrice,
  uploadCSVFileForEditAsin,

  // ---------------------------- FBA出品中商品 (2th page)  apis ----------------
  fbaProductASINList,
  updateFBAProduct,
  deleteFBAProductASIN,

  // --------------------------  記録管理 (history) apis  ----------------------------------
  getHistoryList,

  // --------------------------  FBA商品納品準備 (7th page) apis  ----------------------------------
  getDeliveryProductList,
  addDeliveryProduct,
  updateDeliveryProduct,
  deleteDeliveryProduct,
  returnDeliveryProduct,

  printLabel,

  // --------------------------  FBA納品ログ (8th page) apis  ----------------------------------
  getDeliveryLog,
  addDeliveryLog,
  updateDeliveryLog,
  deleteDeliveryLog,

  // --------------------------  FBA納品ログ (9th page) apis  ----------------------------------
  getRestockInventory,
  updateRestockInventory,
  deleteRestockInventory,

  // --------------------------  FBA納品ログ (10th page) apis  ----------------------------------
  getAllLog,
  addAllLog,
  updateAllLog, // this api use in <FBA log> page too
  deleteAllLog, // this api use in <FBA log> page too
  updateAllLogUsPriceSaleToLog,
  updateAllLogUsPriceLogToSale,


  // --------------------------  FBA商品 Combined (new page) apis  ----------------------------------
  fbaPurchaseASINList,
  addFbaPurchaseProduct,
  copyFbaPurchaseProduct,
  updateFbaPurchaseProduct,
  updateBulkFbaPurchaseProduct,
  deleteFbaPurchaseProduct,
  deleteBulkFbaPurchaseProduct,

  // ------------------------- FBA Order ----------------------
  getFbaOrderList,

  // --------------------------  FBA log apis (it display when click in <delivery-log> and <all-log> page)  ----------------------------------
  getfbaLog,
  updateStores,
  deleteStore,

  getfbaShipplaninfo,
  updatefbaShipplaninfo,

  // -------------------- Category List in Drawer section ---------------------------
  getCategoryData,
  addCategoryData,
  updateCategoryData,

  // -------------------- Exchange Tabs in Setting page ---------------------------
  getExchangeData,
  updateExchangeData,

  listingFbaProductFromShipplanready,

  // -------------------- Employee Tabs in Setting page ---------------------------
  getEmployee,
  createEmployee,
  updateEmployee,
  deleteEmployee,

  // -------------------- content Tabs in Setting page ---------------------------
  getContent,
  createContent,
  updateContent,
  deleteContent,

  // -------------------- auto setting Tabs in Setting page ---------------------------
  getAutoSetting,
  updateAutoSetting,

  // -------------------- ban setting Tabs in Setting page ---------------------------
  getBanSetting,
  createBanSetting,
  updateBanSetting,
  deleteBanSetting,
  // -------------------- ban title setting Tabs in Setting page ---------------------------
  getBanTitleSetting,
  createBanTitleSetting,
  updateBanTitleSetting,
  deleteBanTitleSetting,
  // -------------------- ban manufacture setting Tabs in Setting page ---------------------------
  getBanManufactureSetting,
  createBanManufactureSetting,
  updateBanManufactureSetting,
  deleteBanManufactureSetting,

  // -------------------- package way setting Tabs in Setting page ---------------------------
  getPackageWay,
  createPackageWay,
  updatePackageWay,
  deletePackageWay,

  // -------------------- fnsku list Tabs in Setting page ---------------------------
  getFnskuList,

  // -------------------- product's sales ranking ----------------------------
  getAsinSalesRanksHistory,
  getBulkAsinSalesRanksHistory,
};
