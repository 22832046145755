/* eslint-disable */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { addCategoryData, getCategoryData, getStores, updateCategoryData } from "../../services";
import { useAuth } from "../../hooks/useAuth";
import { Button, Input, Modal, Table } from "antd";
import { FaCheck, FaPlus } from "react-icons/fa6";
import { FiEdit } from "react-icons/fi";

const CategoryTab = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        initFunc,
    }));

    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [categoryData, setCategoryData] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [modalData, setModalData] = useState({
        s_name: "",
        s_value: "",
        id: "",
    });

    const columns = [
        {
            title: 'Name',
            dataIndex: 's_name',
            key: 'name',
        },
        // {
        //     title: 'Count',
        //     dataIndex: 's_value',
        //     key: 'count',
        // },
        {
            title: 'アクション',
            key: 'action',
            width: 100,

            render: (text, record) => {
                return (
                    <div style={{ display: "flex", justifyContent: 'center' }}>
                        <Button type="primary" size="small" onClick={() => handleEdit(record)}>
                            <FiEdit size={13} />
                        </Button>
                    </div>
                )
            }
        },
    ]

    const initFunc = () => {
        getStoresList();
        getCategoryList();
    }

    useEffect(() => {
        initFunc();
    }, [])

    const handleEdit = (record) => {
        setModalData(prev => ({ ...prev, s_name: record.s_name, s_value: record.s_value, id: record.id }))
        setIsModalVisible(true);
    }

    const getStoresList = async () => {
        try {
            await getStores({ user });
        } catch (error) {
            console.log(error);
        }
    }

    const getCategoryList = async () => {
        setLoading(true);
        const result = await getCategoryData({ user });
        setCategoryData(result.data);
        setLoading(false);
    }

    const handleOk = () => {

    }

    const handleCancel = () => {
        handleModalClear();
        setIsModalVisible(false);
    }

    const handleModalClear = () => {
        setModalData({ s_name: "", s_value: "", id: "" })
    }

    const handleSaveCategory = async () => {
        setLoading(true);
        let data = { ...modalData };
        data.user = user;
        if (modalData.id == "")
            await addCategoryData(data);
        else
            await updateCategoryData(data);
        handleCancel();
        initFunc();
        setLoading(false);
    }

    return (
        <>
            {loading && (
                <div id="global-loader">
                    <h2>データ処理中...</h2>
                </div>
            )}
            <div style={{ width: "100%" }}>
                <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", marginBottom: "5px" }}>
                    <h4>Category 表</h4>
                    <button type="button" className="btn btn-success me-2" onClick={() => setIsModalVisible(true)}>
                        <FaPlus /> 追加
                    </button>
                </div>
                <Table bordered
                    size="small"
                    dataSource={categoryData}
                    columns={columns}
                />
                <Modal title="レコード編集" open={isModalVisible} onCancel={handleCancel} footer={false}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <h4 style={{ margin: "10px 0" }}>Category レコード追加</h4>
                        <Input size="large" placeholder="category name" value={modalData.s_name} onChange={(e) => setModalData(prev => ({ ...prev, s_name: e.target.value }))} />
                        {/* <div className="col-md-1" />
                            <Input className="col-md-3" size="large" placeholder="count" /> */}
                    </div>
                    <div
                        style={{ display: "flex", borderTop: "1px solid #e2e2e2", justifyContent: "flex-end", marginTop: "15px", paddingTop: "10px" }}
                    >
                        <button type="button" className="btn btn-success me-2" onClick={() => handleSaveCategory()}>
                            <FaCheck />  保存
                        </button>
                        <button type="button" className="btn btn-danger me-2" onClick={() => handleModalClear()}>
                            リセット
                        </button>
                        <button type="button" className="btn btn-dark me-2" onClick={() => handleCancel()}>
                            クローズ
                        </button>
                    </div>
                </Modal>
            </div>
        </>
    )
});

CategoryTab.displayName = "categoryTab";

export default CategoryTab;