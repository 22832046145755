import React from "react";

export const USAFlag = ({ width = null }) => {
  return <img src="/assets/img/united-states-flag-48.png" width={width ?? 30} />;
};

export const JAPANFlag = ({ width = null }) => {
  return <img src="/assets/img/japan-flag-48.png" width={width ?? 30} />;
};

