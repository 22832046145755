/* eslint-disable */
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { getProductDetails } from "../../services";
import moment from "moment";
import { getOffersForTable, getStocksForTable1 } from "../../utils/util";
import { Checkbox, Modal, Select } from "antd";
import { useAuth } from "../../hooks/useAuth";

/* eslint-disable no-unused-vars */

const duration_max = 365;
const duration_array = [365, 180, 90, 60, 30, 15, 7];

const chartColors = ["#f44336", "#e91e63",  "#9c27b0",  "#673ab7",  "#2196f3",  "#00bcd4",  "#4caf50",  "#ffeb3b",  "#ff9800",  "#ff5722"];

const commonChartOptions = {
  chart: {
    type: "line",
    height: 400,
    dropShadow: {
      enabled: true,
    },
    toolbar: false
  },
  stroke: {
    show: true,
    curve: "straight",
    lineCap: "square",
    width: 2,
  },
  grid: {
    yaxis: {
      lines: {
        show: true,
      },
    },
    xaxis: {
      lines: {
        show: true,
      },
    },
  },
  markers: {
    size: 3,
    colors: ["#ffffff"],
    strokeColors: chartColors,
    strokeWidth: 1,
    fillOpacity: 1,
    shape: "circle",
    hover: {
      sizeOffset: 2,
    },
  },
  tooltip: {
    x: {
      format: "dd MMM yyyy",
    },
    shared: false,
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    position: "left",
    formatter: function(seriesName, opts) {
      return "<span title='" + seriesName +  "'>" + seriesName.substring(0, 10) + (seriesName.length > 10 ? "..." : "") + "</span>";
    },
    itemMargin: {
      vertical: 8
    }
  },
  yaxis: {
    min: 0,
    tickAmount: 8,
  },
  xaxis: {
    type: "datetime",
    labels: {
      formatter: function(value, timestamp) {
        return moment(timestamp).format("YY/MM/DD");
      },
    },
  },
}
const _priceChartOptions = {
  ...commonChartOptions,
  chart: {
    ...commonChartOptions.chart,
    id: "sales",
  },
  tooltip: {
    ...commonChartOptions.tooltip,
    y: {
      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        let stock = w.config.stockInfos?.[seriesIndex][dataPointIndex];
        return `is price $${value}, stock : ${stock || 0}`;
      },
    },
  }
}
const _stockChartOptions = {
  ...commonChartOptions,
  chart: {
    ...commonChartOptions.chart,
    id: "stocks",
  },
  tooltip: {
    ...commonChartOptions.tooltip,
    y: {
      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        let price = w.config.priceInfos?.[seriesIndex][dataPointIndex];
            return `has stock ${value}, price : $${price || 0}`;
      },
    }
  }
}

const commonAreaChartOptions = {
  chart: {
    height: 130,
    type: "line",
    brush: {
      enabled: true,
    },
    selection: {
      enabled: true
    },
  },
  legend: {
    show: false
  },
  stroke: {
    show: true,
    curve: "straight",
    lineCap: "square",
    width: 2,
  },
  xaxis: {
    type: "datetime",
    tooltip: {
      enabled: false,
    },
    labels: {
      formatter: function(value, timestamp) {
        return moment(timestamp).format("YY/MM/DD");
      },
    },
  },
  yaxis: {
    tickAmount: 2,
  },
};
const _priceAreaChartOptions = {
  ...commonAreaChartOptions,
  chart: {
    ...commonAreaChartOptions.chart,
    id: "sales_area",
    brush: {
      target: "sales",
      enabled: true,
    },
  }
}
const _stockAreaChartOptions = {
  ...commonAreaChartOptions,
  chart: {
    ...commonAreaChartOptions.chart,
    id: "stocks_area",
    brush: {
      target: "stocks",
      enabled: true,
    },
  }
}


const GraphModal = ({open, asin, onClose}) => {

  const { user } = useAuth();

  const [loading, setLoading] = useState(false);

  // options
  const [historicalOfferCheckbox, setHistoricalOfferCheckbox] = useState(false);
  const [fbaOfferCheckbox, setFBAOfferCheckbox] = useState(true);

  // price chart
  const [duration, setDuration] = useState(90);
  const [series, setSeries] = useState([]);
  const [displaySeries, setDisplaySeries] = useState([]);

  // stock chart
  const [stockSeries, setStockSeries] = useState([]);
  const [displayStockSeries, setDisplayStockSeries] = useState([]);
  
  const [chartOptions, setChartOptions] = useState(_priceChartOptions);
  const [chartAreaOptions, setChartAreaOptions] = useState(_priceAreaChartOptions);

  const [stockChartOptions, setStockChartOptions] = useState(_stockChartOptions);
  const [stockChartAreaOptions, setStockChartAreaOptions] = useState(_stockAreaChartOptions);
  

  useEffect(() => {
    if (asin != null) {
      productDetails();
    }
  }, [asin]);
  

  useEffect(() => {
    let _series_array = series.map((sales) => {
      let series = {
        "name": sales["name"],
        "data": sales["data"].slice(
          duration_max - duration,
          duration_max
        ),
        "is_live": sales["is_live"],
        "isfba": sales["isfba"],
      };
      for (let i = 0; i < series["data"].length; i++) {
        if (series["data"][i] === undefined) {
          series["data"][i] = 0;
        }
      }
      return series;
    });
    
    if (!historicalOfferCheckbox) {
      _series_array = _series_array.filter(item => item.is_live);
    }
    if (fbaOfferCheckbox) {
      _series_array = _series_array.filter(item => item.isfba);
    }

    // filter empty data
    _series_array = _series_array.filter(item => {
      for (let i = 0; i < item["data"].length; i++) {
        if (item["data"][i] > 0) {
          return true;
        }
      }
      return false;
    });

    // limit count
    _series_array = _series_array.slice(0, 20);

    // calculate chart xvalues
    const currentDate = moment();
    let dates = [];
    let datesInDay = [];
    for (let i = 0; i < duration; i++) {
      const date = currentDate.clone().subtract(duration - i, "days");
      const formattedDate = date.format("DD MMM YYYY");
      dates.push(formattedDate);
      datesInDay.push(duration_max - duration + i);
    }

    // eliminate continuous values 
    let indexes = [];
    for (let i = 1; i < duration - 1; i++) {
      let isSameValue = true;
      for (let s = 0; s < _series_array.length; s++) {
        if (_series_array[s].data[i] != _series_array[s].data[i-1]) {
          isSameValue = false;
          break;
        }
      }
      if (isSameValue) {
        indexes.push(i);
      }
    }
    let length = indexes.length;
    for (let i = 0; i < length; i++) {
      let idx = indexes[length - i - 1];
      dates.splice(idx, 1);
      datesInDay.splice(idx, 1);
      for (let s = 0; s < _series_array.length; s++) {
        _series_array[s].data.splice(idx, 1);
      }
    }

    // get corresponding price values
    let stockInfos = [];
    for (let s = 0; s < _series_array.length; s++) {
      let stockItem = stockSeries?.find(item => item["name"] == _series_array[s]["name"]);

      let stSeries = [];
      for (let i = 0; i < datesInDay.length; i++) {
        stSeries.push(stockItem["data"][datesInDay[i]]);
      }
      stockInfos.push(stSeries);

      _series_array[s]["color"] = chartColors[s];
    }


    // set chart options and series
    setChartOptions((prevChartOptions) => ({
      ...prevChartOptions,
      xaxis: {
        categories: dates,
      },
      stockInfos: stockInfos,
    }));
    setChartAreaOptions((prevChartOptions) => ({
      ...prevChartOptions,
      xaxis: {
        categories: dates,
      },
    }));
    setDisplaySeries(_series_array);

  }, [duration, series, historicalOfferCheckbox, fbaOfferCheckbox]);


  useEffect(() => {
    let _series_array = stockSeries.map((stock) => {
      return {
        "name": stock["name"],
        "data": stock["data"].slice(
          duration_max - duration,
          duration_max
        ),
        "is_live": stock["is_live"],
        "isfba": stock["isfba"],
      };
    });
    
    if (!historicalOfferCheckbox) {
      _series_array = _series_array.filter(item => item.is_live);
    }
    if (fbaOfferCheckbox) {
      _series_array = _series_array.filter(item => item.isfba);
    }


    // filter empty data
    _series_array = _series_array.filter(item => {
      for (let i = 0; i < item["data"].length; i++) {
        if (item["data"][i] > 0) {
          return true;
        }
      }
      return false;
    });

    // limit count
    _series_array = _series_array.slice(0, 20);

    // calculate chart xvalues
    const currentDate = moment();
    let dates = [];
    let datesInDay = [];
    for (let i = 0; i < duration; i++) {
      const date = currentDate.clone().subtract(duration - i, "days");
      const formattedDate = date.format("DD MMM YYYY");
      dates.push(formattedDate);
      datesInDay.push(duration_max - duration + i);
    }

    // eliminate continuous values 
    let indexes = [];
    for (let i = 1; i < duration - 1; i++) {
      let isSameValue = true;
      for (let s = 0; s < _series_array.length; s++) {
        if (_series_array[s].data[i] != _series_array[s].data[i-1]) {
          isSameValue = false;
          break;
        }
      }
      if (isSameValue) {
        indexes.push(i);
      }
    }
    let length = indexes.length;
    for (let i = 0; i < length; i++) {
      let idx = indexes[length - i - 1];
      dates.splice(idx, 1);
      datesInDay.splice(idx, 1);
      for (let s = 0; s < _series_array.length; s++) {
        _series_array[s].data.splice(idx, 1);
      }
    }

    
    // get corresponding price values
    let priceInfos = [];
    for (let s = 0; s < _series_array.length; s++) {
      let saleItem = series?.find(item => item["name"] == _series_array[s]["name"]);

      let priceSeries = [];
      for (let i = 0; i < datesInDay.length; i++) {
        priceSeries.push(saleItem["data"][datesInDay[i]]);
      }
      priceInfos.push(priceSeries);

      _series_array[s]["color"] = chartColors[s];
    }


    // set chart options and series
    setStockChartOptions((prevChartOptions) => ({
      ...prevChartOptions,
      xaxis: {
        categories: dates,
      },
      priceInfos: priceInfos
    }));
    setStockChartAreaOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        categories: dates,
      },
    }));
    setDisplayStockSeries(_series_array);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, stockSeries, historicalOfferCheckbox, fbaOfferCheckbox]);


  const productDetails = async () => {
    try {
    setLoading(true);
      const result = await getProductDetails({ asin, user });
      if (result.data.length > 0) {
        const temp_series = [];
        const temp_stock_series = [];
        for (let i = 0; i < result.data.length; i++) {
          const series_record = {};
          const stock_series_record = {};
          if (result.data[i]["sellername"]) {
            series_record["name"] = result.data[i]["sellername"];
            stock_series_record["name"] = result.data[i]["sellername"];
          } else {
            series_record["name"] = result.data[i]["sellerid"];
            stock_series_record["name"] = result.data[i]["sellerid"];
          }
          series_record["is_live"] = result.data[i]["is_live"] == 1;
          series_record["isfba"] = result.data[i]["isfba"] == 'true';
          stock_series_record["is_live"] = series_record["is_live"];
          stock_series_record["isfba"] = series_record["isfba"];

          const seriesArray = getOffersForTable(result.data[i]["offercsv"]);
          series_record["data"] = seriesArray;
          temp_series.push(series_record);

          const stocksArray = getStocksForTable1(result.data[i]["stockcsv"]);
          stock_series_record["data"] = stocksArray;
          temp_stock_series.push(stock_series_record);
        }

        setSeries(temp_series);
        setStockSeries(temp_stock_series);
      } else {
        setSeries([]);
        setStockSeries([]);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };


  return (
    <Modal
      title="商品詳細情報"
      open={open}
      onCancel={() => onClose(false)}
      width={"80%"}
      footer={false}
      >
        <div className="row">
          {loading && (
            <div id="global-loader">
              <h2>データ処理中...</h2>
            </div>
          )}

          <div className="col-sm-12 d-flex justify-content-between">
            <div>
                <Checkbox checked={historicalOfferCheckbox} style={{ marginLeft: "20px" }} onChange={(e) => setHistoricalOfferCheckbox(e.target.checked)} >
                  <span style={{ color: "#000", fontWeight: "500", lineHeight: "40px" }}>Include historical offers</span>
                </Checkbox>
                <Checkbox checked={fbaOfferCheckbox} style={{ marginLeft: "20px" }} onChange={(e) => setFBAOfferCheckbox(e.target.checked)} >
                  <span style={{ color: "#000", fontWeight: "500", lineHeight: "40px" }}>FBA Offers Only</span>
                </Checkbox>
            </div>

            <Select value={duration} onChange={(e) => setDuration(e)}>
              {duration_array.map((day, index) => {
                  return (
                    <Select.Option key={index} value={day}>{day} days ago</Select.Option>
                  )
              })}
            </Select>
          </div>

          <div className="col-sm-12">
            <ReactApexChart
              options={stockChartOptions}
              series={displayStockSeries}
              type="line"
              height={400}
            />
            <div style={{ paddingLeft: 130 }}>
              <ReactApexChart
                options={stockChartAreaOptions}
                series={displayStockSeries}
                type="line"
                height={130}
              />
            </div>
          </div>

          <div className="col-sm-12">
            <ReactApexChart
              options={chartOptions}
              series={displaySeries}
              type="line"
              height={400}
            />
            <div style={{ paddingLeft: 130 }}>
              <ReactApexChart
                options={chartAreaOptions}
                series={displaySeries}
                type="line"
                height={130}
              />
            </div>
          </div>
        </div>
    </Modal>
  );
};
export default GraphModal;
