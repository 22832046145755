import moment from "moment";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const convertStringToJSON = (values) => {
    const jsonString = values.replace(/\[/g, '[').replace(/]/g, ']');
    const jsonArray = JSON.parse(jsonString);
    return jsonArray;
};

const getOffersForTable = (offercsv_values, duration = 365) => {
    const jsonArray = convertStringToJSON(offercsv_values);
    const dateArray = [];
    const currentDateObj = moment();
    const startDateObj = currentDateObj.clone().subtract(duration, 'days');

    for (let i = 0; i < duration; i++) {
        const date = currentDateObj.clone().subtract(duration - i, 'days');
        const formattedDate = date.format('DD MMM YYYY');
        dateArray.push(formattedDate);
    }

    const responseArray = Array.from({ length: duration }, () => 0);
    for (let j = 0; j < jsonArray.length / 3; j++) {
        //get timestamp and price
        let timestamp = (jsonArray[j * 3] + 21564000) * 60000;
        const dateObject = moment(timestamp);

        if (dateObject.isBetween(startDateObj, currentDateObj)) {
            const index = currentDateObj.diff(dateObject, 'days');
            let price = (jsonArray[j * 3 + 1] + jsonArray[j * 3 + 2]);
            // if(price> 0){
            //     console.log("price:",price)
            // }
            if (price == undefined) price = 0;
            responseArray[duration - index] = price / 100;
        }
    }

    const firstIndex = responseArray.findIndex(element => element > 0);
    // console.log("first index", firstIndex);
    let lastValue = responseArray[firstIndex];
    for (let k = firstIndex; k < responseArray.length; k++) {
        if (responseArray[k] == 0) {
            responseArray[k] = lastValue;
        }
        else {
            lastValue = responseArray[k];
        }
    }
    return responseArray;
}
const getStocksForTable1 = (stockscsv_values, duration = 365) => {
    const jsonArray = convertStringToJSON(stockscsv_values);
    const dateArray = [];
    const currentDateObj = moment();
    const startDateObj = currentDateObj.clone().subtract(duration, 'days');

    for (let i = 0; i < duration; i++) {
        const date = currentDateObj.clone().subtract(duration - i, 'days');
        const formattedDate = date.format('DD MMM YYYY');
        dateArray.push(formattedDate);
    }

    const responseArray = Array.from({ length: duration }, () => 0);
    for (let j = 0; j < jsonArray.length / 2; j++) {
        //get timestamp and price
        let timestamp = (jsonArray[j * 2] + 21564000) * 60000;
        const dateObject = moment(timestamp);

        if (dateObject.isBetween(startDateObj, currentDateObj)) {
            const index = currentDateObj.diff(dateObject, 'days');
            let count = jsonArray[j * 2 + 1];
            if (count == undefined) count = 0;
            responseArray[duration - index] = count;
        }
    }

    const firstIndex = responseArray.findIndex(element => element > 0);
    let lastValue = responseArray[firstIndex];
    for (let k = firstIndex; k < responseArray.length; k++) {
        if (responseArray[k] == 0) {
            responseArray[k] = lastValue;
        }
        else {
            lastValue = responseArray[k];
        }
    }
    return responseArray;
}
const getStocksForTable = (stockscsv_values) => {
    const jsonArray = convertStringToJSON(stockscsv_values);
    const dateArray = [];
    const duration = 365;
    const currentDateObj = moment();
    const startDateObj = currentDateObj.clone().subtract(duration, 'days');

    for (let i = 0; i < duration; i++) {
        const date = currentDateObj.clone().subtract(duration - i, 'days');
        const formattedDate = date.format('DD MMM YYYY');
        dateArray.push(formattedDate);
    }

    const responseArray = Array.from({ length: duration }, () => null);
    for (let j = 0; j < jsonArray.length / 2; j++) {
        //get timestamp and price
        let timestamp = (jsonArray[j * 2] + 21564000) * 60000;
        const dateObject = moment(timestamp);

        if (dateObject.isBetween(startDateObj, currentDateObj)) {
            const index = currentDateObj.diff(dateObject, 'days');
            let count = jsonArray[j * 2 + 1];
            responseArray[duration - index] = count;
        }
    }

    // const firstIndex = responseArray.findIndex(element => element > 0);
    // console.log("first index", firstIndex);
    // let lastValue = responseArray[firstIndex];
    // for (let k = firstIndex; k < responseArray.length; k++) {
    //     if (responseArray[k] == 0) {
    //         responseArray[k] = lastValue;
    //     }
    //     else {
    //         lastValue = responseArray[k];
    //     }
    // }
    return responseArray;
}

const getSalesRanks = (data) => {
    let salesranks = data.salesranks?.length > 0 ? JSON.parse(data.salesranks) : {};
    // let salesrankreferencehistory = JSON.parse(data.salesrankreferencehistory);
    let monthlysoldhistory = data.monthlysoldhistory?.length > 0 ? JSON.parse(data.monthlysoldhistory) : [];
    let categorytree = data.categorytree?.length > 0 ? JSON.parse(data.categorytree) : [];

    let datesArr = [];
    let salesranksArr = [];
    for (const catId in salesranks) {
        const rankItem = {
            catId: catId, 
            name: catId,
            data: []
        }

        let catItem = categorytree.find(item => item.catId == catId);
        if (catItem) {
            rankItem.name = catItem.name;
        }

        for (let i = 0; i < salesranks[catId].length; i += 2) {
            let timestamp = (salesranks[catId][i] + 21564000) * 60000;
            let rank = salesranks[catId][i + 1];
            rankItem.data.push({
                date: timestamp,
                rank: rank,
            });

            if (!datesArr.includes(timestamp)) {
                datesArr.push(timestamp);
            }
        }
        salesranksArr.push(rankItem);
    }
    datesArr.sort();

    let salesranksValArr = [];
    for (const rankItem of salesranksArr) {
        const item = {
            name: rankItem.name,
            data: []
        }
        for (let i = 0; i < datesArr.length; i++) {
            let dateVal = datesArr[i];
            let dItem = rankItem.data.find(ditem => ditem.date == dateVal);
            if (dItem) {
                item.data.push(dItem.rank);
            } else {
                item.data.push(i > 0 ? item.data[i-1] : 0);
            }
        }
        salesranksValArr.push(item);
    }

    let monthlysoldArr = [];
    for (let i = 0; i < monthlysoldhistory.length; i += 2) {
        let timestamp = (monthlysoldhistory[i] + 21564000) * 60000;
        let sold = monthlysoldhistory[i + 1];
        monthlysoldArr.push({
            date: timestamp,
            sold: sold,
        });
    }
    const monthlysoldItem = {
        name: "月間販売数",
        data: []
    }
    for (let i = 0; i < datesArr.length; i++) {
        let dateVal = datesArr[i];
        let value = 0;
        for (let ms = monthlysoldArr.length-1; ms >= 0 ; ms--) {
            if (monthlysoldArr[ms].date <= dateVal) {
                value = monthlysoldArr[ms].sold;
                break;
            }
        }
        monthlysoldItem.data.push(value);
    }
    salesranksValArr.unshift(monthlysoldItem);

    return {
        salesdates: datesArr,
        salesranks: salesranksValArr,
    };
}

const showConfirmBox = (title, text, callback) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
        title: title,
        text: text,
        showCancelButton: true,
        confirmButtonColor: "#00ff00",
        confirmButtonText: "はい、",
        cancelButtonColor: "#ff0000",
        cancelButtonText: "キャンセル",
    }).then(async (result) => {
        if (result.isConfirmed) {
            if (callback) {
                callback(result);
            }
        } else {
            MySwal.close();
        }
    });
}
const showAlertBox = (title, text) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
        title: title,
        text: text,
        className: "btn btn-success",
        confirmButtonText: "OK",
        customClass: {
            confirmButton: "btn btn-success",
        }
    });
}
const showErrorBox = (text) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
        title: "Error",
        text: text,
        confirmButtonColor: "#00ff00",
        confirmButtonText: "Close",
    }).then(() => {
        MySwal.close();
    });
}
const showAlarmBox = (text) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
        title: "Alarm",
        text: text,
        confirmButtonColor: "#00ff00",
        confirmButtonText: "Close",
    }).then(() => {
        MySwal.close();
    });
}

const order_list = ['①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩'];
const getSellerMark = (sellername, store_list) => {
    if (!sellername) return "";

    const index = store_list.findIndex((item) => item.sellername === sellername);
    if (index != -1) {
        return order_list[index] + sellername.slice(0, 2).toUpperCase();
        // return sellername.slice(0, 2).toUpperCase();
    } else {
        return sellername.slice(0, 2).toUpperCase();
    }
}
const getSellerColor = (sellername) => {
    if (sellername == "weeea2") {
        return "#6C8FF8";
    } else if (sellername == "kt.japan" || sellername == "kt") {
        return "#6BC66B";
    } else if (sellername == "njoy") {
        return "#E0849B";
    } else if (sellername == "fhjiyama" || sellername == "fh") {
        return "#B284E0";
    } else if (sellername == "Musashi") {
        return "#ac725e";
    } else {
        return "#000000";
    }
}
const getSellerFNSKU = (item, sellername) => {
    if (item.fulfillment_sku) {
        return item.fulfillment_sku;
    }
    if (sellername == "weeea2") {
        return item.fnsku_weeea2?.toUpperCase();
    } else if (sellername == "kt.japan") {
        return item.fnsku_kt?.toUpperCase();
    } else if (sellername == "njoy") {
        return item.fnsku_njoy?.toUpperCase();
    } else if (sellername == "fhjiyama") {
        return item.fnsku_fh?.toUpperCase();
    } else if (sellername == "Musashi") {
        return item.fnsku_mj?.toUpperCase();
    } else if (item.fnsku) {
        return item.fnsku;
    }
    return "";
}

const getAmazonUrl = (asin) => `https://amazon.com/dp/${asin}`;
const getAmazonJpUrl = (asin) => `https://amazon.jp/dp/${asin}`;
const getKeepaUrl = (asin) => `https://keepa.com/#!product/1-${asin}`;
const getRakutenUrl = (jan) => `https://search.rakuten.co.jp/search/mall/${jan}`;
const getYahooUrl = (jan) => `https://shopping.yahoo.co.jp/search?p=${jan}`;
const getSaikyakuUrl = (jan) => `https://www.saiyasune.com/I1W${jan}.html`;
const getAmazonJpOfferListingUrl = (asin, condition) => `http://www.amazon.co.jp/gp/offer-listing/${asin}/ref=olp_tab_new?ie=UTF8&${condition.toLowerCase() == 'new' ? '/ref=olp_tab_new?ie=UTF8&condition=new' : '/ref=olp_tab_used?ie=UTF8&condition=used'}`;
const getAmazonOfferListingUrl = (asin, condition) => `http://www.amazon.com/gp/offer-listing/${asin}/ref=olp_tab_all?ie=UTF8&shipPromoFilter=1&sort=price&condition=${condition.toLowerCase()}`;
const getAmazonRefUrl = (asin) => `https://www.amazon.com/dp/${asin}/ref=olp-opf-redir?aod=1&ie=UTF8&condition=new`;

const getUgxUrl = (trackingNo) => `https://trackings.post.japanpost.jp/services/srv/search/direct?searchKind=S004&locale=en&reqCodeNo1=${trackingNo}&x=20&y=8`;
const getUpsUrl = (trackingNo) => `https://www.ups.com/track?loc=ja_JP&requester=QUIC&tracknum=${trackingNo}/trackdetails`;
const getFedexUrl = (trackingNo) => `https://www.fedex.com/fedextrack/?trknbr=${trackingNo}`;




const getDateTimeFormat = (value) => {
    let dtVal = (value) ? new Date(value) : new Date();
    let strVal = dtVal.getFullYear() + "-";
    strVal += ("0" + (dtVal.getMonth() + 1)).slice(-2) + "-";
    strVal += ("0" + dtVal.getDate()).slice(-2) + "T";
    strVal += ("0" + dtVal.getHours()).slice(-2) + ":";
    strVal += ("0" + dtVal.getMinutes()).slice(-2) + ":";
    strVal += ("0" + dtVal.getSeconds()).slice(-2);
    return strVal;
}
const getDateTimeFormat2 = (value) => {
    let dtVal = (value) ? new Date(value) : new Date();
    let strVal = dtVal.getFullYear() + "-";
    strVal += ("0" + (dtVal.getMonth() + 1)).slice(-2) + "-";
    strVal += ("0" + dtVal.getDate()).slice(-2) + " ";
    strVal += ("0" + dtVal.getHours()).slice(-2) + ":";
    strVal += ("0" + dtVal.getMinutes()).slice(-2);
    return strVal;
}
const getDateTimeFormat3 = (value) => {
    let dtVal = (value) ? new Date(value) : new Date();
    let strVal = "";
    strVal += ("0" + (dtVal.getMonth() + 1)).slice(-2) + "/";
    strVal += ("0" + dtVal.getDate()).slice(-2) + " ";
    strVal += ("0" + dtVal.getHours()).slice(-2) + ":";
    strVal += ("0" + dtVal.getMinutes()).slice(-2);
    return strVal;
}

const parseDate = (dateStr) => {
    const [month, day, year] = dateStr.split('/').map(Number);
    return new Date(year + 2000, month - 1, day);
}

const debounce = (callback, delay) => {
    let timer;
    return function () {
        clearTimeout(timer)
        timer = setTimeout(() => {
            callback();
        }, delay)
    }
}

const updateArrayString = (orgValue, index, newValue) => {
    let values = orgValue?.split(',') ?? [];
    values[index] = newValue;
    let retValue = values?.join(',');
    retValue = retValue.replace(/(,|\s)+$/g, '');
    return retValue;
}

const getFormatNumber = (number, decimal) => {
    number = number.toFixed(decimal) + '';
    let x = number.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

export {
    convertStringToJSON,
    getOffersForTable,
    getStocksForTable,
    getStocksForTable1,
    getSalesRanks,

    getSellerMark,
    getSellerColor,
    getSellerFNSKU,

    showConfirmBox,
    showAlertBox,
    showErrorBox,
    showAlarmBox,

    getAmazonUrl,
    getAmazonJpUrl,
    getKeepaUrl,
    getRakutenUrl,
    getYahooUrl,
    getSaikyakuUrl,
    getAmazonJpOfferListingUrl,
    getAmazonOfferListingUrl,
    getAmazonRefUrl,

    getUgxUrl,
    getUpsUrl,
    getFedexUrl,

    getFormatNumber,
    getDateTimeFormat,
    getDateTimeFormat2,
    getDateTimeFormat3,
    parseDate,
    debounce,
    updateArrayString,
}