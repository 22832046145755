/* eslint-disable */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { createEmployee, deleteEmployee, getEmployee, updateEmployee } from "../../services";
import { useAuth } from "../../hooks/useAuth";
import { Button, Input, Modal, Table } from "antd";
import { FaCheck, FaPlus } from "react-icons/fa6";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import * as utils from "../../utils/util";

const EmployeeTab = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        initFunc,
    }));

    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [employeeData, setEmployeeData] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [modalData, setModalData] = useState({
        name: "",
        id: "",
    });

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        // {
        //     title: 'Count',
        //     dataIndex: 's_value',
        //     key: 'count',
        // },
        {
            title: 'アクション',
            key: 'action',
            width: 100,

            render: (text, record) => {
                return (
                    <div style={{ display: "flex", justifyContent: 'center' }}>
                        <Button type="primary" size="small" onClick={() => handleEdit(record)}>
                            <FiEdit size={13} />
                        </Button>
                        <Button type="primary" onClick={() => showConfirmationAlertASINDelete(record.id)} size="small" style={{ background: "red", marginLeft: "10px" }}>
                            <MdDelete size={15} color="#fff" />
                        </Button>
                    </div>
                )
            }
        },
    ]

    const initFunc = () => {
        getEmployeeData();
    }

    useEffect(() => {
        initFunc();
    }, [])

    const handleEdit = (record) => {
        setModalData(prev => ({ ...prev, name: record.name, id: record.id }))
        setIsModalVisible(true);
    }

    const getEmployeeData = async () => {
        setLoading(true);
        const result = await getEmployee({ user });
        console.log(result);
        setEmployeeData(result.data);
        setLoading(false);
    }

    const handleCancel = () => {
        handleModalClear();
        setIsModalVisible(false);
    }

    const handleModalClear = () => {
        setModalData({ name: "", id: "" })
    }

    const handleSaveCategory = async () => {
        setLoading(true);
        let data = { ...modalData };
        data.user = user;
        if (modalData.id == "")
            await createEmployee(data);
        else
            await updateEmployee(data);
        handleCancel();
        initFunc();
        setLoading(false);
    }

    const showConfirmationAlertASINDelete = async (id) => {
        utils.showConfirmBox("削除しますか？", "これを元に戻すことはできません。", async () => {
            setLoading(true);
            const result = await deleteEmployee({
                id,
                user: user,
            });
            setLoading(false);
            initFunc();
        });
    };

    return (
        <>
            {loading && (
                <div id="global-loader">
                    <h2>データ処理中...</h2>
                </div>
            )}
            <div style={{ width: "100%" }}>
                <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", marginBottom: "5px" }}>
                    <h4>発注者 表</h4>
                    <button type="button" className="btn btn-success me-2" onClick={() => setIsModalVisible(true)}>
                        <FaPlus /> 追加
                    </button>
                </div>
                <Table bordered
                    size="small"
                    dataSource={employeeData}
                    columns={columns}
                />
                <Modal title="レコード編集" open={isModalVisible} onCancel={handleCancel} footer={false}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <h4 style={{ margin: "10px 0" }}>発注者 レコード追加</h4>
                        <Input size="large" value={modalData.name} onChange={(e) => setModalData(prev => ({ ...prev, name: e.target.value }))} />
                        {/* <div className="col-md-1" />
                            <Input className="col-md-3" size="large" placeholder="count" /> */}
                    </div>
                    <div
                        style={{ display: "flex", borderTop: "1px solid #e2e2e2", justifyContent: "flex-end", marginTop: "15px", paddingTop: "10px" }}
                    >
                        <button type="button" className="btn btn-success me-2" onClick={() => handleSaveCategory()}>
                            <FaCheck />  保存
                        </button>
                        <button type="button" className="btn btn-danger me-2" onClick={() => handleModalClear()}>
                            リセット
                        </button>
                        <button type="button" className="btn btn-dark me-2" onClick={() => handleCancel()}>
                            クローズ
                        </button>
                    </div>
                </Modal>
            </div>
        </>
    )
});

EmployeeTab.displayName = "EmployeeTab";

export default EmployeeTab;