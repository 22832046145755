/* eslint-disable */
import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { FiActivity } from "react-icons/fi";
import { FaCalculator, FaPencil, FaArrowRight, FaTrashCan, FaMagnifyingGlass } from 'react-icons/fa6';

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { all_routes } from "../../../Router/all_routes";
import {
  InputNumber,
  Table,
  Checkbox,
  Select,
  Modal,
  Input,
  Popover,
  AutoComplete
} from "antd";
import {
  sendJobStatus,
  timeout,
  CreateJob,
  getCategoryData,
  fbaPurchaseASINList,
  updateFbaPurchaseProduct,
  deleteFbaPurchaseProduct,
  getPackageWay,
  isTokenErrorResponse,
} from "../../../services";
import { useAuth } from "../../../hooks/useAuth";
import * as utils from "../../../utils/util";
import * as constants from "../../../utils/constants";
import { FilterDrawer } from "../../../core/components/FilterDrawer";


const initialData = {
  topFilter: "fba_purchase_ready_all",
  topAction: "none",
  order_by_value: "",
  order_by_way: 0, // 0 or 1
  filter_multi_asins: "",
  filter_us_min_rank: "",
  filter_us_max_rank: "",
  filter_us_min_weight: "",
  filter_us_max_weight: "",
  filter_us_min_price: "",
  filter_us_max_price: "",
  filter_jp_purchase_min_price: "",
  filter_jp_purchase_max_price: "",
  filter_jp_seller_min_price: "",
  filter_jp_seller_max_price: "",
  filter_min_profit_amount: "",
  filter_max_profit_amount: "",
  filter_min_profit_percent: "",
  filter_max_profit_percent: "",
  filter_product_type1_list: "",
  filter_us_category_list: "",
  filter_purchase_place: "",
  filter_ship_place: "",
  filter_employee_name: "",
  sales_count_not0: false,
  
  filter_start_order_date: "",
  filter_end_order_date: "",
  filter_start_purchase_date: "",
  filter_end_purchase_date: "",
  filter_keyword: "",
};

const topFilterOptions = [
  { value: "fba_purchase_ready_all", label: "全体" },
  { value: "fba_real_weight_compare", label: "実重量＞容積重量 商品" },
  { value: "is_own_product", label: "問屋商品" },
  { value: "export_ban", label: "禁止商品" },
  { value: "is_adult", label: "アダルト商品" },
  { value: "is_stop", label: "出品停止商品" },
  { value: "is_listed", label: "出品中商品" },
  { value: "no_has_us_fba_sellers", label: "米国 FBA 出品者の無い商品" },
  { value: "has_us_fba_sellers", label: "米国 FBA 出品者の商品" },
  { value: "is_new", label: "新品" },
  { value: "is_used", label: "中古" },
  { value: "is_none_price", label: "仕入れ価格がない商品" },
  { value: "pre_profit_minus", label: "米国価格が高い商品" },
  { value: "preorder", label: "予定商品" },
  { value: "FBA_is_SH", label: "FBA Small and Light商品" },
  { value: "SL", label: "SL商品" },
  { value: "SH", label: "SH商品" },
  { value: "BL", label: "BL商品" },
  { value: "BH", label: "BH商品" },
  { value: "rank_out_product", label: "ランク圏外商品" },
];


const PurchaseList = forwardRef((props, ref) => {

  useImperativeHandle(ref, () => ({
    initFunc,
  }));

  const { user, logout } = useAuth();
  const tableRef = useRef(null);

  const active_seller = useSelector((state) => state.active_seller);
  const store_list = useSelector((state) => state.store_list);

  const route = all_routes;

  const [loading, setLoading] = useState(false);

  const [keyword, setKeyword] = useState("");
  const [filterValue, setFilterValue] = useState(initialData);

  const [isDrawer, setIsDrawer] = useState(false);

  const [categoryList, setCategoryList] = useState();
  const [packageWayList, setPackageWayList] = useState([]);

  const [dataSource, setDataSource] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });
  const [jumprow, setJumpRow] = useState("");

  const [addAsin, setAddAsin] = useState("");
  const [editASINIndex, setEditASINIndex] = useState(-1);
  const [editASIN, setEditASIN] = useState("");
  const [editpurchase_price, setEdit_purchase_price] = useState(0);
  const [editus_sell_price, setEditus_price] = useState(0);
  const [editjp_title, setEditjp_title] = useState("");
  const [editus_title, setEditus_title] = useState("");
  const [edit_jan, setEdit_jan] = useState("");
  const [edit_totalofpackage, setEdit_totalofpackage] = useState("");
  const [edit_totalquantity, setEdit_totalquantity] = useState("");
  const [edit_catalognumber, setEdit_catalognumber] = useState("");
  const [edit_package_image, setEdit_package_image] = useState("");
  const [edit_image, setEdit_image] = useState("");
  const [edit_weight, setEdit_weight] = useState(0);
  const [edit_width, setEdit_width] = useState(0);
  const [edit_height, setEdit_height] = useState(0);
  const [edit_length, setEdit_length] = useState(0);
  const [edit_jan3, setEdit_jan3] = useState("");
  const [edit_package_way, setEdit_package_way] = useState("");
  const [edit_package_note, setEdit_package_note] = useState("");
  const [edit_purchase_stock, setEdit_purchase_stock] = useState("");
  const [edit_purchase_stock_changed, setEdit_purchase_stock_changed] = useState(false);
  const [edit_us_stock, setEdit_us_stock] = useState("");

  const [edit_us_amazon_fee, setEdit_us_amazon_fee] = useState("");
  const [edit_fba_fee_auto, setEdit_fba_fee_auto] = useState("");
  const [edit_us_comment, setEdit_us_comment] = useState("");
  const [edit_itemcondition, setEdit_itemcondition] = useState("");
  const [edit_product_type1, setEdit_product_type1] = useState("");
  const [edit_profit, setEdit_profit] = useState("");
  const [edit_variable_closing_fee, setEdit_variable_closing_fee] = useState(1);
  const [edit_purchase_place, setEdit_purchase_place] = useState("");
  const [edit_ship_place, setEdit_ship_place] = useState("");
  const [edit_fnsku_weeea2, setEdit_fnsku_weeea2] = useState("");
  const [edit_fnsku_kt, setEdit_fnsku_kt] = useState("");
  const [edit_fnsku_njoy, setEdit_fnsku_njoy] = useState("");
  const [edit_fnsku_fh, setEdit_fnsku_fh] = useState("");

  const [edit_is_own, setEdit_is_own] = useState(0);
  const [edit_invoice_name, setEdit_invoice_name] = useState("");
  const [edit_hs_code, setEdit_hs_code] = useState("");
  const [edit_hts_code, setEdit_hts_code] = useState("");
  const [edit_min_num_orders, setEdit_min_num_orders] = useState("");
  const [edit_us_fba_seller_num, setEdit_us_fba_seller_num] = useState("");
  const [edit_us_seller_num, setEdit_us_seller_num] = useState("");
  const [edit_gate_fee, setEdit_gate_fee] = useState("");
  const [edit_breakdown_qty, setEdit_breakdown_qty] = useState("");
  const [edit_price_no_tax, setEdit_price_no_tax] = useState("");
  const [edit_price_tax, setEdit_price_tax] = useState("");

  const [edit_modal_visible, setEdit_modal_visible] = useState(false);

  const handleSearch = (e) => {
    if (e == null || e.key === "Enter") {
      if (keyword == "") {
        filterValue.filter_keyword = "";
      }
      setJumpRow(0);
      setPagination({ current: 1, pageSize: pagination.pageSize });
      getResearchList();
      setKeyword("");
    }
  };

  const handleJumpRow = (e) => {
    if (e.key === "Enter") {
      setPagination({
        current: parseInt(jumprow),
        pageSize: pagination.pageSize,
      });
      setJumpRow("");
      getResearchList(parseInt(jumprow));
    }
  };

  const getResearchList = async (page = 1) => {
    try {
      let seller = "";
      if (active_seller != "すべてのアカウント") seller = active_seller;
      setLoading(true);

      const result = await fbaPurchaseASINList({
        page: page - 1,
        count: pagination.pageSize,
        user: user,
        keyword: filterValue.filter_keyword,
        seller: seller,
        product_status: 1,
        filterValue
      });
      if (isTokenErrorResponse(result)) {
        return logout();
      }

      if (result.data && result.data.length > 0) {
        setDataSource(result.data);
        let pages = Math.ceil(parseInt(result.data[0]["full_count"]) / pagination.pageSize);
        setPageCount(pages);
        setTotalItems(result.data[0]["full_count"]);
      } else {
        setDataSource([]);
        setPageCount(0);
        setTotalItems(0);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
    getResearchList(pagination.current);
  };

  const handlePageSizeChange = (pagesize) => {
    pagination.pageSize = pagesize;
    getResearchList();
  };

  const initFunc = () => {
    document.title = "仕入準備";
    getResearchList();
    getCategory();

    getPackageWayList();
  }

  const getPackageWayList = async () => {
    const result = await getPackageWay({ user, page: 0, count: 100, keyword: '' });
    if (result.data && result.data.length > 0) {
      let packageways = result.data.map(item => ({value: item.content}));
      setPackageWayList(packageways);
    }
  }

  useEffect(() => {
    initFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_seller]);

  const checkProductStatus = async (asin) => {
    window.open(`${route.productdetails}?asin=${asin}`, "_blank", "noreferrer");
  };

  const onInputChange = (key, index) => (value) => {
    const newData = [...dataSource];

    if (value.target) {
      newData[index][key] = value.target.value;
    } else {
      newData[index][key] = value;
    }

    setDataSource(newData);
  };

  const getCategory = async () => {
    const result = await getCategoryData({ user });
    setCategoryList(result.data);
  }

  const onEditSelectChange = (key, index) => (e) => {
    let value = e.target.value;
    const newData = [...dataSource];
    let newValue = '';
    switch (key) {
      case "jan":
        newValue = utils.updateArrayString(edit_jan, index, value);
        setEdit_jan(newValue);
        break;
      case "catalognumber":
        newValue = utils.updateArrayString(edit_catalognumber, index, value);
        setEdit_catalognumber(newValue);
        break;
      case "breakdown_qty":
        newValue = utils.updateArrayString(edit_breakdown_qty, index, value);
        setEdit_breakdown_qty(newValue);
        break;
      case "price_no_tax":
        newValue = utils.updateArrayString(edit_price_no_tax, index, value);
        setEdit_price_no_tax(newValue);
        break;
      case "price_tax":
        newValue = utils.updateArrayString(edit_price_tax, index, value);
        setEdit_price_tax(newValue);
        break;
    }
    newData[editASINIndex][key] = newValue;
    setDataSource(newData);
  }
  const onEditInputChange = (key) => (value) => {
    console.log("onEditInputChange key=", key);
    console.log("editASINIndex key=", editASINIndex);
    const newData = [...dataSource];
    let index = editASINIndex;
    switch (key) {
      case "us_price":
        setEditus_price(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "purchase_price":
        setEdit_purchase_price(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "us_title":
        setEditus_title(value.target.value);
        newData[index]["productname"] = value.target.value;
        break;
      case "jp_title":
        setEditjp_title(value.target.value);
        newData[index]["jp_productname"] = value.target.value;
        break;
      
      case "totalofpackage":
        setEdit_totalofpackage(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "image":
        setEdit_image(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "package_image":
        setEdit_package_image(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "weight":
        setEdit_weight(value.target.value);
        newData[index]["weight"] = value.target.value;
        break;
      case "width":
        setEdit_width(value.target.value);
        newData[index]["width"] = value.target.value;
        break;
      case "height":
        setEdit_height(value.target.value);
        newData[index]["height"] = value.target.value;
        break;
      case "length":
        setEdit_length(value.target.value);
        newData[index]["length"] = value.target.value;
        break;
      case "jan3":
        setEdit_jan3(value.target.value);
        newData[index]["jan3"] = value.target.value;
        break;
      case "package_way":
        setEdit_package_way(value.target.value);
        newData[index]["amazon_package_way"] = value.target.value;
        break;
      case "package_note":
        setEdit_package_note(value.target.value);
        newData[index]["amazon_package_note"] = value.target.value;
        break;
      case "purchase_stock":
        setEdit_purchase_stock(value.target.value);
        setEdit_purchase_stock_changed(true);
        newData[index][key] = value.target.value;
        break;
      case "us_stock":
        setEdit_us_stock(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "us_amazon_fee":
        setEdit_us_amazon_fee(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "fba_fee_auto":
        setEdit_fba_fee_auto(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "us_comment":
        setEdit_us_comment(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "itemcondition":
        setEdit_itemcondition(value);
        newData[index][key] = value;
        break;
      case "product_type1":
        setEdit_product_type1(value);
        newData[index][key] = value;
        break;
      case "total_quantity":
        setEdit_totalquantity(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "profit":
        setEdit_profit(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "variable_closing_fee":
        const val = value.target.checked ? 1 : 0;
        setEdit_variable_closing_fee(val);
        newData[index][key] = val;
        break;
      case "purchase_place":
        setEdit_purchase_place(value);
        newData[index][key] = value;
        break;
      case "ship_place":
        setEdit_ship_place(value);
        newData[index][key] = value;
        break;
      case "fnsku_weeea2":
        setEdit_fnsku_weeea2(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "fnsku_kt":
        setEdit_fnsku_kt(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "fnsku_njoy":
        setEdit_fnsku_njoy(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "fnsku_fh":
        setEdit_fnsku_fh(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "min_num_orders":
        setEdit_min_num_orders(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "invoice_name":
        setEdit_invoice_name(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "hs_code":
        setEdit_hs_code(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "hts_code":
        setEdit_hts_code(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "us_fba_seller_num":
        setEdit_us_fba_seller_num(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "us_seller_num":
        setEdit_us_seller_num(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "gate_fee":
        setEdit_gate_fee(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "is_own":
        setEdit_is_own(value.target.checked ? 1 : 0);
        newData[index][key] = value.target.checked ? 1 : 0;
        break;
    }
    setDataSource(newData);
  };

  const handleUpdateProduct = (index) => async () => {
    try {
      setLoading(true);
      const props = dataSource[index];
      props.user = user;

      if (edit_purchase_stock_changed) {
        props.purchase_wait_stock = props.purchase_stock;
        props.package_ready_stock = 0;
        props.package_complete_stock = 0;
        props.package_count = 0;
      }

      await updateFbaPurchaseProduct(props);
      getResearchList(pagination.current);
      setEdit_modal_visible(false);
      setEdit_purchase_stock_changed(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  async function handleJobStatus(id) {
    const result = await sendJobStatus({ id: id, user: user });
    console.log(result);
    if (parseInt(result.data.status) > 0) {
      return true;
    }
    return false;
  }

  const handleCalculate = async (index) => {
    try {
      setLoading(true);
      const product = dataSource[index];
      console.log("product", product);
      const result = await CreateJob({
        jobname: "calculate_profit_purchase_asin",
        jobvalue: {
          name: "asin",
          value: product.asin,
          condition: product.itemcondition,
          sellername: product.sellername
        },
        user: user,
      });
      console.log("calculate response", result);
      if (result.data.id) {
        let id = result.data.id;
        let res = await handleJobStatus(id);
        while (!res) {
          timeout(1000);
          res = await handleJobStatus(id);
        }
        getResearchList(pagination.current);
      }

      //result.data == 1 :  success
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const onHandleDate = (key, index) => async (e) => {
    setLoading(true);

    var newData = [...dataSource];
    newData[index][key] = e.target.value;
    setDataSource(newData);
    const props = newData[index];
    props.user = user;
    await updateFbaPurchaseProduct(props);

    setLoading(false);
  }

  const handleProductToReady = async (index) => {
    setLoading(true);

    var newData = [...dataSource];
    newData[index]["product_status"] = 2;
    const props = newData[index];
    props.user = user;
    await updateFbaPurchaseProduct(props);
   
    getResearchList(pagination.current);
    setLoading(false);
  }

  const handleProductEdit = async (index) => {
    try {
      console.log("index", index);
      const product = dataSource[index];
      setEditASINIndex(index);
      setEditASIN(product.asin);
      setEdit_totalquantity(product.totalquantity);
      setEdit_purchase_price(product.purchase_price);
      setEditus_price(product.us_price);
      setEditjp_title(product.jp_productname);
      setEditus_title(product.productname);
      setEdit_jan(product.jan);
      setEdit_totalofpackage(product.totalofpackage);
      setEdit_catalognumber(product.catalognumber);
      setEdit_image(product.image);
      setEdit_package_image(product.package_image);
      setEdit_weight(product.weight);
      setEdit_width(product.width);
      setEdit_height(product.height);
      setEdit_length(product.length);
      setEdit_jan3(product.jan3);
      setEdit_package_way(product.amazon_package_way);
      setEdit_package_note(product.amazon_package_note);
      setEdit_purchase_stock(product.purchase_stock);
      setEdit_us_stock(product.us_stock);
      setEdit_us_amazon_fee(product.us_amazon_fee);
      setEdit_fba_fee_auto(product.fba_fee_auto);
      setEdit_us_comment(product.us_comment);
      setEdit_itemcondition(product.itemcondition ? product.itemcondition : null);
      setEdit_product_type1(product.product_type1);
      setEdit_profit(product.profit);
      setEdit_variable_closing_fee(product.variable_closing_fee);
      setEdit_purchase_place(product.purchase_place);
      setEdit_ship_place(product.ship_place);
      setEdit_fnsku_weeea2(product.fnsku_weeea2);
      setEdit_fnsku_kt(product.fnsku_kt);
      setEdit_fnsku_njoy(product.fnsku_njoy);
      setEdit_fnsku_fh(product.fnsku_fh);
      setEdit_min_num_orders(product.min_num_orders);
      setEdit_invoice_name(product.invoice_name);
      setEdit_hs_code(product.hs_code);
      setEdit_hts_code(product.hts_code);
      setEdit_breakdown_qty(product.breakdown_qty);
      setEdit_price_no_tax(product.price_no_tax);
      setEdit_price_tax(product.price_tax);
      setEdit_us_fba_seller_num(product.us_fba_seller_num);
      setEdit_us_seller_num(product.us_seller_num);
      setEdit_gate_fee(product.gate_fee);
      setEdit_is_own(product.is_own);
      
      setEdit_modal_visible(true);
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      title: <span>商品画像</span>,
      dataIndex: "image",
      width: 100,
      render: (text, record, index) => {
        return (
          <span className="inventoryimgname">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Link
                to={utils.getAmazonUrl(record.asin)}
                className="product-img stock-img"
                target="_blank"
              >
                <ImageWithBasePath
                  alt=""
                  src={text ?? "assets/img/products/product1.jpg"}
                  isProduct={text ? true : false}
                  width={80}
                  height={80}
                />
              </Link>
            </div>
          </span>
        );
      },
    },
    {
      title: <span>商品名</span>,
      dataIndex: "productname",
      width: 200,
      render: (text, record, index) => {
        return (
          <span className="inventoryimgname">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Popover content={text}>
                <Link
                  to={utils.getAmazonUrl(record.asin)}
                  className="product-title"
                  target="_blank"
                >
                  {text}
                </Link>
              </Popover>
              <Popover content={record.jp_productname}>
                <Link
                  to={utils.getAmazonJpUrl(record.asin)}
                  className="product-title"
                  target="_blank"
                >
                  {record.jp_productname}
                </Link>
              </Popover>
            </div>
          </span>
        );
      },
    },
    {
      title: <span>ASIN</span>,
      dataIndex: "asin",
      width: 100,
      render: (text, record) => {
        return (
          <span>
            <Link to={utils.getAmazonJpOfferListingUrl(record.asin, record.itemcondition)} className="product-title" target="_blank">
              {record.asin}
            </Link>
          </span>
        );
      },
    },
    {
      title: <span>FNSKU</span>,
      dataIndex: "fnsku",
      width: 100,
      render: (text, record) => {
        let color = utils.getSellerColor(record.sellername);
        let fnsku = utils.getSellerFNSKU(record, record.sellername);

        return (
          <span style={{ color: color }}>{fnsku}</span>
        );
      },
    },
    {
      title: <span>アカ</span>,
      dataIndex: "seller_name",
      width: 60,
      render: (text, record) => {
        return (
          <div className={`sellermark ${record.sellername}`}>
            {utils.getSellerMark(record.sellername, store_list)}
          </div>
        );
      },
    },
    {
      title: <span>発注先</span>,
      dataIndex: "purchase_place",
      width: 60,
      render: (text, record) => {
        var purchase_place = (record.purchase_place == "" || record.purchase_place == null) ? "-" : record.purchase_place;
        return (
          <span>{purchase_place.split(" ")[0]}</span>
        );
      },
    },
    {
      title: <span>発注者</span>,
      dataIndex: "ship_place",
      width: 60,
      render: (text, record) => {
        var ship_place = (record.ship_place == "" || record.ship_place == null) ? "-" : record.ship_place;
        return (
          <div style={{ minWidth: 40 }}>{ship_place}</div>
        );
      },
    },
    {
      title: <span>発注日</span>,
      dataIndex: "order_date",
      width: 110,
      render: (text, record, index) => {
        return (
          <input
              type="date"
              value={record.order_date}
              onChange={onHandleDate("order_date", index)}
            />
        );
      },
    },
    {
      title: <span>内容</span>,
      dataIndex: "totalofpackage",
      width: 60,
      render: (text, record) => {
        return (
          <span>{record.totalofpackage} 個</span>
        );
      },
    },
    {
      title: <span>JAN<br/>型番</span>,
      dataIndex: "totalofpackage",
      width: 100,
      render: (text, record) => {
        let jansArr = record.jan?.split(",");
        let jans = <span>
          {jansArr?.map((j, idx) => <span key={idx}>{j}<br/></span>)}
        </span>;

        let catasArr = record.catalognumber?.split(",");
        let catas = <span>
          {catasArr?.map((j, idx) => <span key={idx}>{j}<br/></span>)}
        </span>;
        return (
          <div style={{ maxWidth: 100 }}>
            <div className="line1">
              <Popover content={jans}>{jansArr?.[0]}</Popover>
            </div>
            <div className="line2">
              <Popover content={catas}>{catasArr?.[0] ?? '-'}</Popover>
            </div>
          </div>
        );
      },
    },
    {
      title: <span>仕入</span>,
      dataIndex: "purchase_stock",
      width: 100,
      render: (text, record, index) => {
        return (
          <div>
            <InputNumber
              value={record.purchase_stock}
              onPressEnter={handleUpdateProduct(index)}
              onChange={onInputChange("purchase_stock", index)}
            />
          </div>
        );
      },
    },
    {
      title: <span>日本価格</span>,
      dataIndex: "purchase_price",
      width: 100,
      render: (text, record, index) => {
        return (
          <div>
            <InputNumber
              prefix="¥"
              value={record.purchase_price}
              onPressEnter={handleUpdateProduct(index)}
              onChange={onInputChange("purchase_price", index)}
            /><br/>
            <a className="lowest-price" href={utils.getAmazonJpOfferListingUrl(record.asin, record.itemcondition)} target="_blank">
              最安 &nbsp;&nbsp;&nbsp;¥ {record.jp_lowest_price}
            </a>
          </div>
        );
      },
    },
    {
      title: <span>米価格</span>,
      dataIndex: "us_price",
      width: 100,
      render: (text, record, index) => {
        return (
          <div>
            <InputNumber
              prefix="$"
              value={record.us_price}
              onPressEnter={handleUpdateProduct(index)}
              onChange={onInputChange("us_price", index)}
            /><br/>
            <a className="lowest-price" href={utils.getAmazonOfferListingUrl(record.asin, record.itemcondition)} target="_blank">
              最安 &nbsp;&nbsp;&nbsp;$ {record.us_lowest_price}
            </a>
          </div>
        );
      },
    },
    {
      title: <span>サイズ</span>,
      dataIndex: "product_size",
      width: 60,
      render: (text, record) => {
        var product_type = ((record.product_type == "") || (typeof record.product_type === "undefined")) ? "-" : record.product_type;
        return (
          <span>{product_type}</span>
        );
      },
    },
    {
      title: <span>重量</span>,
      dataIndex: "product_weight",
      width: 60,
      render: (text, record) => {
        var weight = Number(record.weight);
        return (
          <span>{weight}g</span>
        );
      },
    },
    {
      title: <span>利益額</span>,
      dataIndex: "profit",
      width: 60,
      render: (text, record) => {
        return (
          <span>¥ {record.us_prev_profit}</span>
        );
      },
    },
    {
      title: <span>利益率</span>,
      dataIndex: "profit_percent",
      width: 60,
      render: (text, record) => {
        return (
          <span>{record.us_prev_profit_percent} %</span>
        );
      },
    },
    {
      title: "アクション",
      dataIndex: "action",
      width: 100,
      render: (text, record, index) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <div className="input-block add-lists"></div>
            <a
              className="me-1 p-1"
              onClick={() => handleCalculate(index)}
              title="利益計算"
            >
              <FaCalculator className="action-edit" />
            </a>
            <a
              className="me-1 p-1"
              onClick={() => handleProductToReady(index)}
              title="仕入完了"
            >
              <FaArrowRight className="action-edit" />
            </a>
            <a
              className="p-1"
              onClick={() => handleProductEdit(index)}
              title="編集"
            >
              <FaPencil className="action-edit" />
            </a>
          </div>
          <div className="edit-delete-action">
            <a
              className="me-1 p-1"
              onClick={() => checkProductStatus(record.asin)}
              title="詳細"
            >
              <FiActivity className="feather-eye" />
            </a>
            <a
              className="confirm-text me-1 p-1"
              onClick={() => showConfirmationAlertASINDelete(index)}
              title="削除"
            >
              <FaTrashCan className="action-trash" />
            </a>
          </div>
        </div>
      ),
    },
  ];

  const showConfirmationAlertASINDelete = async (index) => {
    utils.showConfirmBox("削除しますか？", "これを元に戻すことはできません。", async () => {
      const product = dataSource[index];
      setLoading(true);
      const result = await deleteFbaPurchaseProduct({
        id: product.id,
        user: user,
      });
      setLoading(false);
      getResearchList(pagination.current);
    });
  };

  const handleTopFilter = async () => {
    getResearchList(pagination.current);
  };

  const onDrawerClose = () => {
    setIsDrawer(false);
  };

  return (
    <>
      {loading && (
        <div id="global-loader">
          <h2>データ処理中...</h2>
        </div>
      )}
      {/* /product list */}
      <div className="card table-list-card">
        <div className="card-body">
          <div className="table-top">
            <div className="search-set">
              <div className="search-input">
                <input
                  value={keyword}
                  type="text"
                  placeholder="Search"
                  className="form-control form-control-sm formsearch"
                  style={{ width: 200 }}
                  onChange={(e) => { 
                    setKeyword(e.target.value);
                    setFilterValue((prev) => ({
                      ...prev,
                      filter_keyword: e.target.value,
                    }))
                  }}
                  onKeyDown={handleSearch}
                  // suffix={<FaMagnifyingGlass />}
                />
                <button
                  type="button"
                  onClick={() => handleSearch(null)}
                  className="btn btn-searchset"
                >
                  <FaMagnifyingGlass />
                </button>
              </div>
              <div
                className="search-input"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "20px",
                }}
              >
                <label style={{ marginRight: "10px" }}>フィルタ</label>
                <Select
                  className="select"
                  options={topFilterOptions}
                  size="large"
                  style={{ width: "200px" }}
                  value={filterValue.topFilter}
                  onChange={(e) =>
                    setFilterValue((prev) => ({
                      ...prev,
                      topFilter: e,
                    }))
                  }
                />
                <button
                  type="button"
                  className="btn btn-primary me-2"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    filterValue.filter_keyword = "";
                    getResearchList()
                  }}
                >
                  検索
                </button>
              </div>
              <div
                className="search-input"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "20px",
                }}
              >
              </div>
              <button
                type="button"
                className="btn btn-primary me-2"
                onClick={() => {
                  setIsDrawer(true);
                }}
              >
                Filter Set
              </button>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="form-sort">
                <Select
                  className="select"
                  options={constants.PageSizeOptions}
                  placeholder="100"
                  onChange={handlePageSizeChange}
                />
              </div>
              <span
                style={{ whiteSpace: "nowrap", marginLeft: "10px" }}
              >
                件ずつ表示
              </span>
            </div>
          </div>
          <div className="table-responsive">
            <div style={{ display: "flex" }}>
              <div className="search-path">
                <div className="search-set">
                  <div className="search-input" style={{ display: "flex" }}>
                    <input
                      value={jumprow}
                      type="number"
                      placeholder="に行く..."
                      className="form-control form-control-sm formsearch"
                      onChange={(e) => setJumpRow(e.target.value)}
                      onKeyDown={handleJumpRow}
                    />
                    <span style={{ margin: "10px", width: "60%" }}>
                      {" "}
                      of {pageCount} pages
                    </span>
                  </div>
                </div>
              </div>
              <div
                style={{
                  textAlign: "end",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                {" "}
                検索結果 : {totalItems} 件中 {(pagination.current-1)*pagination.pageSize+1}-
                  {Math.min(pagination.current*pagination.pageSize, totalItems)} 件
              </div>
            </div>
            <br />
            <div ref={tableRef}>
              <Table bordered
                className="table datanew dataTable no-footer"
                // rowSelection={rowSelection}
                columns={columns}
                dataSource={dataSource}
                rowKey={(record) => record.id}
                tableLayout="auto"
                pagination={{
                  ...pagination,
                  // position: ['topRight', 'bottomRight'],
                  position: ["bottomRight"],
                  total: totalItems,
                  pageSizeOptions: [
                    "100",
                    "150",
                    "200",
                    "300",
                    "500",
                    "1000",
                  ],
                }}
                onChange={handleTableChange}
                scroll={{ y: "calc(100vh - 450px)" }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* /product list */}

      {/* Product edit modal START  */}
      <Modal title={<h4>編集 - ASIN: {editASIN}</h4>} open={edit_modal_visible} footer={false} onCancel={() => setEdit_modal_visible(false)} width={"95%"} centered>
        <div className="py-3">
          <div className="row mb-2">
            <div className="col-md-1 pt-2">
              <label className="form-label">US 商品名</label>
            </div>
            <div className="col-md-11">
              <input
                type="text"
                className="form-control"
                placeholder="US 商品名"
                value={editus_title ?? ''}
                onChange={onEditInputChange("us_title")}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-1 pt-2">
              <label className="form-label">JP 商品名</label>
            </div>
            <div className="col-md-11">
              <input
                type="text"
                className="form-control"
                value={editjp_title ?? ''}
                onChange={onEditInputChange("jp_title")}
                placeholder="JP 商品名"
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-1 pt-2">
              <label className="form-label">インボイス表記名</label>
            </div>
            <div className="col-md-7">
              <input
                type="text"
                className="form-control"
                value={edit_invoice_name ?? ''}
                onChange={onEditInputChange("invoice_name")}
                placeholder=""
              />
            </div>
            <div className="col-md-1 pt-2 text-end">
              <label className="form-label">HSコード</label>
            </div>
            <div className="col-md-1">
              <input
                type="text"
                className="form-control"
                value={edit_hs_code ?? ''}
                onChange={onEditInputChange("hs_code")}
                placeholder=""
              />
            </div>
            <div className="col-md-1 pt-2 text-end">
              <label className="form-label">HTSコード (fedex)</label>
            </div>
            <div className="col-md-1">
              <input
                type="text"
                className="form-control"
                value={edit_hts_code ?? ''}
                onChange={onEditInputChange("hts_code")}
                placeholder=""
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-5">
              <div className="row">
                <div className="col-md-3">
                  <label className="form-label mt-2">weeea2 FNSKU</label>
                  <input
                    type="text"
                    className="form-control"
                    value={edit_fnsku_weeea2 ?? ''}
                    onChange={onEditInputChange("fnsku_weeea2")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label mt-2">kt.japan FNSKU</label>
                  <input
                    type="text"
                    className="form-control"
                    value={edit_fnsku_kt ?? ''}
                    onChange={onEditInputChange("fnsku_kt")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label mt-2">njoy FNSKU</label>
                  <input
                    type="text"
                    className="form-control"
                    value={edit_fnsku_njoy ?? ''}
                    onChange={onEditInputChange("fnsku_njoy")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label mt-2">fhjiyama FNSKU</label>
                  <input
                    type="text"
                    className="form-control"
                    value={edit_fnsku_fh ?? ''}
                    onChange={onEditInputChange("fnsku_fh")}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="row mb-2">
                <div className="col-md-2 pt-2 text-end">
                  <label className="form-label">製品画像 URL</label>
                </div>
                <div className="col-md-10">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_image ?? ''}
                    onChange={onEditInputChange("image")}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-2 pt-2 text-end">
                  <label className="form-label">梱包画像 URL</label>
                </div>
                <div className="col-md-10">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_package_image ?? ''}
                    onChange={onEditInputChange("package_image")}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-2 pt-2 text-end">
                  <label className="form-label">Custom Product Type</label>
                </div>
                <div className="col-md-2">
                  <Select
                    style={{ width: "100%" }}
                    size="large"
                    options={constants.ProductTypeOptions}
                    onChange={onEditInputChange("product_type1")}
                    value={edit_product_type1}
                  />
                </div>

                <div className="col-md-2 pt-2 text-end">
                  <label className="form-label">コンディション</label>
                </div>
                <div className="col-md-2">
                  <Select
                    style={{ width: "100%" }}
                    size="large"
                    options={constants.ConditionOptions}
                    value={edit_itemcondition}
                    onChange={onEditInputChange("itemcondition")}
                  />
                </div>

                <div className="col-md-2 pt-2 text-end">
                  <label className="form-label">Variable closing fee</label>
                </div>
                <div className="col-md-2 pt-2">
                  <Checkbox
                    checked={edit_variable_closing_fee == 1 ? true : false} style={{ marginLeft: "10px" }}
                    onChange={onEditInputChange("variable_closing_fee")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-5">
              <div className="row mb-3">
                <div className="col-md-3">
                  <label className="form-label">長さ(cm)</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_length ?? ''}
                    onChange={onEditInputChange("length")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">幅(cm)</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_width ?? ''}
                    onChange={onEditInputChange("width")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">高さ(cm)</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_height ?? ''}
                    onChange={onEditInputChange("height")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">重量(g)</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_weight ?? ''}
                    onChange={onEditInputChange("weight")}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3">
                  <label className="form-label">セット数</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_totalofpackage ?? ''}
                    onChange={onEditInputChange("totalofpackage")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">仕入数量</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_purchase_stock ?? ''}
                    onChange={onEditInputChange("purchase_stock")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">ロット</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_min_num_orders ?? ''}
                    onChange={onEditInputChange("min_num_orders")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">在庫</label>
                  <input
                    type="text"
                    className="form-control"
                    id="field-3"
                    value={edit_us_stock ?? ''}
                    onChange={onEditInputChange("us_stock")}
                    placeholder=""
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3">
                  <label className="form-label">JP 手動価格(¥)</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editpurchase_price ?? ''}
                    placeholder="JP 手動価格"
                    onChange={onEditInputChange("purchase_price")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">US 販売価格($)</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editus_sell_price ?? ''}
                    placeholder="US 販売価格"
                    onChange={onEditInputChange("us_sell_price")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">発注先</label>
                  <Select 
                    options={constants.PurchasePlaces}
                    value={edit_purchase_place ?? ''}
                    style={{ width: "100%" }}
                    onChange={onEditInputChange("purchase_place")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">問屋</label><br/>
                  <Checkbox
                    checked={edit_is_own == 1 ? true : false} style={{ marginLeft: "10px" }}
                    onChange={onEditInputChange("is_own")}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3">
                  <label className="form-label">Amazon手数料(%)</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_us_amazon_fee ?? ''}
                    onChange={onEditInputChange("us_amazon_fee")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">FBA手数料($)</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_fba_fee_auto ?? ''}
                    onChange={onEditInputChange("fba_fee_auto")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">FBA販売者数</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_us_fba_seller_num ?? ''}
                    onChange={onEditInputChange("us_fba_seller_num")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">販売者数(全体)</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_us_seller_num ?? ''}
                    onChange={onEditInputChange("us_seller_num")}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3 pt-2 text-end">
                  <label className="form-label">インバウンドコスト</label>
                </div>
                <div className="col-md-3">
                  <input
                    type="text"
                    className="form-control"
                    value={edit_gate_fee ?? ''}
                    onChange={onEditInputChange("gate_fee")}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3 pt-2 text-end">
                  <label className="form-label">管理番号</label>
                </div>
                <div className="col-md-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_jan3 ?? ''}
                    onChange={() => {}}
                    readOnly
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3 pt-2 text-end">
                  <label className="form-label">梱包メモ</label>
                </div>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_package_note ?? ''}
                    onChange={onEditInputChange("package_note")}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3 pt-2 text-end">
                  <label className="form-label">梱包方法</label>
                </div>
                <div className="col-md-9">
                  <AutoComplete
                    value={edit_package_way || ""}
                    options={packageWayList}
                    style={{ width: "100%" }}
                    onSelect={(value) => onEditInputChange("package_way")(value)}
                    filterOption={(inputValue, option) => (inputValue.trim().length > 0 && option.value.indexOf(inputValue) != -1)}
                    onChange={onEditInputChange("package_way")}
                  >
                  </AutoComplete>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3 pt-2 text-end">
                  <label className="form-label">商品説明</label>
                </div>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_us_comment ?? ''}
                    onChange={onEditInputChange("us_comment")}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-7">
            <div className="row jan-input-wrapper">
              <div className="col-md-6">
                <div className="row">
                  <label className="col-md-3 form-label">JAN</label>
                  <label className="col-md-3 form-label">型番</label>
                  <label className="col-md-2 form-label">内訳数</label>
                  <label className="col-md-2 form-label px-0">単価(税抜)</label>
                  <label className="col-md-2 form-label px-0">単価(税込)</label>
                </div>
                {"1".repeat(15).split('').map((v, index) => {
                  return (
                    <div className="row" key={index}>
                      <div className="col-md-3 px-0 d-flex">
                        <label className="form-label pt-2 text-center" style={{width: 20}}>{index+1}</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="-"
                          value={edit_jan?.split(",")?.[index] ?? ''}
                          onChange={onEditSelectChange("jan", index)}
                        />
                      </div>
                      <div className="col-md-3 px-0">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="-"
                          value={edit_catalognumber?.split(",")?.[index] ?? ''}
                          onChange={onEditSelectChange("catalognumber", index)}
                        />
                      </div>
                      <div className="col-md-2 px-0">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="-"
                          value={edit_breakdown_qty?.split(",")?.[index] ?? ''}
                          onChange={onEditSelectChange("breakdown_qty", index)}
                        />
                      </div>
                      <div className="col-md-2 px-0">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="-"
                          value={edit_price_no_tax?.split(",")?.[index] ?? ''}
                          onChange={onEditSelectChange("price_no_tax", index)}
                        />
                      </div>
                      <div className="col-md-2 ps-0">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="-"
                          value={edit_price_tax?.split(",")?.[index] ?? ''}
                          onChange={onEditSelectChange("price_tax", index)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="col-md-6">
                <div className="row">
                  <label className="col-md-3 form-label">JAN</label>
                  <label className="col-md-3 form-label">型番</label>
                  <label className="col-md-2 form-label">内訳数</label>
                  <label className="col-md-2 form-label px-0">単価(税抜)</label>
                  <label className="col-md-2 form-label px-0">単価(税込)</label>
                </div>
                {"1".repeat(15).split('').map((v, index) => {
                  let idx = 15+index;
                  return (
                    <div className="row" key={idx}>
                      <div className="col-md-3 px-0 d-flex">
                        <label className="form-label pt-2 text-center" style={{width: 20}}>{idx+1}</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="-"
                          value={edit_jan?.split(",")?.[idx] ?? ''}
                          onChange={onEditSelectChange("jan", idx)}
                        />
                      </div>
                      <div className="col-md-3 px-0">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="-"
                          value={edit_catalognumber?.split(",")?.[idx] ?? ''}
                          onChange={onEditSelectChange("catalognumber", idx)}
                        />
                      </div>
                      <div className="col-md-2 px-0">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="-"
                          value={edit_breakdown_qty?.split(",")?.[idx] ?? ''}
                          onChange={onEditSelectChange("breakdown_qty", idx)}
                        />
                      </div>
                      <div className="col-md-2 px-0">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="-"
                          value={edit_price_no_tax?.split(",")?.[idx] ?? ''}
                          onChange={onEditSelectChange("price_no_tax", idx)}
                        />
                      </div>
                      <div className="col-md-2 ps-0">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="-"
                          value={edit_price_tax?.split(",")?.[idx] ?? ''}
                          onChange={onEditSelectChange("price_tax", idx)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-cancel waves-effect"
            onClick={() => setEdit_modal_visible(false)}
          >
            閉じる
          </button>
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
            style={{ marginLeft: "10px" }}
            onClick={handleUpdateProduct(editASINIndex)}
          >
            保存
          </button>
        </div>
      </Modal>
      {/* Product edit modal END */}

      {/* -------Filter Set Drawer start----------- */}
      <FilterDrawer 
        open={isDrawer}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        categoryList={categoryList}
        onOk={() => {
          setPagination({ current: 1, pageSize: pagination.pageSize });
          onDrawerClose();
          getResearchList();
        }}
        onCancel={onDrawerClose}
      />
      {/* -------Filter Set Drawer end----------- */}
    </>
  );
});

export default PurchaseList;
