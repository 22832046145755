/* eslint-disable */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { createBanSetting, deleteBanSetting, getBanSetting, updateBanSetting } from "../../../services";
import { useAuth } from "../../../hooks/useAuth";
import { Button, Input, Modal, Select, Table } from "antd";
import { FaCheck, FaPlus } from "react-icons/fa6";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import * as utils from "../../../utils/util";

const BanTab = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        initFunc,
    }));

    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [banASINData, setBanASINData] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const [pageCount, setPageCount] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [searchText, setSearchText] = useState("");

    const [modalData, setModalData] = useState({
        id: "",
        name: "",
        condition: "",
    });

    const columns = [
        {
            title: 'Asin',
            dataIndex: 'asin',
            key: 'asin',
        },
        {
            title: 'コンディション',
            dataIndex: 'asin_condition',
            key: 'asin_condition',
        },
        {
            title: '登録日付',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text) => {
                const dateObj = new Date(text);
                const formattedDate = dateObj.getFullYear() + "-" +
                    String(dateObj.getMonth() + 1).padStart(2, '0') + "-" +
                    String(dateObj.getDate()).padStart(2, '0') + " " +
                    String(dateObj.getHours()).padStart(2, '0') + ":" +
                    String(dateObj.getMinutes()).padStart(2, '0') + ":" +
                    String(dateObj.getSeconds()).padStart(2, '0');

                return formattedDate;
            }
        },
        {
            title: 'ユーザーアクション',
            key: 'action',
            width: 200,

            render: (text, record) => {
                return (
                    <div style={{ display: "flex", justifyContent: 'center' }}>
                        <Button type="primary" size="small" onClick={() => handleEdit(record)}>
                            <FiEdit size={13} />
                        </Button>
                        <Button type="primary" onClick={() => showConfirmationAlertASINDelete(record.id)} size="small" style={{ background: "red", marginLeft: "10px" }}>
                            <MdDelete size={15} color="#fff" />
                        </Button>
                    </div>
                )
            }
        },
    ]

    const initFunc = () => {
        getBanASINData();
    }

    useEffect(() => {
        initFunc();
    }, [pagination])

    const handleEdit = (record) => {
        setModalData(prev => ({ ...prev, name: record.asin, condition: record.asin_condition, id: record.id }))
        setIsModalVisible(true);
    }

    const getBanASINData = async () => {
        setLoading(true);
        const result = await getBanSetting({
            page: pagination.current - 1,
            count: pagination.pageSize,
            keyword: searchText,
            user
        });
        if (result.data && result.data.length > 0) {
            let pages = parseInt(result.data[0]["full_count"]) / pagination.pageSize + 1;
            setPageCount(pages.toFixed(0));
            setBanASINData(result.data);
            setTotalItems(result.data[0]["full_count"]);
        } else {
            setBanASINData([]);
            setTotalItems(0);
        }
        setLoading(false);
    }

    const handleCancel = () => {
        handleModalClear();
        setIsModalVisible(false);
    }

    const handleModalClear = () => {
        setModalData({ name: "", id: "", condition: "" })
    }
    const handleTableChange = (pagination) => {
        setPagination(pagination);
    };

    const handleSaveCategory = async () => {
        setLoading(true);
        let data = { ...modalData };
        data.user = user;
        if (modalData.id == "")
            await createBanSetting(data);
        else
            await updateBanSetting(data);
        handleCancel();
        initFunc();
        setLoading(false);
    }

    const showConfirmationAlertASINDelete = async (id) => {
        utils.showConfirmBox("削除しますか？", "これを元に戻すことはできません。", async () => {
            setLoading(true);
            await deleteBanSetting({
                id,
                user: user,
            });
            setLoading(false);
            initFunc();
        });
    };

    return (
        <>
            {loading && (
                <div id="global-loader">
                    <h2>データ処理中...</h2>
                </div>
            )}
            <div style={{ width: "100%" }}>
                <div style={{
                    height: "52px",
                    background: "#d9edf7",
                    color: "#3a87ad",
                    border: "1px solid #bce8f1",
                    padding: "0 20px",
                    width: "100%", display: "flex",
                    justifyContent: "space-between", alignItems: "center"
                }}>
                    <span>出品禁止ASINを入力して管理します。</span>
                </div>
                <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", margin: "10px 0" }}>
                    <div />
                    <button type="button" className="btn btn-success me-2" onClick={() => setIsModalVisible(true)}>
                        <FaPlus /> 追加
                    </button>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Select style={{ width: "100px", marginRight: "5px" }}
                            value={pagination.pageSize}
                            onChange={e => setPagination(prev => ({ ...prev, pageSize: e }))}
                        >
                            <Select.Option value={10}>10</Select.Option>
                            <Select.Option value={25}>25</Select.Option>
                            <Select.Option value={50}>50</Select.Option>
                            <Select.Option value={100}>100</Select.Option>
                        </Select>
                        行ずつ表示
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ whiteSpace: "nowrap", marginRight: "5px" }}>フィルタ</span>
                        <Input value={searchText} onChange={e => setSearchText(e.target.value)} onPressEnter={() => initFunc()} />
                    </div>
                </div>
                <Table bordered
                    size="small"
                    dataSource={banASINData}
                    columns={columns}
                    onChange={handleTableChange}
                    pagination={{
                        ...pagination,
                        total: totalItems,
                    }}
                />
                <Modal title="レコード編集" open={isModalVisible} onCancel={handleCancel} footer={false}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <h4 style={{ margin: "10px 0" }}>レコード追加</h4>
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                            <Input size="large" value={modalData.name} placeholder="ASIN" onChange={(e) => setModalData(prev => ({ ...prev, name: e.target.value }))} />
                            <div className="col-md-1" />
                            <Select style={{ width: "200px" }} size="large" value={modalData.condition} onChange={e => setModalData(prev => ({ ...prev, condition: e }))}>
                                <Select.Option value="New">新品</Select.Option>
                                <Select.Option value="Used">中古</Select.Option>
                            </Select>
                        </div>
                    </div>
                    <div
                        style={{ display: "flex", borderTop: "1px solid #e2e2e2", justifyContent: "flex-end", marginTop: "15px", paddingTop: "10px" }}
                    >
                        <button type="button" className="btn btn-success me-2" onClick={() => handleSaveCategory()}>
                            <FaCheck />  保存
                        </button>
                        <button type="button" className="btn btn-danger me-2" onClick={() => handleModalClear()}>
                            リセット
                        </button>
                        <button type="button" className="btn btn-dark me-2" onClick={() => handleCancel()}>
                            クローズ
                        </button>
                    </div>
                </Modal>
            </div>
        </>
    )
});

BanTab.displayName = "BanTab";

export default BanTab;