/* eslint-disable */
import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle, useMemo, useCallback } from "react";
import {
  FaCalculator, FaPencil, FaPrint, FaMagnifyingGlass, FaTrashCan, FaArrowRotateRight,
  FaAngleLeft, FaAngleRight, FaAnglesLeft, FaAnglesRight, FaBarsStaggered, FaCopy, FaRegCopy
} from 'react-icons/fa6';

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { all_routes } from "../../../Router/all_routes";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  InputNumber,
  Table,
  Input,
  Checkbox,
  Select,
  Modal,
  message,
  Popover,
  Pagination,
  AutoComplete,
} from "antd";

import {
  sendJobStatus,
  timeout,
  uploadCSVFileForFbaStockWaitData,
  CreateJob,
  printLabel,
  getCategoryData,
  getEmployee,

  fbaPurchaseASINList,
  updateFbaPurchaseProduct,
  deleteFbaPurchaseProduct,
  addDeliveryProduct,
  updateBulkFbaPurchaseProduct,
  getPackageWay,
  createPackageWay,
  deleteBulkFbaPurchaseProduct,
  addFbaPurchaseProduct,
  copyFbaPurchaseProduct,
  isTokenErrorResponse
} from "../../../services";
import { useAuth } from "../../../hooks/useAuth";
import * as utils from "../../../utils/util";
import * as constants from "../../../utils/constants";
import { FilterDrawer } from "../../../core/components/FilterDrawer";


const buttonStyle = { display: "inline-block", textAlign: "center", margin: "0 4px" };
const buttonTxtStyle = { fontSize: 10 };

const ButtonOneRight = ({ onClick }) => (
  <a style={buttonStyle} onClick={onClick}>
    <FaAngleRight />
    <div style={buttonTxtStyle}>1つ</div>
  </a>
)
const Button10Right = ({ onClick }) => (
  <a style={buttonStyle} onClick={onClick}>
    <FaAngleRight />
    <div style={buttonTxtStyle}>10つ</div>
  </a>
)
const ButtonAllRight = ({ onClick, title }) => (
  <a style={buttonStyle} onClick={onClick}>
    <FaAnglesRight />
    <div style={buttonTxtStyle}>{title || "全て"} </div>
  </a>
)
const ButtonOneLeft = ({ onClick }) => (
  <a style={buttonStyle} onClick={onClick}>
    <FaAngleLeft />
    <div style={buttonTxtStyle}>1つ</div>
  </a>
)
const ButtonAllLeft = ({ onClick }) => (
  <a style={buttonStyle} onClick={onClick}>
    <FaAnglesLeft />
    <div style={buttonTxtStyle}>全て</div>
  </a>
)


const initialData = {
  topFilter: "fba_input_ready_all",
  topAction: "none",
  order_by_value: "",
  order_by_way: 0, // 0 or 1
  filter_multi_asins: "",
  filter_us_min_rank: "",
  filter_us_max_rank: "",
  filter_us_min_weight: "",
  filter_us_max_weight: "",
  filter_us_min_price: "",
  filter_us_max_price: "",
  filter_jp_purchase_min_price: "",
  filter_jp_purchase_max_price: "",
  filter_jp_seller_min_price: "",
  filter_jp_seller_max_price: "",
  filter_min_profit_amount: "",
  filter_max_profit_amount: "",
  filter_min_profit_percent: "",
  filter_max_profit_percent: "",
  filter_product_type1_list: "",
  filter_us_category_list: "",
  filter_purchase_place: "",
  filter_ship_place: "",
  filter_employee_name: "",
  sales_count_not0: false,

  filter_start_order_date: "",
  filter_end_order_date: "",
  filter_start_purchase_date: "",
  filter_end_purchase_date: "",
  filter_keyword: "",
};

const topFilterOptions = [
  { value: "fba_input_ready_all", label: "全体" },
  { value: "is_new", label: "新品" },
  { value: "is_used", label: "中古" },
  { value: "FBA_is_SH", label: "FBA Small and Light商品" },
];

const topActionOptions = [
  { value: "none", label: "-- なし --" },
  { value: "delete_all", label: "一括削除" },
  { value: "upload_fbaready", label: "入庫待ちアップロード" },
  { value: "move_selected_wait_to_ready", label: "✓入庫待ち→梱包準備" },
  { value: "move_selected_ready_to_complete", label: "✓梱包準備→梱包完了" },
  { value: "move_selected_complete_to_packing", label: "✓梱包完了→箱詰め数" },
  { value: "move_selected_package_to_deliver", label: "✓箱詰め数→納品準備" },
]

const FbaManageList = forwardRef((props, ref) => {

  useImperativeHandle(ref, () => ({
    initFunc,
  }));

  const { user, logout } = useAuth();
  const tableRef = useRef(null);
  const csvUploadActionRef = useRef(null);

  const [isHeaderWidthSeted, setIsHeaderWidthSeted] = useState(false);
  const [headerWidths, setHeaderWidths] = useState([]);

  const [categoryList, setCategoryList] = useState();
  const [employeeList, setEmployeeList] = useState();
  const [packageWayList, setPackageWayList] = useState([]);

  const active_seller = useSelector((state) => state.active_seller);
  const store_list = useSelector((state) => state.store_list);

  const [printValue, setPrintValue] = useState({
    id: 0,
    asin: "",
    fba_sku: "",
    labelNumber: "",
    title: "",
    product_status: 0,
  });

  const route = all_routes;

  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);

  const [keyword, setKeyword] = useState("");
  const [filterValue, setFilterValue] = useState(initialData);
  const [csvUploadAction, setCSVUploadAction] = useState(null);

  const [isDrawer, setIsDrawer] = useState(false);
  const [isUploadModal, setIsUploadModal] = useState(false);

  const [isPrintModalOpen, setPrintModalOpen] = useState(false);

  const [dataSource, setDataSource] = useState([]);

  const [totalItems, setTotalItems] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });
  const [jumprow, setJumpRow] = useState("");

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [editASINIndex, setEditASINIndex] = useState(-1);
  const [editASIN, setEditASIN] = useState("");
  const [editpurchase_price, setEdit_purchase_price] = useState(0);
  const [editus_sell_price, setEditus_price] = useState(0);
  const [editjp_title, setEditjp_title] = useState("");
  const [editus_title, setEditus_title] = useState("");
  const [edit_jan, setEdit_jan] = useState("");
  const [edit_totalofpackage, setEdit_totalofpackage] = useState("");
  const [edit_totalquantity, setEdit_totalquantity] = useState("");
  const [edit_catalognumber, setEdit_catalognumber] = useState("");
  const [edit_package_image, setEdit_package_image] = useState("");
  const [edit_image, setEdit_image] = useState("");
  const [edit_weight, setEdit_weight] = useState(0);
  const [edit_width, setEdit_width] = useState(0);
  const [edit_height, setEdit_height] = useState(0);
  const [edit_length, setEdit_length] = useState(0);
  const [edit_jan3, setEdit_jan3] = useState("");
  const [edit_package_way, setEdit_package_way] = useState("");
  const [edit_package_note, setEdit_package_note] = useState("");
  const [edit_purchase_stock, setEdit_purchase_stock] = useState("");
  const [edit_purchase_stock_changed, setEdit_purchase_stock_changed] = useState(false);
  const [edit_us_stock, setEdit_us_stock] = useState("");

  const [edit_us_amazon_fee, setEdit_us_amazon_fee] = useState("");
  const [edit_fba_fee_auto, setEdit_fba_fee_auto] = useState("");
  const [edit_us_comment, setEdit_us_comment] = useState("");
  const [edit_itemcondition, setEdit_itemcondition] = useState("");
  const [edit_product_type1, setEdit_product_type1] = useState("");
  const [edit_profit, setEdit_profit] = useState("");
  const [edit_variable_closing_fee, setEdit_variable_closing_fee] = useState(1);
  const [edit_purchase_place, setEdit_purchase_place] = useState("");
  const [edit_ship_place, setEdit_ship_place] = useState("");
  const [edit_fnsku_weeea2, setEdit_fnsku_weeea2] = useState("");
  const [edit_fnsku_kt, setEdit_fnsku_kt] = useState("");
  const [edit_fnsku_njoy, setEdit_fnsku_njoy] = useState("");
  const [edit_fnsku_fh, setEdit_fnsku_fh] = useState("");

  const [edit_is_own, setEdit_is_own] = useState(0);
  const [edit_invoice_name, setEdit_invoice_name] = useState("");
  const [edit_hs_code, setEdit_hs_code] = useState("");
  const [edit_hts_code, setEdit_hts_code] = useState("");
  const [edit_min_num_orders, setEdit_min_num_orders] = useState("");
  const [edit_us_fba_seller_num, setEdit_us_fba_seller_num] = useState("");
  const [edit_us_seller_num, setEdit_us_seller_num] = useState("");
  const [edit_gate_fee, setEdit_gate_fee] = useState("");
  const [edit_breakdown_qty, setEdit_breakdown_qty] = useState("");
  const [edit_price_no_tax, setEdit_price_no_tax] = useState("");
  const [edit_price_tax, setEdit_price_tax] = useState("");

  const [edit_modal_visible, setEdit_modal_visible] = useState(false);

  const [previewPackageImageSrc, setPreviewPackageImageSrc] = useState("");
  const [previewPackageImageVisible, setPreviewPackageImageVisible] = useState(false);


  const handleRefresh = async () => {
    setKeyword("");
    setFilterValue(initialData);
    getResearchList(1, true);
  }

  const handleSearch = (e) => {
    if (e == null || e.key === "Enter") {
      if (keyword == "") {
        filterValue.filter_keyword = "";
      }
      setJumpRow("");
      setPagination({ current: 1, pageSize: pagination.pageSize });
      getResearchList();
      setKeyword("");
    }
  };

  const getResearchList = async (page = 1, refresh = false) => {
    try {
      let seller = "";
      if (active_seller != "すべてのアカウント") seller = active_seller;
      setLoading(true);

      const result = await fbaPurchaseASINList({
        page: page - 1,
        count: pagination.pageSize,
        user: user,
        seller: seller,
        product_status: 2,
        keyword: refresh ? '' : filterValue.filter_keyword,
        filterValue: refresh ? initialData : filterValue
      });
      if (isTokenErrorResponse(result)) {
        return logout();
      }

      if (result.data && result.data.length > 0) {
        setDataSource(result.data.map(item => ({
          ...item,
          purchase_place: item.purchase_place?.split(" ")[0]
        })));

        let pages = Math.ceil(parseInt(result.data[0]["full_count"]) / pagination.pageSize);
        setPageCount(pages);
        setTotalItems(result.data[0]["full_count"]);
      } else {
        setDataSource([]);
        setPageCount(0);
        setTotalItems(0);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handlePageChange = (page, pageSize) => {
    setPagination({
      current: page,
      pageSize: pageSize
    });
    getResearchList(page);
  };

  const handlePageSizeChange = (pagesize) => {
    pagination.pageSize = pagesize;
    getResearchList();
  };

  const handlePrintModalOpen = (index) => {
    const id = dataSource[index].id;
    const title = dataSource[index].jp_productname;
    const product_status = dataSource[index].product_status;
    var fba_sku = dataSource[index].fulfillment_sku;
    let asin = dataSource[index].asin;
    let labelNumber = dataSource[index].package_ready_stock;

    setPrintValue(prev => ({ ...prev, id, title, fba_sku, product_status, asin, labelNumber: labelNumber }))
    setPrintModalOpen(true)
  }

  const initFunc = () => {
    document.title = "製品準備";
    getOptions();
    getResearchList();
  }

  useEffect(() => {
    initFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_seller]);

  const onInputChange = (key, index) => (value) => {
    const newData = [...dataSource];

    if (value.target) {
      newData[index][key] = value.target.value;
    } else {
      newData[index][key] = value;
    }
    if (key == "weight") {
      newData[index]["jan1"] = 1;
      newData[index]["updatedat"] = Date.now();
    }

    setDataSource(newData);
  };

  const getOptions = async () => {
    const result = await getCategoryData({ user });
    setCategoryList(result?.data);

    const employees = await getEmployee({ user });
    setEmployeeList(employees?.data);

    getPackageWayList();
  }

  const getPackageWayList = async () => {
    const result = await getPackageWay({ user, page: 0, count: 100, keyword: '' });
    if (result.data && result.data.length > 0) {
      let packageways = result.data.map(item => ({ value: item.content }));
      setPackageWayList(packageways);
    }
  }

  const handleLabelPrint = async () => {
    await printLabel(printValue);

    await CreateJob({
      jobname: "fba_label_print",
      jobvalue: {
        id: printValue.id,
        fnsku: printValue.fba_sku,
        asin: printValue.asin,
        stock: printValue.labelNumber
      },
      user: user,
    });

    setPrintModalOpen(false)
  }
  const onEditSelectChange = (key, index) => (e) => {
    let value = e.target.value;
    const newData = [...dataSource];
    let newValue = '';
    switch (key) {
      case "jan":
        newValue = utils.updateArrayString(edit_jan, index, value);
        setEdit_jan(newValue);
        break;
      case "catalognumber":
        newValue = utils.updateArrayString(edit_catalognumber, index, value);
        setEdit_catalognumber(newValue);
        break;
      case "breakdown_qty":
        newValue = utils.updateArrayString(edit_breakdown_qty, index, value);
        setEdit_breakdown_qty(newValue);
        break;
      case "price_no_tax":
        newValue = utils.updateArrayString(edit_price_no_tax, index, value);
        setEdit_price_no_tax(newValue);
        break;
      case "price_tax":
        newValue = utils.updateArrayString(edit_price_tax, index, value);
        setEdit_price_tax(newValue);
        break;
    }
    newData[editASINIndex][key] = newValue;
    setDataSource(newData);
  }
  const onEditInputChange = (key) => (value) => {
    console.log("onEditInputChange key=", key);
    console.log("editASINIndex key=", editASINIndex);
    const newData = [...dataSource];
    let index = editASINIndex;
    switch (key) {
      case "us_price":
        setEditus_price(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "purchase_price":
        setEdit_purchase_price(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "us_title":
        setEditus_title(value.target.value);
        newData[index]["productname"] = value.target.value;
        break;
      case "jp_title":
        setEditjp_title(value.target.value);
        newData[index]["jp_productname"] = value.target.value;
        break;

      case "totalofpackage":
        setEdit_totalofpackage(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "image":
        setEdit_image(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "package_image":
        setEdit_package_image(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "weight":
        setEdit_weight(value.target.value);
        newData[index]["weight"] = value.target.value;
        break;
      case "width":
        setEdit_width(value.target.value);
        newData[index]["width"] = value.target.value;
        break;
      case "height":
        setEdit_height(value.target.value);
        newData[index]["height"] = value.target.value;
        break;
      case "length":
        setEdit_length(value.target.value);
        newData[index]["length"] = value.target.value;
        break;
      case "jan3":
        setEdit_jan3(value.target.value);
        newData[index]["jan3"] = value.target.value;
        break;
      case "package_way":
        setEdit_package_way(value);
        newData[index]["amazon_package_way"] = value;
        break;
      case "package_note":
        setEdit_package_note(value.target.value);
        newData[index]["amazon_package_note"] = value.target.value;
        break;
      case "purchase_stock":
        setEdit_purchase_stock(value.target.value);
        setEdit_purchase_stock_changed(true);
        newData[index][key] = value.target.value;
        break;
      case "us_stock":
        setEdit_us_stock(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "us_amazon_fee":
        setEdit_us_amazon_fee(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "fba_fee_auto":
        setEdit_fba_fee_auto(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "us_comment":
        setEdit_us_comment(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "itemcondition":
        setEdit_itemcondition(value);
        newData[index][key] = value;
        break;
      case "product_type1":
        setEdit_product_type1(value);
        newData[index][key] = value;
        break;
      case "total_quantity":
        setEdit_totalquantity(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "profit":
        setEdit_profit(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "variable_closing_fee":
        const val = value.target.checked ? 1 : 0;
        setEdit_variable_closing_fee(val);
        newData[index][key] = val;
        break;
      case "purchase_place":
        setEdit_purchase_place(value);
        newData[index][key] = value;
        break;
      case "ship_place":
        setEdit_ship_place(value);
        newData[index][key] = value;
        break;
      case "fnsku_weeea2":
        setEdit_fnsku_weeea2(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "fnsku_kt":
        setEdit_fnsku_kt(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "fnsku_njoy":
        setEdit_fnsku_njoy(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "fnsku_fh":
        setEdit_fnsku_fh(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "min_num_orders":
        setEdit_min_num_orders(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "invoice_name":
        setEdit_invoice_name(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "hs_code":
        setEdit_hs_code(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "hts_code":
        setEdit_hts_code(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "us_fba_seller_num":
        setEdit_us_fba_seller_num(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "us_seller_num":
        setEdit_us_seller_num(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "gate_fee":
        setEdit_gate_fee(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "is_own":
        setEdit_is_own(value.target.checked ? 1 : 0);
        newData[index][key] = value.target.checked ? 1 : 0;
        break;
    }
    setDataSource(newData);
  };

  const handleUpdateProduct = (index) => async () => {
    try {
      setLoading(true);
      const props = dataSource[index];
      props.user = user;

      if (edit_purchase_stock_changed) {
        if (props.package_ready_stock == 0 && props.package_complete_stock == 0 && props.package_count == 0) {
          props.purchase_wait_stock = props.purchase_stock;
        }
        else if (props.package_ready_stock > 0 && props.package_complete_stock == 0 && props.package_count == 0) {
          props.purchase_wait_stock = props.purchase_stock > props.package_ready_stock ? props.purchase_stock - props.package_ready_stock : 0;
          props.package_ready_stock = props.purchase_stock > props.package_ready_stock ? props.package_ready_stock : props.purchase_stock;
        }
        else if (props.package_ready_stock == 0 && props.package_complete_stock > 0 && props.package_count == 0) {
          props.purchase_wait_stock = props.purchase_stock > props.package_complete_stock ? props.purchase_stock - props.package_complete_stock : 0;
          props.package_complete_stock = props.purchase_stock > props.package_complete_stock ? props.package_complete_stock : props.purchase_stock;
        }
        else if (props.package_ready_stock == 0 && props.package_complete_stock == 0 && props.package_count > 0) {
          props.purchase_wait_stock = props.purchase_stock > props.package_count ? props.purchase_stock - props.package_count : 0;
          props.package_count = props.purchase_stock > props.package_count ? props.package_count : props.purchase_stock;
        }
        else {
          props.purchase_wait_stock = props.purchase_stock;
          props.package_ready_stock = 0;
          props.package_complete_stock = 0;
          props.package_count = 0;

        }
      }

      await updateFbaPurchaseProduct(props);
      if (props.amazon_package_way && props.amazon_package_way.trim().length > 0) {
        await createPackageWay({ name: props.amazon_package_way, user });
        getPackageWayList();
      }

      getResearchList(pagination.current);
      setEdit_modal_visible(false);
      setEdit_purchase_stock_changed(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  async function handleJobStatus(id) {
    const result = await sendJobStatus({ id: id, user: user });
    console.log(result);
    if (parseInt(result.data.status) > 0) {
      return true;
    }
    return false;
  }

  const handleCalculate = async (index) => {
    try {
      setLoading(true);
      const product = dataSource[index];
      console.log("product", product);
      const result = await CreateJob({
        jobname: "calculate_profit_purchase_asin",
        jobvalue: {
          name: "asin",
          value: product.asin,
          condition: product.itemcondition,
          sellername: product.sellername
        },
        user: user,
      });
      console.log("calculate response", result);
      if (result.data.id) {
        let id = result.data.id;
        let res = await handleJobStatus(id);
        while (!res) {
          timeout(1000);
          res = await handleJobStatus(id);
        }
        getResearchList(pagination.current);
      }

      //result.data == 1 :  success
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleWaitToReady = async (index, count) => {
    try {
      if (count == 0 || dataSource[index]["purchase_wait_stock"] < count) {
        return;
      }
      setLoading(true);
      var newData = [...dataSource];
      const props = newData[index];
      props["purchase_wait_stock"] -= count;
      props["package_ready_stock"] += count;
      props.user = user;
      await updateFbaPurchaseProduct(props);
      getResearchList(pagination.current);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }
  const handleReadyToComplete = async (index, count) => {
    try {
      if (count == 0 || dataSource[index]["package_ready_stock"] < count) {
        return;
      }
      setLoading(true);
      var newData = [...dataSource];
      const props = newData[index];
      props["package_ready_stock"] -= count;
      props["package_complete_stock"] += count;
      props.user = user;
      await updateFbaPurchaseProduct(props);
      getResearchList(pagination.current);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }
  const handleReadyToWait = async (index, count) => {
    try {

      if (count == 0 || dataSource[index]["package_ready_stock"] < count) {
        return;
      }
      setLoading(true);
      var newData = [...dataSource];
      const props = newData[index];
      props["package_ready_stock"] -= count;
      props["purchase_wait_stock"] += count;
      props.user = user;
      await updateFbaPurchaseProduct(props);
      getResearchList(pagination.current);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }
  const handleCompleteToPackage = async (index, count) => {
    try {
      if (count == 0 || dataSource[index]["package_complete_stock"] < count) {
        return;
      }
      setLoading(true);
      var newData = [...dataSource];
      const props = newData[index];
      props["package_complete_stock"] -= count;
      props["package_count"] += count;
      props.user = user;
      await updateFbaPurchaseProduct(props);
      getResearchList(pagination.current);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }
  const handleCompleteToReady = async (index, count) => {
    try {
      if (count == 0 || dataSource[index]["package_complete_stock"] < count) {
        return;
      }
      setLoading(true);
      var newData = [...dataSource];
      const props = newData[index];
      props["package_complete_stock"] -= count;
      props["package_ready_stock"] += count;
      props.user = user;
      await updateFbaPurchaseProduct(props);
      getResearchList(pagination.current);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }
  const handlePackageToDeliver = async (index, count) => {
    try {
      setLoading(true);
      const props = dataSource[index];
      props.user = user;
      await addDeliveryProduct(props);
      props["package_count"] = 0;
      props["jan1"] = null;

      if (props.purchase_wait_stock == 0 &&
        props.package_ready_stock == 0 &&
        props.package_complete_stock == 0 &&
        props.package_count == 0) {
        await deleteFbaPurchaseProduct({
          id: props.id,
          user: user,
        });
      } else {
        await updateFbaPurchaseProduct(props);
      }

      getResearchList(pagination.current);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }
  const handlePackageToComplete = async (index, count) => {
    try {
      if (count == 0 || dataSource[index]["package_count"] < count) {
        return;
      }
      setLoading(true);
      var newData = [...dataSource];
      const props = newData[index];
      props["package_count"] -= count;
      props["package_complete_stock"] += count;
      props.user = user;
      await updateFbaPurchaseProduct(props);
      getResearchList(pagination.current);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }


  const handleChangeReadyStock = (index) => async (e) => {
    try {
      var newData = [...dataSource];
      const props = newData[index];

      let value = Number(e.target.value);
      let orgVal = props["purchase_wait_stock"] + props["package_ready_stock"];
      if (value > orgVal) {
        return;
      }

      props["purchase_wait_stock"] = orgVal - value;
      props["package_ready_stock"] = value;

      setDataSource(newData);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }
  const handleChangeCompleteStock = (index) => async (e) => {
    try {
      var newData = [...dataSource];
      const props = newData[index];

      let value = Number(e.target.value);
      let orgVal = props["package_ready_stock"] + props["package_complete_stock"];
      if (value > orgVal) {
        return;
      }

      props["package_ready_stock"] = orgVal - value;
      props["package_complete_stock"] = value;

      setDataSource(newData);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }


  const handleProductEdit = async (index) => {
    try {
      const product = dataSource[index];

      setEditASINIndex(index);
      setEditASIN(product.asin);
      setEdit_totalquantity(product.totalquantity);
      setEdit_purchase_price(product.purchase_price);
      setEditus_price(product.us_price);
      setEditjp_title(product.jp_productname);
      setEditus_title(product.productname);
      setEdit_jan(product.jan);
      setEdit_totalofpackage(product.totalofpackage);
      setEdit_catalognumber(product.catalognumber);
      setEdit_image(product.image);
      setEdit_package_image(product.package_image);
      setEdit_weight(product.weight);
      setEdit_width(product.width);
      setEdit_height(product.height);
      setEdit_length(product.length);
      setEdit_jan3(product.jan3);
      setEdit_package_way(product.amazon_package_way);
      setEdit_package_note(product.amazon_package_note);
      setEdit_purchase_stock(product.purchase_stock);
      setEdit_us_stock(product.us_stock);
      setEdit_us_amazon_fee(product.us_amazon_fee);
      setEdit_fba_fee_auto(product.fba_fee_auto);
      setEdit_us_comment(product.us_comment);
      setEdit_itemcondition(product.itemcondition ? product.itemcondition : null);
      setEdit_product_type1(product.product_type1);
      setEdit_profit(product.profit);
      setEdit_variable_closing_fee(product.variable_closing_fee);
      setEdit_purchase_place(product.purchase_place);
      setEdit_ship_place(product.ship_place);
      setEdit_fnsku_weeea2(product.fnsku_weeea2);
      setEdit_fnsku_kt(product.fnsku_kt);
      setEdit_fnsku_njoy(product.fnsku_njoy);
      setEdit_fnsku_fh(product.fnsku_fh);
      setEdit_min_num_orders(product.min_num_orders);
      setEdit_invoice_name(product.invoice_name);
      setEdit_hs_code(product.hs_code);
      setEdit_hts_code(product.hts_code);
      setEdit_breakdown_qty(product.breakdown_qty);
      setEdit_price_no_tax(product.price_no_tax);
      setEdit_price_tax(product.price_tax);
      setEdit_us_fba_seller_num(product.us_fba_seller_num);
      setEdit_us_seller_num(product.us_seller_num);
      setEdit_gate_fee(product.gate_fee);
      setEdit_is_own(product.is_own);

      setEdit_modal_visible(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onHandleDate = (key, index) => async (e) => {
    setLoading(true);

    var newData = [...dataSource];
    newData[index][key] = e.target.value;
    setDataSource(newData);
    const props = newData[index];
    props.user = user;
    await updateFbaPurchaseProduct(props);

    setLoading(false);
  }

  const onHandleSelect = (key, index) => async (value) => {

    setLoading(true);
    var newData = [...dataSource];
    newData[index][key] = value;
    setDataSource(newData);
    const props = newData[index];
    props.user = user;
    await updateFbaPurchaseProduct(props);

    setLoading(false);
  }

  const columns = [
    {
      title: <span>商品画像<br />管理番号</span>,
      dataIndex: "image",
      width: 100,
      render: (text, record, index) => {
        return (
          <div>
            <div>
              <ImageWithBasePath
                alt=""
                src={text ?? "assets/img/products/product1.jpg"}
                isProduct={text ? true : false}
                preview={true}
                width={80}
                height={80}
              />
            </div>
            <div className="mt-4">{record.jan3}</div>
          </div>
        );
      },
    },
    {
      title: <span>商品タイトル</span>,
      dataIndex: "productname",
      className: "overflow-visible",
      width: 200,
      render: (text, record, index) => {
        let isHeader = (isHeaderWidthSeted && headerWidths[0] < 300 && headerWidths[1] > 10);
        let width = isHeader ? (headerWidths[2] + headerWidths[3] + headerWidths[4] + headerWidths[5]) : 464;
        width = width - 10;

        return (
          <div className="product-titles">
            <div className="line1">
              <Popover content={text}>
                <Link
                  to={utils.getAmazonUrl(record.asin)}
                  className="product-title"
                  target="_blank"
                >
                  {text}
                </Link>
              </Popover>
            </div>
            <div className="line2">
              <Popover content={record.jp_productname}>
                <Link
                  to={utils.getAmazonJpUrl(record.asin)}
                  className="product-title"
                  target="_blank"
                >
                  {record.jp_productname}
                </Link>
              </Popover>
            </div>
            <div className="line3 colspan" style={{ width: width }}>
              <Input
                style={{ width: width / 2 - 5 }}
                value={record.memo || ""}
                onPressEnter={handleUpdateProduct(index)}
                onChange={onInputChange("memo", index)}
                placeholder="メモ1"
              />
              <Input
                style={{ width: width / 2 - 5, marginLeft: 10 }}
                value={record.memo1 || ""}
                onPressEnter={handleUpdateProduct(index)}
                onChange={onInputChange("memo1", index)}
                placeholder="メモ2"
              />
            </div>
          </div>
        );
      },
    },
    {
      title: <span>日本価格</span>,
      dataIndex: "purchase_price",
      width: 90,
      render: (text, record, index) => {
        return (
          <div>
            <div className="line1">
              <InputNumber
                prefix="¥"
                value={record.purchase_price || ""}
                onPressEnter={handleUpdateProduct(index)}
                onChange={onInputChange("purchase_price", index)}
              />
            </div>
            <div className="line2">
              <a className="lowest-price" href={utils.getAmazonJpOfferListingUrl(record.asin, record.itemcondition)} target="_blank">
                最安 &nbsp;&nbsp;&nbsp;¥ {record.jp_lowest_price}
              </a>
            </div>
          </div>
        );
      },
    },
    {
      title: <span>米価格</span>,
      dataIndex: "us_price",
      width: 90,
      render: (text, record, index) => {
        return (
          <div>
            <div className="line1">
              <InputNumber
                prefix="$"
                value={record.us_price || ""}
                onPressEnter={handleUpdateProduct(index)}
                onChange={onInputChange("us_price", index)}
              />
            </div>
            <div className="line2">
              <a className="lowest-price" href={utils.getAmazonOfferListingUrl(record.asin, record.itemcondition)} target="_blank">
                最安 &nbsp;&nbsp;&nbsp;$ {record.us_lowest_price}
              </a>
            </div>
          </div>
        );
      },
    },
    {
      title: <span>利益額<br />利益率</span>,
      dataIndex: "profit",
      width: 70,
      render: (text, record) => {
        return (
          <div>
            <div className="line1">
              <span>¥ {Math.round(record.us_prev_profit)}</span>
            </div>
            <div className="line2">
              <span>{record.us_prev_profit_percent} %</span>
            </div>
          </div>
        );
      },
    },
    {
      title: <span>アカ</span>,
      dataIndex: "seller_name",
      className: "overflow-visible",
      width: 60,
      render: (text, record, index) => {
        let isHeader = (isHeaderWidthSeted && headerWidths[0] < 300 && headerWidths[1] > 10);
        let width3 = isHeader ? (headerWidths[6]) : 69;
        let width4 = isHeader ? (headerWidths[7] + headerWidths[8] + headerWidths[9] + headerWidths[10]) : 360;
        let width = width3 + width4 - 10;

        return (
          <div style={{ maxWidth: 60 }}>
            <div className="line1">
              <div className={`sellermark ${record.sellername}`}>
                {utils.getSellerMark(record.sellername, store_list)}
              </div>
            </div>
            <div className="line2">&nbsp;</div>
            <div className="line3 colspan" style={{ width: width }}>
              <a style={{ width: width3 + 10, display: "inline-block", color: record.package_image ? "blue" : "black", textAlign: "center" }}
                onClick={() => {
                  if (record.package_image) {
                    setPreviewPackageImageSrc(record.package_image);
                    setPreviewPackageImageVisible(true);
                  }
                }}>梱包画像</a>
              <AutoComplete
                value={record.amazon_package_way || ""}
                options={packageWayList}
                style={{ width: width4 - 20 }}
                onSelect={(value) => onInputChange("amazon_package_way", index)(value)}
                filterOption={(inputValue, option) => (inputValue.trim().length > 0 && option.value.indexOf(inputValue) != -1)}
                onChange={onInputChange("amazon_package_way", index)}
                placeholder="梱包方法"
              >
                <Input onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    handleUpdateProduct(index)();
                  }
                }} />
              </AutoComplete>
            </div>
          </div>
        );
      },
    },
    {
      title: <span>ASIN<br />FNSKU</span>,
      dataIndex: "asin",
      width: 100,
      render: (text, record) => {
        let color = utils.getSellerColor(record.sellername);
        let fnsku = utils.getSellerFNSKU(record, record.sellername);
        return (
          <div style={{ maxWidth: 100 }}>
            <div className="line1">
              <CopyToClipboard text={record.asin} onCopy={() => messageApi.success('Copied!')}>
                <a title="クリックでコピー">{record.asin}</a>
              </CopyToClipboard>
            </div>
            <div className="line2">
              <CopyToClipboard text={fnsku} onCopy={() => messageApi.success('Copied!')}>
                <a title="クリックでコピー" style={{ color: color }}>{fnsku}</a>
              </CopyToClipboard>
            </div>
          </div>
        );
      },
    },
    {
      title: <span>JAN<br />型番</span>,
      dataIndex: "jan",
      width: 100,
      render: (text, record) => {
        let jansArr = record.jan?.split(",");
        let jans = <span>
          {jansArr?.map((j, idx) => <span key={idx}>{j}<br /></span>)}
        </span>;

        let catasArr = record.catalognumber?.split(",");
        let catas = <span>
          {catasArr?.map((j, idx) => <span key={idx}>{j}<br /></span>)}
        </span>;
        return (
          <div style={{ maxWidth: 100 }}>
            <div className="line1">
              <Popover content={jans}>{jansArr?.[0]}</Popover>
            </div>
            <div className="line2">
              <Popover content={catas}>{catasArr?.[0] ?? '-'}</Popover>
            </div>
          </div>
        );
      },
    },
    {
      title: <span>サイズ<br />重量</span>,
      dataIndex: "product_size",
      className: "padding-none",
      width: 70,
      render: (text, record, index) => {
        var product_type = ((record.product_type == "") || (typeof record.product_type === "undefined")) ? "-" : record.product_type;
        let borderColor = "#d6000f";
        if (record.jan1 == 1) {
          borderColor = "#f1e100";
        }
        return (
          <div style={{ maxWidth: 70, minWidth: 60 }}>
            <div className="line1">{product_type}</div>
            <div className="line2">
              <Input
                value={record.weight || ""}
                style={{ padding: "3px", borderColor: borderColor, textAlign: "right" }}
                onPressEnter={() => {
                  dataSource[index].jan1 = 1;
                  handleUpdateProduct(index)();
                }}
                onChange={onInputChange("weight", index)}
              />g
            </div>
          </div>
        );
      },
    },
    {
      title: <span>発注日<br />入荷日</span>,
      dataIndex: "order_date",
      width: 100,
      render: (text, record, index) => {
        let purchaseDate = record.purchase_date;
        //if (purchaseDate == null || purchaseDate == "")
        //  purchaseDate = record.createdat?record.createdat.split("T")[0]:"";

        return <div>
          <div className="line1">
            <input
              type="date"
              value={record.order_date}
              onChange={onHandleDate("order_date", index)}
            />
          </div>
          <div className="line2">
            <input
              type="date"
              value={purchaseDate}
              onChange={onHandleDate("purchase_date", index)}
            />
          </div>
        </div>
      },
    },
    {
      title: <span>発注先<br />発注者</span>,
      dataIndex: "purchase_place",
      className: "overflow-visible",
      width: 75,
      render: (text, record, index) => {
        let isHeader = (isHeaderWidthSeted && headerWidths[0] < 300 && headerWidths[1] > 10);
        let width5 = isHeader ? (headerWidths[11]) : 58;
        let width6 = isHeader ? (headerWidths[12] + headerWidths[13]) : 83;
        let width = width5 + width6;

        return (
          <div style={{ maxWidth: 75, minWidth: 60 }}>
            <div className="line1">
              <Select
                options={constants.PurchasePlaces}
                value={record.purchase_place}
                style={{ width: "100%" }}
                onChange={onHandleSelect("purchase_place", index)}
                dropdownStyle={{ width: 150 }}
              />
            </div>
            <div className="line2">
              <Select
                options={constants.ShipPlaces}
                value={record.ship_place}
                style={{ width: "100%" }}
                onChange={onHandleSelect("ship_place", index)}
                dropdownStyle={{ width: 150 }}
              />
            </div>
            <div className="line3 colspan" style={{ width: width }}>
              <span style={{ width: width5, display: "inline-block", textAlign: "center" }}>梱包担当</span>
              <Select
                style={{ width: width6 }}
                value={record.employee_name}
                onChange={onHandleSelect("employee_name", index)}
              >
                {
                  employeeList && employeeList.map((item, key) =>
                    <Select.Option key={key} value={item.name}>
                      {item.name}
                    </Select.Option>)
                }
              </Select>
            </div>
          </div>
        );
      },
    },
    {
      title: <span>内容<br />数量</span>,
      dataIndex: "totalofpackage",
      width: 50,
      render: (text, record, index) => {
        return (
          <div style={{ maxWidth: 60, minWidth: 40 }}>
            <div className="line1">{record.totalofpackage} 個</div>
            <div className="line2">&nbsp;</div>
          </div>
        );
      },
    },
    {
      title: <span>仕入</span>,
      dataIndex: "purchase_stock",
      width: 50,
      render: (text, record, index) => {
        return (
          <div style={{ minWidth: 40 }}>
            <div className="line1">{record.purchase_stock}</div>
            <div className="line2">&nbsp;</div>
          </div>
        );
      },
    },
    {
      title: <span>入庫待ち</span>,
      dataIndex: "purchase_wait_stock",
      width: 70,
      className: "border-start _actions_",
      render: (text, record, index) => {
        return (
          <div>
            <div className="line1">{record.purchase_wait_stock}</div>
            <div className="line2">
              <ButtonOneRight onClick={() => handleWaitToReady(index, 1)} />
              <Button10Right onClick={() => handleWaitToReady(index, 10)} />
              <ButtonAllRight onClick={() => handleWaitToReady(index, record.purchase_wait_stock)} />
            </div>
            <div className="line3">
              {record.jan2 == 1 && <FaRegCopy />}
            </div>
          </div>
        );
      },
    },
    {
      title: <span>梱包準備</span>,
      dataIndex: "package_ready_stock",
      width: 70,
      className: "border-start actions",
      render: (text, record, index) => {

        let borderColor = "#d9d9d9";
        if (record.package_ready_stock > 0) {
          borderColor = "#f1e100";
        }

        return (
          <div>
            <div className="line1">
              <Input
                value={record.package_ready_stock}
                style={{ borderColor: borderColor, textAlign: "center" }}
                onPressEnter={handleUpdateProduct(index)}
                onChange={handleChangeReadyStock(index)}
              />
            </div>
            <div className="line2">
              <ButtonOneRight onClick={() => handleReadyToComplete(index, 1)} />
              <ButtonAllRight onClick={() => handleReadyToComplete(index, record.package_ready_stock)} />
            </div>
            <div className="line3">
              <ButtonOneLeft onClick={() => handleReadyToWait(index, 1)} />
              <ButtonAllLeft onClick={() => handleReadyToWait(index, record.package_ready_stock)} />
            </div>
          </div>
        );
      },
    },
    {
      title: <span>梱包完了</span>,
      dataIndex: "package_complete_stock",
      width: 70,
      className: "border-start actions",
      render: (text, record, index) => {
        let borderColor = "#d9d9d9";
        if (record.package_complete_stock > 0) {
          borderColor = "#f1e100";
        }
        return (
          <div>
            <div className="line1">
              <Input
                value={record.package_complete_stock}
                style={{ borderColor: borderColor, textAlign: "center", width: 50 }}
                onPressEnter={handleUpdateProduct(index)}
                onChange={handleChangeCompleteStock(index)}
                width={50}
              />
            </div>
            <div className="line2">
              <ButtonOneRight onClick={() => handleCompleteToPackage(index, 1)} />
              <Button10Right onClick={() => handleCompleteToPackage(index, 10)} />
              <ButtonAllRight onClick={() => handleCompleteToPackage(index, record.package_complete_stock)} />
            </div>
            <div className="line3">
              <ButtonOneLeft onClick={() => handleCompleteToReady(index, 1)} />
              <ButtonAllLeft onClick={() => handleCompleteToReady(index, record.package_complete_stock)} />
            </div>
          </div>
        );
      },
    },
    {
      title: <span>箱詰数量</span>,
      dataIndex: "package_count",
      width: 70,
      className: "border-start actions",
      render: (text, record, index) => {
        return (
          <div>
            <div className="line1">{record.package_count}</div>
            <div className="line2">
              <ButtonAllRight title={"納品"} onClick={() => handlePackageToDeliver(index, record.package_count)} />
            </div>
            <div className="line3">
              <ButtonOneLeft onClick={() => handlePackageToComplete(index, 1)} />
              <ButtonAllLeft onClick={() => handlePackageToComplete(index, record.package_count)} />
            </div>
          </div>
        );
      },
    },
    {
      title: "アクション",
      dataIndex: "action",
      className: "actions",
      width: 80,
      render: (text, record, index) => (
        <div className="action-table-data">
          <div className="edit-delete-action line1">
            <a
              className="me-1 p-1"
              onClick={() => handleCalculate(index)}
              title="利益計算"
            >
              <FaCalculator className="action-edit" />
            </a>
            <a
              className="me-1 p-1"
              onClick={() => handlePrintModalOpen(index)}
              title="ラベル出力"
            >
              <FaPrint className="action-edit" />
            </a>
          </div>
          <div className="edit-delete-action line2">
            <a
              className="me-1 p-1"
              onClick={() => handleProductEdit(index)}
              title="編集"
            >
              <FaPencil className="action-edit" />
            </a>
            <a
              className="me-1 p-1"
              onClick={() => {
                window.open(`${route.history}?search=${record.fulfillment_sku}&jobname=fba_label_print`, "_blank", "noreferrer");
              }}
              title="ラベル印刷記録"
            >
              <FaBarsStaggered className="action-edit" />
            </a>
          </div>
          <div className="edit-delete-action line3">
            <a
              className="confirm-text me-1 p-1"
              onClick={() => showConfirmationAlertASINDelete(index)}
              title="削除"
            >
              <FaTrashCan className="action-trash" />
            </a>
            <a
              className="me-1 p-1"
              onClick={() => handleCopyProduct(index)}
              title="Copy"
            >
              <FaCopy className="action-edit" />
            </a>
          </div>
        </div>
      ),
    },
  ];

  const showConfirmationAlertASINDelete = async (index) => {
    utils.showConfirmBox("削除しますか?", "これを元に戻すことはできません。", async () => {
      const product = dataSource[index];
      setLoading(true);
      const result = await deleteFbaPurchaseProduct({
        id: product.id,
        user: user,
      });
      setLoading(false);
      getResearchList(pagination.current);
    });
  };

  const handleCopyProduct = (index) => {
    utils.showConfirmBox("商品コピー", "この商品をコピーしますか?", async () => {
      setLoading(true);
      const product = dataSource[index];

      const newProduct = { ...product };
      newProduct.package_count = 0;
      newProduct.package_complete_stock = 0;
      newProduct.package_ready_stock = 0;
      newProduct.jan2 = "1";
      await copyFbaPurchaseProduct(newProduct.sellername, 2, newProduct, user);

      product.purchase_wait_stock = 0;
      product.package_ready_stock = 0;
      product.user = user;
      await updateFbaPurchaseProduct(product);

      setLoading(false);
      getResearchList(pagination.current);
    });
  };

  const onChangeUploadCSVActionFile = (event) => {
    const selectedFile = event.target.files[0];
    setCSVUploadAction(selectedFile)
    event.target.value = null;
  }

  const handleCSVUploadAction = async () => {
    setLoading(true);
    await uploadCSVFileForFbaStockWaitData({
      file: csvUploadAction,
      user: user,
    });
    getResearchList(pagination.current);
    setIsUploadModal(false);
    setCSVUploadAction(null);
    setLoading(false);
  }

  const handleTopAction = async () => {
    try {

      if (filterValue.topAction == "upload_fbaready") {

        setCSVUploadAction(null);
        setIsUploadModal(true);
        return;
      } else {

        if (selectedRowKeys.length == 0) {
          utils.showAlertBox("警告", "このアクションを適用するには、製品を選択する必要があります。");
          return;
        }

        if (filterValue.topAction == "delete_all") {
          utils.showConfirmBox("警告", "本当に削除しますか?", async () => {
            setLoading(true);
            let deleteData = [];
            for (let key of selectedRowKeys) {
              let item = dataSource.find(product => product.id == key);
              deleteData.push({
                id: item.id
              });
            }

            await deleteBulkFbaPurchaseProduct(deleteData, user);
            setLoading(false);
            getResearchList();
          });
          return;
        }

        if (filterValue.topAction == "move_selected_package_to_deliver") {
          setLoading(true);

          for (let key of selectedRowKeys) {
            let item = dataSource.find(product => product.id == key);
            item.user = user;
            await addDeliveryProduct(item);
            item.package_count = 0;

            if (item.purchase_wait_stock == 0 &&
              item.package_ready_stock == 0 &&
              item.package_complete_stock == 0 &&
              item.package_count == 0) {
              await deleteFbaPurchaseProduct({
                id: item.id,
                user: user,
              });
            } else {
              await updateFbaPurchaseProduct(item);
            }

          }

          getResearchList();
          setSelectedRowKeys([]);
          setLoading(false);

          return;
        }

        setLoading(true);
        let updateData = [];
        for (let key of selectedRowKeys) {
          let item = dataSource.find(product => product.id == key);
          let uitem = null;

          if (filterValue.topAction == "move_selected_wait_to_ready") {
            uitem = {
              id: item.id,
              package_ready_stock: item["package_ready_stock"] + item["purchase_wait_stock"],
              purchase_wait_stock: 0
            };
          } else if (filterValue.topAction == "move_selected_ready_to_complete") {
            uitem = {
              id: item.id,
              package_complete_stock: item["package_complete_stock"] + item["package_ready_stock"],
              package_ready_stock: 0
            };
          } else if (filterValue.topAction == "move_selected_complete_to_packing") {
            uitem = {
              id: item.id,
              package_count: item["package_count"] + item["package_complete_stock"],
              package_complete_stock: 0
            };
          }

          updateData.push(uitem);
        }

        await updateBulkFbaPurchaseProduct(updateData, user);
        getResearchList();
        setSelectedRowKeys([]);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onDrawerClose = () => {
    setIsDrawer(false);
  };

  const calculateHeaderWidths = () => {
    const bodyRows = tableRef.current.querySelectorAll(
      ".ant-table-tbody > tr"
    );

    const widths = [];
    for (var index = 0; index <= columns.length; index++) {
      const bodyCell = bodyRows[0].querySelector(
        `.ant-table-cell:nth-child(${index + 1})`
      );
      widths.push(bodyCell && bodyCell.offsetWidth);
    }

    setHeaderWidths(widths);
    if (!isHeaderWidthSeted) {
      setIsHeaderWidthSeted(true);
    }
  }


  useEffect(() => {
    const handleScroll = () => {
      calculateHeaderWidths();
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);
    // tableRef.current.addEventListener("load", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
      // tableRef.current.removeEventListener("load", handleScroll);
    };
  }, []);


  return (
    <>
      {loading && (
        <div id="global-loader">
          <h2>データ処理中...</h2>
        </div>
      )}

      {contextHolder}

      {/* /product list */}
      <div className="card table-list-card fbamanage">
        <div className="card-body">
          <div className="table-top">
            <div className="search-set">
              <a
                type="button"
                onClick={() => handleRefresh()}
                style={{ paddingRight: 10 }}
              >
                <FaArrowRotateRight />
              </a>

              <div className="search-input">
                <input
                  value={keyword}
                  type="text"
                  placeholder="Search"
                  className="form-control form-control-sm formsearch"
                  style={{ width: 200 }}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                    setFilterValue((prev) => ({
                      ...prev,
                      filter_keyword: e.target.value,
                    }))
                  }}
                  onKeyDown={handleSearch}
                />
                <button
                  type="button"
                  onClick={() => handleSearch(null)}
                  className="btn btn-searchset"
                >
                  <FaMagnifyingGlass />
                </button>
              </div>

              <div
                className="search-input"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "0",
                }}
              >
                <label style={{ marginRight: "10px" }}>アクション</label>
                <Select
                  className="select"
                  options={topActionOptions}
                  size="large"
                  style={{ width: "180px" }}
                  value={filterValue.topAction}
                  onChange={(e) =>
                    setFilterValue((prev) => ({
                      ...prev,
                      topAction: e,
                    }))
                  }
                />
                <button
                  type="button"
                  className="btn btn-primary me-2"
                  style={{ marginLeft: "10px" }}
                  onClick={handleTopAction}
                >
                  適用
                </button>
              </div>
              <button
                type="button"
                className="btn btn-primary me-2"
                onClick={() => {
                  setIsDrawer(true);
                }}
              >
                Filter Set
              </button>

            </div>
            <div style={{ display: "flex", alignItems: "center" }}>

              <div className="search-input">
                <Pagination current={pagination.current} pageSize={pagination.pageSize} showSizeChanger={false} total={totalItems} onChange={handlePageChange} />
              </div>

              <div style={{ marginLeft: 20, marginRight: 20 }}>
                検索結果 : {totalItems} 件中 {(pagination.current - 1) * pagination.pageSize + 1}-
                {Math.min(pagination.current * pagination.pageSize, totalItems)} 件
              </div>

              <div className="form-sort">
                <Select
                  className="select"
                  options={constants.PageSizeOptions}
                  placeholder="100"
                  onChange={handlePageSizeChange}
                />
              </div>
              <span
                style={{
                  whiteSpace: "nowrap",
                  marginLeft: "10px",
                }}
              >
                件ずつ表示
              </span>
            </div>
          </div>
          <div className="table-responsive">
            <div ref={tableRef}>
              <Table
                className="table datanew dataTable no-footer"
                rowSelection={{
                  type: "checkbox",
                  selectedRowKeys,
                  onChange: (newSelectedRowKeys) => {
                    setSelectedRowKeys(newSelectedRowKeys);
                  }
                }}
                tableLayout="auto"
                columns={columns}
                dataSource={dataSource}
                rowKey={(record) => record.id}
                pagination={false}
                onChange={handlePageChange}
                scroll={{ y: "calc(100vh - 350px)" }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* /product list */}

      {/* Product edit modal START  */}
      <Modal title={<h4>編集 - ASIN: {editASIN}</h4>} open={edit_modal_visible} footer={false} onCancel={() => setEdit_modal_visible(false)} width={"95%"} centered>
        <div className="py-3">
          <div className="row mb-2">
            <div className="col-md-1 pt-2">
              <label className="form-label">US 商品名</label>
            </div>
            <div className="col-md-11">
              <input
                type="text"
                className="form-control"
                placeholder="US 商品名"
                value={editus_title ?? ''}
                onChange={onEditInputChange("us_title")}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-1 pt-2">
              <label className="form-label">JP 商品名</label>
            </div>
            <div className="col-md-11">
              <input
                type="text"
                className="form-control"
                value={editjp_title ?? ''}
                onChange={onEditInputChange("jp_title")}
                placeholder="JP 商品名"
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-1 pt-2">
              <label className="form-label">インボイス表記名</label>
            </div>
            <div className="col-md-7">
              <input
                type="text"
                className="form-control"
                value={edit_invoice_name ?? ''}
                onChange={onEditInputChange("invoice_name")}
                placeholder=""
              />
            </div>
            <div className="col-md-1 pt-2 text-end">
              <label className="form-label">HSコード</label>
            </div>
            <div className="col-md-1">
              <input
                type="text"
                className="form-control"
                value={edit_hs_code ?? ''}
                onChange={onEditInputChange("hs_code")}
                placeholder=""
              />
            </div>
            <div className="col-md-1 pt-2 text-end">
              <label className="form-label">HTSコード (fedex)</label>
            </div>
            <div className="col-md-1">
              <input
                type="text"
                className="form-control"
                value={edit_hts_code ?? ''}
                onChange={onEditInputChange("hts_code")}
                placeholder=""
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-5">
              <div className="row">
                <div className="col-md-3">
                  <label className="form-label mt-2">weeea2 FNSKU</label>
                  <input
                    type="text"
                    className="form-control"
                    value={edit_fnsku_weeea2 ?? ''}
                    onChange={onEditInputChange("fnsku_weeea2")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label mt-2">kt.japan FNSKU</label>
                  <input
                    type="text"
                    className="form-control"
                    value={edit_fnsku_kt ?? ''}
                    onChange={onEditInputChange("fnsku_kt")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label mt-2">njoy FNSKU</label>
                  <input
                    type="text"
                    className="form-control"
                    value={edit_fnsku_njoy ?? ''}
                    onChange={onEditInputChange("fnsku_njoy")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label mt-2">fhjiyama FNSKU</label>
                  <input
                    type="text"
                    className="form-control"
                    value={edit_fnsku_fh ?? ''}
                    onChange={onEditInputChange("fnsku_fh")}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="row mb-2">
                <div className="col-md-2 pt-2 text-end">
                  <label className="form-label">製品画像 URL</label>
                </div>
                <div className="col-md-10">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_image ?? ''}
                    onChange={onEditInputChange("image")}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-2 pt-2 text-end">
                  <label className="form-label">梱包画像 URL</label>
                </div>
                <div className="col-md-10">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_package_image ?? ''}
                    onChange={onEditInputChange("package_image")}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-2 pt-2 text-end">
                  <label className="form-label">Custom Product Type</label>
                </div>
                <div className="col-md-2">
                  <Select
                    style={{ width: "100%" }}
                    size="large"
                    options={constants.ProductTypeOptions}
                    onChange={onEditInputChange("product_type1")}
                    value={edit_product_type1}
                  />
                </div>

                <div className="col-md-2 pt-2 text-end">
                  <label className="form-label">コンディション</label>
                </div>
                <div className="col-md-2">
                  <Select
                    style={{ width: "100%" }}
                    size="large"
                    options={constants.ConditionOptions}
                    value={edit_itemcondition}
                    onChange={onEditInputChange("itemcondition")}
                  />
                </div>

                <div className="col-md-2 pt-2 text-end">
                  <label className="form-label">Variable closing fee</label>
                </div>
                <div className="col-md-2 pt-2">
                  <Checkbox
                    checked={edit_variable_closing_fee == 1 ? true : false} style={{ marginLeft: "10px" }}
                    onChange={onEditInputChange("variable_closing_fee")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-5">
              <div className="row mb-3">
                <div className="col-md-3">
                  <label className="form-label">長さ(cm)</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_length ?? ''}
                    onChange={onEditInputChange("length")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">幅(cm)</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_width ?? ''}
                    onChange={onEditInputChange("width")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">高さ(cm)</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_height ?? ''}
                    onChange={onEditInputChange("height")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">重量(g)</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_weight ?? ''}
                    onChange={onEditInputChange("weight")}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3">
                  <label className="form-label">セット数</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_totalofpackage ?? ''}
                    onChange={onEditInputChange("totalofpackage")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">仕入数量</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_purchase_stock ?? ''}
                    onChange={onEditInputChange("purchase_stock")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">ロット</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_min_num_orders ?? ''}
                    onChange={onEditInputChange("min_num_orders")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">在庫</label>
                  <input
                    type="text"
                    className="form-control"
                    id="field-3"
                    value={edit_us_stock ?? ''}
                    onChange={onEditInputChange("us_stock")}
                    placeholder=""
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3">
                  <label className="form-label">JP 手動価格(¥)</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editpurchase_price ?? ''}
                    placeholder="JP 手動価格"
                    onChange={onEditInputChange("purchase_price")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">US 販売価格($)</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editus_sell_price ?? ''}
                    placeholder="US 販売価格"
                    onChange={onEditInputChange("us_sell_price")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">発注先</label>
                  <Select
                    options={constants.PurchasePlaces}
                    value={edit_purchase_place ?? ''}
                    style={{ width: "100%" }}
                    onChange={onEditInputChange("purchase_place")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">問屋</label><br />
                  <Checkbox
                    checked={edit_is_own == 1 ? true : false} style={{ marginLeft: "10px" }}
                    onChange={onEditInputChange("is_own")}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3">
                  <label className="form-label">Amazon手数料(%)</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_us_amazon_fee ?? ''}
                    onChange={onEditInputChange("us_amazon_fee")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">FBA手数料($)</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_fba_fee_auto ?? ''}
                    onChange={onEditInputChange("fba_fee_auto")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">FBA販売者数</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_us_fba_seller_num ?? ''}
                    onChange={onEditInputChange("us_fba_seller_num")}
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">販売者数(全体)</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_us_seller_num ?? ''}
                    onChange={onEditInputChange("us_seller_num")}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3 pt-2 text-end">
                  <label className="form-label">インバウンドコスト</label>
                </div>
                <div className="col-md-3">
                  <input
                    type="text"
                    className="form-control"
                    value={edit_gate_fee ?? ''}
                    onChange={onEditInputChange("gate_fee")}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3 pt-2 text-end">
                  <label className="form-label">管理番号</label>
                </div>
                <div className="col-md-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_jan3 ?? ''}
                    onChange={() => { }}
                    readOnly
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3 pt-2 text-end">
                  <label className="form-label">梱包メモ</label>
                </div>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_package_note ?? ''}
                    onChange={onEditInputChange("package_note")}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3 pt-2 text-end">
                  <label className="form-label">梱包方法</label>
                </div>
                <div className="col-md-9">
                  <AutoComplete
                    value={edit_package_way || ""}
                    options={packageWayList}
                    style={{ width: "100%" }}
                    onSelect={(value) => onEditInputChange("package_way")(value)}
                    filterOption={(inputValue, option) => (inputValue.trim().length > 0 && option.value.indexOf(inputValue) != -1)}
                    onChange={onEditInputChange("package_way")}
                  >
                  </AutoComplete>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3 pt-2 text-end">
                  <label className="form-label">商品説明</label>
                </div>
                <div className="col-md-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="-"
                    value={edit_us_comment ?? ''}
                    onChange={onEditInputChange("us_comment")}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="row jan-input-wrapper">
                <div className="col-md-6">
                  <div className="row">
                    <label className="col-md-3 form-label">JAN</label>
                    <label className="col-md-3 form-label">型番</label>
                    <label className="col-md-2 form-label">内訳数</label>
                    <label className="col-md-2 form-label px-0">単価(税抜)</label>
                    <label className="col-md-2 form-label px-0">単価(税込)</label>
                  </div>
                  {"1".repeat(15).split('').map((v, index) => {
                    return (
                      <div className="row" key={index}>
                        <div className="col-md-3 px-0 d-flex">
                          <label className="form-label pt-2 text-center" style={{ width: 20 }}>{index + 1}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="-"
                            value={edit_jan?.split(",")?.[index] ?? ''}
                            onChange={onEditSelectChange("jan", index)}
                          />
                        </div>
                        <div className="col-md-3 px-0">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="-"
                            value={edit_catalognumber?.split(",")?.[index] ?? ''}
                            onChange={onEditSelectChange("catalognumber", index)}
                          />
                        </div>
                        <div className="col-md-2 px-0">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="-"
                            value={edit_breakdown_qty?.split(",")?.[index] ?? ''}
                            onChange={onEditSelectChange("breakdown_qty", index)}
                          />
                        </div>
                        <div className="col-md-2 px-0">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="-"
                            value={edit_price_no_tax?.split(",")?.[index] ?? ''}
                            onChange={onEditSelectChange("price_no_tax", index)}
                          />
                        </div>
                        <div className="col-md-2 ps-0">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="-"
                            value={edit_price_tax?.split(",")?.[index] ?? ''}
                            onChange={onEditSelectChange("price_tax", index)}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <label className="col-md-3 form-label">JAN</label>
                    <label className="col-md-3 form-label">型番</label>
                    <label className="col-md-2 form-label">内訳数</label>
                    <label className="col-md-2 form-label px-0">単価(税抜)</label>
                    <label className="col-md-2 form-label px-0">単価(税込)</label>
                  </div>
                  {"1".repeat(15).split('').map((v, index) => {
                    let idx = 15 + index;
                    return (
                      <div className="row" key={idx}>
                        <div className="col-md-3 px-0 d-flex">
                          <label className="form-label pt-2 text-center" style={{ width: 20 }}>{idx + 1}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="-"
                            value={edit_jan?.split(",")?.[idx] ?? ''}
                            onChange={onEditSelectChange("jan", idx)}
                          />
                        </div>
                        <div className="col-md-3 px-0">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="-"
                            value={edit_catalognumber?.split(",")?.[idx] ?? ''}
                            onChange={onEditSelectChange("catalognumber", idx)}
                          />
                        </div>
                        <div className="col-md-2 px-0">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="-"
                            value={edit_breakdown_qty?.split(",")?.[idx] ?? ''}
                            onChange={onEditSelectChange("breakdown_qty", idx)}
                          />
                        </div>
                        <div className="col-md-2 px-0">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="-"
                            value={edit_price_no_tax?.split(",")?.[idx] ?? ''}
                            onChange={onEditSelectChange("price_no_tax", idx)}
                          />
                        </div>
                        <div className="col-md-2 ps-0">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="-"
                            value={edit_price_tax?.split(",")?.[idx] ?? ''}
                            onChange={onEditSelectChange("price_tax", idx)}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-cancel waves-effect"
            onClick={() => setEdit_modal_visible(false)}
          >
            閉じる
          </button>
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
            style={{ marginLeft: "10px" }}
            onClick={handleUpdateProduct(editASINIndex)}
          >
            保存
          </button>
        </div>
      </Modal>
      {/* Product edit modal END */}

      {/* -------Filter Set Drawer start----------- */}
      <FilterDrawer
        open={isDrawer}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        categoryList={categoryList}
        employeeList={employeeList}
        additionalOptions={{
          orderTypes: [
            { value: "fba_order_day", label: "注文日順" },
            { value: "fba_purchase_number", label: "仕入数量順" },
            { value: "fba_waiting_number", label: "入庫待ち数量順" },
            { value: "fba_package_ready_number", label: "梱包準備数量順" },
            { value: "fba_package_complete_number", label: "梱包完了数量順" },
            { value: "fba_package_number", label: "箱詰数量順" },
          ]
        }}
        onOk={() => {
          setPagination({ current: 1, pageSize: pagination.pageSize });
          onDrawerClose();
          getResearchList();
        }}
        onCancel={onDrawerClose}
      />
      {/* -------Filter Set Drawer end----------- */}
      {/* -------Print Modal start----------- */}
      <Modal
        title="ラベル印刷"
        open={isPrintModalOpen}
        onOk={() => handleLabelPrint()}
        okText="ラベル出力"
        cancelText="キャンセル"
        okButtonProps={{ disabled: printValue.labelNumber ? false : true }}
        onCancel={() => setPrintModalOpen(false)}
      >
        <form style={{ marginTop: "20px" }}>
          <div>
            <label>FBA SKU</label>
            <Input
              type="text"
              size="large"
              value={printValue.fba_sku}
              onChange={(e) =>
                setPrintValue((prev) => ({
                  ...prev,
                  fba_sku: e.target.value,
                }))
              }
            />
          </div>
          <div style={{ margin: "10px 0" }}>
            <label>ラベル枚数</label>
            <Input
              type="text"
              size="large"
              value={printValue.labelNumber}
              onChange={(e) =>
                setPrintValue((prev) => ({
                  ...prev,
                  labelNumber: e.target.value,
                }))
              }
            />
          </div>
          <div>
            <label>Title</label>
            <Input
              type="text"
              size="large"
              value={printValue.title}
              onChange={(e) =>
                setPrintValue((prev) => ({
                  ...prev,
                  title: e.target.value,
                }))
              }
            />
          </div>
        </form>
      </Modal>
      {/* -------Print Modal end----------- */}

      {/* upload fba wait  modal  */}
      <Modal
        title="入庫待ちアップロード"
        open={isUploadModal}
        okText="アップロード"
        cancelText="閉じる"
        onOk={() => handleCSVUploadAction()}
        onCancel={() => setIsUploadModal(false)}>
        <div className="modal-body p-4">
          <div className="row">
            <div className="col-md-12">
              <input
                type="file"
                ref={csvUploadActionRef}
                onChange={onChangeUploadCSVActionFile}
                style={{ display: 'none' }}
                accept=".csv"
              />
              <div style={{ display: "flex", alignItems: "center" }}>
                <button type="button" className="btn btn-primary me-2" onClick={() => csvUploadActionRef.current.click()}>
                  ファイル アップロード
                </button>
                <label className="form-label">
                  {csvUploadAction ? csvUploadAction.name : ""}
                </label>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* package image preview  */}
      <div style={{ display: 'none' }}>
        <ImageWithBasePath
          width={400}
          style={{ display: 'none' }}
          isProduct={previewPackageImageSrc ? true : false}
          src={previewPackageImageSrc}
          preview={{
            visible: previewPackageImageVisible,
            src: { previewPackageImageSrc },
            onVisibleChange: (value) => {
              setPreviewPackageImageVisible(value);
            },
          }}
        />
      </div>
      {/* */}
    </>
  );
});

export default FbaManageList;
