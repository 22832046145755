/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import {
    Tabs,
    Card,
} from "antd";

import BanTab from "./banTab";
import TitleTab from "./titleTab";
import ManufctureTab from "./manufactureTab";

const { TabPane } = Tabs;

const BanTabContainer = () => {
    const banRef = useRef();
    const titleRef = useRef();
    const manufactureRef = useRef();

    const [activeTab, setActiveTab] = useState(1);

    const handleChangeTab = (key) => {
        setActiveTab(key);
    }

    useEffect(() => {
        if (activeTab == 1) {
            if (banRef.current)
                banRef.current.initFunc();
        } else if (activeTab == 2) {
            if (titleRef.current)
                titleRef.current.initFunc();
        } else if (activeTab == 3) {
            if (manufactureRef.current)
                manufactureRef.current.initFunc();
        }
    }, [activeTab])

    return (
        <div>
            <h4 style={{ marginBottom: "10px" }}>Entry Ban Product</h4>
            {/* -------------------------------------------------------------- */}
            <Tabs defaultActiveKey="1" type="card" onChange={handleChangeTab}>
                <TabPane tab={<span>ASIN</span>} key="1">
                    <BanTab ref={banRef} />
                </TabPane>
                <TabPane tab={<span>商品名</span>} key="2">
                    <TitleTab ref={titleRef} />
                </TabPane>
                <TabPane tab={<span>メーカー</span>} key="3">
                    <ManufctureTab ref={manufactureRef} />
                </TabPane>
            </Tabs>
            {/* -------------------------------------------------------------- */}
        </div>
    );
};

export default BanTabContainer;
