import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Input, Select } from "antd";
import { getStores, updateStores } from "../../services";
import { useAuth } from "../../hooks/useAuth";

const SellerTab = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        initFunc,
    }));

    const initStoreAccount = {
        sellername: "",
        sellerid: "",
        marketplaceid: "",
        sp_app_client_id: "",
        sp_app_client_secret: "",
        refresh_token: "",
        aws_sp_role: "",
        googlesheetid: "",
        googlesheet_range: "",
        updatedAt: ""
    }
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [storeAccountValue, setStoreAccountValue] = useState(initStoreAccount);
    const [storeList, setStoreList] = useState([]);

    const initFunc = () => {
        getStoresList();
    };

    useEffect(() => {
        initFunc();
    }, [])

    const getStoresList = async () => {
        try {
            setLoading(true);
            const result = await getStores({ user });
            setStoreList(result.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    const handleStoreAccount = (key, value) => {
        if (key == "sellername") {
            var selectedSellerInfo = storeList.filter(item => item.sellername == value)[0];
            if (selectedSellerInfo.updatedAt != 0) {
                const date = new Date(Number(selectedSellerInfo.updatedAt));
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                const formattedDate = `${year}-${month}-${day}`;
                console.log(888, formattedDate);
                setStoreAccountValue({ ...selectedSellerInfo, updatedAt: formattedDate })
            } else {
                setStoreAccountValue({ ...selectedSellerInfo, updatedAt: "" })
            }
        }
        else {
            setStoreAccountValue(prev => ({ ...prev, [key]: value }))
        }
    }

    const handleStoreAccountSave = async () => {
        setLoading(true);
        let data = storeAccountValue;
        const updatedAt = data.updatedAt;
        const timeStamp = new Date(updatedAt).getTime();
        await updateStores({ ...data, updatedAt: timeStamp, user });
        setLoading(false);
    }

    const handleStoreAccountCancel = () => {
        setStoreAccountValue(initStoreAccount);
    }

    return (
        <>
            {loading && (
                <div id="global-loader">
                    <h2>データ処理中...</h2>
                </div>
            )}
            <div style={{ width: "100%" }}>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-4">
                        <div>Seller Name</div>
                        <Select
                            options={storeList.map(item => ({ value: item.sellername, label: item.sellername }))}
                            placeholder="Seller Name"
                            style={{ width: "100%" }}
                            onChange={(e) => handleStoreAccount("sellername", e)}
                            value={storeAccountValue.sellername}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-4">
                        <div>Seller ID</div>
                        <Input placeholder="Seller ID" value={storeAccountValue.sellerid} onChange={(e) => handleStoreAccount("sellerid", e.target.value)} />
                    </div>
                    <div className="col-md-4">
                        <div>MarketPlace ID</div>
                        <Input placeholder="MarketPlace ID" value={storeAccountValue.marketplaceid} onChange={(e) => handleStoreAccount("marketplaceid", e.target.value)} />
                    </div>
                    <div className="col-md-4">
                        <div>Client ID</div>
                        <Input placeholder="sp_app_client_id" value={storeAccountValue.sp_app_client_id} onChange={(e) => handleStoreAccount("sp_app_client_id", e.target.value)} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-4">
                        <div>Client Secret</div>
                        <Input placeholder="sp_app_client_secret" value={storeAccountValue.sp_app_client_secret} onChange={(e) => handleStoreAccount("sp_app_client_secret", e.target.value)} />
                    </div>
                    <div className="col-md-4">
                        <div>Refresh Token</div>
                        <Input placeholder="refresh_token" value={storeAccountValue.refresh_token} onChange={(e) => handleStoreAccount("refresh_token", e.target.value)} />
                    </div>
                    <div className="col-md-4">
                        <div>AWS Role</div>
                        <Input placeholder="aws_sp_role" value={storeAccountValue.aws_sp_role} onChange={(e) => handleStoreAccount("aws_sp_role", e.target.value)} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-4">
                        <div>Googlesheet ID</div>
                        <Input placeholder="googlesheetid" value={storeAccountValue.googlesheetid} onChange={(e) => handleStoreAccount("googlesheetid", e.target.value)} />
                    </div>
                    <div className="col-md-4">
                        <div>Googlesheet Range</div>
                        <Input placeholder="googlesheet_range" value={storeAccountValue.googlesheet_range} onChange={(e) => handleStoreAccount("googlesheet_range", e.target.value)} />
                    </div>
                    <div className="col-md-4">
                        <div>Updated Date</div>
                        <Input type="date" placeholder="updatedAt" value={storeAccountValue.updatedAt} onChange={(e) => handleStoreAccount("updatedAt", e.target.value)} />
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                    <button type="button" className="btn btn-success me-2" onClick={() => handleStoreAccountSave()}>
                        保存
                    </button>
                    <button type="button" className="btn btn-primary me-2" onClick={() => handleStoreAccountCancel()}>
                        解除
                    </button>
                </div>
            </div>
        </>
    )
});

SellerTab.displayName = "SellerTab";

export default SellerTab;