import React from "react";
import { Route, Navigate } from "react-router-dom";

import Signin from "../pages/login/signin";
import Dashboard from "../pages/dashboard/Dashboard";
import Settings from "../pages/settings";

import ResearchList from "../pages/research/Research";
import ProductDetail from "../pages/research/productdetail";

import ItemsSaleManagement from "../pages/sale-management";
import SalesList from "../pages/sale-management/sale/SalesList";
import PurchaseList from "../pages/sale-management/purchase/PurchaseList";

import DeliveryManagement from "../pages/delivery-management";
import DeliveryList from "../pages/delivery-management/delivery-product/deliveryProduct";
import RestockInventory from "../pages/restock-inventory/restockInventory";
import ItemsAllLogList from "../pages/delivery-management/delivery-all-log";

import FBALog from "../pages/delivery-management/items-fba-log";
import LogList from "../pages/delivery-management/items-delivery-log/deliveryLog";


import FBAProductorder from "../pages/fbaorder/FbaProductOrder";

import History from "../pages/history/historyList";


const routes = all_routes;
import { all_routes } from "./all_routes";

export const publicRoutes = [
  {
    id: 0,
    path: "/",
    name: "マイ ダッシュボード",
    element: <Dashboard />,
    route: Route,
  },
  {
    id: 1,
    path: routes.dashboard,
    name: "マイ ダッシュボード",
    element: <Dashboard />,
    route: Route,
  },
  {
    id: 117,
    path: routes.research,
    name: "商品情報調査",
    element: <ResearchList />,
    route: Route,
  },
  {
    id: 118,
    path: routes.saleslist,
    name: "出品中商品",
    element: <SalesList />,
    route: Route,
  },
  {
    id: 119,
    path: routes.deliverylist,
    name: "納品準備",
    element: <DeliveryList />,
    route: Route,
  },
  {
    id: 136,
    path: routes.allloglist,
    name: "納品総合ログ",
    element: <ItemsAllLogList />,
    route: Route,
  },
  {
    id: 122,
    path: routes.loglist,
    name: "納品ログ",
    element: <LogList />,
    route: Route,
  },
  {
    id: 137,
    path: routes.fbaloglist,
    name: "納品ログ",
    element: <FBALog />,
    route: Route,
  },
  {
    id: 139,
    path: routes.deliveryManagement,
    name: "納品管理",
    element: <DeliveryManagement />,
    route: Route,
  },
  {
    id: 140,
    path: routes.itemsSaleManagement,
    name: "納品管理",
    element: <ItemsSaleManagement />,
    route: Route,
  },
  {
    id: 123,
    path: routes.purchaselist,
    name: "仕入準備",
    element: <PurchaseList />,
    route: Route,
  },
  {
    id: 113,
    path: routes.productdetails,
    name: "productdetails",
    element: <ProductDetail />,
    route: Route,
  },

  {
    id: 133,
    path: routes.fbaproductorder,
    name: "発注ページ",
    element: <FBAProductorder />,
    route: Route,
  },
  {
    id: 133,
    path: routes.history,
    name: "記録管理",
    element: <History />,
    route: Route,
  },
  {
    id: 135,
    path: routes.restockInventory,
    name: "在庫補充",
    element: <RestockInventory />,
    route: Route,
  },
  {
    id: 136,
    path: routes.settings,
    name: "設定",
    element: <Settings />,
    route: Route,
  },

  {
    id: 115,
    path: "/",
    name: "Root",
    element: <Navigate to="/" />,
    route: Route,
  },
  {
    id: 116,
    path: "*",
    name: "NotFound",
    element: <Navigate to="/" />,
    route: Route,
  },


];

export const pagesRoute = [
  {
    id: 1,
    path: routes.signin,
    name: "signin",
    element: <Signin />,
    route: Route,
  },
];
