export const all_routes = {
  signin: "/signin",
  register: "/register",

  dashboard: "/dashboard",
  deliveryManagement: "/delivery-management",
  itemsSaleManagement: "/items-sale-management",
  research: "/research",
  saleslist: "/items-sale",
  //For Delivery Routes
  deliverysaleslist: "/delivery-items-sale",
  purchaselist: "/prepare-purchase",
  packComplete: "/items-pack-complete",
  stocklist: "/waiting-stock",
  packinglist: "/prepare-packing",
  deliverylist: "/prepare-delivery",
  loglist: "/delivery-log",
  fbaloglist: "/fba-log",
  allloglist: "/all-log",
  restockInventory : "/restock-inventory",
  settings: "/settings",
  productdetails: "/product-details",
  editproduct: "/edit-product",
  fbaproductorder: "/fba-product-order",

  history: "/history",
  

};
