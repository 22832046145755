/* eslint-disable */
import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { FiActivity } from "react-icons/fi";
import { FaCalculator, FaTrashCan, FaMagnifyingGlass, FaArrowUpFromBracket } from 'react-icons/fa6';

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { all_routes } from "../../../Router/all_routes";
import {
  InputNumber,
  Table,
  Checkbox,
  Select,
  Radio,
  Popover,
  Input,
} from "antd";
import {
  sendJobStatus,
  timeout,
  fbaProductASINList,
  deleteFBAProductASIN,
  updateFBAProduct,
  CreateJob,
  getCategoryData,
  addFbaPurchaseProduct,
  isTokenErrorResponse,
} from "../../../services";

import { FBASellerInfoDrawer } from "../../research/FBASellerInfoDrawer";

import { useAuth } from "../../../hooks/useAuth";
import { FilterDrawer } from "../../../core/components/FilterDrawer";
import * as utils from "../../../utils/util";
import * as constants from "../../../utils/constants";
import SellersGraphPopover from "../../research/SellersGraphPopover";


const initialData = {
  topFilter: "fba_list_all",
  topAction: "none",
  order_by_value: "",
  order_by_way: 0, // 0 or 1
  filter_multi_asins: "",
  filter_us_min_rank: "",
  filter_us_max_rank: "",
  filter_us_min_weight: "",
  filter_us_max_weight: "",
  filter_us_min_price: "",
  filter_us_max_price: "",
  filter_jp_purchase_min_price: "",
  filter_jp_purchase_max_price: "",
  filter_jp_seller_min_price: "",
  filter_jp_seller_max_price: "",
  filter_min_profit_amount: "",
  filter_max_profit_amount: "",
  filter_min_profit_percent: "",
  filter_max_profit_percent: "",
  filter_product_type1_list: "",
  filter_us_category_list: "",
  filter_purchase_place: "",
  filter_ship_place: "",
  filter_employee_name: "",
  sales_count_not0: false,

  filter_start_order_date: "",
  filter_end_order_date: "",
  filter_start_purchase_date: "",
  filter_end_purchase_date: "",

  filter_keyword: "",
};

const topFilterOptions = [
  { value: "fba_list_all", label: "全体" },
  { value: "fba_inbound_product", label: "納品商品" },
  { value: "fba_inbound_and_diff_price", label: "納品 ＆ Diff Price 商品" },
  { value: "fba_weeea2_product", label: "①WE 中商品" },
  { value: "fba_kt_product", label: "②KT 中商品" },
  { value: "fba_njoy_product", label: "③NJ 中商品" },
  { value: "fba_fh_product", label: "④FH 中商品" },
  { value: "fba_weeea2_product_and_fba_diff_price", label: "①WE FBA出品中 & Diff Price 商品" },
  { value: "fba_kt_product_and_fba_diff_price", label: "②KT FBA出品中 & Diff Price 商品" },
  { value: "fba_njoy_product_and_fba_diff_price", label: "③NJ FBA出品中 & Diff Price 商品" },
  { value: "fba_fh_product_and_fba_diff_price", label: "④FH FBA出品中 & Diff Price 商品" },
];

const topActionOptions = [
  { value: "none", label: "-- なし --" },
  { value: "calculate_profit_bulk_listing_page", label: "自動利益額計算" },
]

const SalesList = forwardRef((props, ref) => {
  const { user, logout } = useAuth();
  const tableRef = useRef(null);

  const active_seller = useSelector((state) => state.active_seller);
  const store_list = useSelector((state) => state.store_list);

  const [loading, setLoading] = useState(false);

  const [keyword, setKeyword] = useState("");
  const [filterValue, setFilterValue] = useState(initialData);

  const [isDrawer, setIsDrawer] = useState(false);

  const [categoryList, setCategoryList] = useState();

  const [dataSource, setDataSource] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });
  const [jumprow, setJumpRow] = useState("");

  // for seller info drawer
  const [sellerInfoOpen, setSellerInfoOpen] = useState(false);
  const [sellerInfoAsin, setSellerInfoAsin] = useState(null);

  const handleSearch = (e) => {
    if (e == null || e.key === "Enter") {
      if (keyword == "") {
        filterValue.filter_keyword = "";
      }

      setJumpRow(0);
      setPagination({ current: 1, pageSize: pagination.pageSize });
      getResearchList();
      setKeyword("");
    }
  };

  const handleJumpRow = (e) => {
    if (e.key === "Enter") {
      setPagination({
        current: parseInt(jumprow),
        pageSize: pagination.pageSize,
      });
      setJumpRow("");
      getResearchList(parseInt(jumprow));
    }
  };

  const getResearchList = async (page = 1) => {
    try {
      let seller = "";
      if (active_seller != "すべてのアカウント") seller = active_seller;
      setLoading(true);

      const result = await fbaProductASINList({
        page: page - 1,
        count: pagination.pageSize,
        user: user,
        keyword: filterValue.filter_keyword,
        seller: seller,
        filterValue
      });

      if (isTokenErrorResponse(result)) {
        return logout();
      }

      if (result.data && result.data.length > 0) {
        setDataSource(result.data);
        let pages = Math.ceil(parseInt(result.data[0]["full_count"]) / pagination.pageSize);
        setPageCount(pages);
        setTotalItems(result.data[0]["full_count"]);
      } else {
        setDataSource([]);
        setTotalItems(0);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
    getResearchList(pagination.current);
  };

  const handlePageSizeChange = (pagesize) => {
    pagination.pageSize = pagesize;
    getResearchList();
  };

  const initFunc = () => {
    document.title = "出品中商品";
    getResearchList();
    getCategory();
  }

  useImperativeHandle(ref, () => ({
    initFunc,
  }));

  useEffect(() => {
    initFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_seller]);

  const checkProductStatus = async (asin) => {
    window.open(`${all_routes.productdetails}?asin=${asin}`, "_blank", "noreferrer");
  };

  const onInputChange = (key, index) => (value) => {
    const newData = [...dataSource];
    if (value.target) {
      newData[index][key] = value.target.value;
    } else {
      newData[index][key] = value;
    }

    setDataSource(newData);
  };

  const getCategory = async () => {
    const result = await getCategoryData({ user });
    setCategoryList(result.data);
  }

  const handleUpdateProduct = (index) => async () => {
    try {
      setLoading(true);
      const props = dataSource[index];
      props.user = user;
      await updateFBAProduct(props);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  async function handleJobStatus(id) {
    const result = await sendJobStatus({ id: id, user: user });
    if (parseInt(result.data.status) > 0) {
      return true;
    }
    return false;
  }

  const handleCalculate = async (index) => {
    try {
      setLoading(true);
      const product = dataSource[index];
      console.log("product", product);
      const result = await CreateJob({
        jobname: "calculate_profit_asin",
        jobvalue: {
          name: "asin",
          value: product.asin,
          condition: product.itemcondition,
          sellername: product.sellername,
        },
        user: user,
      });
      if (result.data.id) {
        let id = result.data.id;
        let res = await handleJobStatus(id);
        while (!res) {
          timeout(1000);
          res = await handleJobStatus(id);
        }
        getResearchList(pagination.current);
      }

      //result.data == 1 :  success
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };


  const handleUpdatePriceStockAsin = async (index) => {
    try {
      setLoading(true);
      const product = dataSource[index];
      await CreateJob({
        jobname: "update_price_stock_asin",
        jobvalue: {
          name: "asin",
          value: product.asin,
          sellername: product.sellername,
        },
        user: user,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleViewSellerInfo = async (index) => {
    const product = dataSource[index];
    setSellerInfoAsin(product.asin);
    setSellerInfoOpen(true);
  }


  const showAddConfirm = (index, sellerName) => {
    utils.showConfirmBox("FBA製品の仕入準備ページに追加", "この商品をFBA商品仕入準備のページに追加しますか?", async () => {
      setLoading(true);
      const product = dataSource[index];
      console.log("product", product);
      const result = await addFbaPurchaseProduct(sellerName, 1, product, user);
      setLoading(false);
      getResearchList(pagination.current);
    });
  };



  const columns = [
    {
      title: "商品画像",
      dataIndex: "image",
      width: 100,
      render: (text, record) => {
        var sellerName = "";
        if (record.sellername == "weeea2")
          sellerName = "weeea2";
        else if (record.sellername == "kt.japan")
          sellerName = "kt";
        else if (record.sellername == "njoy")
          sellerName = "njoy";
        else if (record.sellername == "fhjiyama")
          sellerName = "fh";
        else if (record.sellername == "Musashi")
          sellerName = "mj";
        else if (record.sellername == "ur")
          sellerName = "ur";
        else if (record.sellername == "da")
          sellerName = "da";
        else if (record.sellername == "js")
          sellerName = "js";
        return (
          <span className="inventoryimgname">
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Link to={utils.getAmazonUrl(record.asin)} className="product-img stock-img" target="_blank">
                <ImageWithBasePath alt=""
                  src={text ?? "assets/img/products/product1.jpg"}
                  isProduct={text ? true : false}
                  width={80}
                  height={80} />
              </Link>
              {/* <div className="link-title" style={{ fontSize: "16px" }}>
                {sellerName}
              </div> */}
            </div>

          </span>
        );
      },
    },
    {
      title: (
        <span>商品名</span>
      ),
      dataIndex: "productname",
      width: 200,
      render: (text, record, index) => {
        return (
          <div className="inventoryimgname">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Popover content={text}>
                <Link
                  to={utils.getAmazonUrl(record.asin)}
                  className="product-title"
                  target="_blank"
                >
                  {text}
                </Link>
              </Popover>
              <Popover content={record.jp_productname}>
                <Link
                  to={utils.getAmazonJpUrl(record.asin)}
                  className="product-title"
                  target="_blank"
                >
                  {record.jp_productname}
                </Link>
              </Popover>
            </div>
          </div>
        );
      },
    },
    {
      title: <span>ASIN<br />コンディション</span>,
      dataIndex: "asin",
      width: 120,
      render: (text, record, index) => {
        return <span>
          <Link to={`https://sellercentral.amazon.com/skucentral?mSku=${record.asin}_FBA_N&ref=myi_skuc`} className="product-title text-blue" target="_blank">
            {record.asin}
          </Link>
          <br />
          {record.itemcondition == "New" ? "新品" : "中古"}
          <br />

        </span>
      }
    },
    {
      title: <span>FNSKU<br />SellerSKU</span>,
      dataIndex: "fnsku",
      width: 140,
      render: (text, record, index) => {
        return (
          <div>
            <Input
              className="product-title"
              value={record.fnsku}
              onPressEnter={handleUpdateProduct(index)}
              onChange={onInputChange("fnsku", index)}
              style={{ textAlign: "center" }}
            /><br />
            <span style={{ color: utils.getSellerColor(record.sellername) }}>{record.sellersku}</span>
          </div>
        );
      },
    },
    {
      title: <span>アカ</span>,
      dataIndex: "sellername",
      width: 80,
      render: (text, record, index) => {
        return (
          <div className={`sellermark ${record.sellername}`} onClick={() => showAddConfirm(index, record.sellername)}>
            {utils.getSellerMark(record.sellername, store_list)}
          </div>
        );
      }

    },
    {
      title: <span>JAN</span>,
      dataIndex: "us_sell_price",
      width: 120,
      render: (text, record, index) => {
        return (
          <div>
            {record.jan ? record.jan.split(",").map(jan => <>{jan}<br /></>) : ""}
          </div>
        );
      },
    },
    {
      title: <span>手動価格<br />問屋価格</span>,
      dataIndex: "jp_sell_price",
      width: 120,
      render: (text, record, index) => {
        return (
          <div>
            <InputNumber prefix="¥" value={record.purchase_price} onPressEnter={handleUpdateProduct(index)} onChange={onInputChange("purchase_price", index)} controls={false} /><br />
            <InputNumber prefix="¥" value={record.seller_price} onPressEnter={handleUpdateProduct(index)} onChange={onInputChange("seller_price", index)} style={{ marginTop: 5 }} controls={false} /><br />
            <a className="lowest-price d-flex justify-content-between p-2"
              href={utils.getAmazonJpOfferListingUrl(record.asin, record.itemcondition)} target="_blank">
              <span>最安</span>
              <span>¥ {record.jp_lowest_price}</span>
            </a>
          </div>
        );
      },
    },
    {
      title: <span>
        出品価格<br />
        他セラー最低価格<br />
        最低価格<br />
        おすすめ価格
      </span>,
      dataIndex: "us_sell_price",
      width: 140,
      render: (text, record, index) => {
        let updateat = utils.getDateTimeFormat3(Number(record.sellers_lowest_price_updateat));
        return (
          <div>
            <InputNumber prefix="$" value={record.us_sell_price} onPressEnter={handleUpdateProduct(index)} onChange={onInputChange("us_sell_price", index)} controls={false} />
            {record.sellers_lowest_price &&
              <div className="lowest-price d-flex justify-content-between py-2 px-1 gap-1">
                <span>({updateat})</span>
                <span>$ {record.sellers_lowest_price}</span>
              </div>
            }

            <a className="lowest-price d-flex justify-content-between p-2"
              href={utils.getAmazonOfferListingUrl(record.asin, record.itemcondition)} target="_blank">
              <span>最安</span>
              <span>$ {record.us_fba_lowest_price}</span>
            </a>
            {record.us_featuredoffer_price &&
              <div className="lowest-price d-flex justify-content-between p-2">
                <span>おすすめ</span>
                <span>$ {record.us_featuredoffer_price}</span>
              </div>
            }
          </div>
        );
      },
    },
    {
      title: <span>在庫あり<br />納品<br />販売不可<br />入出荷作業中</span>,
      dataIndex: "product_type",
      width: 80,
      render: (text, record, index) => {
        return (
          <div>
            <div>{record.available}</div>
            <div>{record.inbound}</div>
            <div>{record.unfulfillable}</div>
            <div>{record.reservedqty}</div>
          </div>
        );
      },
    },
    {
      title: <span>サイズ<br />重量</span>,
      dataIndex: "package_weight",
      width: 70,
      render: (text, record, index) => {
        return (
          <div>
            <div>{record.product_type ? record.product_type : "-"}</div>
            <div>{record.package_weight} g</div>
          </div>
        );
      },
    },
    {
      title: <span>利益額<br />利益率</span>,
      dataIndex: "profit",
      width: 70,
      render: (text, record, index) => {
        var color = "";
        var weight = "";

        let profit = (record.product_type != "SL") ? record.profit : record.us_profit_amount_sl;
        let profitRate = (record.product_type != "SL") ? record.profit_rate : record.us_profit_percent_sl;
        if (profitRate < 0) {
          color = "red";
          weight = "bold";
        } else if (profitRate <= 4) {
          color = "green";
          weight = "bold";
        } else {
          color = "black";
          weight = "normal";
        }
        return (
          <div>
            <div style={{ color: color, fontWeight: weight }}>¥ {Math.round(profit)}</div>
            <div style={{ color: color, fontWeight: weight }}>{profitRate} %</div>
          </div>
        );
      },
    },
    {
      title: <span>価格改定日時<br />在庫取得時間</span>,
      dataIndex: "updatedat",
      width: 100,
      render: (text, record, index) => {
        let formattedDate = utils.getDateTimeFormat2(Number(record.updatedat));
        let formattedDate2 = utils.getDateTimeFormat2(Number(record.jan4));
        return (
          <div>
            <div>{formattedDate}</div>
            <div className="mb-2">{formattedDate2}</div>
            <a style={{ color: "blue" }} onMouseOver={() => handleViewSellerInfo(index)} >FBA販売者情報</a>
          </div>
        );
      },
    },
    {
      title: "アクション",
      dataIndex: "action",
      width: 100,
      render: (text, record, index) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <a
              className="me-1 p-1"
              onClick={() => handleCalculate(index)}
              title="利益計算"
            >
              <FaCalculator className="action-edit" />
            </a>
            <Popover content={<SellersGraphPopover asin={record.asin} />}>
              <a
                className="me-1 p-1"
                onClick={() => checkProductStatus(record.asin)}
                title="詳細"
              >
                <FiActivity className="feather-eye" />
              </a>
            </Popover>
          </div>
          <div className="edit-delete-action">
            <a
              className="me-1 p-1"
              onClick={() => handleUpdatePriceStockAsin(index)}
              title="同期"
            >
              <FaArrowUpFromBracket className="action-edit" />
            </a>
            <a
              className="me-1 p-1"
              onClick={() => showConfirmationAlertASINDelete(index)}
              title="削除"
            >
              <FaTrashCan className="action-trash" />
            </a>
          </div>
        </div>
      ),
    },
  ];

  const showConfirmationAlertASINDelete = async (index) => {
    utils.showConfirmBox("削除しますか？", "これを元に戻すことはできません。", async () => {
      const product = dataSource[index];
      setLoading(true);
      const result = await deleteFBAProductASIN({
        id: product.id,
        user: user,
      });
      setLoading(false);
      getResearchList(pagination.current);
    });
  };


  const handleTopAction = async () => {
    try {

      utils.showConfirmBox("アクションの適用", "それを適用しますか？", async () => {
        setLoading(true);
        await CreateJob({
          jobname: filterValue.topAction,
          jobvalue: {
            name: "",
            value: "",
            condition: "",
            sellername: active_seller
          },
          user: user,
        });
        setLoading(false);
        getResearchList();
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const onDrawerClose = () => {
    setIsDrawer(false);
  };

  return (
    <>
      {loading && (
        <div id="global-loader">
          <h2>データ処理中...</h2>
        </div>
      )}
      {/* /product list */}
      <div className="card table-list-card">
        <div className="card-body">
          <div className="table-top">
            <div className="search-set">
              <div className="search-input">
                <input
                  value={keyword}
                  type="text"
                  placeholder="Search"
                  className="form-control form-control-sm formsearch"
                  style={{ width: 200 }}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                    setFilterValue((prev) => ({
                      ...prev,
                      filter_keyword: e.target.value,
                    }))
                  }}
                  onKeyDown={handleSearch}
                // suffix={<FaMagnifyingGlass />}
                />
                <button
                  type="button"
                  onClick={() => handleSearch(null)}
                  className="btn btn-searchset"
                >
                  <FaMagnifyingGlass />
                </button>
              </div>
              <div
                className="search-input"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "20px",
                }}
              >
                <label style={{ marginRight: "10px" }}>フィルタ</label>
                <Select
                  className="select"
                  options={topFilterOptions}
                  size="large"
                  style={{ width: "250px" }}
                  value={filterValue.topFilter}
                  onChange={(e) =>
                    setFilterValue((prev) => ({
                      ...prev,
                      topFilter: e,
                    }))
                  }
                />
                <button
                  type="button"
                  className="btn btn-primary me-2"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    filterValue.filter_keyword = "";
                    getResearchList()
                  }}
                >
                  検索
                </button>
              </div>
              <div
                className="search-input"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "20px",
                }}
              >
                <label style={{ marginRight: "10px" }}>アクション</label>
                <Select
                  className="select"
                  options={topActionOptions}
                  size="large"
                  style={{ width: "200px" }}
                  value={filterValue.topAction}
                  onChange={(e) =>
                    setFilterValue((prev) => ({
                      ...prev,
                      topAction: e,
                    }))
                  }
                />
                <button
                  type="button"
                  className="btn btn-primary me-2"
                  style={{ marginLeft: "10px" }}
                  onClick={handleTopAction}
                >
                  適用
                </button>
              </div>
              <button
                type="button"
                className="btn btn-primary me-2"
                onClick={() => {
                  setIsDrawer(true);
                }}
              >
                Filter Set
              </button>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="form-sort">
                <Select
                  className="select"
                  options={constants.PageSizeOptions}
                  placeholder="100"
                  onChange={handlePageSizeChange}
                />
              </div>
              <span style={{ whiteSpace: "nowrap", marginLeft: "10px" }}>
                件ずつ表示
              </span>
            </div>
          </div>
          <div className="table-responsive">

            <div style={{ display: "flex" }}>
              <div className="search-path">
                <div className="search-set">
                  <div className="search-input" style={{ display: "flex" }}>
                    <input
                      value={jumprow}
                      type="number"
                      placeholder="に行く..."
                      className="form-control form-control-sm formsearch"
                      onChange={(e) => setJumpRow(e.target.value)}
                      onKeyDown={handleJumpRow}
                    />
                    <span style={{ margin: "10px", width: "60%" }}>
                      {" "}
                      of {pageCount} pages
                    </span>
                  </div>
                </div>
              </div>
              <div
                style={{
                  textAlign: "end",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                {" "}
                検索結果 : {totalItems} 件中 {(pagination.current - 1) * pagination.pageSize + 1}-
                {Math.min(pagination.current * pagination.pageSize, totalItems)} 件
              </div>
            </div>
            <br />
            <div ref={tableRef}>
              <Table bordered
                className="table datanew dataTable no-footer"
                // rowSelection={rowSelection}
                columns={columns}
                dataSource={dataSource}
                rowKey={(record) => record.id}
                pagination={{
                  ...pagination,
                  // position: ['topRight', 'bottomRight'],
                  position: ["bottomRight"],
                  total: totalItems,
                  pageSizeOptions: [
                    "100",
                    "150",
                    "200",
                    "300",
                    "500",
                    "1000",
                  ],
                }}
                onChange={handleTableChange}
                scroll={{ y: "calc(100vh - 450px)" }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* /product list */}

      {/* -------Filter Set Drawer start----------- */}
      <FilterDrawer
        open={isDrawer}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        categoryList={categoryList}
        onOk={() => {
          setPagination({ current: 1, pageSize: pagination.pageSize });
          onDrawerClose();
          getResearchList();
        }}
        onCancel={onDrawerClose}
      />
      {/* -------Filter Set Drawer end----------- */}

      <FBASellerInfoDrawer
        open={sellerInfoOpen}
        asin={sellerInfoAsin}
        onClose={() => setSellerInfoOpen(false)}
      />
    </>
  );
});

export default SalesList;
