/* eslint-disable */
import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { FiActivity } from "react-icons/fi";
import { FaMagnifyingGlass } from 'react-icons/fa6';
import moment from 'moment';
// import 'moment/locale/ja';
// moment.locale('ja');

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { all_routes } from "../../Router/all_routes";
import {
  Table,
  Select,
  Popover,
  DatePicker,
  Checkbox,
} from "antd";
import {
  getBulkAsinSalesRanksHistory,
  getBulkProductDetails,
  getCategoryData,
  getFbaOrderList,
  isTokenErrorResponse,
} from "../../services";
import { useAuth } from "../../hooks/useAuth";
import { FilterDrawer } from "../../core/components/FilterDrawer";
import * as utils from "../../utils/util";
import * as constants from "../../utils/constants";
import SellersGraphPopover from "../research/SellersGraphPopover";
import { FBASellerInfoDrawer } from "../research/FBASellerInfoDrawer";
import RankingGraph from "../../core/components/RankingGraph";
import SellersGraph from "../../core/components/SellersGraph";


const initialData = {
  topFilter: "fba_list_all",
  topAction: "none",
  order_by_value: "",
  order_by_way: 0, // 0 or 1
  filter_multi_asins: "",
  filter_us_min_rank: "",
  filter_us_max_rank: "",
  filter_us_min_weight: "",
  filter_us_max_weight: "",
  filter_us_min_price: "",
  filter_us_max_price: "",
  filter_jp_purchase_min_price: "",
  filter_jp_purchase_max_price: "",
  filter_jp_seller_min_price: "",
  filter_jp_seller_max_price: "",
  filter_min_profit_amount: "",
  filter_max_profit_amount: "",
  filter_min_profit_percent: "",
  filter_max_profit_percent: "",
  filter_product_type1_list: "",
  filter_us_category_list: "",
  filter_purchase_place: "",
  filter_ship_place: "",
  filter_employee_name: "",
  sales_count_not0: false,

  order_date_filter: "",

  filter_start_order_date: "",
  filter_end_order_date: "",
  filter_start_purchase_date: "",
  filter_end_purchase_date: "",

  is_remove_duplication_asin: false,
  filter_keyword: "",
};

const topFilterOptions = [
  { value: "fba_list_all", label: "全体" },
  { value: "fba_pending_orders", label: "Pending 注文" },
  { value: "fba_shipped_orders", label: "Shipped 注文" },
  { value: "fba_canceled_orders", label: "Cenceled 注文" },
];

const topFilterOptions2 = [
  { value: "", label: "全体" },
  { value: "fba_last_day", label: "Last day" },
  { value: "fba_last_3days", label: "Last 3 days" },
  { value: "fba_last_7days", label: "Last 7 days" },
  { value: "fba_last_14days", label: "Last 14 days" },
  { value: "fba_last_30days", label: "Last 30 days" },
  { value: "fba_last_90days", label: "Last 90 days" },
  { value: "fba_last_180days", label: "Last 180 days" },
  { value: "fba_last_365days", label: "Last 365 days" },
  { value: "fba_custom_day_range", label: "Custom Date Range" },
];


const getLowestPriceByAsin = (asin, historyData) => {
  if (!historyData) return null;

  let item = historyData.find(item => item.asin == asin);
  if (item != undefined && item != null) {
    let lowestPrice = 0;
    let sellername = '';
    for (const sellerData of item.sellerData) {
      if (sellerData.is_live == 0 || sellerData.isfba != "true") continue;
      if (["A3JI8G7GLVJ8RK", "ADM4YVOBFVRQJ", "A1ZAW85G1UOJOM", "A1WBP1CV3MPY5V"].includes(sellerData.sellerid)) continue;

      if (lowestPrice == 0 || lowestPrice > sellerData.lowest_price) {
        sellername = sellerData.sellername;
        lowestPrice = sellerData.lowest_price;
      }
    }
    return { sellername, lowestPrice };
  }
  return null;
}
const getHistoryByAsin = (asin, historyData) => {
  let item = historyData.find(item => item.asin == asin);
  if (item != undefined && item != null) {
    return item;
  }
  return null;
}

const FbaProductOrder = forwardRef((props, ref) => {
  const { user, lgout } = useAuth();
  const tableRef = useRef(null);

  const active_seller = useSelector((state) => state.active_seller);
  const store_list = useSelector((state) => state.store_list);
  const sidebar_visible = useSelector((state) => state.sidebar_visible);

  const [headerWidths, setHeaderWidths] = useState([]);
  const [isHeaderFixed, setHeaderFixed] = useState(false);

  const route = all_routes;

  const [loading, setLoading] = useState(false);

  const [keyword, setKeyword] = useState("");
  const [filterValue, setFilterValue] = useState(initialData);

  const [isDrawer, setIsDrawer] = useState(false);

  const [categoryList, setCategoryList] = useState();

  const [dataSource, setDataSource] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const [weCount, setWeCount] = useState(0);
  const [ktCount, setKtCount] = useState(0);
  const [njCount, setNjCount] = useState(0);
  const [fhCount, setFhCount] = useState(0);

  const [pageCount, setPageCount] = useState(0);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });
  const [jumprow, setJumpRow] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);


  // for seller info drawer
  const [sellerInfoOpen, setSellerInfoOpen] = useState(false);
  const [sellerInfoAsin, setSellerInfoAsin] = useState(null);

  const [historyData, setHistoryData] = useState([]);


  const handleSearch = (e) => {
    if (e == null || e.key === "Enter") {
      if (keyword == "") {
        filterValue.filter_keyword = "";
      }
      setJumpRow(0);
      setPagination({ current: 1, pageSize: pagination.pageSize });
      getResearchList();
      setKeyword("");
    }
  };

  const handleJumpRow = (e) => {
    if (e.key === "Enter") {
      setPagination({
        current: parseInt(jumprow),
        pageSize: pagination.pageSize,
      });
      setJumpRow("");
      getResearchList(parseInt(jumprow));
    }
  };

  const getResearchList = async (page = 1) => {
    try {
      let seller = "";
      if (active_seller != "すべてのアカウント") seller = active_seller;
      setLoading(true);

      const result = await getFbaOrderList({
        page: page - 1,
        count: pagination.pageSize,
        user: user,
        keyword: filterValue.filter_keyword,
        seller: seller,
        filterValue
      });
      if (isTokenErrorResponse(result)) {
        return logout();
      }

      if (result.data && result.data.data && result.data.data.length > 0) {
        setDataSource(result.data.data);
        let pages = Math.ceil(parseInt(result.data.recordsTotal) / pagination.pageSize);
        setPageCount(pages);
        setTotalItems(result.data.recordsTotal);
        setWeCount(Number(result.data.weCount));
        setKtCount(Number(result.data.ktCount));
        setNjCount(Number(result.data.njCount));
        setFhCount(Number(result.data.fhCount));
      } else {
        setDataSource([]);
        setTotalItems(0);
        setWeCount(0);
        setKtCount(0);
        setNjCount(0);
        setFhCount(0);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
    getResearchList(pagination.current);
  };

  const handlePageSizeChange = (pagesize) => {
    pagination.pageSize = pagesize;
    getResearchList();
  };

  const initFunc = () => {
    document.title = "FBA 注文";
    getResearchList();
    getCategory();
  }

  useEffect(() => {
    getResearchList();
  }, [filterValue.is_remove_duplication_asin]);

  useImperativeHandle(ref, () => ({
    initFunc,
  }));

  useEffect(() => {
    initFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_seller]);

  const getCategory = async () => {
    const result = await getCategoryData({ user });
    setCategoryList(result.data);
  }


  const checkProductStatus = async (asin) => {
    window.open(`${route.productdetails}?asin=${asin}`, "_blank", "noreferrer");
  };

  const handleClickSeller = async (sellerId) => {
    window.open(`${route.research}?sellerId=${sellerId}`, "_blank", "noreferrer");
  }

  const handleViewSellerInfo = async (index) => {
    const product = dataSource[index];
    setSellerInfoAsin(product[0].asin);
    setSellerInfoOpen(true);
  }

  const isVisible = (record, index) => {
    return true;
  }
  const loadHistoryData = async () => {
    const asins = [];

    for (const product of dataSource) {
      let isExist = getHistoryByAsin(product[0].asin, historyData);
      if (!isExist) {
        asins.push(product[0].asin);
      }
    }
    if (asins.length == 0) {
      return;
    }

    const newHistories = [];

    const result1 = await getBulkProductDetails(asins, user);
    if (result1.data && result1.data.length > 0) {
      for (const sellerData of result1.data) {
        if (sellerData.length == 0) continue;
        newHistories.push({
          asin: sellerData[0].asin,
          sellerData: sellerData
        });
      }
    }
    const result2 = await getBulkAsinSalesRanksHistory(asins, user);
    if (result2.data && result2.data.length > 0) {
      for (const rankingData of result2.data) {
        if (rankingData.length == 0) continue;
        let existItem = newHistories.find(item => item.asin == rankingData[0].asin);
        if (existItem) {
          existItem.rankingData = rankingData;
        }
      }
    }

    setHistoryData([
      ...historyData,
      ...newHistories
    ]);
  }

  useEffect(() => {
    // if (!sidebar_visible) {
    loadHistoryData();
    // }
  }, [dataSource]);

  const columns = [
    {
      title: <span>注文日時</span>,
      dataIndex: "order_date",
      width: 120,
      render: (text, record, index) => {

        let lpinfo = getLowestPriceByAsin(record[0].asin, historyData);

        return (
          <div className="text-start">
            <strong>{moment(record[0].purchasedate).fromNow()}</strong><br />
            <span>{utils.getDateTimeFormat2(record[0].purchasedate).split(" ")[0]}</span><br />
            <span>{utils.getDateTimeFormat2(record[0].purchasedate).split(" ")[1]}</span>
            <div className={`ms-0 sellermark ${record[0].sellername}`} >
              {utils.getSellerMark(record[0].sellername, store_list)}
            </div>
            <div className="mt-1">
              <a style={{ color: "blue" }} onMouseOver={() => handleViewSellerInfo(index)}>FBA販売者情報</a>
            </div>
            <div className="mt-1">
              {lpinfo &&
                <>
                  {/* <span>{lpinfo.sellername}: </span> */}
                  <strong>$ {lpinfo.lowestPrice}</strong>
                </>
              }
            </div>
          </div>
        );
      },
    },
    {
      title: "商品画像",
      dataIndex: "image",
      width: 120,
      render: (text, record) => {
        return (
          <span className="inventoryimgname">
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Link to={utils.getAmazonUrl(record[0].asin)} className="product-img stock-img" target="_blank">
                <ImageWithBasePath alt=""
                  src={record[0].image ?? "assets/img/products/product1.jpg"}
                  isProduct={record[0].image ? true : false}
                  width={80}
                  height={80} />
              </Link>
            </div>
          </span>
        );
      },
    },
    {
      title: (
        <span>商品タイトル</span>
      ),
      width: 200,
      dataIndex: "productname",
      render: (text, record, index) => {
        let quantity = record[0].numberofitemsshipped + record[0].numberofitemsunshipped;
        return (
          <div className="position-relative">
            <div className="text-start" style={{ maxWidth: 350 }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Popover content={record[0].productname}>
                  <Link
                    to={utils.getAmazonUrl(record[0].asin)}
                    className="product-title"
                    target="_blank"
                    style={{ color: "#009688" }}
                  >
                    {record[0].productname}
                  </Link>
                </Popover>
                <Popover content={record[0].jp_productname}>
                  <Link
                    to={utils.getAmazonJpUrl(record[0].asin)}
                    className="product-title"
                    target="_blank"
                  >
                    {record[0].jp_productname}
                  </Link>
                </Popover>
                <span>ASIN: {record[0].asin}</span>
                <span style={{ color: utils.getSellerColor(record[0].sellername) }}>SKU: {record[0].sellersku}</span>
                <Popover content={record[0].jan}>
                  <span>JAN: {record[0].jan}</span>
                </Popover>
                <span>数量: {quantity}</span>
                <span>累計金額: {record[0].itemprice > 0 ? record[0].itemprice : (quantity * record[0].us_price)} $</span>
                <span>Order Item ID: {record[0].orderitemid}</span>

                <div className="action-table-data me-1 p-1 position-absolute end-0 bottom-0">
                  {record[0].we_order_last_30days > 0 && <div className="fw-bold" style={{ color: "#0040ff" }}>WE: {record[0].we_order_last_30days}</div>}
                  {record[0].kt_order_last_30days > 0 && <div className="fw-bold" style={{ color: "#008b00" }}>KT: {record[0].kt_order_last_30days}</div>}
                  {record[0].nj_order_last_30days > 0 && <div className="fw-bold" style={{ color: "#df0038" }}>NJ: {record[0].nj_order_last_30days}</div>}
                  {record[0].fh_order_last_30days > 0 && <div className="fw-bold" style={{ color: "#6d00da" }}>FH: {record[0].fh_order_last_30days}</div>}
                  <div className="mt-1">
                    <Popover
                      content={<SellersGraphPopover
                        // asin={record[0].asin}
                        data={getHistoryByAsin(record[0].asin, historyData)?.sellerData}
                      />}
                    >
                      <a
                        className=""
                        onClick={() => checkProductStatus(record[0].asin)}
                        title="詳細"
                      >
                        <FiActivity className="feather-eye" />
                      </a>
                    </Popover>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      },
    },

    {
      title: (
        <span>
          グラフ
        </span>
      ),
      dataIndex: "graph",
      width: 200,
      hidden: sidebar_visible,
      render: (text, record, index) => {
        return (
          <div style={{ maxWidth: 250 }}>
            {isVisible(record, index) ? (
              <>
                <div>
                  <RankingGraph
                    user={user}
                    data={getHistoryByAsin(record[0].asin, historyData)?.rankingData}
                    height={150}
                    simple={true}
                  />
                </div>
                <div>
                  <SellersGraph
                    user={user}
                    data={getHistoryByAsin(record[0].asin, historyData)?.sellerData}
                    height={150}
                    simple={true}
                  />
                </div>
              </>) : <></>}
          </div>
        );
      },
    },

    {
      title: <span>アカ</span>,
      dataIndex: "sellername", //
      width: 70,
      render: (text, record) => {

        const listingList = record[0].listing_asin_list;
        const values = [{}, {}, {}, {}];
        if (listingList) {
          for (const item of listingList) {
            let index = 0;
            if (item.sellername == "weeea2") index = 0;
            else if (item.sellername == "kt.japan") index = 1;
            else if (item.sellername == "njoy") index = 2;
            else if (item.sellername == "fhjiyama") index = 3;

            let profit = (record[0].product_type != "SL") ? item.profit : item.us_profit_amount_sl;
            let profitRate = (record[0].product_type != "SL") ? item.profit_rate : item.us_profit_percent_sl;
            values[index] = {
              price: item.us_sell_price,
              profit: profit,
              profitRate: profitRate,
            }
          }
        }


        return (
          <div>
            {(record[0].we_list_date !== null || record[0].we_units_sold_last_30days !== null || record[0].we_total_units !== null || record[0].we_supplier !== null) &&
              <div className={`text-start mb-1`}>
                <span className={`sellermark weeea2 ps-1 pe-1 me-1`}>{utils.getSellerMark("weeea2", store_list)} </span>
                ${values[0].price} ¥{Math.round(values[0].profit)} ({values[0].profitRate}%)
              </div>
            }
            {(record[0].kt_list_date !== null || record[0].kt_units_sold_last_30days !== null || record[0].kt_total_units !== null || record[0].kt_supplier !== null) &&
              <div className="text-start mb-1">
                <span className={`sellermark kt.japan ps-1 pe-1 me-1`}>{utils.getSellerMark("kt.japan", store_list)} </span>
                ${values[1].price} ¥{Math.round(values[1].profit)} ({values[1].profitRate}%)
              </div>
            }
            {(record[0].njoy_list_date !== null || record[0].njoy_units_sold_last_30days !== null || record[0].njoy_total_units !== null || record[0].njoy_supplier !== null) &&
              <div className="text-start mb-1">
                <span className={`sellermark njoy ps-1 pe-1 me-1`}>{utils.getSellerMark("njoy", store_list)} </span>
                ${values[2].price} ¥{Math.round(values[2].profit)} ({values[2].profitRate}%)
              </div>
            }
            {(record[0].fh_list_date !== null || record[0].fh_units_sold_last_30days !== null || record[0].fh_total_units !== null || record[0].fh_supplier !== null) &&
              <div className="text-start mb-1">
                <span className={`sellermark fhjiyama ps-1 pe-1 me-1`}>{utils.getSellerMark("fhjiyama", store_list)} </span>
                ${values[3].price} ¥{Math.round(values[3].profit)} ({values[3].profitRate}%)
              </div>
            }
          </div>
        );
      },
    },
    {
      title: <span>売上実績<br />(30日)</span>,
      dataIndex: "sold_last_days", //
      width: 70,
      render: (text, record) => {
        return (
          <div>
            {(record[0].we_list_date !== null || record[0].we_units_sold_last_30days !== null || record[0].we_total_units !== null || record[0].we_supplier !== null) &&
              <div className="mb-1">{record[0].we_units_sold_last_30days ?? '-'}</div>
            }
            {(record[0].kt_list_date !== null || record[0].kt_units_sold_last_30days !== null || record[0].kt_total_units !== null || record[0].kt_supplier !== null) &&
              <div className="mb-1">{record[0].kt_units_sold_last_30days ?? '-'}</div>
            }
            {(record[0].njoy_list_date !== null || record[0].njoy_units_sold_last_30days !== null || record[0].njoy_total_units !== null || record[0].njoy_supplier !== null) &&
              <div className="mb-1">{record[0].njoy_units_sold_last_30days ?? '-'}</div>
            }
            {(record[0].fh_list_date !== null || record[0].fh_units_sold_last_30days !== null || record[0].fh_total_units !== null || record[0].fh_supplier !== null) &&
              <div>{record[0].fh_units_sold_last_30days ?? '-'}</div>
            }
          </div>
        );
      },
    },
    {
      title: <span>在庫数量</span>,
      dataIndex: "total_units",
      width: 80,
      render: (text, record) => {
        let restock_asin_list = record[0].restock_asin_list;
        let stris_purchase_recommend_we = ""
        let stris_purchase_recommend_kt = ""
        let stris_purchase_recommend_nj = ""
        let stris_purchase_recommend_fh = ""
        for (let i = 0; i < restock_asin_list.length; i++) {
          let item = restock_asin_list[i]
          if (item.is_purchase_recommend == 1) {
            if (item.sellername == "weeea2") stris_purchase_recommend_we = " : 発注"
            if (item.sellername == "kt.japan") stris_purchase_recommend_kt = " : 発注"
            if (item.sellername == "njoy") stris_purchase_recommend_nj = " : 発注"
            if (item.sellername == "fhjiyama") stris_purchase_recommend_fh = " : 発注"
          }
        }
        return (
          <div>
            {(record[0].we_list_date !== null || record[0].we_units_sold_last_30days !== null || record[0].we_total_units !== null || record[0].we_supplier !== null) &&
              <div className="mb-1 text-start position-relative">{record[0].we_total_units !== null ? record[0].we_total_units + ' 個' : '-'}<span className="fw-bold">{stris_purchase_recommend_we}</span></div>
            }
            {(record[0].kt_list_date !== null || record[0].kt_units_sold_last_30days !== null || record[0].kt_total_units !== null || record[0].kt_supplier !== null) &&
              <div className="mb-1 text-start position-relative">{record[0].kt_total_units !== null ? record[0].kt_total_units + ' 個' : '-'}<span className="fw-bold">{stris_purchase_recommend_kt}</span></div>
            }
            {(record[0].njoy_list_date !== null || record[0].njoy_units_sold_last_30days !== null || record[0].njoy_total_units !== null || record[0].njoy_supplier !== null) &&
              <div className="mb-1 text-start position-relative">{record[0].njoy_total_units !== null ? record[0].njoy_total_units + ' 個' : '-'}<span className="fw-bold">{stris_purchase_recommend_nj}</span></div>
            }
            {(record[0].fh_list_date !== null || record[0].fh_units_sold_last_30days !== null || record[0].fh_total_units !== null || record[0].fh_supplier !== null) &&
              <div className="mb-1 text-start position-relative">{record[0].fh_total_units !== null ? record[0].fh_total_units + ' 個' : '-'}<span className="fw-bold">{stris_purchase_recommend_fh}</span></div>
            }
          </div>
        );
      },
    },
    {
      title: <span>Supplier</span>,
      dataIndex: "supplier", //
      width: 110,
      render: (text, record) => {
        return (
          <div>
            {(record[0].we_list_date !== null || record[0].we_units_sold_last_30days !== null || record[0].we_total_units !== null || record[0].we_supplier !== null) &&
              <div className="mb-1">{(record[0].we_supplier && record[0].we_supplier != "unassigned") ? (record[0].we_supplier.replace(/ 20/, " ")) : "-"}</div>
            }
            {(record[0].kt_list_date !== null || record[0].kt_units_sold_last_30days !== null || record[0].kt_total_units !== null || record[0].kt_supplier !== null) &&
              <div className="mb-1">{(record[0].kt_supplier && record[0].kt_supplier != "unassigned") ? (record[0].kt_supplier.replace(/ 20/, " ")) : "-"}</div>
            }
            {(record[0].njoy_list_date !== null || record[0].njoy_units_sold_last_30days !== null || record[0].njoy_total_units !== null || record[0].njoy_supplier !== null) &&
              <div className="mb-1">{(record[0].njoy_supplier && record[0].njoy_supplier != "unassigned") ? (record[0].njoy_supplier.replace(/ 20/, " ")) : "-"}</div>
            }
            {(record[0].fh_list_date !== null || record[0].fh_units_sold_last_30days !== null || record[0].fh_total_units !== null || record[0].fh_supplier !== null) &&
              <div>{(record[0].fh_supplier && record[0].fh_supplier != "unassigned") ? (record[0].fh_supplier.replace(/ 20/, " ")) : "-"}</div>
            }
          </div>
        );
      },
    },
    {
      title: <span>納品日</span>,
      dataIndex: "list_date",
      width: 85,
      render: (text, record) => {
        return (
          <div>
            {(record[0].we_list_date !== null || record[0].we_units_sold_last_30days !== null || record[0].we_total_units !== null || record[0].we_supplier !== null) &&
              <div className="mb-1">{record[0].we_list_date ? record[0].we_list_date.split(" ")[0] : "-"}</div>
            }
            {(record[0].kt_list_date !== null || record[0].kt_units_sold_last_30days !== null || record[0].kt_total_units !== null || record[0].kt_supplier !== null) &&
              <div className="mb-1">{record[0].kt_list_date ? record[0].kt_list_date.split(" ")[0] : "-"}</div>
            }
            {(record[0].njoy_list_date !== null || record[0].njoy_units_sold_last_30days !== null || record[0].njoy_total_units !== null || record[0].njoy_supplier !== null) &&
              <div className="mb-1">{record[0].njoy_list_date ? record[0].njoy_list_date.split(" ")[0] : "-"}</div>
            }
            {(record[0].fh_list_date !== null || record[0].fh_units_sold_last_30days !== null || record[0].fh_total_units !== null || record[0].fh_supplier !== null) &&
              <div>{record[0].fh_list_date ? record[0].fh_list_date.split(" ")[0] : "-"}</div>
            }
          </div>
        );
      },
    },
    {
      title: "注文詳細",
      dataIndex: "order_details",
      width: 200,
      render: (text, record) => {
        let quantity = record[0].numberofitemsshipped + record[0].numberofitemsunshipped;

        let price = record[0].itemprice > 0 ? (record[0].itemprice / (quantity > 0 ? quantity : 1)) : record[0].us_price;
        let profit = Math.round(quantity * (price > 10 ? record[0].profit : record[0].us_profit_amount_sl));
        let profitRate = (price > 10 ? record[0].profit_rate : record[0].us_profit_percent_sl);
        let fontWeight = "bold";
        let fontColor = "black";
        if (profitRate < 0) {
          fontWeight = "bold";
          fontColor = "red";
        } else if (profitRate <= 4) {
          fontWeight = "bold";
          fontColor = "green";
        }

        return (
          <div className="text-start">
            <div className="fw-bold" style={{ color: "#009688" }}>{record[0].amazonorderid}</div>
            {record[0].saleschannel && <div>Sales channel: {record[0].saleschannel}</div>}
            <div>利益額: <span style={{ fontWeight: fontWeight, color: fontColor }}>¥ {profit}</span></div>
            <div>利益率: <span style={{ fontWeight: fontWeight, color: fontColor }}>{profitRate} %</span></div>
            <div>ロット: {record[0].we_min_num_orders}</div>
            <div>容積: {Math.round(record[0].fba_weight)} g</div>
            <div>重量: {Math.round(record[0].package_weight)} g</div>
          </div>
        );
      },
    },
    {
      title: <span>注文状況<br />注文タイプ</span>,
      dataIndex: "order_status",
      width: 120,
      render: (text, record) => {
        let bgcolor = record[0].orderstatus == 'Pending' ? '#c5c524' : record[0].orderstatus == 'Shipped' ? '#7373f8' : '#1b2f56';
        return (
          <div className="text-start">
            <div style={{ width: 60, background: bgcolor, color: "#fff", textAlign: "center" }}>{record[0].orderstatus}</div>
            {record[0].orderstatus == 'Pending' && <div>Awaiting payment</div>}
            <div className="fw-bold">{record[0].shipservicelevel}</div><br />
          </div>
        );
      },
    },
  ];

  const onDrawerClose = () => {
    setIsDrawer(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const tableHeader = tableRef.current.querySelector(".ant-table-thead");
      const bodyRows = tableRef.current.querySelectorAll(
        ".ant-table-tbody > tr"
      );
      const tableRect = tableRef.current.getBoundingClientRect();
      const headerRect = tableHeader.getBoundingClientRect();

      const widths = [];
      let index = 0;
      for (var i = 0; i <= columns.length; i++) {
        if (i > 0 && columns[i - 1]?.hidden === true) widths.push(0);
        index++;
        const bodyCell = bodyRows[0].querySelector(
          `.ant-table-cell:nth-child(${index})`
        );
        widths.push(bodyCell && bodyCell.offsetWidth);
      }

      setHeaderWidths(widths);

      if (tableRect.top < headerRect.height) {
        setHeaderFixed(true);
      } else {
        tableHeader.style.position = "static";
        setHeaderFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sidebar_visible]);

  return (
    <>
      {loading && (
        <div id="global-loader">
          <h2>データ処理中...</h2>
        </div>
      )}
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>FBA 注文</h4>
              </div>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card fbamanage">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      value={keyword}
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      style={{ width: 200 }}
                      onChange={(e) => {
                        setKeyword(e.target.value);
                        setFilterValue((prev) => ({
                          ...prev,
                          filter_keyword: e.target.value,
                        }))
                      }}
                      onKeyDown={handleSearch}
                    // suffix={<FaMagnifyingGlass />}
                    />
                    <button
                      type="button"
                      onClick={() => handleSearch(null)}
                      className="btn btn-searchset"
                    >
                      <FaMagnifyingGlass />
                    </button>
                  </div>
                  <div
                    className="search-input"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    <label style={{ marginRight: "10px" }}>フィルタ</label>
                    <Select
                      className="select"
                      options={topFilterOptions}
                      size="large"
                      style={{ width: "200px" }}
                      value={filterValue.topFilter}
                      onChange={(e) => {
                        setFilterValue((prev) => ({
                          ...prev,
                          topFilter: e,
                        }));
                        if (e != "fba_custom_day_range") {
                          setFilterValue((prev) => ({
                            ...prev,
                            filter_start_order_date: "",
                            filter_end_order_date: "",
                          }));
                        }
                      }}
                    />
                  </div>

                  <div
                    className="search-input"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "10px",
                    }}
                  >
                    <label style={{ marginRight: "10px" }}>注文日時</label>
                    <Select
                      className="select"
                      options={topFilterOptions2}
                      size="large"
                      style={{ width: "200px" }}
                      value={filterValue.order_date_filter}
                      onChange={(e) => {
                        setFilterValue((prev) => ({
                          ...prev,
                          order_date_filter: e,
                        }));
                        if (e != "fba_custom_day_range") {
                          setFilterValue((prev) => ({
                            ...prev,
                            filter_start_order_date: "",
                            filter_end_order_date: "",
                          }));
                        }
                      }}
                    />
                    {filterValue.order_date_filter == "fba_custom_day_range" &&
                      <DatePicker.RangePicker
                        className="ms-2"
                        size="large"
                        onChange={(dates, dateStrings) => {
                          if (dates) {
                            setFilterValue((prev) => ({
                              ...prev,
                              filter_start_order_date: dateStrings[0],
                              filter_end_order_date: dateStrings[1],
                            }))
                          } else {
                            setFilterValue((prev) => ({
                              ...prev,
                              filter_start_order_date: "",
                              filter_end_order_date: "",
                            }))
                          }
                        }}
                      />
                    }
                  </div>

                  <button
                    type="button"
                    className="btn btn-primary me-2"
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      filterValue.filter_keyword = "";
                      getResearchList()
                    }}
                  >
                    検索
                  </button>

                  <button
                    type="button"
                    className="btn btn-primary me-2"
                    onClick={() => {
                      setIsDrawer(true);
                    }}
                  >
                    Filter Set
                  </button>

                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="form-sort">
                    <Select
                      className="select"
                      options={[
                        { value: 15, label: "15" },
                        { value: 25, label: "25" },
                        { value: 50, label: "50" },
                        { value: 100, label: "100" },
                        { value: 200, label: "200" },
                        { value: 300, label: "300" },
                      ]}
                      placeholder="100"
                      onChange={handlePageSizeChange}
                    />
                  </div>
                  <span style={{ whiteSpace: "nowrap", marginLeft: "10px" }}>
                    件ずつ表示
                  </span>
                </div>
              </div>
              <div className="table-responsive">

                <div style={{ display: "flex" }}>
                  <div className="search-path">
                    <div className="search-set">
                      <div className="search-input" style={{ display: "flex" }}>
                        <input
                          value={jumprow}
                          type="number"
                          placeholder="に行く..."
                          className="form-control form-control-sm formsearch"
                          onChange={(e) => setJumpRow(e.target.value)}
                          onKeyDown={handleJumpRow}
                        />
                        <span style={{ margin: "10px", width: "60%" }}>
                          {" "}
                          of {pageCount} pages
                        </span>
                      </div>
                    </div>


                  </div>

                  <div>
                    <Checkbox
                      value={filterValue.is_remove_duplication_asin == 1}
                      onChange={(e) => {
                        setFilterValue((prev) => ({
                          ...prev,
                          is_remove_duplication_asin: e.target.checked ? 1 : 0,
                        }));
                      }}
                      style={{ width: 200, lineHeight: "35px" }}
                    >
                      重複ASIN削除
                    </Checkbox>
                  </div>
                  <div className="d-flex justify-content-center gap-3">
                    {weCount > 0 && <div className="fw-bold" style={{ color: "#0040ff", whiteSpace: "nowrap", lineHeight: "35px" }}>WE: {weCount}</div>}
                    {ktCount > 0 && <div className="fw-bold" style={{ color: "#008b00", whiteSpace: "nowrap", lineHeight: "35px" }}>KT: {ktCount}</div>}
                    {njCount > 0 && <div className="fw-bold" style={{ color: "#df0038", whiteSpace: "nowrap", lineHeight: "35px" }}>NJ: {njCount}</div>}
                    {fhCount > 0 && <div className="fw-bold" style={{ color: "#6d00da", whiteSpace: "nowrap", lineHeight: "35px" }}>FH: {fhCount}</div>}
                  </div>

                  <div
                    style={{
                      textAlign: "end",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    検索結果 : {totalItems} 件中 {(pagination.current - 1) * pagination.pageSize + 1}-
                    {Math.min(pagination.current * pagination.pageSize, totalItems)} 件
                  </div>
                </div>
                <br />
                {isHeaderFixed && (
                  <div style={{ zIndex: 1000, background: "#fff", position: "fixed", top: 0 }}>
                    <table>
                      <thead>
                        <tr>
                          <th
                            key={0}
                            style={{ width: headerWidths[0], textAlign: "center", border: "2px solid #f0f0f0" }}
                          >
                            #
                          </th>
                          {columns.map((column, index) => (
                            (column?.hidden === true) ? <></> :
                              <th
                                key={index + 1}
                                style={{ width: headerWidths[index + 1], textAlign: "center", border: "2px solid #f0f0f0", height: 65 }}
                              >
                                {column.title}
                              </th>
                          ))}
                        </tr>
                      </thead>
                    </table>
                  </div>
                )}

                <div ref={tableRef}>
                  <Table bordered
                    className="table datanew dataTable no-footer"
                    rowSelection={{
                      type: "checkbox",
                      selectedRowKeys,
                      onChange: (newSelectedRowKeys) => {
                        setSelectedRowKeys(newSelectedRowKeys);
                      }
                    }}
                    columns={columns}
                    dataSource={dataSource}
                    rowKey={(record) => record.id}
                    pagination={{
                      ...pagination,
                      // position: ['topRight', 'bottomRight'],
                      position: ["bottomRight"],
                      total: totalItems,
                      pageSizeOptions: [
                        "15",
                        "25",
                        "50",
                        "100",
                        "200",
                        "300",
                      ],
                    }}
                    onChange={handleTableChange}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /product list */}
        </div>
      </div>

      {/* -------Filter Set Drawer start----------- */}
      <FilterDrawer
        open={isDrawer}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        categoryList={categoryList}
        onOk={() => {
          setPagination({ current: 1, pageSize: pagination.pageSize });
          onDrawerClose();
          getResearchList();
        }}
        onCancel={onDrawerClose}
      />
      {/* -------Filter Set Drawer end----------- */}

      <FBASellerInfoDrawer
        open={sellerInfoOpen}
        asin={sellerInfoAsin}
        onClose={() => setSellerInfoOpen(false)}
        onClickSeller={handleClickSeller}
      />
    </>
  );
});

export default FbaProductOrder;
