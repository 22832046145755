import React from "react";
import { FiLock, FiUser } from "react-icons/fi";
import { toast } from 'react-toastify';

import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { loginUser } from "../../services";

const Signin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();

    let res = await loginUser({email: username, password: password});
    if (res.status == 200) {
      let userInfo = {
        email: res.data.data.email,
        name: res.data.data.name,
        image: res.data.data.image,
        token: res.data.token
      }
      await login(userInfo);
    }
    else{
      toast.error(res.data.message);
    }
  };
  return (
    <div className="main-wrapper">
      <div className="login-star-wrapper">
        <div className="login-star-form">
          <div className="login-star-form-title" />
          <form>
            <div className="login-star-form-body">
              <h3>Sign In</h3>
              <div className="login-star-form-input">
                <FiUser />
                <input type="text" placeholder="Username" name="email" value={username} onChange={(e) => setUsername(e.target.value)} />
              </div>
              <div className="login-star-form-input">
                <FiLock />
                <input type="password" placeholder="Password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
              <button className="login-star-signin-button" onClick={handleLogin}>Sign In</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signin;
