/* eslint-disable */
import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { FiActivity, FiPlusCircle } from "react-icons/fi";
import { FaCalculator, FaPencil, FaArrowUpFromBracket, FaTrashCan, FaMagnifyingGlass, FaArrowRotateRight } from "react-icons/fa6";

import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { all_routes } from "../../Router/all_routes";
import {
  InputNumber,
  Table,
  Input,
  Checkbox,
  Select,
  Modal,
  Popover,
  AutoComplete,
  notification,
} from "antd";
import {
  updateResearchProduct,
  sendJobStatus,
  timeout,
  addProduct,
  researchASINList,
  deleteResearchASIN,
  addFbaPurchaseProduct,
  uploadCSVFile,
  CreateJob,
  downloadASINList,
  uploadCSVFileForLowestPrice,
  getCategoryData,
  uploadCSVFileForSellerPrice,
  getPackageWay,
  createPackageWay,
  isTokenErrorResponse,
  deleteBulkResearchASIN,
  uploadCSVFileForEditAsin,
  getBulkProductDetails,
  getBulkAsinSalesRanksHistory
} from "../../services";
import { useAuth } from "../../hooks/useAuth";
import * as utils from "../../utils/util";
import * as constants from "../../utils/constants";

import { USAFlag, JAPANFlag } from "../../core/components/Flags";
import { FilterDrawer } from "../../core/components/FilterDrawer";
import GraphModal from "./GraphModal";
import SellersGraphPopover from "./SellersGraphPopover";
import RankingGraphPopover from "./RankingGraphPopover";
import { FBASellerInfoDrawer } from "./FBASellerInfoDrawer";
import RankingGraph from "../../core/components/RankingGraph";
import SellersGraph from "../../core/components/SellersGraph";

const initialData = {
  topFilter: "fba_research_all",
  topAction: "none",
  order_by_value: "",
  order_by_way: 0, // 0 or 1
  filter_multi_asins: "",
  filter_us_min_rank: "",
  filter_us_max_rank: "",
  filter_us_min_weight: "",
  filter_us_max_weight: "",
  filter_us_min_price: "",
  filter_us_max_price: "",
  filter_jp_purchase_min_price: "",
  filter_jp_purchase_max_price: "",
  filter_jp_seller_min_price: "",
  filter_jp_seller_max_price: "",
  filter_min_profit_amount: "",
  filter_max_profit_amount: "",
  filter_min_profit_percent: "",
  filter_max_profit_percent: "",
  filter_product_type1_list: "",
  filter_us_category_list: "",
  filter_purchase_place: "",
  filter_ship_place: "",
  filter_employee_name: "",

  // sales_count_not0: false,
  rank_drop_count_not0: false,
  filter_is_own_product: '0',
  filter_fba_real_weight_compare: '0',
  filter_fba_real_weight_compare_anti: '0',
  filter_no_has_us_fba_sellers: '0',
  filter_has_us_fba_sellers: '0',

  filter_start_order_date: "",
  filter_end_order_date: "",
  filter_start_purchase_date: "",
  filter_end_purchase_date: "",
  filter_keyword: "",

  filter_seller_id: "",
};

const TopFilterOptions = [
  { value: "fba_research_all", label: "全体" },
  { value: "fba_real_weight_compare", label: "実重量＞容積重量 商品" },
  { value: "is_listed", label: "出品中商品" },
  { value: "is_unlisted", label: "未出品商品" },
  { value: "no_has_us_fba_sellers", label: "米国 FBA 出品者の無い商品" },
  { value: "has_us_fba_sellers", label: "米国 FBA 出品者の商品" },
];

const TopActionOptions = [
  { value: "none", label: "-- なし --" },
  { value: "download_research_page_asin", label: "ASINをダウンロード" },
  { value: "upload_lowest_price", label: "最安値をアップロードする" },
  { value: "upload_edit_asin", label: "編集ファイルをアップロード" },
  { value: "bulk_listing_research_asin", label: "一括FBA商品出品" },
  { value: "calculate_profit_bulk_research_page", label: "自動利益額計算" },
  { value: "delete_bulk", label: "一括削除" },
];

const ResearchList = forwardRef((props, ref) => {

  useImperativeHandle(ref, () => ({
    initFunc,
  }));

  const { user, logout } = useAuth();
  const tableRef = useRef(null);
  const csvUploadRef = useRef(null);
  const csvUploadActionRef = useRef(null);
  const csvEditAsinRef = useRef(null);

  const [headerWidths, setHeaderWidths] = useState([]);
  const [isHeaderFixed, setHeaderFixed] = useState(false);

  const active_seller = useSelector((state) => state.active_seller);
  const store_list = useSelector((state) => state.store_list);
  const sidebar_visible = useSelector((state) => state.sidebar_visible);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchSellerId = searchParams.get("sellerId");

  const route = all_routes;

  const [loading, setLoading] = useState(false);

  const [keyword, setKeyword] = useState("");
  const [filterValue, setFilterValue] = useState({
    ...initialData,
    filter_seller_id: searchSellerId ? searchSellerId : ""
  });
  const [csvFile, setCSVFile] = useState(null);
  const [csvUploadAction, setCSVUploadAction] = useState(null);
  const [csvEditAsin, setCsvEditAsin] = useState(null);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [isDrawer, setIsDrawer] = useState(false);
  const [isLowestPriceModal, setIsLowestPriceModal] = useState(false);
  const [isUploadEditAsinModal, setIsUploadEditAsinModal] = useState(false);

  const [categoryList, setCategoryList] = useState();
  const [packageWayList, setPackageWayList] = useState([]);

  const [dataSource, setDataSource] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });
  const [jumprow, setJumpRow] = useState("");

  const [historyData, setHistoryData] = useState([]);

  const [addAsinCondition, setAddAsinCondition] = useState("New");
  const [region, setRegion] = useState("us");

  const [addAsin, setAddAsin] = useState("");
  const [editASINIndex, setEditASINIndex] = useState(-1);
  const [editASIN, setEditASIN] = useState("");
  const [editpurchase_price, setEditpurchase_price] = useState(0);
  const [editus_sell_price, setEditus_sell_price] = useState(0);
  const [edit_handling_time, setEdit_handling_time] = useState("");
  const [editus_seller_price, setEditus_seller_price] = useState(0);
  const [editjp_title, setEditjp_title] = useState("");
  const [editus_title, setEditus_title] = useState("");
  const [edit_jan, setEdit_jan] = useState("");
  const [edit_totalofpackage, setEdit_totalofpackage] = useState("");
  const [edit_catalognumber, setEdit_catalognumber] = useState("");
  const [edit_image, setEdit_image] = useState("");
  const [edit_package_image, setEdit_package_image] = useState("");
  const [edit_weight, setEdit_weight] = useState(0);
  const [edit_width, setEdit_width] = useState(0);
  const [edit_height, setEdit_height] = useState(0);
  const [edit_length, setEdit_length] = useState(0);
  const [edit_jan3, setEdit_jan3] = useState("");
  const [edit_package_way, setEdit_package_way] = useState("");
  const [edit_package_note, setEdit_package_note] = useState("");
  const [edit_purchase_stock, setEdit_purchase_stock] = useState("");
  const [edit_us_amazon_fee, setEdit_us_amazon_fee] = useState("");
  const [edit_fba_fee_auto, setEdit_fba_fee_auto] = useState("");
  const [edit_us_comment, setEdit_us_comment] = useState("");
  const [edit_itemcondition, setEdit_itemcondition] = useState("");
  const [edit_product_type1, setEdit_product_type1] = useState("");
  const [edit_profit, setEdit_profit] = useState("");
  const [edit_variable_closing_fee, setEdit_variable_closing_fee] = useState(1);
  const [edit_purchase_place, setEdit_purchase_place] = useState("");
  const [edit_ship_place, setEdit_ship_place] = useState("");
  const [edit_fnsku_weeea2, setEdit_fnsku_weeea2] = useState("");
  const [edit_fnsku_kt, setEdit_fnsku_kt] = useState("");
  const [edit_fnsku_njoy, setEdit_fnsku_njoy] = useState("");
  const [edit_fnsku_fh, setEdit_fnsku_fh] = useState("");
  const [edit_us_stock, setEdit_us_stock] = useState("");

  const [edit_is_own, setEdit_is_own] = useState(0);
  const [edit_invoice_name, setEdit_invoice_name] = useState("");
  const [edit_hs_code, setEdit_hs_code] = useState("");
  const [edit_hts_code, setEdit_hts_code] = useState("");
  const [edit_min_num_orders, setEdit_min_num_orders] = useState("");
  const [edit_us_fba_seller_num, setEdit_us_fba_seller_num] = useState("");
  const [edit_us_seller_num, setEdit_us_seller_num] = useState("");
  const [edit_gate_fee, setEdit_gate_fee] = useState("");
  const [edit_breakdown_qty, setEdit_breakdown_qty] = useState("");
  const [edit_price_no_tax, setEdit_price_no_tax] = useState("");
  const [edit_price_tax, setEdit_price_tax] = useState("");

  const [exhibitModalVisible, setExhibitModalVisible] = useState(false);
  const [edit_modal_visible, setEdit_modal_visible] = useState(false);

  const [exhibitData, setExhibitData] = useState({
    label: "",
    asin_data: "",
    sellername: null,
    sku: "",
    isExhibit: false,
  });

  // for graph view modal
  const [graphModalOpen, setGraphModalOpen] = useState(false);
  const [graphModalAsin, setGraphModalAsin] = useState(null);

  // for seller info drawer
  const [sellerInfoOpen, setSellerInfoOpen] = useState(false);
  const [sellerInfoAsin, setSellerInfoAsin] = useState(null);

  // bulk listing research modal
  const [bulkListingModalOpen, setBulkListingModalOpen] = useState(false);
  const [bulkListingSellername, setBulkListingSellername] = useState(false);


  const handleRefresh = async () => {
    setKeyword("");
    setFilterValue(initialData);
    getResearchList(1, true);
  }

  const handleSearch = (e) => {
    if (e == null || e.key === "Enter") {
      if (keyword == "") {
        filterValue.filter_keyword = "";
      }
      setJumpRow(0);
      setPagination({ current: 1, pageSize: pagination.pageSize });
      getResearchList();
      setKeyword("");
    }
  };

  const getHistoryByAsin = (asin) => {
    let item = historyData.find(item => item.asin == asin);
    if (item != undefined && item != null) {
      return item;
    }
    return null;
  }
  const loadHistoryData = async () => {
    const asins = [];

    for (const product of dataSource) {
      let isExist = getHistoryByAsin(product.asin);
      if (!isExist) {
        asins.push(product.asin);

        // if (asins.length > 10) {
        //   break;
        // }
      }
    }
    if (asins.length == 0) {
      return;
    }

    const newHistories = [];

    const result1 = await getBulkProductDetails(asins, user);
    if (result1.data && result1.data.length > 0) {
      for (const sellerData of result1.data) {
        if (sellerData.length == 0) continue;
        newHistories.push({
          asin: sellerData[0].asin,
          sellerData: sellerData
        });
      }
    }
    const result2 = await getBulkAsinSalesRanksHistory(asins, user);
    if (result2.data && result2.data.length > 0) {
      for (const rankingData of result2.data) {
        if (rankingData.length == 0) continue;
        let existItem = newHistories.find(item => item.asin == rankingData[0].asin);
        if (existItem) {
          existItem.rankingData = rankingData;
        }
      }
    }

    setHistoryData([
      ...historyData,
      ...newHistories
    ]);
  }

  useEffect(() => {
    if (!sidebar_visible) {
    loadHistoryData();
    }
  }, [sidebar_visible, dataSource]);


  const handleJumpRow = (e) => {
    if (e.key === "Enter") {
      setPagination({
        current: parseInt(jumprow),
        pageSize: pagination.pageSize,
      });
      setJumpRow("");
      getResearchList(parseInt(jumprow));
    }
  };

  const getResearchList = async (page = 1, refresh = false) => {
    setLoading(true);
    try {
      let seller = (active_seller != "すべてのアカウント") ? active_seller : "";
      let totalItems = 0;
      let totalPages = 0;

      const result = await researchASINList({
        page: page - 1,
        count: pagination.pageSize,
        user: user,
        seller: seller,
        keyword: refresh ? '' : filterValue.filter_keyword,
        filterValue: refresh ? initialData : filterValue
      });
      if (isTokenErrorResponse(result)) {
        return logout();
      }

      if (result.data && result.data.length > 0) {
        setDataSource(result.data);
        totalItems = parseInt(result.data[0]["full_count"]);
        totalPages = Math.ceil(totalItems / pagination.pageSize);
      } else {
        setDataSource([]);
      }
      setTotalItems(totalItems);
      setPageCount(totalPages);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };


  const handleTableChange = (pagination) => {
    setHistoryData([]);
    setPagination(pagination);
    getResearchList(pagination.current);
  };

  const handlePageSizeChange = (pagesize) => {
    pagination.pageSize = pagesize;
    getResearchList();
  };

  const handleAddASIN = async () => {
    if (addAsin == "") {
      utils.showErrorBox("Please, you input ASIN.");
    } else if (addAsinCondition == "") {
      utils.showErrorBox("Please, you select Condition.");
    } else {
      setLoading(true);
      console.log("handleAddASIN:", addAsin);
      console.log("addAsinCondition:", addAsinCondition);
      let sellername = "";
      if (active_seller != "すべてのアカウント") sellername = active_seller;
      const result = await addProduct({
        asin: addAsin,
        fnsku: "",
        itemcondition: addAsinCondition,
        sellersku: "",
        sellername: sellername,
        user: user,
      });
      console.log(result);
      setLoading(false);
      let msg = "";
      getResearchList(pagination.current);
      if (result.data && result.message) {
        msg = result.message;
      } else {
        msg = result.data.message;
      }
      utils.showAlarmBox(msg);
    }
  };

  const uploadCSVASINFile = (event) => {
    const selectedFile = event.target.files[0];
    setCSVFile(selectedFile)

    uploadCSVFile(selectedFile, user);
    getResearchList(pagination.current);

    event.target.value = null;
    setCSVFile(null);
  }

  const uploadCSVActionASINFile = (event) => {
    const selectedFile = event.target.files[0];
    setCSVUploadAction(selectedFile)

    event.target.value = null;
    // setCSVFile(null);
  }

  const uploadCSVEditAsin = (event) => {
    const selectedFile = event.target.files[0];
    setCsvEditAsin(selectedFile)
    event.target.value = null;
  }

  // Function to check the type
  const checkTypeofValue = (value) => {
    if (value == null || value == undefined) {
      return 'unknown'
    } else {
      if (isNaN(value)) {
        return 'string';
      } else {

        return 'number';
      }
    }
  }

  const initFunc = () => {
    document.title = "情報調査";
    getResearchList();
    getCategory();
    getPackageWayList();
  }

  useEffect(() => {
    initFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_seller]);

  const checkProductStatus = async (asin) => {
    window.open(`${route.productdetails}?asin=${asin}`, "_blank", "noreferrer");
  };

  const handleClickSeller = async (sellerId) => {
    window.open(`${route.research}?sellerId=${sellerId}`, "_blank", "noreferrer");
  }

  const onInputChange = (key, index) => (value) => {
    const newData = [...dataSource];

    if (value && value.target) {
      newData[index][key] = value.target.value;
    } else {
      newData[index][key] = value;
    }
    setDataSource(newData);
  };

  const getCategory = async () => {
    const result = await getCategoryData({ user });
    setCategoryList(result.data);
  }

  const getPackageWayList = async () => {
    const result = await getPackageWay({ user, page: 0, count: 100, keyword: '' });
    if (result.data && result.data.length > 0) {
      let packageways = result.data.map(item => ({ value: item.content }));
      setPackageWayList(packageways);
    }
  }

  const onInputExhibit = (name, value) => {
    if (name == "sellername") {
      setExhibitData((prev) => ({ ...prev, sellername: value }));
      const product = dataSource[editASINIndex];
      console.log(value);
      switch (value) {
        case "weeea2":
          setExhibitData((prev) => ({
            ...prev,
            sku: product.asin + "_FBA_N",
            isExhibit: false,
            label: "weeea2_sku",
          }));
          break;
        case "kt.japan":
          setExhibitData((prev) => ({
            ...prev,
            sku: product.asin + "_FBA_N-tk",
            isExhibit: false,
            label: "kt_sku",
          }));
          break;
        case "njoy":
          setExhibitData((prev) => ({
            ...prev,
            sku: "fb_" + product.asin + "_nj",
            isExhibit: false,
            label: "njoy_sku",
          }));
          break;
        case "fhjiyama":
          setExhibitData((prev) => ({
            ...prev,
            sku: "FBA_" + product.asin + "_fh",
            isExhibit: false,
            label: "fh_sku",
          }));
          break;
      }
    }
  };

  const onEditSelectChange = (key, index) => (e) => {
    let value = e.target.value;
    const newData = [...dataSource];
    let newValue = '';
    switch (key) {
      case "jan":
        newValue = utils.updateArrayString(edit_jan, index, value);
        setEdit_jan(newValue);
        break;
      case "catalognumber":
        newValue = utils.updateArrayString(edit_catalognumber, index, value);
        setEdit_catalognumber(newValue);
        break;
      case "breakdown_qty":
        newValue = utils.updateArrayString(edit_breakdown_qty, index, value);
        setEdit_breakdown_qty(newValue);
        break;
      case "price_no_tax":
        newValue = utils.updateArrayString(edit_price_no_tax, index, value);
        setEdit_price_no_tax(newValue);
        break;
      case "price_tax":
        newValue = utils.updateArrayString(edit_price_tax, index, value);
        setEdit_price_tax(newValue);
        break;
    }
    console.log(key, newValue);
    newData[editASINIndex][key] = newValue;
    setDataSource(newData);
  }
  const onEditInputChange = (key) => (value) => {
    console.log("onEditInputChange key=", key);
    console.log("editASINIndex key=", editASINIndex);
    const newData = [...dataSource];
    let index = editASINIndex;
    switch (key) {
      case "us_sell_price":
        setEditus_sell_price(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "handling_time":
        setEdit_handling_time(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "purchase_price":
        setEditpurchase_price(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "us_title":
        setEditus_title(value.target.value);
        newData[index]["productname"] = value.target.value;
        break;
      case "jp_title":
        setEditjp_title(value.target.value);
        newData[index]["jp_productname"] = value.target.value;
        break;
      case "jan":
        setEdit_jan(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "totalofpackage":
        setEdit_totalofpackage(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "catalognumber":
        setEdit_catalognumber(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "image":
        setEdit_image(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "package_image":
        setEdit_package_image(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "weight":
        setEdit_weight(value.target.value);
        newData[index]["package_weight"] = value.target.value;
        break;
      case "width":
        setEdit_width(value.target.value);
        newData[index]["packagewidth"] = value.target.value;
        break;
      case "height":
        setEdit_height(value.target.value);
        newData[index]["packageheight"] = value.target.value;
        break;
      case "length":
        setEdit_length(value.target.value);
        newData[index]["packagelength"] = value.target.value;
        break;
      case "jan3":
        setEdit_jan3(value.target.value);
        newData[index]["jan3"] = value.target.value;
        break;
      case "package_way":
        setEdit_package_way(value);
        newData[index]["amazon_package_way"] = value;
        break;
      case "package_note":
        setEdit_package_note(value.target.value);
        newData[index]["amazon_package_note"] = value.target.value;
        break;
      case "purchase_stock":
        setEdit_purchase_stock(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "us_stock":
        setEdit_us_stock(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "us_amazon_fee":
        setEdit_us_amazon_fee(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "fba_fee_auto":
        setEdit_fba_fee_auto(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "us_comment":
        setEdit_us_comment(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "itemcondition":
        setEdit_itemcondition(value);
        newData[index][key] = value;
        break;
      case "product_type1":
        setEdit_product_type1(value);
        newData[index][key] = value;
        break;
      case "profit":
        setEdit_profit(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "variable_closing_fee":
        const val = value.target.checked ? 1 : 0;
        setEdit_variable_closing_fee(val);
        newData[index][key] = val;
        break;
      case "purchase_place":
        setEdit_purchase_place(value);
        newData[index][key] = value;
        break;
      case "ship_place":
        setEdit_ship_place(value);
        newData[index][key] = value;
        break;
      case "fnsku_weeea2":
        setEdit_fnsku_weeea2(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "fnsku_kt":
        setEdit_fnsku_kt(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "fnsku_njoy":
        setEdit_fnsku_njoy(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "fnsku_fh":
        setEdit_fnsku_fh(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "min_num_orders":
        setEdit_min_num_orders(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "invoice_name":
        setEdit_invoice_name(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "hs_code":
        setEdit_hs_code(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "hts_code":
        setEdit_hts_code(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "us_fba_seller_num":
        setEdit_us_fba_seller_num(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "us_seller_num":
        setEdit_us_seller_num(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "gate_fee":
        setEdit_gate_fee(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "is_own":
        setEdit_is_own(value.target.checked ? 1 : 0);
        newData[index][key] = value.target.checked ? 1 : 0;
        break;

      case "weeea2_sku":
        setExhibitData((prev) => ({ ...prev, sku: value.target.value }));
        newData[index][key] = value.target.value;
        break;
      case "kt_sku":
        setExhibitData((prev) => ({ ...prev, sku: value.target.value }));
        newData[index][key] = value.target.value;
        break;
      case "njoy_sku":
        setExhibitData((prev) => ({ ...prev, sku: value.target.value }));
        newData[index][key] = value.target.value;
        break;
      case "fh_sku":
        setExhibitData((prev) => ({ ...prev, sku: value.target.value }));
        newData[index][key] = value.target.value;
        break;
    }
    setDataSource(newData);
  };

  const handleUpdateProduct = (index) => async () => {
    try {
      setLoading(true);
      const props = dataSource[index];
      props.user = user;

      await updateResearchProduct(props);

      if (props.amazon_package_way && props.amazon_package_way.trim().length > 0) {
        await createPackageWay({ name: props.amazon_package_way, user });
        getPackageWayList();
      }

      getResearchList(pagination.current);
      setEdit_modal_visible(false);
      setExhibitModalVisible(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  async function handleJobStatus(id) {
    const result = await sendJobStatus({ id: id, user: user });
    console.log(result);
    if (parseInt(result.data.status) > 0) {
      return result.data.status;
    }
    return false;
  }

  const handleExhibit = async (index, sellername = null) => {
    setLoading(true);
    try {
      const product = dataSource[index];
      const result = await CreateJob({
        jobname: "listing_research_asin",
        jobvalue: {
          name: "asin",
          value: product.asin,
          condition: product.itemcondition,
          sellername: sellername ? sellername : exhibitData.sellername,
        },
        user: user,
      });
      handleUpdateProduct(index)();
      if (result.data.id) {
        let id = result.data.id;
        let res = await handleJobStatus(id);
        while (!res) {
          timeout(1000);
          res = await handleJobStatus(id);
        }
        // if (res == 1) {
        //   notification.success({
        //     description: "商品出品 成功!",
        //   });
        // } else {
        //   notification.error({
        //     description: "商品出品 失敗!",
        //   });
        // }
        getResearchList(pagination.current);
      }

      setExhibitModalVisible(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleExhibitProduct = (index, sellerName) => {
    const product = dataSource[index];

    utils.showConfirmBox(
      "FBA商品出品",
      `出品アカ: ${sellerName}, 出品価格: $ ${product.us_fba_lowest_price ?? product.us_price}`,
      async () => {
        handleExhibit(index, sellerName);
      });
  };



  const handleCalculate = async (index) => {
    setLoading(true);
    try {
      const product = dataSource[index];
      console.log("product", product);
      const result = await CreateJob({
        jobname: "calculate_profit_research_asin",
        jobvalue: {
          name: "asin",
          value: product.asin,
          condition: product.itemcondition,
        },
        user: user,
      });
      console.log("calculate response", result);
      if (result.data.id) {
        let id = result.data.id;
        let res = await handleJobStatus(id);
        while (!res) {
          timeout(1000);
          res = await handleJobStatus(id);
        }
        getResearchList(pagination.current);
      }

      //result.data == 1 :  success
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const showConfirm = (index, sellerName) => {
    utils.showConfirmBox("FBA製品の購入準備ページに追加", "この商品をFBA商品仕入準備のページに追加しますか?", async () => {
      setLoading(true);
      const product = dataSource[index];
      console.log("product", product);
      const result = await addFbaPurchaseProduct(sellerName, 1, product, user);
      setLoading(false);
      getResearchList(pagination.current);
    });
  };

  const handleProductEdit = async (index) => {
    try {
      const product = dataSource[index];
      console.log(product);
      setEditASINIndex(index);
      setEditASIN(product.asin);
      setEdit_purchase_stock(product.purchase_stock);
      setEditpurchase_price(product.purchase_price);
      setEditus_sell_price(product.us_sell_price);
      setEdit_handling_time(product.handling_time);
      setEditjp_title(product.jp_productname);
      setEditus_title(product.productname);
      setEdit_jan(product.jan);
      setEdit_totalofpackage(product.totalofpackage);
      setEdit_catalognumber(product.catalognumber);
      setEdit_image(product.image);
      setEdit_package_image(product.package_image);
      setEdit_weight(product.package_weight);
      setEdit_width(product.packagewidth);
      setEdit_height(product.packageheight);
      setEdit_length(product.packagelength);
      setEdit_jan3(product.jan3);
      setEdit_package_way(product.amazon_package_way);
      setEdit_package_note(product.amazon_package_note);
      setEdit_purchase_place(product.purchase_place);
      setEdit_ship_place(product.ship_place);
      setEdit_us_amazon_fee(product.us_amazon_fee);
      setEdit_fba_fee_auto(product.fba_fee_auto);
      setEdit_us_comment(product.us_comment);
      setEdit_itemcondition(product.itemcondition);
      setEdit_product_type1(product.product_type1);
      setEdit_profit(product.profit);
      setEdit_variable_closing_fee(product.variable_closing_fee);
      setEdit_fnsku_weeea2(product.fnsku_weeea2);
      setEdit_fnsku_kt(product.fnsku_kt);
      setEdit_fnsku_njoy(product.fnsku_njoy);
      setEdit_fnsku_fh(product.fnsku_fh);
      setEdit_min_num_orders(product.min_num_orders);
      setEdit_invoice_name(product.invoice_name);
      setEdit_hs_code(product.hs_code);
      setEdit_hts_code(product.hts_code);
      setEdit_breakdown_qty(product.breakdown_qty);
      setEdit_price_no_tax(product.price_no_tax);
      setEdit_price_tax(product.price_tax);
      setEdit_us_fba_seller_num(product.us_fba_seller_num);
      setEdit_us_seller_num(product.us_seller_num);
      setEdit_gate_fee(product.gate_fee);
      setEdit_is_own(product.is_own);

      setEdit_modal_visible(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleExhibitModalVisible = async (index) => {
    const product = dataSource[index];
    setExhibitData({
      label: "",
      asin_data: "",
      sellername: null,
      sku: "",
      isExhibit: true,
    })
    setExhibitData(prev => ({ ...prev, sellername: null }));
    setEditASINIndex(index);
    setEditASIN(product.asin);
    setEdit_purchase_stock(product.purchase_stock);
    setEdit_us_stock(product.us_stock);
    setEditpurchase_price(product.purchase_price);
    setEditus_sell_price(product.us_sell_price);
    setEdit_handling_time(product.handling_time);
    setEditjp_title(product.jp_productname);
    setEditus_title(product.productname);
    setEdit_jan(product.jan);
    setEdit_totalofpackage(product.totalofpackage);
    setEdit_catalognumber(product.catalognumber);
    setEdit_image(product.image);
    setEdit_package_image(product.package_image);
    setEdit_weight(product.package_weight);
    setEdit_width(product.packagewidth);
    setEdit_height(product.packageheight);
    setEdit_length(product.packagelength);
    setEdit_jan3(product.jan3);
    setEdit_package_way(product.amazon_package_way);
    setEdit_package_note(product.amazon_package_note);
    setEdit_purchase_place(product.purchase_place);
    setEdit_ship_place(product.ship_place);
    setEdit_us_amazon_fee(product.us_amazon_fee);
    setEdit_fba_fee_auto(product.fba_fee_auto);
    setEdit_us_comment(product.us_comment);
    setEdit_itemcondition(product.itemcondition);
    setEdit_product_type1(product.product_type1);
    setEdit_profit(product.profit);
    setExhibitModalVisible(true);
  }

  const handleViewGraph = async (index) => {
    const product = dataSource[index];
    setGraphModalAsin(product.asin);
    setGraphModalOpen(true);
  }

  const handleViewSellerInfo = async (index) => {
    const product = dataSource[index];
    setSellerInfoAsin(product.asin);
    setSellerInfoOpen(true);
  }

  const isVisible = (record, index) => {
    return true;
  }


  const columns = [
    {
      title: "商品画像",
      dataIndex: "image",
      width: 100,
      render: (text, record) => {
        return (
          <span className="inventoryimgname">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Link
                to={utils.getAmazonUrl(record.asin)}
                className="product-img stock-img mb-2"
                target="_blank"
              >
                <ImageWithBasePath
                  alt=""
                  src={text ?? "assets/img/products/product1.jpg"}
                  isProduct={text ? true : false}
                />
              </Link>
              <div className="mb-1">
                <Link to={utils.getRakutenUrl(record.jan?.split(",")[0])} className="link-title" target="_blank">
                  楽天市場
                </Link>
              </div>
              <div className="mb-1">
                <Link to={utils.getYahooUrl(record.jan?.split(",")[0])} className="link-title" target="_blank">
                  ヤフショ
                </Link>
              </div>
              <div className="mb-1">
                <Link to={utils.getSaikyakuUrl(record.jan?.split(",")[0])} className="link-title" target="_blank">
                  最安値.com
                </Link>
              </div>
              <div className="mb-1">
                <Link to={utils.getKeepaUrl(record.asin)} className="link-title" target="_blank">
                  Keepa
                </Link>
              </div>
              <div className="mb-1">
                <Popover content={
                  <RankingGraphPopover
                    data={getHistoryByAsin(record.asin)?.rankingData}
                  // asin={record.asin}
                  />}>
                  <a style={{ color: "blue" }}>ランキング</a>
                </Popover>
              </div>
            </div>
          </span>
        );
      },
    },
    {
      title: (
        <span>
          {" "}
          商品名 <br /> カテゴリー <br /> メモ
        </span>
      ),
      dataIndex: "productname",
      width: 200,
      render: (text, record, index) => {
        return (
          <span className="inventoryimgname">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Popover content={text}>
                <Link
                  to={utils.getAmazonUrl(record.asin)}
                  className="product-title"
                  target="_blank"
                >
                  {text}
                </Link>
              </Popover>
              <Popover content={record.jp_productname}>
                <Link
                  to={utils.getAmazonJpUrl(record.asin)}
                  className="product-title"
                  target="_blank"
                >
                  {record.jp_productname}
                </Link>
              </Popover>
              <br />
              <span className="category-title">{record.category}</span>
              <br />
              <Input
                className="product-title"
                value={record.memo}
                onPressEnter={handleUpdateProduct(index)}
                onChange={onInputChange("memo", index)}
                placeholder="メモ1"
              />
              <br />
              <Input
                className="product-title"
                value={record.memo1}
                onPressEnter={handleUpdateProduct(index)}
                onChange={onInputChange("memo1", index)}
                placeholder="メモ2"
              />
              <br />
              <AutoComplete
                value={record.amazon_package_way || ""}
                options={packageWayList}
                style={{ width: 200 }}
                onSelect={(value) => onInputChange("amazon_package_way", index)(value)}
                filterOption={(inputValue, option) => (inputValue.trim().length > 0 && option.value.indexOf(inputValue) != -1)}
                onChange={onInputChange("amazon_package_way", index)}
                placeholder="梱包方法"
              >
                <Input onKeyDown={(e) => { if (e.key == "Enter") handleUpdateProduct(index)(); }} />
              </AutoComplete>
              <br />
              <div className="d-flex justify-content-center gap-1 seller-title">
                {record.is_list_weeea2 == 1 || record.fnsku_weeea2 ? "" : (
                  <div className={`sellermark mb-1 weeea2`} onClick={() => handleExhibitProduct(index, "weeea2")}>
                    {record.us_fba_lowest_price ?? record.us_price}
                  </div>
                )}
                {record.is_list_kt == 1 || record.fnsku_kt ? "" : (
                  <div className={`sellermark mb-1 kt.japan`} onClick={() => handleExhibitProduct(index, "kt.japan")}>
                    {record.us_fba_lowest_price ?? record.us_price}
                  </div>
                )}
                {record.is_list_njoy == 1 || record.fnsku_njoy ? "" : (
                  <div className={`sellermark mb-1 njoy`} onClick={() => handleExhibitProduct(index, "njoy")}>
                    {record.us_fba_lowest_price ?? record.us_price}
                  </div>
                )}
                {record.is_list_fh == 1 || record.fnsku_fh ? "" : (
                  <div className={`sellermark mb-1 fhjiyama`} onClick={() => handleExhibitProduct(index, "fhjiyama")}>
                    {record.us_fba_lowest_price ?? record.us_price}
                  </div>
                )}
              </div>
            </div>
          </span>
        );
      },
    },
    {
      title: (
        <span>
          {" "}
          ASIN <br />
          JAN <br />
          FNSKU SKU<br />
          セット数 <br />
          型番 <br />
          メーカー<br />
          グラフ
        </span>
      ),
      dataIndex: "asin",
      width: 100,
      render: (text, record, index) => {
        // var url_fba = "https://sellercentral.amazon.com/skucentral?mSku=" + record.sku + "&ref=myi_skuc";
        var url = utils.getAmazonJpOfferListingUrl(record.asin, record.itemcondition);
        var product_type = parseInt(record.is_own) === 1 ? "問屋商品" : record.product_type;
        var catalognumber = record.catalognumber;
        if (catalognumber == null || catalognumber == "") catalognumber = "-";
        var manufacture = record.jp_manufacturer;
        if (manufacture == null || manufacture == "") manufacture = "-";

        let jansArr = record.jan?.split(",");
        let jans = <span>
          {jansArr?.map((j, idx) => <span key={idx}>{j}<br /></span>)}
        </span>;

        let catasArr = record.catalognumber?.split(",");
        let catas = <span>
          {catasArr?.map((j, idx) => <span key={idx}>{j}<br /></span>)}
        </span>;

        return (
          <span>
            <Link to={url} className="product-title" target="_blank">
              {record.asin}
            </Link>
            <br />
            <span><Popover content={jans}>{jansArr?.[0]}</Popover></span> <br />
            <br />
            {record.is_list_weeea2 == 1 || record.fnsku_weeea2 ? (
              <>
                {" "}
                <span style={{ color: utils.getSellerColor("weeea2") }}>
                  {record.fnsku_weeea2?.toUpperCase()}
                  &nbsp;&nbsp;&nbsp;
                  {record.weeea2_sku?.toUpperCase()}
                </span>{" "}
                <br />{" "}
              </>
            ) : (
              ""
            )}
            {record.is_list_kt == 1 || record.fnsku_kt ? (
              <>
                {" "}
                <span style={{ color: utils.getSellerColor("kt.japan") }}>
                  {record.fnsku_kt?.toUpperCase()}
                  &nbsp;&nbsp;&nbsp;
                  {record.kt_sku?.toUpperCase()}
                </span>{" "}
                <br />{" "}
              </>
            ) : (
              ""
            )}
            {record.is_list_njoy == 1 || record.fnsku_njoy ? (
              <>
                {" "}
                <span style={{ color: utils.getSellerColor("njoy") }}>
                  {record.fnsku_njoy?.toUpperCase()}
                  &nbsp;&nbsp;&nbsp;
                  {record.njoy_sku?.toUpperCase()}
                </span>{" "}
                <br />{" "}
              </>
            ) : (
              ""
            )}
            {record.is_list_fh == 1 || record.fnsku_fh ? (
              <>
                {" "}
                <span style={{ color: utils.getSellerColor("fhjiyama") }}>
                  {record.fnsku_fh?.toUpperCase()}
                  &nbsp;&nbsp;&nbsp;
                  {record.fh_sku?.toUpperCase()}
                </span>{" "}
                <br />{" "}
              </>
            ) : (
              ""
            )}
            <span>{product_type}</span> <br />
            <span>{record.totalofpackage} 個</span> <br />
            <span><Popover content={catas}>{catasArr?.[0] ?? '-'}</Popover></span> <br />
            <span>{manufacture}</span> <br />
            <Popover content={
              <SellersGraphPopover
                data={getHistoryByAsin(record.asin)?.sellerData}
              // asin={record.asin}
              />}>
              <a style={{ color: "blue" }} onClick={() => handleViewGraph(index)}>グラフ</a><br />
            </Popover>
          </span>
        );
      },
    },

    {
      title: (
        <span>
          グラフ
        </span>
      ),
      dataIndex: "graph",
      width: 200,
      hidden: sidebar_visible,
      render: (text, record, index) => {
        return (
          <div>
            {isVisible(record, index) ? (
              <>
                <div>
                  <RankingGraph
                    user={user}
                    data={getHistoryByAsin(record.asin)?.rankingData}
                    height={150}
                    simple={true}
                  />
                </div>
                <div>
                  <SellersGraph
                    user={user}
                    data={getHistoryByAsin(record.asin)?.sellerData}
                    height={150}
                    simple={true}
                  />
                </div>
              </>) : <></>}
          </div>
        );
      },
    },

    {
      title: "アカ",
      dataIndex: "is_list_weeea2",
      render: (text, record, index) => {
        return (
          <span className="seller-title" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            {record.is_list_weeea2 == 1 || record.fnsku_weeea2 ? (
              <div className={`sellermark mb-1 weeea2`} onClick={() => showConfirm(index, "weeea2")}>
                {utils.getSellerMark("weeea2", store_list)}
              </div>
            ) : (
              ""
            )}
            {record.is_list_kt == 1 || record.fnsku_kt ? (
              <div className={`sellermark mb-1 kt.japan`} onClick={() => showConfirm(index, "kt.japan")}>
                {utils.getSellerMark("kt.japan", store_list)}
              </div>
            ) : (
              ""
            )}
            {record.is_list_njoy == 1 || record.fnsku_njoy ? (
              <div className={`sellermark mb-1 njoy`} onClick={() => showConfirm(index, "njoy")}>
                {utils.getSellerMark("njoy", store_list)}
              </div>
            ) : (
              ""
            )}
            {record.is_list_fh == 1 || record.fnsku_fh ? (
              <div className={`sellermark mb-1 fhjiyama`} onClick={() => showConfirm(index, "fhjiyama")}>
                {utils.getSellerMark("fhjiyama", store_list)}
              </div>
            ) : (
              ""
            )}
          </span>
        );
      },
    },
    {
      title: (
        <span>
          コンディション
          <br />
          FBA販売者数
          <br />
          販売者数(全体)
          <br />
          在庫
          <br />
          ロット
        </span>
      ),
      dataIndex: "itemcondition",
      render: (text, record) => {
        return (
          <span>
            {record.itemcondition == "New" ? "新品" : "中古"}
            <br />
            {record.us_fba_seller_num}
            <br />
            {record.us_seller_num}
            <br />
            {record.us_fba_seller_num}
            <br />
            {record.jp_stock > 0 ? (
              <>
                {" "}
                <span style={{ fontStyle: "bold" }}>
                  {record.jp_stock}
                </span>{" "}
              </>
            ) : (
              record.jp_stock
            )}
            <br />
            {record.min_num_orders}
            <br />
            <a href={`/restock-inventory?asin=${record.asin}`} target="restock">在庫補充</a>
          </span>
        );
      },
    },
    {
      title: (
        <span>
          <JAPANFlag />
          <br />
          <br />
          最低価格
          <br />
          BuyBox価格
          <br />
          手動価格
          <br />
          問屋価格
        </span>
      ),
      dataIndex: "purchase_price",
      render: (text, record, index) => (
        <span>
          <Link
            to={utils.getAmazonJpOfferListingUrl(record.asin, "New")}
            target="_blank"
            className="custom-text-blue"
          >
            {record.jp_lowest_price ? `¥ ${record.jp_lowest_price}` : "¥ -"}
          </Link>
          <br /> <br />
          <Link
            to={utils.getAmazonJpUrl(record.asin)}
            target="_blank"
            className="custom-text-blue"
          >
            {record.jp_price ? `¥ ${record.jp_price}` : "¥ -"}
          </Link>
          <br /> <br />
          <div className="editable-cell">
            <InputNumber
              prefix="¥"
              value={text ?? 0}
              onPressEnter={handleUpdateProduct(index)}
              onChange={onInputChange("purchase_price", index)}
            />
          </div>
          <br />
          <div className="editable-cell">
            <InputNumber
              prefix="¥"
              value={record.seller_price ?? 0}
              onPressEnter={handleUpdateProduct(index)}
              onChange={onInputChange("seller_price", index)}
            />
          </div>
        </span>
      ),
    },
    {
      title: (
        <span>
          <USAFlag />
          <br />
          FBA最低価格<br />
          BuyBox価格<br />
          手動設定価格
        </span>
      ),
      dataIndex: "us_sell_price",
      render: (text, record, index) => {
        return (
          <div>
            <Link
              to={utils.getAmazonRefUrl(record.asin)}
              target="_blank"
            >
              <span className="custom-text-blue">
                {record.us_fba_lowest_price ? `$ ${record.us_fba_lowest_price}` : "$ -"}
              </span>
            </Link>
            <br /> <br />
            <Link
              to={utils.getAmazonRefUrl(record.asin)}
              target="_blank"
            >
              <span className="custom-text-blue">
                {record.us_price ? `$ ${record.us_price}` : "$ -"}
              </span>
            </Link>
            <br />
            <br />
            <div className="editable-cell">
              <InputNumber
                prefix="$"
                value={text}
                onPressEnter={handleUpdateProduct(index)}
                onChange={onInputChange("us_sell_price", index)}
              />
            </div>
            <br />
            <a style={{ color: "blue" }} onMouseOver={() => handleViewSellerInfo(index)}>FBA販売者情報</a>
          </div>
        );
      },
    },

    {
      title: (
        <span>
          <USAFlag />
          <br />
          4つのカテゴリ<br />
          容積重量<br />
          実重量
        </span>
      ),
      dataIndex: "package_size",
      render: (text, data) => {
        var product_type =
          data.product_type == "" || typeof data.product_type === "undefined"
            ? "-"
            : data.product_type;
        var product_type1 =
          data.product_type1 == "" || typeof data.product_type1 === "undefined"
            ? "-"
            : data.product_type1;
        if (product_type1 !== "") {
          if (product_type1 === "std") product_type = "STD";
          else if (product_type1 === "obv") product_type = "OVS";

          if (product_type1 === "std") product_type1 = "standard size";
          else if (product_type1 === "obv") product_type1 = "over size";
        }

        return (
          <span>
            {data.product_type ? data.product_type : "-"}
            <br /> <br />
            {product_type1 ? product_type1 : "-"}
            <br /> <br />
            <span style={{ fontStyle: "bold" }}>
              {Math.round(data.fba_weight)} g
            </span> <br /> <br />
            <span style={{ fontStyle: "bold" }}>
              {Math.round(data.package_weight)} g
            </span> <br /> <br />
          </span>
        );
      },
    },
    {
      title: (
        <span>
          <USAFlag />
          <br />
          Amazon.comの手数料 <br /> FBA手数料
          <br />
          合計手数料
          <br />
          インバウンドコスト
          <br />
          メディア 手数料
        </span>
      ),
      dataIndex: "profit",
      render: (text, data) => {
        let categoryFee = 0;
        let category_fee = data.us_amazon_fee != 0 ? Math.round(data.us_amazon_fee * 100) + "%" : "-";
        let fba_fee = data.fba_fee_auto != 0 ? "$" + data.fba_fee_auto + "(計算)" : "-";
        let all_fee = data.fba_fee_auto + data.us_amazon_fee * data.us_sell_price;
        all_fee = all_fee > 0 ? "$" + all_fee.toFixed(2) + `(${data.package_size}計算)` : "-";
        let gate_fee = data.gate_fee != 0 ? "$" + data.gate_fee : "-";
        let fba_code2asin_fee = "$" + data.pickandpack_fee_code2asin + "(code2asin)";

        if (constants.MediaTypes.includes(data.category) && !constants.IgnoreMediaAsins.includes(data.asin)) {
          categoryFee = 1.8;
        }

        return (
          <span>
            {category_fee}
            <br />
            <br />
            <span>{fba_fee}</span> <br />
            <br />
            <span>{fba_code2asin_fee}</span> <br />
            <br />
            <span>{all_fee}</span> <br />
            <br />
            <span>{gate_fee}</span> <br />
            <br />
            <span>$ {categoryFee}</span>
          </span>
        );
      },
    },
    {
      title: (
        <span>
          <USAFlag />
          <br />
          <br />
          利益額 <br /> 利益率<br />カテゴリ別%表示<br />kg単価送料
        </span>
      ),
      dataIndex: "profit",
      render: (text, data) => {
        let profit = data.profit != 0 ? `¥ ${data.profit}` : "-";
        let profit_rate = data.profit_rate != 0 ? `${data.profit_rate} %` : "-";


        let stdBold = data.product_type == "STD" ? "bold" : "normal";
        let stdDeco = data.product_type == "STD" ? "underline" : "noe";
        let stdColor = "";
        if (data.profit_rate < 0) {
          stdColor = "red";
        } else if (data.profit_rate < 5) {
          stdColor = "green";
        } else {
          stdColor = "black";
        }

        let slBold = data.product_type == "SL" ? "bold" : "normal";
        let slDeco = data.product_type == "SL" ? "underline" : "noe";
        let slColor = "";
        if (data.us_profit_percent_sl < 0) {
          slColor = "red";
        } else if (data.us_profit_percent_sl < 5) {
          slColor = "green";
        } else {
          slColor = "black";
        }

        return (
          <span>
            <span style={{ fontWeight: stdBold, color: stdColor, textDecoration: stdDeco }}>{profit}</span> <br /><br />
            <span style={{ fontWeight: stdBold, color: stdColor, textDecoration: stdDeco }}>{profit_rate}</span><br /><br />
            <span>{`Small and Light`}</span><br /><br />
            <span style={{ fontWeight: slBold, color: slColor, textDecoration: slDeco }}>¥{data.us_profit_amount_sl}</span><br /><br />
            <span style={{ fontWeight: slBold, color: slColor, textDecoration: slDeco }}>{data.us_profit_percent_sl} %</span><br /><br />
            <span>{data.us_rank}</span><br /><br />
            <span>¥ {data.priceperkgforresearch}</span>
          </span>
        );
      },
    },

    {
      title: (
        <span>
          <USAFlag />
          <br />
          ランキング<br />降下回数
          <br />
          価格抽出日
          <br />
          ランキング
        </span>
      ),
      dataIndex: "sales_history", //
      render: (text, data) => {
        var out_ban = data.is_ban == 0 ? "" : "禁止";
        // var is_adult = (data.isadultproduct == 0) ? "" : "アダルト";
        var updateDate = "";

        if (checkTypeofValue(data.updatedat) == 'string') {
          updateDate = data.updatedat.split(" ")[0];
        } else if (checkTypeofValue(data.updatedat) == 'number') {
          const date = new Date(Number(data.updatedat));
          let year = date.getFullYear();
          let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
          let day = String(date.getDate()).padStart(2, '0');
          let formattedDate = `${year}-${month}-${day}`;

          updateDate = formattedDate;
        } else {
          updateDate = "-"
        }

        return (
          <>
            {out_ban != "" ? (
              <>
                {" "}
                <span>{out_ban}</span> <br /> <br />{" "}
              </>
            ) : (
              ""
            )}
            {/* {is_adult != '' ? <> <span>{is_adult}</span> <br /> < br /> </> : ''} */}

            <span>{`30日-${data.salesrankdrops30 ?? '0'}`}</span>
            <br />
            <span>{`90日-${data.salesrankdrops90 ?? '0'}`}</span>
            <br />
            <span>{`180日-${data.salesrankdrops180 ?? '0'}`}</span>
            <br />
            <span>{`365日-${data.salesrankdrops365 ?? '0'}`}</span>
            <br /><br />
            <span>{`${updateDate}`}</span>
            <br /><br />
            <span>{`${data.us_rank}`}</span>
          </>
        );
      },
    },
    {
      title: "アクション",
      dataIndex: "action",
      render: (text, record, index) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <a
              className="me-1 p-1"
              onClick={() => handleCalculate(index)}
              title="利益計算"
            >
              <FaCalculator className="action-edit" />
            </a>
            <a
              className="me-1 p-1"
              // onClick={showPublishAlert}
              onClick={() => handleExhibitModalVisible(index)}
              title="出品"
            >
              <FaArrowUpFromBracket className="action-edit" />
            </a>
          </div>
          <div className="edit-delete-action">
            <a
              className="me-1 p-1"
              onClick={() => handleProductEdit(index)}
              title="編集"
            >
              <FaPencil className="action-edit" />
            </a>
            <a
              className="p-1"
              onClick={() => checkProductStatus(record.asin)}
              title="詳細"
            >
              <FiActivity className="feather-eye" />
            </a>
          </div>
          <div className="edit-delete-action">
            <a
              className="confirm-text p-1"
              onClick={() => showConfirmationAlertASINDelete(index)}
              title="削除"
            >
              <FaTrashCan />
            </a>
          </div>
        </div>
      ),
    },
  ];

  const showConfirmationAlertASINDelete = async (index) => {
    // utils.showConfirmBox("削除しますか？", "これを元に戻すことはできません。", async () => {
    const product = dataSource[index];
    setLoading(true);
    const result = await deleteResearchASIN({
      id: product.id,
      user: user,
    });
    setLoading(false);
    console.log("delete response", result);
    getResearchList(pagination.current);
    // });
  };

  const handleCSVUploadAction = async () => {
    setLoading(true);
    await uploadCSVFileForLowestPrice({
      region: region,
      file: csvUploadAction,
      user: user,
    });
    getResearchList(pagination.current);
    setIsLowestPriceModal(false);
    setCSVUploadAction(null);
    setLoading(false);
  }

  const handleCSVSellerPrice = async () => {
    setLoading(true);
    await uploadCSVFileForEditAsin({
      file: csvEditAsin,
      user: user,
    });
    getResearchList(pagination.current);
    setIsUploadEditAsinModal(false);
    setCsvEditAsin(null);
    setLoading(false);
  }

  const handleTopAction = async () => {
    try {

      if (filterValue.topAction == "download_research_page_asin") {
        let res = await downloadASINList({
          user: user,
        });
        const url = window.URL.createObjectURL(new Blob([res]))
        const link = document.createElement('a')
        link.href = url
        const fileName = `researchPageASINList.csv`;
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
        return;
      }
      if (filterValue.topAction == "upload_lowest_price") {

        setRegion(null);
        setCSVUploadAction(null);
        setIsLowestPriceModal(true);
        return;
      }
      if (filterValue.topAction == "upload_edit_asin") {
        setCSVUploadAction(null);
        setIsUploadEditAsinModal(true);
        return;
      }
      if (filterValue.topAction == "delete_bulk") {
        if (selectedRowKeys.length == 0) {
          utils.showAlertBox("警告", "このアクションを適用するには、製品を選択する必要があります。");
          return;
        }

        let deleteData = [];
        for (let key of selectedRowKeys) {
          let item = dataSource.find(product => product.id == key);
          deleteData.push({
            id: item.id
          });
        }

        utils.showConfirmBox("警告", "本当に削除しますか?", async () => {
          setLoading(true);

          await deleteBulkResearchASIN(deleteData, user);
          setLoading(false);
          getResearchList();
        });
        return;
      }
      if (filterValue.topAction == "bulk_listing_research_asin") {
        if (selectedRowKeys.length == 0) {
          utils.showAlertBox("警告", "このアクションを適用するには、製品を選択する必要があります。");
          return;
        }

        setBulkListingModalOpen(true);
        setBulkListingSellername(null);
        return;
      }

      utils.showConfirmBox("アクションの適用", "それを適用しますか？", async () => {
        setLoading(true);
        await CreateJob({
          jobname: filterValue.topAction,
          jobvalue: {
            name: "",
            value: "",
            condition: "",
            sellername: active_seller
          },
          user: user,
        });
        getResearchList();
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleBulkListing = async () => {
    if (!bulkListingSellername) {
      utils.showAlertBox("警告", "販売者名を選択してください。");
      return;
    }

    setLoading(true);

    let asins = [];
    for (let key of selectedRowKeys) {
      let item = dataSource.find(product => product.id == key);
      asins.push(item.asin);
    }

    const result = await CreateJob({
      jobname: "bulk_listing_research_asin",
      jobvalue: {
        name: "asin",
        value: asins.join(","),
        condition: "New",
        sellername: bulkListingSellername
      },
      user: user,
    });
    setLoading(false);
    setBulkListingModalOpen(false);
  }

  const onDrawerClose = () => {
    setIsDrawer(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const tableHeader = tableRef.current.querySelector(".ant-table-thead");
      const bodyRows = tableRef.current.querySelectorAll(
        ".ant-table-tbody > tr"
      );
      const tableRect = tableRef.current.getBoundingClientRect();
      const headerRect = tableHeader.getBoundingClientRect();

      const widths = [];
      let index = 0;
      for (let i = 0; i <= columns.length; i++) {
        if (i > 0 && columns[i-1]?.hidden === true) widths.push(0);
        index++;

        const bodyCell = bodyRows[0].querySelector(
          `.ant-table-cell:nth-child(${index})`
        );
        widths.push(bodyCell && bodyCell.offsetWidth);
      }

      setHeaderWidths(widths);

      if (tableRect.top < headerRect.height) {
        setHeaderFixed(true);
      } else {
        tableHeader.style.position = "static";
        setHeaderFixed(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sidebar_visible]);


  // useEffect(() => {
  //   console.log(sidebar_visible);

  // }, [sidebar_visible]);

  return (
    <>
      {loading && (
        <div id="global-loader">
          <h2>データ処理中...</h2>
        </div>
      )}
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>FBA商品情報調査</h4>
              </div>
            </div>

            <div className="page-btn">
              <a
                className="btn btn-added"
                data-bs-toggle="modal"
                data-bs-target="#product-add-modal"
              >
                <FiPlusCircle className="me-2 iconsize" />
                ASIN 追加
              </a>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <a
                    type="button"
                    onClick={() => handleRefresh()}
                    style={{ paddingRight: 10 }}
                  >
                    <FaArrowRotateRight />
                  </a>
                  <div className="search-input">
                    <input
                      value={keyword}
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      style={{ width: 200 }}
                      onChange={(e) => {
                        setKeyword(e.target.value);
                        setFilterValue((prev) => ({
                          ...prev,
                          filter_keyword: e.target.value,
                        }))
                      }}
                      onKeyDown={handleSearch}
                    // suffix={<FaMagnifyingGlass />}
                    />
                    <button
                      type="button"
                      onClick={() => handleSearch(null)}
                      className="btn btn-searchset"
                    >
                      <FaMagnifyingGlass />
                    </button>
                  </div>
                  <div
                    className="search-input"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    <label style={{ marginRight: "10px" }}>フィルタ</label>
                    <Select
                      className="select"
                      options={TopFilterOptions}
                      size="large"
                      style={{ width: "200px" }}
                      value={filterValue.topFilter}
                      onChange={(e) =>
                        setFilterValue((prev) => ({
                          ...prev,
                          topFilter: e,
                        }))
                      }
                    />
                    <button
                      type="button"
                      className="btn btn-primary me-2"
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        filterValue.filter_keyword = "";
                        getResearchList()
                      }}
                    >
                      検索
                    </button>
                  </div>
                  <div
                    className="search-input"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    <label style={{ marginRight: "10px" }}>アクション</label>
                    <Select
                      className="select"
                      options={TopActionOptions}
                      size="large"
                      style={{ width: "200px" }}
                      value={filterValue.topAction}
                      onChange={(e) =>
                        setFilterValue((prev) => ({
                          ...prev,
                          topAction: e,
                        }))
                      }
                    />
                    <button
                      type="button"
                      className="btn btn-primary me-2"
                      style={{ marginLeft: "10px" }}
                      onClick={handleTopAction}
                    >
                      適用
                    </button>
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary me-2"
                    onClick={() => {
                      setIsDrawer(true);
                    }}
                  >
                    Filter Set
                  </button>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ display: "flex", alignItems: "center", marginLeft: "20px" }}>
                    <div className="form-sort">
                      <Select
                        className="select"
                        options={constants.PageSizeOptions}
                        placeholder="100"
                        onChange={handlePageSizeChange}
                      />
                    </div>
                    <span style={{ whiteSpace: "nowrap", marginLeft: "10px" }}>
                      件ずつ表示
                    </span>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <div style={{ display: "flex" }}>
                  <div className="search-path">
                    <div className="search-set">
                      <div className="search-input" style={{ display: "flex" }}>
                        <input
                          value={jumprow}
                          type="number"
                          placeholder="に行く..."
                          className="form-control form-control-sm formsearch"
                          onChange={(e) => setJumpRow(e.target.value)}
                          onKeyDown={handleJumpRow}
                        />
                        <span style={{ margin: "10px", width: "60%" }}>
                          {" "}
                          of {pageCount} pages
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      textAlign: "end",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    検索結果 : {totalItems} 件中 {(pagination.current - 1) * pagination.pageSize + 1}-
                    {Math.min(pagination.current * pagination.pageSize, totalItems)} 件
                  </div>
                </div>
                <br />
                {isHeaderFixed && (
                  <div style={{ zIndex: 1000, background: "#fff", position: "fixed", top: 0 }}>
                    <table>
                      <thead>
                        <tr>
                          <th
                            style={{ width: headerWidths[0], textAlign: "center", border: "2px solid #f0f0f0" }}
                          >
                            #
                          </th>
                          {columns.map((column, index) => (
                            (column.hidden === true) ? <></> :
                              <th
                                key={index}
                                style={{ width: headerWidths[index + 1], textAlign: "center", border: "2px solid #f0f0f0" }}
                              >
                                {column.title}
                              </th>
                          ))}
                        </tr>
                      </thead>
                    </table>
                  </div>
                )}
                <div ref={tableRef}>
                  <Table bordered
                    rowSelection={{
                      type: "checkbox",
                      selectedRowKeys,
                      onChange: (newSelectedRowKeys) => {
                        setSelectedRowKeys(newSelectedRowKeys);
                      }
                    }}
                    className="table datanew dataTable no-footer"
                    // rowSelection={rowSelection}
                    columns={columns}
                    dataSource={dataSource}
                    rowKey={(record) => record.id}
                    pagination={{
                      ...pagination,
                      // position: ['topRight', 'bottomRight'],
                      position: ["bottomRight"],
                      total: totalItems,
                      pageSizeOptions: [
                        "100",
                        "150",
                        "200",
                        "300",
                        "500",
                        "1000",
                      ],
                    }}
                    onChange={handleTableChange}
                  // sticky={{
                  //   offsetHeader: 0,
                  // }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /product list */}

          {/* Product edit modal  */}
          <Modal title={<h4>編集 - ASIN: {editASIN}</h4>} open={edit_modal_visible} footer={false} onCancel={() => setEdit_modal_visible(false)} width={"95%"} centered>
            <div className="py-3">
              <div className="row mb-2">
                <div className="col-md-1 pt-2">
                  <label className="form-label">US 商品名</label>
                </div>
                <div className="col-md-11">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="US 商品名"
                    value={editus_title ?? ''}
                    onChange={onEditInputChange("us_title")}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-1 pt-2">
                  <label className="form-label">JP 商品名</label>
                </div>
                <div className="col-md-11">
                  <input
                    type="text"
                    className="form-control"
                    value={editjp_title ?? ''}
                    onChange={onEditInputChange("jp_title")}
                    placeholder="JP 商品名"
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-1 pt-2">
                  <label className="form-label">インボイス表記名</label>
                </div>
                <div className="col-md-7">
                  <input
                    type="text"
                    className="form-control"
                    value={edit_invoice_name ?? ''}
                    onChange={onEditInputChange("invoice_name")}
                    placeholder=""
                  />
                </div>
                <div className="col-md-1 pt-2 text-end">
                  <label className="form-label">HSコード</label>
                </div>
                <div className="col-md-1">
                  <input
                    type="text"
                    className="form-control"
                    value={edit_hs_code ?? ''}
                    onChange={onEditInputChange("hs_code")}
                    placeholder=""
                  />
                </div>
                <div className="col-md-1 pt-2 text-end">
                  <label className="form-label">HTSコード (fedex)</label>
                </div>
                <div className="col-md-1">
                  <input
                    type="text"
                    className="form-control"
                    value={edit_hts_code ?? ''}
                    onChange={onEditInputChange("hts_code")}
                    placeholder=""
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-5">
                  <div className="row">
                    <div className="col-md-3">
                      <label className="form-label mt-2">weeea2 FNSKU</label>
                      <input
                        type="text"
                        className="form-control"
                        value={edit_fnsku_weeea2 ?? ''}
                        onChange={onEditInputChange("fnsku_weeea2")}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label mt-2">kt.japan FNSKU</label>
                      <input
                        type="text"
                        className="form-control"
                        value={edit_fnsku_kt ?? ''}
                        onChange={onEditInputChange("fnsku_kt")}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label mt-2">njoy FNSKU</label>
                      <input
                        type="text"
                        className="form-control"
                        value={edit_fnsku_njoy ?? ''}
                        onChange={onEditInputChange("fnsku_njoy")}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label mt-2">fhjiyama FNSKU</label>
                      <input
                        type="text"
                        className="form-control"
                        value={edit_fnsku_fh ?? ''}
                        onChange={onEditInputChange("fnsku_fh")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="row mb-2">
                    <div className="col-md-2 pt-2 text-end">
                      <label className="form-label">製品画像 URL</label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="-"
                        value={edit_image ?? ''}
                        onChange={onEditInputChange("image")}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-2 pt-2 text-end">
                      <label className="form-label">梱包画像 URL</label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="-"
                        value={edit_package_image ?? ''}
                        onChange={onEditInputChange("package_image")}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2 pt-2 text-end">
                      <label className="form-label">Custom Product Type</label>
                    </div>
                    <div className="col-md-2">
                      <Select
                        style={{ width: "100%" }}
                        size="large"
                        options={constants.ProductTypeOptions}
                        onChange={onEditInputChange("product_type1")}
                        value={edit_product_type1}
                      />
                    </div>

                    <div className="col-md-2 pt-2 text-end">
                      <label className="form-label">コンディション</label>
                    </div>
                    <div className="col-md-2">
                      <Select
                        style={{ width: "100%" }}
                        size="large"
                        options={constants.ConditionOptions}
                        value={edit_itemcondition}
                        onChange={onEditInputChange("itemcondition")}
                      />
                    </div>

                    <div className="col-md-2 pt-2 text-end">
                      <label className="form-label">Variable closing fee</label>
                    </div>
                    <div className="col-md-2 pt-2">
                      <Checkbox
                        checked={edit_variable_closing_fee == 1 ? true : false} style={{ marginLeft: "10px" }}
                        onChange={onEditInputChange("variable_closing_fee")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-5">
                  <div className="row mb-3">
                    <div className="col-md-3">
                      <label className="form-label">長さ(cm)</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="-"
                        value={edit_length ?? ''}
                        onChange={onEditInputChange("length")}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">幅(cm)</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="-"
                        value={edit_width ?? ''}
                        onChange={onEditInputChange("width")}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">高さ(cm)</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="-"
                        value={edit_height ?? ''}
                        onChange={onEditInputChange("height")}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">重量(g)</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="-"
                        value={edit_weight ?? ''}
                        onChange={onEditInputChange("weight")}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-3">
                      <label className="form-label">セット数</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="-"
                        value={edit_totalofpackage ?? ''}
                        onChange={onEditInputChange("totalofpackage")}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">仕入数量</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="-"
                        value={edit_purchase_stock ?? ''}
                        onChange={onEditInputChange("purchase_stock")}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">ロット</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="-"
                        value={edit_min_num_orders ?? ''}
                        onChange={onEditInputChange("min_num_orders")}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">在庫</label>
                      <input
                        type="text"
                        className="form-control"
                        id="field-3"
                        value={edit_us_stock ?? ''}
                        onChange={onEditInputChange("us_stock")}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-3">
                      <label className="form-label">JP 手動価格(¥)</label>
                      <input
                        type="text"
                        className="form-control"
                        value={editpurchase_price ?? ''}
                        placeholder="JP 手動価格"
                        onChange={onEditInputChange("purchase_price")}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">US 販売価格($)</label>
                      <input
                        type="text"
                        className="form-control"
                        value={editus_sell_price ?? ''}
                        placeholder="US 販売価格"
                        onChange={onEditInputChange("us_sell_price")}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">発注先</label>
                      <Select
                        options={constants.PurchasePlaces}
                        value={edit_purchase_place ?? ''}
                        style={{ width: "100%" }}
                        onChange={onEditInputChange("purchase_place")}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">問屋</label><br />
                      <Checkbox
                        checked={edit_is_own == 1 ? true : false} style={{ marginLeft: "10px" }}
                        onChange={onEditInputChange("is_own")}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-3">
                      <label className="form-label">Amazon手数料(%)</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="-"
                        value={edit_us_amazon_fee ?? ''}
                        onChange={onEditInputChange("us_amazon_fee")}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">FBA手数料($)</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="-"
                        value={edit_fba_fee_auto ?? ''}
                        onChange={onEditInputChange("fba_fee_auto")}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">FBA販売者数</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="-"
                        value={edit_us_fba_seller_num ?? ''}
                        onChange={onEditInputChange("us_fba_seller_num")}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">販売者数(全体)</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="-"
                        value={edit_us_seller_num ?? ''}
                        onChange={onEditInputChange("us_seller_num")}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-3 pt-2 text-end">
                      <label className="form-label">インバウンドコスト</label>
                    </div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        className="form-control"
                        value={edit_gate_fee ?? ''}
                        onChange={onEditInputChange("gate_fee")}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-3 pt-2 text-end">
                      <label className="form-label">管理番号</label>
                    </div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="-"
                        value={edit_jan3 ?? ''}
                        onChange={() => { }}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-3 pt-2 text-end">
                      <label className="form-label">梱包メモ</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="-"
                        value={edit_package_note ?? ''}
                        onChange={onEditInputChange("package_note")}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-3 pt-2 text-end">
                      <label className="form-label">梱包方法</label>
                    </div>
                    <div className="col-md-9">
                      <AutoComplete
                        value={edit_package_way || ""}
                        options={packageWayList}
                        style={{ width: "100%" }}
                        onSelect={(value) => onEditInputChange("package_way")(value)}
                        filterOption={(inputValue, option) => (inputValue.trim().length > 0 && option.value.indexOf(inputValue) != -1)}
                        onChange={onEditInputChange("package_way")}
                      >
                      </AutoComplete>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-3 pt-2 text-end">
                      <label className="form-label">商品説明</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="-"
                        value={edit_us_comment ?? ''}
                        onChange={onEditInputChange("us_comment")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="row jan-input-wrapper">
                    <div className="col-md-6">
                      <div className="row">
                        <label className="col-md-3 form-label">JAN</label>
                        <label className="col-md-3 form-label">型番</label>
                        <label className="col-md-2 form-label">内訳数</label>
                        <label className="col-md-2 form-label px-0">単価(税抜)</label>
                        <label className="col-md-2 form-label px-0">単価(税込)</label>
                      </div>
                      {"1".repeat(15).split('').map((v, index) => {
                        return (
                          <div className="row" key={index}>
                            <div className="col-md-3 px-0 d-flex">
                              <label className="form-label pt-2 text-center" style={{ width: 20 }}>{index + 1}</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="-"
                                value={edit_jan?.split(",")?.[index] ?? ''}
                                onChange={onEditSelectChange("jan", index)}
                              />
                            </div>
                            <div className="col-md-3 px-0">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="-"
                                value={edit_catalognumber?.split(",")?.[index] ?? ''}
                                onChange={onEditSelectChange("catalognumber", index)}
                              />
                            </div>
                            <div className="col-md-2 px-0">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="-"
                                value={edit_breakdown_qty?.split(",")?.[index] ?? ''}
                                onChange={onEditSelectChange("breakdown_qty", index)}
                              />
                            </div>
                            <div className="col-md-2 px-0">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="-"
                                value={edit_price_no_tax?.split(",")?.[index] ?? ''}
                                onChange={onEditSelectChange("price_no_tax", index)}
                              />
                            </div>
                            <div className="col-md-2 ps-0">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="-"
                                value={edit_price_tax?.split(",")?.[index] ?? ''}
                                onChange={onEditSelectChange("price_tax", index)}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <label className="col-md-3 form-label">JAN</label>
                        <label className="col-md-3 form-label">型番</label>
                        <label className="col-md-2 form-label">内訳数</label>
                        <label className="col-md-2 form-label px-0">単価(税抜)</label>
                        <label className="col-md-2 form-label px-0">単価(税込)</label>
                      </div>
                      {"1".repeat(15).split('').map((v, index) => {
                        let idx = 15 + index;
                        return (
                          <div className="row" key={idx}>
                            <div className="col-md-3 px-0 d-flex">
                              <label className="form-label pt-2 text-center" style={{ width: 20 }}>{idx + 1}</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="-"
                                value={edit_jan?.split(",")?.[idx] ?? ''}
                                onChange={onEditSelectChange("jan", idx)}
                              />
                            </div>
                            <div className="col-md-3 px-0">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="-"
                                value={edit_catalognumber?.split(",")?.[idx] ?? ''}
                                onChange={onEditSelectChange("catalognumber", idx)}
                              />
                            </div>
                            <div className="col-md-2 px-0">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="-"
                                value={edit_breakdown_qty?.split(",")?.[idx] ?? ''}
                                onChange={onEditSelectChange("breakdown_qty", idx)}
                              />
                            </div>
                            <div className="col-md-2 px-0">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="-"
                                value={edit_price_no_tax?.split(",")?.[idx] ?? ''}
                                onChange={onEditSelectChange("price_no_tax", idx)}
                              />
                            </div>
                            <div className="col-md-2 ps-0">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="-"
                                value={edit_price_tax?.split(",")?.[idx] ?? ''}
                                onChange={onEditSelectChange("price_tax", idx)}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-cancel waves-effect"
                onClick={() => setEdit_modal_visible(false)}
              >
                閉じる
              </button>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
                style={{ marginLeft: "10px" }}
                onClick={handleUpdateProduct(editASINIndex)}
              >
                保存
              </button>
            </div>
          </Modal>

          {/* Product add modal  */}
          <div
            id="product-add-modal"
            className="modal fade"
            tabIndex={-1}
            role="dialog"
            aria-hidden="true"
            style={{ display: "none" }}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">追加</h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body p-4">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="form-label">ASIN:</label>
                      <div className="d-flex">
                        <input
                          type="text"
                          className="form-control"
                          style={{ width: "220px" }}
                          id="add_asin_id"
                          onChange={(e) => setAddAsin(e.target.value)}
                        />
                        <input
                          type="file"
                          ref={csvUploadRef}
                          onChange={uploadCSVASINFile}
                          style={{ display: 'none' }}
                          accept=".csv"
                        />
                        <button type="button" className="btn btn-primary me-2" style={{ marginLeft: "10px" }} onClick={() => csvUploadRef.current.click()}>
                          ファイル アップロード
                        </button>
                      </div>

                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="field-1" className="form-label">
                          Condition
                        </label>
                        <Select
                          style={{ width: "100%" }}
                          size="large"
                          options={constants.ConditionOptions}
                          value={addAsinCondition}
                          onChange={({ value }) => setAddAsinCondition(value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-cancel waves-effect"
                    data-bs-dismiss="modal"
                  >
                    閉じる
                  </button>
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={handleAddASIN}
                  >
                    保存
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* -----Product exhibit modal START----- */}
          <Modal open={exhibitModalVisible} title={<h4>商品出品ダイアログボックス</h4>} footer={false} onCancel={() => setExhibitModalVisible(false)}>
            <div>
              <div className="row" style={{ marginTop: "20px" }}>
                <div className="col-md-6 d-flex align-items-center">
                  <label className="form-label">ASIN: {editASIN}</label>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={() => handleCalculate(editASINIndex)}
                  >
                    計算
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="field-3" className="form-label">
                      Seller Name
                    </label>
                    <Select
                      style={{ width: "100%" }}
                      size="large"
                      onChange={(e) =>
                        onInputExhibit("sellername", e)
                      }
                      value={exhibitData.sellername}
                    >
                      {
                        store_list && store_list.map((item, key) => <Select.Option key={key} value={item.sellername}>{item.sellername}</Select.Option>)
                      }
                    </Select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="field-3" className="form-label">
                      SKU
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="field-3"
                      value={exhibitData.sku ?? ''}
                      onChange={onEditInputChange(exhibitData.label)}
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="field-3" className="form-label">
                      コンディション
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="field-3"
                      value={edit_itemcondition ?? ''}
                      disabled={true}
                      onChange={onEditInputChange("jp_title")}
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="field-3" className="form-label">
                      JAN
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="field-3"
                      value={edit_jan ?? ''}
                      onChange={onEditInputChange("jan")}
                      placeholder="JAN"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="field-3" className="form-label">
                      商品説明
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="field-3"
                      value={edit_us_comment ?? ''}
                      onChange={onEditInputChange("us_comment")}
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="field-3" className="form-label">
                      出荷日数
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="field-3"
                      value={edit_handling_time ?? ''}
                      onChange={onEditInputChange("handling_time")}
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="field-3" className="form-label">
                      出品価格($)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="field-3"
                      value={editus_sell_price ?? ''}
                      onChange={onEditInputChange("us_sell_price")}
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="field-3" className="form-label">
                      在庫
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="field-3"
                      value={edit_purchase_stock ?? ''}
                      onChange={onEditInputChange("purchase_stock")}
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="field-3" className="form-label">
                      アマゾン手数料
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="field-3"
                      value={edit_us_amazon_fee * 100}
                      disabled={true}
                      onChange={onEditInputChange("us_amazon_fee")}
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2 d-flex align-items-center">
                  <label className="form-label">送料</label>
                </div>
                <div className="col-md-10 row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="field-1" className="form-label">
                        価格
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="field-1"
                        value={""}
                        disabled={true}
                        placeholder=""
                      // onChange={onEditInputChange("jp_sell_price")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="field-2" className="form-label">
                        発送方法
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        disabled={true}
                        id="field-2"
                        value={""}
                        placeholder=""
                      // onChange={onEditInputChange("us_sell_price")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="field-3" className="form-label">
                      利益額
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled={true}
                      id="field-3"
                      value={`${edit_profit}¥`}
                      onChange={onEditInputChange("profit")}
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-cancel waves-effect"
                onClick={() => setExhibitModalVisible(false)}
              >
                閉じる
              </button>
              <button
                type="button"
                disabled={exhibitData.isExhibit}
                className="btn btn-primary waves-effect waves-light"
                style={{ marginLeft: "10px", cursor: exhibitData.isExhibit ? "not-allowed" : "pointer" }}
                onClick={() => handleExhibit(editASINIndex)}
              >
                FBA出品
              </button>
            </div>
          </Modal>
          {/* -----Product exhibit modal END----- */}

          {/* -------Filter Set Drawer start----------- */}
          <FilterDrawer
            open={isDrawer}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            categoryList={categoryList}
            additionalOptions={{
              orderTypes: [
                { value: "fba_weight_order", label: "重" },
                { value: "updatedat", label: "価格抽出日" }
              ]
            }}
            isSalesRanks={true}
            onOk={() => {
              setPagination({ current: 1, pageSize: pagination.pageSize });
              onDrawerClose();
              getResearchList();
            }}
            onCancel={onDrawerClose}
          />
          {/* -------Filter Set Drawer end----------- */}

          {/* upload lowest price modal  */}
          <Modal
            title="最安値をアップロードする"
            open={isLowestPriceModal}
            okText="アップロード"
            cancelText="閉じる"
            onOk={() => handleCSVUploadAction()}
            onCancel={() => setIsLowestPriceModal(false)}>
            <div className="modal-body p-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="field-1" className="form-label">
                      Region
                    </label>
                    <Select
                      className="select"
                      size="large"
                      value={region}
                      placeholder="Select Region"
                      style={{ width: "100%" }}
                      options={constants.RegionOptions}
                      onChange={(value) => setRegion(value)}
                    />
                  </div>
                  <input
                    type="file"
                    ref={csvUploadActionRef}
                    onChange={uploadCSVActionASINFile}
                    style={{ display: 'none' }}
                    accept=".csv"
                  />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <button type="button" className="btn btn-primary me-2" onClick={() => csvUploadActionRef.current.click()}>
                      ファイル アップロード
                    </button>
                    <label className="form-label">
                      {csvUploadAction ? csvUploadAction.name : ""}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          {/* upload seller price modal  */}
          <Modal
            title="編集ファイルをアップロードする"
            open={isUploadEditAsinModal}
            okText="アップロード"
            cancelText="閉じる"
            onOk={() => handleCSVSellerPrice()}
            onCancel={() => setIsUploadEditAsinModal(false)}>
            <div className="modal-body p-4">
              <div className="row">
                <div className="col-md-12">
                  <input
                    type="file"
                    ref={csvEditAsinRef}
                    onChange={uploadCSVEditAsin}
                    style={{ display: 'none' }}
                    accept=".csv"
                  />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <button type="button" className="btn btn-primary me-2" onClick={() => csvEditAsinRef.current.click()}>
                      ファイル アップロード
                    </button>
                    <label className="form-label">
                      {csvEditAsin ? csvEditAsin.name : ""}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            title="一括FBA商品出品"
            open={bulkListingModalOpen}
            okText="FBA出品"
            cancelText="閉じる"
            onOk={() => handleBulkListing()}
            onCancel={() => setBulkListingModalOpen(false)}>
            <div className="modal-body p-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label htmlFor="field-3" className="form-label">Seller Name</label>
                    <Select style={{ width: "100%" }} size="large"
                      onChange={(value) => setBulkListingSellername(value)}
                      value={bulkListingSellername}
                    >
                      {
                        store_list && store_list.map((item, key) => <Select.Option key={key} value={item.sellername}>{item.sellername}</Select.Option>)
                      }
                    </Select>
                  </div>
                </div>
              </div>
            </div>
          </Modal>


          <GraphModal
            open={graphModalOpen}
            asin={graphModalAsin}
            onClose={() => setGraphModalOpen(false)}
          />

          <FBASellerInfoDrawer
            open={sellerInfoOpen}
            asin={sellerInfoAsin}
            onClose={() => setSellerInfoOpen(false)}
            onClickSeller={handleClickSeller}
          />
        </div>
      </div >
    </>
  );
});

export default ResearchList;
