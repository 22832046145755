/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { FiActivity, FiChevronDown, FiChevronUp } from "react-icons/fi";
import { FaMagnifyingGlass } from "react-icons/fa6";

import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { all_routes } from "../../Router/all_routes";
import {
  Table,
  Input,
  Drawer,
  Checkbox,
  Select,
  Radio,
  Popover,
} from "antd";
import {
  CreateJob,
  getBulkAsinSalesRanksHistory,
  getBulkProductDetails,
  getCategoryData,
  getRestockInventory,
  isTokenErrorResponse,
} from "../../services";
import { useAuth } from "../../hooks/useAuth";
import * as constants from "../../utils/constants";
import * as utils from "../../utils/util";
import SellersGraphPopover from "../research/SellersGraphPopover";
import { FBASellerInfoDrawer } from "../research/FBASellerInfoDrawer";
import RankingGraphPopover from "../research/RankingGraphPopover";
import { FilterDrawer } from "../../core/components/FilterDrawer";
import RankingGraph from "../../core/components/RankingGraph";
import SellersGraph from "../../core/components/SellersGraph";


const initialData = {
  topFilter: "fba_research_all",
  topAction: "none",
  order_by_value: "",
  order_by_way: 0, // 0 or 1
  filter_multi_asins: "",
  filter_us_min_rank: "",
  filter_us_max_rank: "",
  filter_us_min_weight: "",
  filter_us_max_weight: "",
  filter_us_min_price: "",
  filter_us_max_price: "",
  filter_jp_purchase_min_price: "",
  filter_jp_purchase_max_price: "",
  filter_jp_seller_min_price: "",
  filter_jp_seller_max_price: "",
  filter_min_profit_amount: "",
  filter_max_profit_amount: "",
  filter_min_profit_percent: "",
  filter_max_profit_percent: "",
  filter_product_type1_list: "",
  filter_us_category_list: "",
  filter_purchase_place: "",
  filter_ship_place: "",
  filter_employee_name: "",
  // sales_count_not0: false,

  rank_drop_count_not0: false,
  filter_is_own_product: '0',
  filter_purchase_recommend_product: '0',

  filter_start_order_date: "",
  filter_end_order_date: "",
  filter_start_purchase_date: "",
  filter_end_purchase_date: "",
  filter_keyword: "",
};

const topFilterOptions = [
  { value: "fba_research_all", label: "全体" },
  { value: "purchase_recommend_product", label: "発注推奨商品" },
  { value: "fba_real_weight_compare", label: "実重量 ＞ 容積重量 商品" },
  { value: "fba_real_weight_compare_anti", label: "容積重量 ＞ 実重量 商品" },
];

const topActionOptions = [
  { value: "none", label: "-- なし --" },
  { value: "calculate_profit_bulk_listing_page", label: "自動利益額計算" },
]


const RestockInventory = () => {
  const { user, logout } = useAuth();
  const tableRef = useRef(null);

  const [headerWidths, setHeaderWidths] = useState([]);
  const [isHeaderFixed, setHeaderFixed] = useState(false);


  const active_seller = useSelector((state) => state.active_seller);
  const sidebar_visible = useSelector((state) => state.sidebar_visible);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchAsin = searchParams.get("asin");

  const route = all_routes;

  const [loading, setLoading] = useState(false);

  const [keyword, setKeyword] = useState("");
  const [filterValue, setFilterValue] = useState({
    ...initialData,
    filter_keyword: searchAsin ? searchAsin : ''
  });

  const [categoryList, setCategoryList] = useState();
  const [isDrawer, setIsDrawer] = useState(false);

  const [dataSource, setDataSource] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });
  const [jumprow, setJumpRow] = useState("");

  // for seller info drawer
  const [sellerInfoOpen, setSellerInfoOpen] = useState(false);
  const [sellerInfoAsin, setSellerInfoAsin] = useState(null);

  const [historyData, setHistoryData] = useState([]);

  const handleSearch = (e) => {
    if (e == null || e.key === "Enter") {
      if (keyword == "") {
        filterValue.filter_keyword = "";
      }
      setJumpRow(0);
      setPagination({ current: 1, pageSize: pagination.pageSize });
      getResearchList();
      setKeyword("");
    }
  };

  const getCategory = async () => {
    const result = await getCategoryData({ user });
    setCategoryList(result.data);
  }

  const getResearchList = async (page = 1) => {
    try {
      let seller = "";
      if (active_seller != "すべてのアカウント") seller = active_seller;
      setLoading(true);

      const result = await getRestockInventory({
        page: page - 1,
        count: pagination.pageSize,
        user: user,
        keyword: filterValue.filter_keyword,
        seller: seller,
        filterValue
      });
      if (isTokenErrorResponse(result)) {
        return logout();
      }

      if (result.data.data && result.data.data.length > 0) {
        setDataSource(result.data.data);

        let pages = Math.ceil(parseInt(result.data.recordsTotal) / pagination.pageSize);
        setPageCount(pages);
        setTotalItems(result.data.recordsTotal);
      } else {
        setDataSource([]);
        setTotalItems(0);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
    getResearchList(pagination.current);
  };

  const handlePageSizeChange = (pagesize) => {
    pagination.pageSize = pagesize;
    getResearchList();
  };
  useEffect(() => {
    document.title = "在庫補充";
    getCategory();
    getResearchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_seller]);

  const checkProductStatus = async (asin) => {
    window.open(`${route.productdetails}?asin=${asin}`, "_blank", "noreferrer");
  };

  const handleSortSellerName = (array) => {
    const order = ["weeea2", "kt.japan", "njoy", "fhjiyama", "Musashi"];
    array.sort((a, b) => {
      return order.indexOf(a.sellername) - order.indexOf(b.sellername);
    });
    return array;
  }

  const handleViewSellerInfo = async (index) => {
    const product = dataSource[index];
    setSellerInfoAsin(product[0].asin);
    setSellerInfoOpen(true);
  }

  const handleClickSeller = async (sellerId) => {
    window.open(`${route.research}?sellerId=${sellerId}`, "_blank", "noreferrer");
  }

  const getHistoryByAsin = (asin) => {
    let item = historyData.find(item => item.asin == asin);
    if (item != undefined && item != null) {
      return item;
    }
    return null;
  }
  const loadHistoryData = async () => {
    const asins = [];

    for (const product of dataSource) {
      let isExist = getHistoryByAsin(product[0].asin);
      if (!isExist) {
        asins.push(product[0].asin);

        // if (asins.length > 10) {
        //   break;
        // }
      }
    }
    if (asins.length == 0) {
      return;
    }

    const newHistories = [];

    const result1 = await getBulkProductDetails(asins, user);
    if (result1.data && result1.data.length > 0) {
      for (const sellerData of result1.data) {
        if (sellerData.length == 0) continue;
        newHistories.push({
          asin: sellerData[0].asin,
          sellerData: sellerData
        });
      }
    }
    const result2 = await getBulkAsinSalesRanksHistory(asins, user);
    if (result2.data && result2.data.length > 0) {
      for (const rankingData of result2.data) {
        if (rankingData.length == 0) continue;
        let existItem = newHistories.find(item => item.asin == rankingData[0].asin);
        if (existItem) {
          existItem.rankingData = rankingData;
        }
      }
    }

    setHistoryData([
      ...historyData,
      ...newHistories
    ]);
  }

  useEffect(() => {
    if (!sidebar_visible) {
      loadHistoryData();
    }
  }, [sidebar_visible, dataSource]);

  const columns = [
    {
      title: "商品画像",
      dataIndex: "image",
      width: 100,
      render: (text, record) => {
        return (
          <div>
            <span className="inventoryimgname">
              <div className="mb-1" style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Link to={`https://amazon.com/dp/${record[0].asin}`} className="product-img stock-img">
                  <ImageWithBasePath alt="" src={record[0].image ?? "assets/img/products/product1.jpg"} isProduct={record[0].image ? true : false} />
                </Link>
              </div>
            </span>
            <div className="mt-2">
              <Popover content={<RankingGraphPopover asin={record[0].asin} />}>
                <a style={{ color: "blue" }}>ランキング</a>
              </Popover>
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <span>商品タイトル</span>
      ),
      dataIndex: "productname",
      width: 200,
      render: (text, record, index) => {
        return (
          <div className="inventoryimgname">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Popover content={record[0].us_title}>
                <Link
                  to={`http://www.amazon.com/gp/offer-listing/${record[0].asin}`}
                  className="product-title"
                  target="_blank"
                >
                  {record[0].us_title}
                </Link>
              </Popover>
              <br />
              <Popover content={record[0].title}>
                <Link
                  to={`http://www.amazon.co.jp/gp/offer-listing/${record[0].asin}`}
                  className="product-title"
                  target="_blank"
                >
                  {record[0].title}
                </Link>
              </Popover>
            </div>
          </div>
        );
      },
    },
    {
      title: <span>ASIN<br />
        JAN<br />
        在庫<br />
        型番<br />
        メーカー
      </span>,
      width: 100,
      dataIndex: "asin",
      render: (text, record) => {
        let jansArr = record[0].jan?.split(",");
        let jans = <span>
          {jansArr?.map((j, idx) => <span key={idx}>{j}<br /></span>)}
        </span>;

        let catasArr = record[0].catalognumber?.split(",");
        let catas = <span>
          {catasArr?.map((j, idx) => <span key={idx}>{j}<br /></span>)}
        </span>;


        return <div style={{ width: "100px", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div>{record[0].asin}</div>
          <div><Popover content={jans}>{jansArr?.[0]}</Popover></div>
          <div>{record[0].jp_stock}</div>
          <div style={{ whiteSpace: "wrap" }}><Popover content={catas}>{catasArr?.[0] ?? '-'}</Popover></div>
          <div>{record[0].manufacture}</div>
          <div>{record[0].fba_weight} g</div>
          <div>{record[0].package_weight} g</div>
        </div>
      }
    },
    {
      title: <span>Account</span>,
      dataIndex: "itemcondition",
      align: "center",
      render: (text, record) => {
        return (
          <div className="seller-title_" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            {
              handleSortSellerName(record).map((item) => {
                if (item.sellername == "weeea2")
                  return <div className="mb-1 sellermark weeea2">WE</div>;
                if (item.sellername == "kt.japan")
                  return <div className="mb-1 sellermark kt.japan">KT</div>;
                if (item.sellername == "njoy")
                  return <div className="mb-1 sellermark njoy">NJ</div>;
                if (item.sellername == "fhjiyama")
                  return <div className="mb-1 sellermark fhjiyama">FH</div>;
              })
            }
          </div>
        );
      },
    },

    {
      title: (
        <span>
          グラフ
        </span>
      ),
      dataIndex: "graph",
      width: 200,
      hidden: sidebar_visible,
      render: (text, record, index) => {
        return (
          <div>
            <div>
              <RankingGraph
                user={user}
                data={getHistoryByAsin(record[0].asin)?.rankingData}
                height={150}
                simple={true}
              />
            </div>
            <div>
              <SellersGraph
                user={user}
                data={getHistoryByAsin(record[0].asin)?.sellerData}
                height={150}
                simple={true}
              />
            </div>
          </div>
        );
      },
    },

    {
      title: <span>SKU</span>,
      dataIndex: "jp_sell_price",
      render: (text, record, index) => {
        return (
          <div>
            {record.map((item, key) => <div className="mb-1">{item.us_sku}</div>)}
            <div>
              <a style={{ color: "blue" }} onMouseOver={() => handleViewSellerInfo(index)}>FBA販売者情報</a>
            </div>
            <div className="action-table-data align-items-end mt-1 me-1">
              <Popover content={<SellersGraphPopover asin={record[0].asin} />}>
                <a
                  className="me-1 p-1"
                  onClick={() => checkProductStatus(record[0].asin)}
                  title="詳細"
                >
                  <FiActivity className="feather-eye" />
                </a>
              </Popover>
            </div>
          </div>
        )
      }
    },
    {
      title: <span>FNSKU</span>,
      dataIndex: "us_sell_price",
      render: (text, record, index) => {
        return (
          <div>
            {
              handleSortSellerName(record).map((item) => {
                if (item.sellername == "weeea2")
                  return <div className="mb-1" style={{ color: utils.getSellerColor("weeea2") }}>{item.fulfillment_sku}</div>;
                if (item.sellername == "kt.japan")
                  return <div className="mb-1" style={{ color: utils.getSellerColor("kt.japan") }}>{item.fulfillment_sku}</div>;
                if (item.sellername == "njoy")
                  return <div className="mb-1" style={{ color: utils.getSellerColor("njoy") }}>{item.fulfillment_sku}</div>;
                if (item.sellername == "fhjiyama")
                  return <div className="mb-1" style={{ color: utils.getSellerColor("fhjiyama") }}>{item.fulfillment_sku}</div>;
              })
            }
          </div>
        );
      },
    },
    {
      title: <span>Price<br />他セラー最低価格</span>,
      dataIndex: "us_price",
      render: (text, record) => {
        let updateat = utils.getDateTimeFormat3(Number(record[0]?.sellers_lowest_price_updateat));
        return (
          <div>
            {record.map((item, key) => <div className="mb-1">$ {item.us_price}</div>)}
            {record[0]?.sellers_lowest_price &&
              <div className="lowest-price d-flex flex-column justify-content-between pt-2 px-1 gap-1">
                <span>$ {record[0]?.sellers_lowest_price}</span>
                <span>({updateat})</span>
              </div>
            }
          </div>
        )
      },
    },
    {
      title: <span>	製品の種類<br />
        利益額<br />
        利益率<br />
        ロット</span>,
      render: (text, record) => {
        var profit = "";
        var percent = "";
        if (record[0].product_type == "STD") {
          profit = record[0].us_prev_profit;
          percent = record[0].us_prev_profit_percent;
        }
        else {
          profit = record[0].us_profit_amount_sl;
          percent = record[0].us_profit_percent_sl;
        }

        let fontWeight = "normal";
        let fontColor = "black";
        if (percent < 0) {
          fontWeight = "bold";
          fontColor = "red";
        } else if (percent <= 4) {
          fontWeight = "bold";
          fontColor = "green";
        }

        return (
          <div>
            <div className="mb-1">{record[0].product_type}</div>
            <div className="mb-1" style={{ fontWeight: fontWeight, color: fontColor }}>¥ {profit}</div>
            <div className="mb-1" style={{ fontWeight: fontWeight, color: fontColor }}>{percent} %</div>
            <div>{record[0].min_num_orders}</div>
          </div>
        );
      },
    },
    {
      title: <span>売上実績<br />(30日)</span>,
      dataIndex: "sales_history", //
      render: (text, record) => {
        return (
          <div>
            {record.map((item, key) => <div className="mb-1">{item.units_sold_last_30days}</div>)}
          </div>
        );
      },
    },
    {
      title: <span>在庫数量</span>,
      dataIndex: "sales_history",
      render: (text, record) => {

        return (
          <div>
            {record.map((item, key) => <div className="mb-1">{item.total_units} 個</div>)}
          </div>
        );
      },
    },
    {
      title: <span>Supplier</span>,
      dataIndex: "sales_history", //
      render: (text, record) => {
        return (
          <div>
            {record.map((item, key) => <div className="mb-1">{(item.supplier && item.supplier != "unassigned") ? (item.supplier.replace(/ 20/, " ")) : "-"}</div>)}
          </div>
        );
      },
    },
    {
      title: <span>納品日</span>,
      dataIndex: "sales_history",
      render: (text, record) => {

        return (
          <div>
            {record.map((item, key) => <div className="mb-1">{item.list_date ? item.list_date.split(" ")[0] : "-"}</div>)}
          </div>
        );
      },
    },
    {
      title: <span>推奨在庫<br />補充数量</span>,
      dataIndex: "sales_history", //
      render: (text, record) => {

        return (
          <div>
            {record.map((item, key) =>
              <div className="mb-1">
                <span>{item.recommendedqty ? item.recommendedqty : "0"} 個</span>
                {item.is_purchase_recommend == 1 && <span className="ms-2 fw-bold text-danger-">発注</span>}
              </div>)}
          </div>
        );
      },
    },
    {
      title: <span>更新日時</span>,
      dataIndex: "updated_date", //
      render: (text, record) => {
        let values = [];
        for (const item of record) {
          var tempUpDate = "";
          if (item.updated_date) {
            const date = new Date(Number(item.updated_date));
            let year = date.getFullYear();
            let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            let day = String(date.getDate()).padStart(2, '0');
            let hour = String(date.getHours()).padStart(2, '0');
            let min = String(date.getMinutes()).padStart(2, '0');

            let formattedDate = `${year}-${month}-${day} ${hour}:${min}`;
            tempUpDate = formattedDate;
          }
          else tempUpDate = "-";
          values.push(<div className="mb-1">{tempUpDate}</div>);
        }

        return (
          <div>{values}</div>
        );
      },
    },
    {
      title: <span>在庫抽出日</span>,
      dataIndex: "jpstock_updated_date", //
      render: (text, record) => {
        let values = [];
        for (const item of record) {
          var tempUpDate = "";
          if (item.jpstock_updated_date) {
            const date = new Date(Number(item.jpstock_updated_date));
            let year = date.getFullYear();
            let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            let day = String(date.getDate()).padStart(2, '0');
            let hour = String(date.getHours()).padStart(2, '0');
            let min = String(date.getMinutes()).padStart(2, '0');

            let formattedDate = `${year}-${month}-${day}`;
            tempUpDate = formattedDate;
          }
          else tempUpDate = "-";
          values.push(<div className="mb-1">{tempUpDate}</div>);
        }
        return (
          <div>{values}</div>
        );
      },
    },
    {
      title: (
        <span>
          ランキング<br />降下回数
        </span>
      ),
      dataIndex: "salesrankdrops30",
      render: (text, record) => {
        return (
          <div>
            <span>{`30日-${record[0].salesrankdrops30 ?? '0'}`}</span><br />
            <span>{`90日-${record[0].salesrankdrops90 ?? '0'}`}</span><br />
            <span>{`180日-${record[0].salesrankdrops180 ?? '0'}`}</span><br />
            <span>{`365日-${record[0].salesrankdrops365 ?? '0'}`}</span><br />
            <span>{`${record[0].us_rank ?? '-'}`}</span>
          </div>
        );
      },
    },
  ];


  const handleTopAction = async () => {
    try {

      utils.showConfirmBox("アクションの適用", "それを適用しますか？", async () => {
        setLoading(true);
        await CreateJob({
          jobname: filterValue.topAction,
          jobvalue: {
            name: "",
            value: "",
            condition: "",
            sellername: active_seller
          },
          user: user,
        });
        setLoading(false);
        getResearchList();
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const onDrawerClose = () => {
    setIsDrawer(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const tableHeader = tableRef.current.querySelector(".ant-table-thead");
      const bodyRows = tableRef.current.querySelectorAll(
        ".ant-table-tbody > tr"
      );
      const tableRect = tableRef.current.getBoundingClientRect();
      const headerRect = tableHeader.getBoundingClientRect();

      const widths = [];
      let index = 0;
      for (let i = 0; i < columns.length; i++) {
        index++;
        if (columns[i]?.hidden) widths.push(0);

        const bodyCell = bodyRows[0].querySelector(
          `.ant-table-cell:nth-child(${index})`
        );
        widths.push(bodyCell && bodyCell.offsetWidth);
      }

      setHeaderWidths(widths);

      if (tableRect.top < headerRect.height) {
        setHeaderFixed(true);
      } else {
        tableHeader.style.position = "static";
        setHeaderFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sidebar_visible]);

  return (
    <>
      {loading && (
        <div id="global-loader">
          <h2>データ処理中...</h2>
        </div>
      )}
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>FBA在庫補充</h4>
              </div>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      value={keyword}
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      style={{ width: 200 }}
                      onChange={(e) => {
                        setKeyword(e.target.value);
                        setFilterValue((prev) => ({
                          ...prev,
                          filter_keyword: e.target.value,
                        }))
                      }}
                      onKeyDown={handleSearch}
                    // suffix={<FaMagnifyingGlass />}
                    />
                    <button
                      type="button"
                      onClick={() => handleSearch(null)}
                      className="btn btn-searchset"
                    >
                      <FaMagnifyingGlass />
                    </button>
                  </div>

                  <div
                    className="search-input"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    <label style={{ marginRight: "10px" }}>フィルタ</label>
                    <Select
                      className="select"
                      options={topFilterOptions}
                      size="large"
                      style={{ width: "200px" }}
                      value={filterValue.topFilter}
                      onChange={(e) => {
                        setFilterValue((prev) => ({
                          ...prev,
                          topFilter: e,
                        }));
                      }}
                    />
                    <button
                      type="button"
                      className="btn btn-primary me-2"
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        filterValue.filter_keyword = "";
                        getResearchList()
                      }}
                    >
                      検索
                    </button>
                  </div>

                  <div
                    className="search-input"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    <label style={{ marginRight: "10px" }}>アクション</label>
                    <Select
                      className="select"
                      options={topActionOptions}
                      size="large"
                      style={{ width: "200px" }}
                      value={filterValue.topAction}
                      onChange={(e) =>
                        setFilterValue((prev) => ({
                          ...prev,
                          topAction: e,
                        }))
                      }
                    />
                    <button
                      type="button"
                      className="btn btn-primary me-2"
                      style={{ marginLeft: "10px" }}
                      onClick={handleTopAction}
                    >
                      適用
                    </button>
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary me-2"
                    onClick={() => {
                      setIsDrawer(true);
                    }}
                  >
                    Filter Set
                  </button>
                </div>
                <div className="d-flex">
                  <div className="mt-1">
                    検索結果 : {totalItems} 件中 {(pagination.current - 1) * pagination.pageSize + 1}-
                    {Math.min(pagination.current * pagination.pageSize, totalItems)} 件
                  </div>
                  <div className="form-sort ms-3">
                    <Select
                      className="select"
                      options={constants.PageSizeOptions}
                      placeholder="100"
                      onChange={handlePageSizeChange}
                    />
                  </div>
                  <span className="ms-2 mt-1">
                    件ずつ表示
                  </span>
                </div>
              </div>
              <div className="table-responsive">
                {isHeaderFixed && (
                  <div style={{ zIndex: 1000, background: "#fff", position: "fixed", top: 0 }}>
                    <table>
                      <thead>
                        <tr>
                          {columns.map((column, index) => (
                            column.hidden ? <></> :
                              <th
                                key={index}
                                style={{ width: headerWidths[index], textAlign: "center", border: "2px solid #f0f0f0" }}
                              >
                                {column.title}
                              </th>
                          ))}
                        </tr>
                      </thead>
                    </table>
                  </div>
                )}
                <div ref={tableRef}>
                  <Table bordered
                    className="table datanew dataTable no-footer"
                    // rowSelection={rowSelection}
                    columns={columns}
                    dataSource={dataSource}
                    rowKey={(record) => record.id}
                    pagination={{
                      ...pagination,
                      position: ["bottomRight"],
                      total: totalItems,
                      pageSizeOptions: [
                        "100",
                        "150",
                        "200",
                        "300",
                        "500",
                        "1000",
                      ],
                    }}
                    onChange={handleTableChange}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /product list */}

          {/* -------Filter Set Drawer start----------- */}
          <FilterDrawer
            open={isDrawer}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            categoryList={categoryList}
            additionalOptions={{
              orderTypes: [
                { value: "fba_weight_order", label: "重" },
                { value: "fba_price_order", label: "安" }
              ]
            }}
            onOk={() => {
              setPagination({ current: 1, pageSize: pagination.pageSize });
              onDrawerClose();
              getResearchList();
            }}
            onCancel={onDrawerClose}
            isSalesRanks={true}
          />
          {/* -------Filter Set Drawer end----------- */}

          <FBASellerInfoDrawer
            open={sellerInfoOpen}
            asin={sellerInfoAsin}
            onClose={() => setSellerInfoOpen(false)}
            onClickSeller={handleClickSeller}
          />
        </div>
      </div>
    </>
  );
};

export default RestockInventory;
