/* eslint-disable */
import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { FaArrowRight, FaArrowLeft, FaTrashCan, FaMagnifyingGlass, FaArrowRotateRight } from 'react-icons/fa6';
import dayjs from 'dayjs';

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import {
  InputNumber,
  Table,
  Input,
  Checkbox,
  Select,
  Modal,
  DatePicker,
  AutoComplete,
  Popover,
} from "antd";
import {
  CreateJob,
  getDeliveryProductList,
  updateDeliveryProduct,
  deleteDeliveryProduct,
  returnDeliveryProduct,
  getfbaShipplaninfo,
  updatefbaShipplaninfo,
  addDeliveryLog,
  listingFbaProductFromShipplanready,
  isTokenErrorResponse
} from "../../../services";
import { useAuth } from "../../../hooks/useAuth";
import * as utils from "../../../utils/util";
import * as constants from "../../../utils/constants";


const initialData = {
  topFilter: "fba_list_ready_all",
  topAction: "none",
  order_by_value: "",
  order_by_way: 0, // 0 or 1
  filter_multi_asins: "",
  filter_us_min_rank: "",
  filter_us_max_rank: "",
  filter_us_min_weight: "",
  filter_us_max_weight: "",
  filter_us_min_price: "",
  filter_us_max_price: "",
  filter_jp_purchase_min_price: "",
  filter_jp_purchase_max_price: "",
  filter_jp_seller_min_price: "",
  filter_jp_seller_max_price: "",
  filter_min_profit_amount: "",
  filter_max_profit_amount: "",
  filter_min_profit_percent: "",
  filter_max_profit_percent: "",
  filter_product_type1_list: "",
  filter_us_category_list: "",
  filter_purchase_place: "",
  filter_ship_place: "",
  filter_employee_name: "",
  sales_count_not0: false,

  filter_start_order_date: "",
  filter_end_order_date: "",
  filter_start_purchase_date: "",
  filter_end_purchase_date: "",
  filter_keyword: "",
};

const initshipinfo = {
  AccountId: "",
  PlanName: "",
  CreatedAt: "",
  CollectionDate: "",
  ReceiptDeadline: "",
  FBAStock: "",
  DeliveryFee: "",
  BoxSize: "",
  width: "",
  height: "",
  length: "",
  BoxWeight: "",
  BoxNumber: "",
  TSCA: 0,
  FDA: 0,
  Watch: 0,
  Wood: 0,
  BLPlayer: 0,
  Oversized: 0,
}

const _topActionOptions = [
  { value: "none", label: "-- なし --" },
  { value: "fba_shipping_plan_output_weeea2", label: "①WE プラン作成", sellername: "weeea2" },
  { value: "fba_shipping_plan_output_kt", label: "②KT プラン作成", sellername: "kt.japan" },
  { value: "fba_shipping_plan_output_njoy", label: "③NJ プラン作成", sellername: "njoy" },
  { value: "fba_shipping_plan_output_fh", label: "④FH プラン作成", sellername: "fhjiyama" },
];


const PrepareDeliveryList = forwardRef((props, ref) => {

  useImperativeHandle(ref, () => ({
    initFunc,
  }));

  const { user, logout } = useAuth();
  const tableRef = useRef(null);

  const active_seller = useSelector((state) => state.active_seller);
  const store_list = useSelector((state) => state.store_list);

  const [loading, setLoading] = useState(false);

  const [keyword, setKeyword] = useState("");
  const [filterValue, setFilterValue] = useState(initialData);

  const [dataSource, setDataSource] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });
  const [jumprow, setJumpRow] = useState("");

  const [shipplaninfo, setShipplaninfo] = useState();
  const [shipplanModalInfo, setShipplanModalInfo] = useState(initshipinfo);
  const [isShipPlan, setIsShipPlan] = useState(false);
  const [sellerNum, setSellerNum] = useState();
  const [totalSkuCount, setTotalSkuCount] = useState();
  const [totalPackageCount, setTotalPackageCount] = useState();

  const [topActionOptions, setTopActionOptions] = useState(_topActionOptions);

  const handleRefresh = async () => {
    setKeyword("");
    setFilterValue(initialData);
    getResearchList(1, true);
  }

  const handleSearch = (e) => {
    if (e == null || e.key === "Enter") {
      if (keyword == "") {
        filterValue.filter_keyword = "";
      }
      setJumpRow(0);
      setPagination({ current: 1, pageSize: pagination.pageSize });
      getResearchList();
      setKeyword("");
    }
  };

  const getResearchList = async (page = 1, refresh = false) => {
    try {
      let seller = "";
      if (active_seller != "すべてのアカウント") seller = active_seller;
      setLoading(true);

      const result = await getDeliveryProductList({
        page: page - 1,
        count: pagination.pageSize,
        user: user,
        seller: seller,
        keyword: refresh ? '' : filterValue.filter_keyword,
        filterValue: refresh ? initialData : filterValue
      });
      if (isTokenErrorResponse(result)) {
        return logout();
      }

      if (result.data && result.data.length > 0) {
        setDataSource(result.data);
        let pages = Math.ceil((result.data[0]["full_count"]) / pagination.pageSize);
        setPageCount(pages);
        setTotalItems(result.data[0]["full_count"]);
      } else {
        setDataSource([]);
        setPageCount(0);
        setTotalItems(0);
      }

      if (result.data1) {
        setTotalSkuCount(result.data1.totalskucount || 0);
        setTotalPackageCount(result.data1.totalpackagecount || 0);
      } else {
        setTotalSkuCount(0);
        setTotalPackageCount(0);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleToDeliveryLog = async (index) => {
    setLoading(true);
    var newData = [...dataSource];

    const props = newData[index];
    props.user = user;
    await addDeliveryLog(props);
    getResearchList();
    setLoading(false);
  }

  const handleTableChange = (pagination) => {
    setPagination(pagination);
    getResearchList(pagination.current);
  };

  const handlePageSizeChange = (pagesize) => {
    pagination.pageSize = pagesize;
    getResearchList();
  };

  const getShipPlanInfo = async () => {
    const info = await getfbaShipplaninfo({ user });
    setShipplaninfo(info.data);
  }

  const initFunc = () => {
    document.title = "プラン作成"
    getResearchList();
    getShipPlanInfo();
  }

  useEffect(() => {

    if (active_seller == "すべてのアカウント") {
      setTopActionOptions(_topActionOptions);
    } else {
      let topActions = [];
      for (let i = 0; i < _topActionOptions.length; i++) {
        if (!_topActionOptions[i].sellername || _topActionOptions[i].sellername == active_seller) {
          topActions.push(_topActionOptions[i]);
        }
      }
      setTopActionOptions(topActions);
      setFilterValue((prev) => ({
        ...prev,
        topAction: topActions[0].value,
      }));
    }

    initFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_seller]);


  const onInputChange = (key, index) => (value) => {
    const newData = [...dataSource];

    if (key == "memo" || key == "memo1") {
      newData[index][key] = value.target.value;
    } else {
      newData[index][key] = value;
    }

    setDataSource(newData);
  };

  const handleUpdateProduct = (index) => async () => {
    try {
      setLoading(true);
      const props = dataSource[index];
      props.user = user;

      await updateDeliveryProduct(props);
      getResearchList(pagination.current);
      setEdit_modal_visible(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleReturnProduct = async (index) => {
    try {
      setLoading(true);
      const props = dataSource[index];
      props.user = user;
      await returnDeliveryProduct(props);
      getResearchList();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const columns = [
    {
      title: <span>商品画像</span>,
      dataIndex: "image",
      width: 100,
      render: (text, record, index) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Link
              to={utils.getAmazonUrl(record.asin)}
              className="product-img stock-img"
              target="_blank"
            >
              <ImageWithBasePath
                alt=""
                src={text ?? "assets/img/products/product1.jpg"}
                isProduct={text ? true : false}
                width={80}
                height={80}
              />
            </Link>
            <div>{record.jan3}</div>
          </div>
        );
      },
    },
    {
      title: <span>商品名</span>,
      dataIndex: "productname",
      width: 200,
      render: (text, record, index) => {
        return (
          <span className="inventoryimgname">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Popover content={text}>
                <Link
                  to={utils.getAmazonUrl(record.asin)}
                  className="product-title"
                  target="_blank"
                >
                  {text}
                </Link>
              </Popover>
              <Popover content={record.jp_productname}>
                <Link
                  to={utils.getAmazonJpUrl(record.asin)}
                  className="product-title"
                  target="_blank"
                >
                  {record.jp_productname}
                </Link>
              </Popover>
            </div>
          </span>
        );
      },
    },
    {
      title: <span>ASIN</span>,
      dataIndex: "asin",
      width: 100,
      render: (text, record) => {
        return (
          <span>
            <Link to={utils.getAmazonJpOfferListingUrl(record.asin, record.itemcondition)} className="product-title" target="_blank">
              {record.asin}
            </Link>
          </span>
        );
      },
    },
    {
      title: <span>FNSKU</span>,
      dataIndex: "fnsku",
      width: 100,
      render: (text, record) => {
        let color = utils.getSellerColor(record.sellername);
        let fnsku = utils.getSellerFNSKU(record, record.sellername);

        return (
          <span style={{ color: color }}>{fnsku}</span>
        );
      },
    },
    {
      title: <span>アカ</span>,
      dataIndex: "seller_name",
      width: 80,
      render: (text, record) => {
        return (
          <div className={`sellermark ${record.sellername}`}>
            {utils.getSellerMark(record.sellername, store_list)}
          </div>
        );
      },
    },
    {
      title: <span>内容</span>,
      dataIndex: "totalofpackage",
      width: 80,
      render: (text, record) => {
        return (
          <span>{record.totalofpackage}</span>
        );
      },
    },
    {
      title: <span>JAN<br />型番</span>,
      dataIndex: "jan",
      width: 100,
      render: (text, record) => {
        let jansArr = record.jan?.split(",");
        let jans = <span>
          {jansArr?.map((j, idx) => <span key={idx}>{j}<br /></span>)}
        </span>;

        let catasArr = record.catalognumber?.split(",");
        let catas = <span>
          {catasArr?.map((j, idx) => <span key={idx}>{j}<br /></span>)}
        </span>;
        return (
          <div>
            <div className="line1">
              <Popover content={jans}>{jansArr?.[0]}</Popover>
            </div>
            <div className="line2">
              <Popover content={catas}>{catasArr?.[0] ?? '-'}</Popover>
            </div>
          </div>
        );
      },
    },
    {
      title: <span>箱詰数</span>,
      dataIndex: "package_count",
      width: 80,
      render: (text, record, index) => {
        return (
          <span>{record.package_count}</span>
        );
      },
    },
    {
      title: <span>日本価格</span>,
      dataIndex: "purchase_price",
      width: 100,
      render: (text, record, index) => {
        return (
          <div>
            <InputNumber
              prefix="¥"
              value={record.purchase_price}
              onPressEnter={handleUpdateProduct(index)}
              onChange={onInputChange("purchase_price", index)}
            /><br />
            <span className="lowest-price">
              最安 &nbsp;&nbsp;&nbsp;¥ {record.jp_lowest_price}
            </span>
          </div>
        );
      },
    },
    {
      title: <span>米価格</span>,
      dataIndex: "us_price",
      width: 100,
      render: (text, record, index) => {
        return (
          <div>
            <InputNumber
              prefix="$"
              value={record.us_price}
              onPressEnter={handleUpdateProduct(index)}
              onChange={onInputChange("us_price", index)}
            /><br />
            <span className="lowest-price">
              最安 &nbsp;&nbsp;&nbsp;$ {record.us_lowest_price}
            </span>
          </div>
        );
      },
    },
    {
      title: <span>サイズ</span>,
      dataIndex: "product_size",
      width: 80,
      render: (text, record) => {
        var product_type = ((record.product_type == "") || (typeof record.product_type === "undefined")) ? "-" : record.product_type;
        return (
          <span>{product_type}</span>
        );
      },
    },
    {
      title: <span>重量</span>,
      dataIndex: "product_weight",
      width: 80,
      render: (text, record) => {
        var weight = Number(record.weight);
        return (
          <span>{weight}g</span>
        );
      },
    },
    {
      title: <span>利益額</span>,
      dataIndex: "profit",
      width: 100,
      render: (text, record) => {
        return (
          <span>¥ {record.us_prev_profit}</span>
        );
      },
    },
    {
      title: <span>利益率</span>,
      dataIndex: "profit_percent",
      width: 100,
      render: (text, record) => {
        return (
          <span>{record.us_prev_profit_percent} %</span>
        );
      },
    },
    {
      title: "アクション",
      dataIndex: "action",
      render: (text, record, index) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <div className="input-block add-lists"></div>
            <a
              className="me-1 p-1"
              onClick={() => handleToDeliveryLog(index)}
              title="納品ログ"
            >
              <FaArrowRight className="action-edit" />
            </a>
            <a
              className="me-1 p-1"
              onClick={() => handleReturnProduct(index)}
              title="戻す"
            >
              <FaArrowLeft className="action-edit" />
            </a>
          </div>
          <div className="edit-delete-action">
            <a
              className="confirm-text me-1 p-1"
              onClick={() => showConfirmationAlertASINDelete(index)}
              title="削除"
            >
              <FaTrashCan className="action-trash" />
            </a>
          </div>
        </div>
      ),
    },
  ];

  const showConfirmationAlertASINDelete = async (index) => {
    utils.showConfirmBox("削除しますか？", "これを元に戻すことはできません。", async () => {
      const product = dataSource[index];
      setLoading(true);
      const result = await deleteDeliveryProduct({
        id: product.id,
        user: user,
      });
      setLoading(false);
      getResearchList();
    });
  };

  const updateShipPlan = async () => {

    let shipInfo = {
      ...initshipinfo,
      ...shipplanModalInfo
    };
    if (shipInfo.CreatedAt == null || shipInfo.CreatedAt == "") {
      shipInfo.CreatedAt = dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
    }
    shipInfo.user = user;
    setLoading(true);
    let res = await updatefbaShipplaninfo(shipInfo);
    getShipPlanInfo();
    setIsShipPlan(false);

    const result = await listingFbaProductFromShipplanready({
      sellername: shipInfo.AccountId,
      user: user,
    });

    await CreateJob({
      jobname: filterValue.topAction,
      jobvalue: {
        name: "",
        value: "",
        condition: "",
        sellername: active_seller
      },
      user: user,
    });

    if (result.message && result.message.toLowerCase() == "success") {
      initFunc();
    }

    setLoading(false);
  }

  const handleTopAction = async () => {
    try {
      
      let isJob = true;
      if (filterValue.topAction == "none") {
        return;
      }
      else if (filterValue.topAction == "fba_shipping_plan_output_weeea2") {
        const modalData = shipplaninfo.filter(item => item.AccountId == "weeea2")[0];
        setShipplanModalInfo(modalData);
        setSellerNum(1);
        setIsShipPlan(true);
        console.log(modalData);
      }
      else if (filterValue.topAction == "fba_shipping_plan_output_kt") {
        const modalData = shipplaninfo.filter(item => item.AccountId == "kt.japan")[0];
        setShipplanModalInfo(modalData);
        setSellerNum(2);
        setIsShipPlan(true);
      }
      else if (filterValue.topAction == "fba_shipping_plan_output_njoy") {
        const modalData = shipplaninfo.filter(item => item.AccountId == "njoy")[0];
        setShipplanModalInfo(modalData);
        setSellerNum(3);
        setIsShipPlan(true);
      }
      else if (filterValue.topAction == "fba_shipping_plan_output_fh") {
        const modalData = shipplaninfo.filter(item => item.AccountId == "fhjiyama")[0];
        setShipplanModalInfo(modalData);
        setSellerNum(4);
        setIsShipPlan(true);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      {loading && (
        <div id="global-loader">
          <h2>データ処理中...</h2>
        </div>
      )}
      {/* /product list */}
      <div className="card table-list-card">
        <div className="card-body">
          <div className="table-top">
            <div className="search-set">
              <a
                type="button"
                onClick={() => handleRefresh()}
                style={{ paddingRight: 10 }}
              >
                <FaArrowRotateRight />
              </a>
              <div className="search-input">
                <input
                  value={keyword}
                  type="text"
                  placeholder="Search"
                  className="form-control form-control-sm formsearch"
                  style={{ width: 200 }}
                  onChange={(e) => { 
                    setKeyword(e.target.value);
                    setFilterValue((prev) => ({
                      ...prev,
                      filter_keyword: e.target.value,
                    }))
                  }}
                  onKeyDown={handleSearch}
                // suffix={<FaMagnifyingGlass />}
                />
                <button
                  type="button"
                  onClick={() => handleSearch(null)}
                  className="btn btn-searchset"
                >
                  <FaMagnifyingGlass />
                </button>
              </div>
              <div
                className="search-input"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <label style={{ marginRight: "10px" }}>アクション</label>
                <Select
                  className="select"
                  options={topActionOptions}
                  size="large"
                  style={{ width: "180px" }}
                  value={filterValue.topAction}
                  onChange={(e) =>
                    setFilterValue((prev) => ({
                      ...prev,
                      topAction: e,
                    }))
                  }
                />
                <button
                  type="button"
                  className="btn btn-primary me-2"
                  style={{ marginLeft: "10px" }}
                  onClick={handleTopAction}
                >
                  適用
                </button>
              </div>

            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ width: 200 }}>
                <span>SKU 合計数 :</span> <span style={{ marginLeft: 20 }}>{totalSkuCount}</span>
              </div>
              <div style={{ width: 200 }}>
                <span>箱詰め 合計数 :</span> <span style={{ marginLeft: 20 }}>{totalPackageCount}</span>
              </div>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: 10 }}>
                検索結果 : {totalItems} 件中 {(pagination.current - 1) * pagination.pageSize + 1}-
                {Math.min(pagination.current * pagination.pageSize, totalItems)} 件
              </div>

              <div className="form-sort">
                <Select
                  className="select"
                  options={constants.PageSizeOptions}
                  placeholder="100"
                  onChange={handlePageSizeChange}
                />
              </div>
              <span
                style={{
                  whiteSpace: "nowrap",
                  marginLeft: "10px",
                }}
              >
                件ずつ表示
              </span>
            </div>
          </div>
          <div className="table-responsive">
            <div ref={tableRef}>
              <Table bordered
                className="table datanew dataTable no-footer"
                // rowSelection={rowSelection}
                columns={columns}
                dataSource={dataSource}
                rowKey={(record) => record.id}
                tableLayout="auto"
                pagination={{
                  ...pagination,
                  // position: ['topRight', 'bottomRight'],
                  position: ["bottomRight"],
                  total: totalItems,
                  pageSizeOptions: [
                    "100",
                    "150",
                    "200",
                    "300",
                    "500",
                    "1000",
                  ],
                }}
                onChange={handleTableChange}
                scroll={{ y: "calc(100vh - 400px)" }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* /product list */}

      {/* --------------------(TOP) when click アクション of top part-------------------- */}
      <Modal
        title="プラン詳細 入力ウィンドウ"
        open={isShipPlan}
        footer={false}
        width={800}
        onCancel={() => setIsShipPlan(false)}
      >
        <div style={{ marginTop: "30px" }}>
          <div className="row mt-3 d-flex align-items-center">
            <div className="col-md-2 text-end">アカウント</div>
            <div className="col-md-4">
              <input
                type="text"
                className="form-control"
                value={shipplanModalInfo.AccountId}
                disabled />
            </div>
          </div>
          <div className="row mt-3 d-flex align-items-center">
            <div className="col-md-2 text-end">日付</div>
            <div className="col-md-4">
              <DatePicker
                value={dayjs(shipplanModalInfo.CreatedAt || new Date())}
                format="YYYY-MM-DD HH:mm:ss"
                showTime={true}
                onChange={(date, dateString) => setShipplanModalInfo(prev => ({ ...prev, CreatedAt: dateString }))}
              />
            </div>

            <div className="col-md-2 text-end">プラン名</div>
            <div className="col-md-4">
              <Input
                value={shipplanModalInfo.PlanName}
                onChange={(e) => setShipplanModalInfo(prev => ({ ...prev, PlanName: e.target.value }))} />
            </div>
          </div>
          <div className="row mt-3 d-flex align-items-center">
            <div className="col-md-2 text-end">国内集荷日</div>
            <div className="col-md-4">
              <DatePicker
                value={shipplanModalInfo.CollectionDate ? dayjs(shipplanModalInfo.CollectionDate) : null}
                format="YYYY-MM-DD"
                onChange={(date, dateString) => {
                  let receiptdate = "";
                  if (date) {
                    let dtreceipt = date.add(7, 'day');
                    receiptdate = dtreceipt.format("YYYY-MM-DD");
                  }
                  setShipplanModalInfo(prev => ({ ...prev, CollectionDate: dateString, ReceiptDeadline: receiptdate }));
                }}
              />
            </div>

            <div className="col-md-2 text-end">受領期限<br />変更最終日</div>
            <div className="col-md-4">
              <input
                type="text"
                value={shipplanModalInfo.ReceiptDeadline ? shipplanModalInfo.ReceiptDeadline.split(" ")[0] : ""}
                className="form-control"
                disabled
              />
            </div>
          </div>
          <div className="row mt-3 d-flex align-items-center">
            <div className="col-md-2 text-end">FBA倉庫</div>
            <div className="col-md-3">
              <Input
                value={shipplanModalInfo.FBAStock}
                onChange={(e) => setShipplanModalInfo(prev => ({ ...prev, FBAStock: e.target.value }))}
              />
            </div>
            <div className="col-md-1"></div>

            <div className="col-md-2 text-end">納品手数料</div>
            <div className="col-md-4">
              <InputNumber
                prefix="$"
                value={shipplanModalInfo.DeliveryFee}
                onChange={(value) => setShipplanModalInfo(prev => ({ ...prev, DeliveryFee: value }))}
              />
            </div>
          </div>
          <div className="row mt-3 d-flex align-items-center">
            <div className="col-md-2 text-end">箱サイズ</div>
            <div className="col-md-3">
              <AutoComplete
                value={shipplanModalInfo.BoxSize}
                options={constants.BoxSizeOptions}
                onSelect={(value, option) => {
                  if (option) {
                    setShipplanModalInfo(prev => ({ ...prev, width: option.width, height: option.height, length: option.length }));
                  }
                }}
                onChange={(value) => setShipplanModalInfo(prev => ({ ...prev, BoxSize: value }))}
                style={{ width: 150 }}
                filterOption={(inputValue, option) =>
                  option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <InputNumber style={{ width: 60, marginRight: 10 }}
                value={shipplanModalInfo.width}
                onChange={(value) => setShipplanModalInfo(prev => ({ ...prev, width: value }))}
              /><span>╳</span>
              <InputNumber style={{ width: 60, marginLeft: 10, marginRight: 10 }}
                value={shipplanModalInfo.height}
                onChange={(value) => setShipplanModalInfo(prev => ({ ...prev, height: value }))}
              /><span>╳</span>
              <InputNumber style={{ width: 60, marginLeft: 10, marginRight: 10 }}
                value={shipplanModalInfo.length}
                onChange={(value) => setShipplanModalInfo(prev => ({ ...prev, length: value }))}
              /><span>cm</span>
            </div>
          </div>
          <div className="row mt-3 d-flex align-items-center">
            <div className="col-md-2 text-end">箱重量</div>
            <div className="col-md-3">
              <InputNumber suffix="Kg"
                value={shipplanModalInfo.BoxWeight}
                onChange={(value) => setShipplanModalInfo(prev => ({ ...prev, BoxWeight: value }))}
              />
            </div>
            <div className="col-md-1"></div>

            <div className="col-md-2 text-end">箱数</div>
            <div className="col-md-1">
              <InputNumber
                value={shipplanModalInfo.BoxNumber}
                onChange={(value) => setShipplanModalInfo(prev => ({ ...prev, BoxNumber: value }))}
              />
            </div>
          </div>
          <div className="row mt-3 d-flex align-items-center">
            <div className="col-md-2 text-end">TSCA</div>
            <div className="col-md-1">
              <Checkbox
                checked={shipplanModalInfo.TSCA == 1}
                onChange={(e) => setShipplanModalInfo(prev => ({ ...prev, TSCA: e.target.checked ? 1 : 0 }))}>
              </Checkbox>
            </div>

            <div className="col-md-2 text-end">FDA</div>
            <div className="col-md-1">
              <Checkbox
                checked={shipplanModalInfo.FDA == 1}
                onChange={(e) => setShipplanModalInfo(prev => ({ ...prev, FDA: e.target.checked ? 1 : 0 }))}>
              </Checkbox>
            </div>

            <div className="col-md-2 text-end">時計</div>
            <div className="col-md-1">
              <Checkbox
                checked={shipplanModalInfo.Watch == 1}
                onChange={(e) => setShipplanModalInfo(prev => ({ ...prev, Watch: e.target.checked ? 1 : 0 }))}>
              </Checkbox>
            </div>

            <div className="col-md-2 text-end">木製</div>
            <div className="col-md-1">
              <Checkbox
                checked={shipplanModalInfo.Wood == 1}
                onChange={(e) => setShipplanModalInfo(prev => ({ ...prev, Wood: e.target.checked ? 1 : 0 }))}>
              </Checkbox>
            </div>
          </div>
          <div className="row mt-3 d-flex align-items-center">
            <div className="col-md-2 text-end">BLプレイヤー</div>
            <div className="col-md-4">
              <Checkbox
                checked={shipplanModalInfo.BLPlayer == 1}
                onChange={(e) => setShipplanModalInfo(prev => ({ ...prev, BLPlayer: e.target.checked ? 1 : 0 }))}>
              </Checkbox>
            </div>

            <div className="col-md-5 text-end">オーバーサイズ</div>
            <div className="col-md-1">
              <Checkbox
                checked={shipplanModalInfo.Oversized == 1}
                onChange={(e) => setShipplanModalInfo(prev => ({ ...prev, Oversized: e.target.checked ? 1 : 0 }))}>
              </Checkbox>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-3">
            <button type="button" className="btn btn-success me-2" onClick={() => updateShipPlan()}>
              作成
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
});

export default PrepareDeliveryList;
