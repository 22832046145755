/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { researchASINList, getProductDetails } from "../../services";
import moment from "moment";
import { getOffersForTable, getStocksForTable1 } from "../../utils/util";
import { Input, Checkbox, Select } from "antd";
import StockLineChart from "../../core/components/StockLineChart";
import { useAuth } from "../../hooks/useAuth";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import * as utils from "../../utils/util";

/* eslint-disable no-unused-vars */

const duration_max = 365;
const duration_array = [365, 180, 90, 60, 30, 15, 7];

const chartColors = ["#f44336", "#e91e63",  "#9c27b0",  "#673ab7",  "#2196f3",  "#00bcd4",  "#4caf50",  "#ffeb3b",  "#ff9800",  "#ff5722"];

const commonChartOptions = {
  chart: {
    type: "line",
    height: 400,
    dropShadow: {
      enabled: true,
    },
    toolbar: false
  },
  stroke: {
    show: true,
    curve: "straight",
    lineCap: "square",
    width: 2,
  },
  grid: {
    yaxis: {
      lines: {
        show: true,
      },
    },
    xaxis: {
      lines: {
        show: true,
      },
    },
  },
  markers: {
    size: 3,
    colors: ["#ffffff"],
    strokeColors: chartColors,
    strokeWidth: 1,
    fillOpacity: 1,
    shape: "circle",
    hover: {
      sizeOffset: 2,
    },
  },
  tooltip: {
    x: {
      format: "dd MMM yyyy",
    },
    shared: false,
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    position: "left",
    formatter: function(seriesName, opts) {
      return "<span title='" + seriesName +  "'>" + seriesName.substring(0, 10) + (seriesName.length > 10 ? "..." : "") + "</span>";
    },
    itemMargin: {
      vertical: 8
    }
  },
  yaxis: {
    min: 0,
    tickAmount: 8,
  },
  xaxis: {
    type: "datetime",
    labels: {
      formatter: function(value, timestamp) {
        return moment(timestamp).format("YY/MM/DD");
      },
    },
  },
}
const _priceChartOptions = {
  ...commonChartOptions,
  chart: {
    ...commonChartOptions.chart,
    id: "sales",
  },
  tooltip: {
    ...commonChartOptions.tooltip,
    y: {
      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        let stock = w.config.stockInfos?.[seriesIndex][dataPointIndex];
        return `is price $${value}, stock : ${stock || 0}`;
      },
    },
  }
}
const _stockChartOptions = {
  ...commonChartOptions,
  chart: {
    ...commonChartOptions.chart,
    id: "stocks",
  },
  tooltip: {
    ...commonChartOptions.tooltip,
    y: {
      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        let price = w.config.priceInfos?.[seriesIndex][dataPointIndex];
            return `has stock ${value}, price : $${price || 0}`;
      },
    }
  }
}

const commonAreaChartOptions = {
  chart: {
    height: 130,
    type: "line",
    brush: {
      enabled: true,
    },
    selection: {
      enabled: true
    },
  },
  legend: {
    show: false
  },
  stroke: {
    show: true,
    curve: "straight",
    lineCap: "square",
    width: 2,
  },
  xaxis: {
    type: "datetime",
    tooltip: {
      enabled: false,
    },
    labels: {
      formatter: function(value, timestamp) {
        return moment(timestamp).format("YY/MM/DD");
      },
    },
  },
  yaxis: {
    tickAmount: 2,
  },
};
const _priceAreaChartOptions = {
  ...commonAreaChartOptions,
  chart: {
    ...commonAreaChartOptions.chart,
    id: "sales_area",
    brush: {
      target: "sales",
      enabled: true,
    },
  }
}
const _stockAreaChartOptions = {
  ...commonAreaChartOptions,
  chart: {
    ...commonAreaChartOptions.chart,
    id: "stocks_area",
    brush: {
      target: "stocks",
      enabled: true,
    },
  }
}


const ProductDetail = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [chartFlag, setChartFlag] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [asin, setAsin] = useState(null);
  const [productInfo, setProductInfo] = useState(null);

  // options
  const [historicalOfferCheckbox, setHistoricalOfferCheckbox] = useState(false);
  const [fbaOfferCheckbox, setFBAOfferCheckbox] = useState(true);

  // price chart
  const [duration, setDuration] = useState(90);
  const [series, setSeries] = useState([]);
  const [displaySeries, setDisplaySeries] = useState([]);

  // stock chart
  const [stockDuration, setStockDuration] = useState(90);
  const [stockSeries, setStockSeries] = useState([]);
  const [displayStockSeries, setDisplayStockSeries] = useState([]);

  // individual chart list property
  const [individualDuration, setIndividualDuration] = useState(90);
  const [individualData, setIndividualData] = useState();
  const [filteredData, setFilteredData] = useState();

  
  const [chartOptions, setChartOptions] = useState(_priceChartOptions);
  const [chartAreaOptions, setChartAreaOptions] = useState(_priceAreaChartOptions);

  const [stockChartOptions, setStockChartOptions] = useState(_stockChartOptions);
  const [stockChartAreaOptions, setStockChartAreaOptions] = useState(_stockAreaChartOptions);
  

  useEffect(() => {
    let asin = searchParams.get("asin");
    setAsin(asin);
    document.title = `Amaze-${asin}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (asin != null) {
      productDetails();
    }
  }, [asin]);
  

  useEffect(() => {
    let _series_array = series.map((sales) => {
      let series = {
        "name": sales["name"],
        "data": sales["data"].slice(
          duration_max - duration,
          duration_max
        ),
        "is_live": sales["is_live"],
        "isfba": sales["isfba"],
      };
      for (let i = 0; i < series["data"].length; i++) {
        if (series["data"][i] === undefined) {
          series["data"][i] = 0;
        }
      }
      return series;
    });
    
    if (!historicalOfferCheckbox) {
      _series_array = _series_array.filter(item => item.is_live);
    }
    if (fbaOfferCheckbox) {
      _series_array = _series_array.filter(item => item.isfba);
    }

    // filter empty data
    _series_array = _series_array.filter(item => {
      for (let i = 0; i < item["data"].length; i++) {
        if (item["data"][i] > 0) {
          return true;
        }
      }
      return false;
    });

    // limit count
    _series_array = _series_array.slice(0, 20);

    // calculate chart xvalues
    const currentDate = moment();
    let dates = [];
    let datesInDay = [];
    for (let i = 0; i < duration; i++) {
      const date = currentDate.clone().subtract(duration - i, "days");
      const formattedDate = date.format("DD MMM YYYY");
      dates.push(formattedDate);
      datesInDay.push(duration_max - duration + i);
    }

    // eliminate continuous values 
    let indexes = [];
    for (let i = 1; i < duration - 1; i++) {
      let isSameValue = true;
      for (let s = 0; s < _series_array.length; s++) {
        if (_series_array[s].data[i] != _series_array[s].data[i-1]) {
          isSameValue = false;
          break;
        }
      }
      if (isSameValue) {
        indexes.push(i);
      }
    }
    let length = indexes.length;
    for (let i = 0; i < length; i++) {
      let idx = indexes[length - i - 1];
      dates.splice(idx, 1);
      datesInDay.splice(idx, 1);
      for (let s = 0; s < _series_array.length; s++) {
        _series_array[s].data.splice(idx, 1);
      }
    }

    // get corresponding price values
    let stockInfos = [];
    for (let s = 0; s < _series_array.length; s++) {
      let stockItem = stockSeries?.find(item => item["name"] == _series_array[s]["name"]);

      let stSeries = [];
      for (let i = 0; i < datesInDay.length; i++) {
        stSeries.push(stockItem["data"][datesInDay[i]]);
      }
      stockInfos.push(stSeries);

      _series_array[s]["color"] = chartColors[s];
    }


    // set chart options and series
    setChartOptions((prevChartOptions) => ({
      ...prevChartOptions,
      xaxis: {
        categories: dates,
      },
      stockInfos: stockInfos,
    }));
    setChartAreaOptions((prevChartOptions) => ({
      ...prevChartOptions,
      xaxis: {
        categories: dates,
      },
    }));
    setDisplaySeries(_series_array);

  }, [duration, series, historicalOfferCheckbox, fbaOfferCheckbox]);


  useEffect(() => {
    let _series_array = stockSeries.map((stock) => {
      return {
        "name": stock["name"],
        "data": stock["data"].slice(
          duration_max - stockDuration,
          duration_max
        ),
        "is_live": stock["is_live"],
        "isfba": stock["isfba"],
      };
    });
    
    if (!historicalOfferCheckbox) {
      _series_array = _series_array.filter(item => item.is_live);
    }
    if (fbaOfferCheckbox) {
      _series_array = _series_array.filter(item => item.isfba);
    }


    // filter empty data
    _series_array = _series_array.filter(item => {
      for (let i = 0; i < item["data"].length; i++) {
        if (item["data"][i] > 0) {
          return true;
        }
      }
      return false;
    });

    // limit count
    _series_array = _series_array.slice(0, 20);

    // calculate chart xvalues
    const currentDate = moment();
    let dates = [];
    let datesInDay = [];
    for (let i = 0; i < stockDuration; i++) {
      const date = currentDate.clone().subtract(stockDuration - i, "days");
      const formattedDate = date.format("DD MMM YYYY");
      dates.push(formattedDate);
      datesInDay.push(duration_max - stockDuration + i);
    }

    // eliminate continuous values 
    let indexes = [];
    for (let i = 1; i < stockDuration - 1; i++) {
      let isSameValue = true;
      for (let s = 0; s < _series_array.length; s++) {
        if (_series_array[s].data[i] != _series_array[s].data[i-1]) {
          isSameValue = false;
          break;
        }
      }
      if (isSameValue) {
        indexes.push(i);
      }
    }
    let length = indexes.length;
    for (let i = 0; i < length; i++) {
      let idx = indexes[length - i - 1];
      dates.splice(idx, 1);
      datesInDay.splice(idx, 1);
      for (let s = 0; s < _series_array.length; s++) {
        _series_array[s].data.splice(idx, 1);
      }
    }

    
    // get corresponding price values
    let priceInfos = [];
    for (let s = 0; s < _series_array.length; s++) {
      let saleItem = series?.find(item => item["name"] == _series_array[s]["name"]);

      let priceSeries = [];
      for (let i = 0; i < datesInDay.length; i++) {
        priceSeries.push(saleItem["data"][datesInDay[i]]);
      }
      priceInfos.push(priceSeries);

      _series_array[s]["color"] = chartColors[s];
    }


    // set chart options and series
    setStockChartOptions((prevChartOptions) => ({
      ...prevChartOptions,
      xaxis: {
        categories: dates,
      },
      priceInfos: priceInfos
    }));
    setStockChartAreaOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        categories: dates,
      },
    }));
    setDisplayStockSeries(_series_array);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockDuration, stockSeries, historicalOfferCheckbox, fbaOfferCheckbox]);


  useEffect(() => {
    const getDurationDate = (data, duration) => {
      if (data == null) {
        return [];
      }
  
      var tempData = [...data];
  
      const today = new Date();
      const thirtyDaysAgo = new Date(today);
      thirtyDaysAgo.setDate(today.getDate() - duration);
      let recentData = tempData.filter(item => {
        if (item.stockcsv.length == 0) {
          return false;
        }
        const itemDate = utils.parseDate(item.stockcsv[item.stockcsv.length-1].date);
        if (itemDate < thirtyDaysAgo) {
          return false;
        }
        return true;
      });
  
      return recentData;
    }

    let data = getDurationDate(individualData, individualDuration);

    // filtering
    if (data.length > 0) {
      data = data.filter(e => e.stockcsv.length > 0)
    }
    if (!historicalOfferCheckbox && data.length > 0) {
      data = data.filter(e => e.is_live);
    }
    if (fbaOfferCheckbox && data.length > 0) {
      data = data.filter(e => e.isfba);
    }
    setFilteredData(data);
    
  }, [individualDuration, individualData, historicalOfferCheckbox, fbaOfferCheckbox]);
  

  const productDetails = async () => {
    setLoading(true);
    try {
      const result = await getProductDetails({ asin, user });
      if (result.data.length > 0) {
        const productInfos = await researchASINList({ user, keyword: asin, filterValue: {} });
        if (productInfos.data.length > 0) {
          setProductInfo(productInfos.data[0]);
        }

        let rearrageData = result.data.map((item) => ({
          seller_name: item.sellername ?? item.sellerid, // If sellername is null, set it to an empty string
          offercsv: JSON.parse(item.offercsv),
          stockcsv: JSON.parse(item.stockcsv),
          is_live: item.is_live == 1,
          isfba: item.isfba == 'true',
          lastseen: Number(item.lastseen)
        }));

        let usableData = rearrageData.map((item, key) => {
          let tempOfferData = [];
          let tempStockData = [];
          for (let i = 0; i < item.offercsv.length; i += 3) {
            const temp = (item.offercsv[i] + 21564000) * 60000;
            const tempStamp = new Date(temp);
            const month = tempStamp.getMonth() + 1;
            const day = tempStamp.getDate();
            const year = tempStamp.getFullYear().toString().slice(-2);

            const date = `${day}/${month}/${year}`;
            const close = (item.offercsv[i + 1] + item.offercsv[i + 2]) / 100;
            tempOfferData.push({ date, stock: close });
          }
          for (let i = 0; i < item.stockcsv.length; i += 2) {
            const temp1 = (item.stockcsv[i] + 21564000) * 60000;
            const tempStamp1 = new Date(temp1);
            const month1 = tempStamp1.getMonth() + 1;
            const day1 = tempStamp1.getDate();
            const year1 = tempStamp1.getFullYear().toString().slice(-2);

            const date1 = `${month1}/${day1}/${year1}`;
            const close1 = item.stockcsv[i + 1];
            tempStockData.push({ date: date1, stock: close1 });
          }

          // fill last stock data till lastseen
          if (tempStockData.length > 0 && (item.lastseen > 0 || item.is_live)) {
            const tsLastseen = (item.lastseen + 21564000) * 60000;
            let dtLastseen = moment(tsLastseen);
            let dtLaststock = moment(tempStockData[tempStockData.length - 1].date);
            let nLaststock = tempStockData[tempStockData.length - 1]['stock'];
            if (item.is_live) {
              dtLastseen = moment();
            }

            if (dtLastseen.isAfter(dtLaststock, 'day')) {
              const date1 = dtLastseen.format('M/D/YY');
              tempStockData.push({ date: date1, stock: nLaststock });
            }
          }

          return {
            name: item.seller_name,
            offercsv: tempOfferData,
            stockcsv: tempStockData,
            is_live: item.is_live,
            isfba: item.isfba,
          };
        });
        setIndividualData(usableData);

        const temp_series = [];
        const temp_stock_series = [];
        for (let i = 0; i < result.data.length; i++) {
          const series_record = {};
          const stock_series_record = {};
          if (result.data[i]["sellername"]) {
            series_record["name"] = result.data[i]["sellername"];
            stock_series_record["name"] = result.data[i]["sellername"];
          } else {
            series_record["name"] = result.data[i]["sellerid"];
            stock_series_record["name"] = result.data[i]["sellerid"];
          }
          series_record["is_live"] = result.data[i]["is_live"] == 1;
          series_record["isfba"] = result.data[i]["isfba"] == 'true';
          stock_series_record["is_live"] = series_record["is_live"];
          stock_series_record["isfba"] = series_record["isfba"];

          const seriesArray = getOffersForTable(result.data[i]["offercsv"]);
          series_record["data"] = seriesArray;
          temp_series.push(series_record);

          const stocksArray = getStocksForTable1(result.data[i]["stockcsv"]);
          stock_series_record["data"] = stocksArray;
          temp_stock_series.push(stock_series_record);
        }

        setSeries(temp_series);
        setStockSeries(temp_stock_series);
      } else {
        setIndividualData([]);
        setFilteredData([]);
        setSeries([]);
        setStockSeries([]);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };


  const handleSearch = (value) => {
    if (value == "") {
      return;
    }
    setSearchParams({"asin": value})
  }

  return (
    <>
      {loading && (
        <div id="global-loader">
          <h2>データ処理中...</h2>
        </div>
      )}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row d-flex justify-content-between w-100">
              <div className="col-sm-6 d-flex justify-content-start">
                <div>
                  <h3 className="page-title">商品詳細情報</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item active">asin-{asin}</li>
                  </ul>
                </div>

                <Input.Search
                  placeholder="商品ASINの検索"
                  allowClear
                  size="large"
                  style={{ width: 250, marginLeft: 25 }}
                  onSearch={handleSearch}
                />
              </div>
              <div className="col-sm-6 d-flex justify-content-end">

                <Checkbox checked={historicalOfferCheckbox} style={{ marginLeft: "20px" }} onChange={(e) => setHistoricalOfferCheckbox(e.target.checked)} >
                  <span style={{ color: "#000", fontWeight: "500", lineHeight: "40px" }}>Include historical offers</span>
                </Checkbox>
                <Checkbox checked={fbaOfferCheckbox} style={{ marginLeft: "20px" }} onChange={(e) => setFBAOfferCheckbox(e.target.checked)} >
                  <span style={{ color: "#000", fontWeight: "500", lineHeight: "40px" }}>FBA Offers Only</span>
                </Checkbox>

                <Select
                  className="select"
                  size="large"
                  style={{ width: "150px", marginLeft: "20px" }}
                  onChange={(value) => setChartFlag(value)}
                  options={[
                    { value: true, label: "個人" },
                    { value: false, label: "全て" },
                  ]}
                  value={chartFlag}
                />
              </div>
            </div>
          </div>

          <div className="row">
            {productInfo == null ? <></>:
            <div className="col-md-2 col-sm-3">
              <div className="card">
                <div className="card-header">
                  <h6>
                    <Link
                      to={utils.getAmazonRefUrl(productInfo.asin)}
                      className="product-img stock-img" target="_blank"
                    >{productInfo.jp_productname}</Link>
                  </h6>
                  <div>{productInfo.category}</div>
                  <div>{productInfo.memo}</div>
                  <div className="text-center">
                    <Link to={utils.getRakutenUrl(productInfo.jan?.split(",")[0])} className="link-title" target="_blank">楽天市場</Link>
                    <br />
                    <Link to={utils.getYahooUrl(productInfo.jan?.split(",")[0])} className="link-title" target="_blank">ヤフショ</Link>
                    <br />
                    <Link to={utils.getSaikyakuUrl(productInfo.jan?.split(",")[0])} className="link-title" target="_blank">最安値.com</Link>
                    <br />
                    <Link to={utils.getKeepaUrl(productInfo.asin)} className="link-title" target="_blank">Keepa</Link>
                  </div>
                </div>
                <div className="card-body">
                  <Link
                    to={utils.getAmazonRefUrl(productInfo.asin)}
                    className="product-img stock-img" target="_blank"
                  >
                    <ImageWithBasePath
                      alt=""
                      src={productInfo.image ?? "assets/img/products/product1.jpg"}
                      isProduct={true}
                    />
                  </Link>
                </div>
                <div className="card-footer">
                  <div className="d-flex" style={{ justifyContent: "space-between" }}><span>JAN :</span><span>{productInfo.jan?.split(",").map(jan => <>{jan}<br/></>)}</span></div>
                  <div className="d-flex" style={{ justifyContent: "space-between" }}><span>容積重量 :</span><span>{productInfo.fba_weight} g</span></div>
                  <div className="d-flex" style={{ justifyContent: "space-between" }}><span>実重量 :</span><span>{productInfo.package_weight} g</span></div>
                  <div className="d-flex" style={{ justifyContent: "space-between" }}><span>利益額 :</span><span>¥ {productInfo.profit}</span></div>
                  <div className="d-flex" style={{ justifyContent: "space-between" }}><span>利益率 :</span><span>{productInfo.profit_rate} %</span></div>
                </div>
              </div>
            </div>
            }
            <div className="col-md-10 col-sm-9">
              <div className={`${chartFlag ? "d-none" : ""}`}>
                <div className="col-md-12">
                  <div className="card flex-fill">
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <div className="card-title">Stocks</div>
                      <div className="graph-sets">
                        <Select value={stockDuration} onChange={(e) => setStockDuration(e)}>
                          {duration_array.map((day, index) => {
                              return (
                                <Select.Option key={index} value={day}>{day} days ago</Select.Option>
                              )
                          })}
                        </Select>
                      </div>
                    </div>
                    <div className="card-body">
                      {/* <div id="sales_charts" /> */}
                      <ReactApexChart
                        options={stockChartOptions}
                        series={displayStockSeries}
                        type="line"
                        height={400}
                      />
                      <div style={{ paddingLeft: 130 }}>
                        <ReactApexChart
                          options={stockChartAreaOptions}
                          series={displayStockSeries}
                          type="line"
                          height={130}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* Chart */}
                {/* /Page Header */}
                <div className="row">
                  {/* Chart */}
                  <div className="col-md-12">
                    <div className="card flex-fill">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <div className="card-title">Prices</div>
                        <div className="graph-sets">
                          <Select value={duration} onChange={(e) => setDuration(e)}>
                            {duration_array.map((day, index) => {
                                return (
                                  <Select.Option key={index} value={day}>{day} days ago</Select.Option>
                                )
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="card-body">
                        {/* <div id="sales_charts" /> */}
                        <ReactApexChart
                          options={chartOptions}
                          series={displaySeries}
                          type="line"
                          height={400}
                        />
                        <div style={{ paddingLeft: 130 }}>
                          <ReactApexChart
                            options={chartAreaOptions}
                            series={displaySeries}
                            type="line"
                            height={130}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* /Chart */}
              <div className={`${!chartFlag ? "d-none" : ""}`}>
                <div className="row">
                  {/* Chart */}
                  <div className="col-md-12">
                    <div className="card flex-fill">
                      <div className="card-header d-flex justify-content-between align-items-center">
                        <div className="card-title">
                          <label className="form-label">個別</label>
                        </div>
                        <div>
                          <Select value={individualDuration} onChange={(e) => setIndividualDuration(e)}>
                            <Select.Option value={30}>30 days ago</Select.Option>
                            <Select.Option value={90}>90 days ago</Select.Option>
                            <Select.Option value={180}>180 days ago</Select.Option>
                            <Select.Option value={365}>365 days ago</Select.Option>
                          </Select>
                        </div>
                      </div>
                      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                        {filteredData &&
                          filteredData.map((item, key) => (
                            <div key={key} style={{ padding: "10px" }}>
                              <div
                                style={{ display: "flex", justifyContent: "center" }}
                              >
                                {item && (
                                  <div
                                    style={{
                                      width: "300px",
                                      marginLeft: "30px",
                                      border: "1px solid #e2e2e2",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                        margin: "10px 0 0 10px",
                                      }}
                                    >
                                      {item.name} 
                                      {item.is_live ? "★" : ""}
                                    </span>
                                    <StockLineChart
                                      data={item?.stockcsv}
                                      duration={individualDuration}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};
export default ProductDetail;
