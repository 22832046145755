/* eslint-disable */
import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaPencil, FaTrashCan, FaMagnifyingGlass, FaArrowRotateRight, FaCalculator } from 'react-icons/fa6';
import dayjs from "dayjs";

import { useSelector } from "react-redux";
import {
  Table,
  Select,
  Modal,
  Input,
  DatePicker,
  AutoComplete,
  InputNumber,
  Checkbox,
  Pagination
} from "antd";
import {
  sendJobStatus,
  timeout,
  addProduct,
  CreateJob,
  downloadASINList,
  uploadCSVFileForLowestPrice,
  getDeliveryLog,
  deleteDeliveryLog,
  updateDeliveryLog,
  isTokenErrorResponse,
} from "../../../services";
import { all_routes } from "../../../Router/all_routes";
import { useAuth } from "../../../hooks/useAuth";
import * as utils from "../../../utils/util";
import * as constants from "../../../utils/constants";

const initialData = {
  topFilter: "fba_package_ready_all",
  topAction: "none",
  order_by_value: "",
  order_by_way: 0, // 0 or 1
  filter_multi_asins: "",
  filter_us_min_rank: "",
  filter_us_max_rank: "",
  filter_us_min_weight: "",
  filter_us_max_weight: "",
  filter_us_min_price: "",
  filter_us_max_price: "",
  filter_jp_purchase_min_price: "",
  filter_jp_purchase_max_price: "",
  filter_jp_seller_min_price: "",
  filter_jp_seller_max_price: "",
  filter_min_profit_amount: "",
  filter_max_profit_amount: "",
  filter_min_profit_percent: "",
  filter_max_profit_percent: "",
  filter_product_type1_list: "",
  filter_us_category_list: "",
  filter_purchase_place: "",
  filter_ship_place: "",
  filter_employee_name: "",
  sales_count_not0: false,
  ship_plan_id: "",

  filter_start_order_date: "",
  filter_end_order_date: "",
  filter_start_purchase_date: "",
  filter_end_purchase_date: "",
  filter_keyword: "",
};

const topFilterOptions = [
  { value: "fba_package_ready_all", label: "全体" },
  { value: "is_new", label: "新品" },
  { value: "is_used", label: "中古" },
];

const topActionOptions = [
  { value: "none", label: "-- なし --" },
  { value: "fba_ship_log_profit_calculate", label: "自動利益額計算" },
];

const getShipPlanName = (createdat, planname) => {
  return "FBA (" + dayjs(createdat).format("YY/MM/DD HH:mm") + ") " + planname;
}

const DeliveryLog = forwardRef((props, ref) => {

  useImperativeHandle(ref, () => ({
    initFunc,
  }));

  const location = useLocation();
  const { user, logout } = useAuth();
  const tableRef = useRef(null);

  const queryParams = new URLSearchParams(location.search);

  const [headerWidths, setHeaderWidths] = useState([]);
  const [isHeaderFixed, setHeaderFixed] = useState(false);

  const active_seller = useSelector((state) => state.active_seller);
  const store_list = useSelector((state) => state.store_list);


  const [loading, setLoading] = useState(false);

  const [keyword, setKeyword] = useState("");
  const [filterValue, setFilterValue] = useState(initialData);

  const [dataSource, setDataSource] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });
  const [jumprow, setJumpRow] = useState("");
  const [addAsinCondition, setAddAsinCondition] = useState("New");
  const [region, setRegion] = useState("us");

  const [addAsin, setAddAsin] = useState("");
  const [editASINIndex, setEditASINIndex] = useState(-1);

  const [name, setName] = useState("");
  const [fba_amazon_ship_id, setfba_amazon_ship_id] = useState("");
  const [fba_amazon_reference_id, setfba_amazon_reference_id] = useState("");
  const [tracking_id, settracking_id] = useState("");
  const [fedex_tracking_id, setfedex_tracking_id] = useState("");
  const [ups_track_number, setups_track_number] = useState("");
  const [promotion_percent, setpromotion_percent] = useState("");
  const [purchase_price, setpurchase_price] = useState("");
  const [tariff_price, settariff_price] = useState("");
  const [ship_price, setship_price] = useState("");
  const [internal_ship_price, setinternal_ship_price] = useState("");
  const [weight_ship_price, setweight_ship_price] = useState("");
  const [package_ship_price, setpackage_ship_price] = useState("");
  const [package_fee_price, setpackage_fee_price] = useState("");
  const [amazon_fee_price, setamazon_fee_price] = useState("");
  const [created_date, setcreated_date] = useState("");
  const [package_weight, setpackage_weight] = useState("");
  const [all_weight, setall_weight] = useState("");
  const [edit_variable_closing_fee, setEdit_variable_closing_fee] = useState(1);

  const [edit_shipinfo, setEdit_shipinfo] = useState({});
  const [edit_modal_visible, setEdit_modal_visible] = useState(false);

  const handleSearch = (e) => {
    if (e == null || e.key === "Enter") {
      if (keyword == "") {
        filterValue.filter_keyword = "";
      }
      setJumpRow(0);
      setPagination({ current: 1, pageSize: pagination.pageSize });
      getResearchList();
      setKeyword("");
    }
  };

  const getResearchList = async (page = 1, refresh = false) => {
    try {
      let seller = "";
      if (active_seller != "すべてのアカウント") seller = active_seller;
      setLoading(true);
      const result = await getDeliveryLog({
        page: page - 1,
        count: pagination.pageSize,
        user: user,
        keyword: filterValue.filter_keyword,
        seller: seller,
        filterValue: queryParams.get("ship_plan_id") ? { ...filterValue, ship_plan_id: queryParams.get("ship_plan_id") } : { ...filterValue },
      });
      if (isTokenErrorResponse(result)) {
        return logout();
      }

      if (result.data && result.data.length > 0) {
        setDataSource(result.data);
        let pages = parseInt(result.data[0]["full_count"]) / pagination.pageSize + 1;
        setPageCount(pages.toFixed(0));
        setTotalItems(result.data[0]["full_count"]);
      } else {
        setDataSource([]);
        setTotalItems(0);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleTableChange = (page, pageSize) => {
    setPagination({
      current: page,
      pageSize: pageSize
    });
    getResearchList(page);
  };

  const handlePageSizeChange = (value) => {
    pagination.pageSize = value;
    getResearchList();
  };

  const initFunc = () => {
    document.title = "納品ログ";
    getResearchList();
  }

  useEffect(() => {
    initFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_seller]);

  const onEditModalInputChange = (key) => (value) => {
    const newData = [...dataSource];
    let index = editASINIndex;
    switch (key) {
      case "us_sell_price":
        setEditus_sell_price(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "name":
        setname(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "fba_amazon_ship_id":
        setfba_amazon_ship_id(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "fba_amazon_reference_id":
        setfba_amazon_reference_id(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "tracking_id":
        settracking_id(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "fedex_tracking_id":
        setfedex_tracking_id(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "ups_track_number":
        setups_track_number(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "promotion_percent":
        setpromotion_percent(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "purchase_price":
        setpurchase_price(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "tariff_price":
        settariff_price(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "ship_price":
        setship_price(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "internal_ship_price":
        setinternal_ship_price(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "weight_ship_price":
        setweight_ship_price(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "package_ship_price":
        setpackage_ship_price(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "package_fee_price":
        setpackage_fee_price(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "amazon_fee_price":
        setamazon_fee_price(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "created_date":
        setcreated_date(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "package_weight":
        setpackage_weight(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "all_weight":
        setall_weight(value.target.value);
        newData[index][key] = value.target.value;
        break;
      case "variable_closing_fee":
        const val = value.target.checked ? 1 : 0;
        setEdit_variable_closing_fee(val);
        newData[index][key] = val;
        break;
    }
    setDataSource(newData);
  }

  const handleUpdateProduct = (index) => async () => {
    try {
      setLoading(true);
      const props = dataSource[index];
      props.user = user;
      props.name = getShipPlanName(edit_shipinfo.CreatedAt, edit_shipinfo.PlanName);
      props.info = JSON.stringify(edit_shipinfo);

      await updateDeliveryLog(props);
      getResearchList(pagination.current);
      setEdit_modal_visible(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleProductEdit = async (index) => {
    try {
      var product = dataSource[index];
      setEditASINIndex(index);

      if (product.info) {
        let info = JSON.parse(product.info);
        let name = getShipPlanName(info?.CreatedAt, info?.PlanName);
        setName(name)
      } else {
        setName(product.name)
      }
      setfba_amazon_ship_id(product.fba_amazon_ship_id)
      setfba_amazon_reference_id(product.fba_amazon_reference_id)
      settracking_id(product.tracking_id)
      setfedex_tracking_id(product.fedex_tracking_id)
      setups_track_number(product.ups_track_number)
      setpromotion_percent(product.promotion_percent)
      setpurchase_price(product.purchase_price)
      settariff_price(product.tariff_price)
      setship_price(product.ship_price)
      setinternal_ship_price(product.internal_ship_price)
      setweight_ship_price(product.weight_ship_price)
      setpackage_ship_price(product.package_ship_price)
      setpackage_fee_price(product.package_fee_price)
      setcreated_date(product.created_date)
      setpackage_weight(product.package_weight)
      setall_weight(product.all_weight)
      setamazon_fee_price(product.amazon_fee_price);
      setEdit_variable_closing_fee(product.variable_closing_fee);
      setEdit_shipinfo(JSON.parse(product.info) || {});
      setEdit_modal_visible(true);
    } catch (error) {
      console.log(error);
    }
  };


  async function handleJobStatus(id) {
    const result = await sendJobStatus({ id: id, user: user });
    if (parseInt(result.data.status) > 0) {
      return true;
    }
    return false;
  }
  const handleCalculate = async (index) => {
    try {
      setLoading(true);
      const product = dataSource[index];
      const result = await CreateJob({
        jobname: "calculate_profit_shipplan",
        jobvalue: {
          name: "shipplanid",
          value: product.ship_plan_id,
          shipplanname: JSON.parse(product.info)?.PlanName,
        },
        user: user,
      });
      if (result.data.id) {
        let id = result.data.id;
        let res = await handleJobStatus(id);
        while (!res) {
          timeout(1000);
          res = await handleJobStatus(id);
        }
        getResearchList(pagination.current);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };


  const columns = [
    {
      title: <span>アカ</span>,
      dataIndex: "seller_name",
      width: 80,
      render: (text, record) => {
        return (
          <div className={`sellermark ${record.sellername}`}>
            {utils.getSellerMark(record.sellername, store_list)}
          </div>
        );
      },
    },
    {
      title: <div className="text-start">プラン名<br />Shipment ID, Reference ID</div>,
      dataIndex: "shipping",
      render: (text, record) => {
        let info = JSON.parse(record.info);
        let name = info ? getShipPlanName(info?.CreatedAt, info?.PlanName) : record.name;
        return (
          <div className="w-100 text-start" style={{ whiteSpace: "break-spaces" }}>
            <Link to={`${all_routes.fbaloglist}?ship_plan_id=${record.ship_plan_id}&ship_plan_name=${name}`} target="_blank">{name}</Link><br />
            {record.fba_amazon_ship_id ? record.fba_amazon_ship_id + "," : ""} {record.fba_amazon_reference_id}
          </div>
        );
      },
    },
    {
      title: <div className="text-start">Amaze プラン ID</div>,
      dataIndex: "amaze_plan_id",
      render: (text, record) => {
        let info = JSON.parse(record.info);
        let name = info ? getShipPlanName(info?.CreatedAt, info?.PlanName) : record.name;
        return (
          <div className="w-100 text-start" style={{ whiteSpace: "break-spaces" }}>
            <Link to={`${all_routes.fbaloglist}?ship_plan_id=${record.ship_plan_id}&ship_plan_name=${name}`} target="_blank">{record.ship_plan_id}</Link><br />
          </div>
        );
      },
    },
    {
      title: <div className="text-start">追跡番号</div>,
      dataIndex: "tracking_id",
      width: 150,
      render: (text, record) => {
        let values = [];
        if (record.tracking_id) values.push(<div><a href={utils.getUgxUrl(record.tracking_id)} target="_blank">{record.tracking_id}</a></div>);
        if (record.fedex_tracking_id) values.push(<div><a href={utils.getFedexUrl(record.fedex_tracking_id)} target="_blank">{record.fedex_tracking_id}</a></div>);
        if (record.ups_track_number) values.push(<div><a href={utils.getUpsUrl(record.ups_track_number)} target="_blank">{record.ups_track_number}</a></div>);

        return (
          <div className="text-start">
            {values}
          </div>
        );
      },
    },
    {
      title: <div className="text-end">状態</div>,
      dataIndex: "update_date_us_price",
      width: 120,
      render: (text, record) => {
        return (
          <div className="text-end">
            {record.update_status_us_price == 1 ? "処理中..." : record.update_status_us_price == 2 ? '価格更新完了' : ''}<br />
            {(record.update_status_us_price != 0 && Number(record.update_date_us_price)) ? utils.getDateTimeFormat2(Number(record.update_date_us_price)) : ''}
          </div>
        );
      },
    },
    {
      title: <div className="text-end">SKUs<br />納品数</div>,
      dataIndex: "total_skus",
      width: 60,
      render: (text, record) => {
        return (
          <div className="text-end">
            {record.total_skus > 0 ? record.total_skus : ''}<br />
            {record.product_count > 0 ? record.product_count : ''}
          </div>
        );
      },
    },
    {
      title: <div className="text-start">重量</div>,
      dataIndex: "weight",
      width: 110,
      render: (text, record) => {
        let info = JSON.parse(record.info);
        return (
          <div className="text-start">
            実重量 : {Number(record.all_product_weight) ? parseFloat((Number(record.all_product_weight) / 1000).toFixed(3)) : 0}kg<br />
            箱重量 : {info?.BoxWeight ? info?.BoxWeight : 0}kg
          </div>
        );
      },
    },
    {
      title: <div className="text-start">累計金額</div>,
      dataIndex: "prices",
      width: 110,
      render: (text, record) => {
        return (
          <div className="text-start">
            仕入値 : ¥{record.purchase_price}<br />
            販売額 : ${record.sell_price}
          </div>
        );
      },
    },
    {
      title: <div className="text-end">クーリエ<br />送料</div>,
      dataIndex: "product_count",
      width: 100,
      render: (text, record) => {
        let values = [];
        if (record.tracking_id) values.push(<a href={utils.getUgxUrl(record.tracking_id)} target="_blank">UGX, </a>);
        if (record.fedex_tracking_id) values.push(<a href={utils.getFedexUrl(record.fedex_tracking_id)} target="_blank">Fedex, </a>);
        if (record.ups_track_number) values.push(<a href={utils.getUpsUrl(record.ups_track_number)} target="_blank">UPS</a>);
        return (
          <div className="text-end">
            {values.length > 0 ? values : "-"}
            <br />
            ¥{record.internal_ship_price}
          </div>
        );
      },
    },
    {
      title: <div className="text-end">利益額<br />利益率</div>,
      dataIndex: "profit",
      width: 80,
      render: (text, record) => {
        return (
          <div className="text-end">
            ¥{record.profit_price}<br />
            {record.profit_percent}%
          </div>
        );
      },
    },
    {
      title: <span>詳細</span>,
      dataIndex: "detail",
      width: 100,
      render: (text, record) => {
        let info = JSON.parse(record.info);
        return (
          <div>
            {info?.TSCA ? <div>TSCA</div> : ""}
            {info?.FDA ? <div>FDA</div> : ""}
            {info?.Watch ? <div>時計</div> : ""}
            {info?.Wood ? <div>木製</div> : ""}
            {info?.BLPlayer ? <div>BLプレイヤー</div> : ""}
            {info?.Oversized ? <div>オーバーサイズ</div> : ""}
          </div>
        );
      },
    },
    {
      title: <div className="text-end">箱数</div>,
      dataIndex: "package_count",
      width: 60,
      render: (text, record) => {
        let info = JSON.parse(record.info);
        return (
          <div className="text-end">{info?.BoxNumber}</div>
        );
      },
    },
    {
      title: "アクション",
      dataIndex: "action",
      width: 120,
      render: (text, record, index) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <a
              className="me-1 p-1"
              onClick={() => handleCalculate(index)}
              title="利益計算"
            >
              <FaCalculator className="action-edit" />
            </a>
            <a
              className="me-1 p-1"
              onClick={() => handleProductEdit(index)}
              title="編集"
            >
              <FaPencil className="action-edit" />
            </a>
            <a
              className="confirm-text me-1 p-1"
              onClick={() => showConfirmationAlertASINDelete(index)}
              title="削除"
            >
              <FaTrashCan className="action-trash" />
            </a>
          </div>
        </div>
      ),
    },
  ];

  const showConfirmationAlertASINDelete = async (index) => {
    utils.showConfirmBox("削除しますか？", "これを元に戻すことはできません。", async () => {
      const product = dataSource[index];
      setLoading(true);
      const result = await deleteDeliveryLog({
        id: product.id,
        user: user,
      });
      setLoading(false);
      getResearchList(pagination.current);
    });
  };

  const handleTopAction = async () => {
    try {
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const tableHeader = tableRef.current.querySelector(".ant-table-thead");
      const bodyRows = tableRef.current.querySelectorAll(
        ".ant-table-tbody > tr"
      );
      const tableRect = tableRef.current.getBoundingClientRect();
      const headerRect = tableHeader.getBoundingClientRect();

      const widths = [];
      columns.forEach((col, index) => {
        const bodyCell = bodyRows[0].querySelector(
          `.ant-table-cell:nth-child(${index + 1})`
        );
        widths.push(bodyCell && bodyCell.offsetWidth);
      });

      setHeaderWidths(widths);

      if (tableRect.top < headerRect.height) {
        setHeaderFixed(true);
      } else {
        tableHeader.style.position = "static";
        setHeaderFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {loading && (
        <div id="global-loader">
          <h2>データ処理中...</h2>
        </div>
      )}
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>FBA納品ログ</h4>
              </div>
            </div>
          </div>
          {/* /product list */}
          <div className="card table-list-card delivery-logs">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      value={keyword}
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      style={{ width: 200 }}
                      onChange={(e) => { 
                        setKeyword(e.target.value);
                        setFilterValue((prev) => ({
                          ...prev,
                          filter_keyword: e.target.value,
                        }))
                      }}
                      onKeyDown={handleSearch}
                    />
                    <button
                      type="button"
                      onClick={() => handleSearch(null)}
                      className="btn btn-searchset"
                    >
                      <FaMagnifyingGlass />
                    </button>
                  </div>
                  <div
                    className="search-input"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    <label style={{ marginRight: "10px" }}>フィルタ</label>
                    <Select
                      className="select"
                      options={topFilterOptions}
                      size="large"
                      style={{ width: "200px" }}
                      value={filterValue.topFilter}
                      onChange={(e) =>
                        setFilterValue((prev) => ({
                          ...prev,
                          topFilter: e,
                        }))
                      }
                    />
                    <button
                      type="button"
                      className="btn btn-primary me-2"
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        filterValue.filter_keyword = "";
                        getResearchList()
                      }}
                    >
                      検索
                    </button>
                  </div>
                  <div
                    className="search-input"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    <label style={{ marginRight: "10px" }}>アクション</label>
                    <Select
                      className="select"
                      options={topActionOptions}
                      size="large"
                      style={{ width: "200px" }}
                      value={filterValue.topAction}
                      onChange={(e) =>
                        setFilterValue((prev) => ({
                          ...prev,
                          topAction: e,
                        }))
                      }
                    />
                    <button
                      type="button"
                      className="btn btn-primary me-2"
                      style={{ marginLeft: "10px" }}
                      onClick={handleTopAction}
                    >
                      適用
                    </button>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <Pagination current={pagination.current} pageSize={pagination.pageSize} showSizeChanger={false} total={totalItems} onChange={handleTableChange} />
                  <Select
                    className="select ms-3"
                    options={constants.PageSizeOptions}
                    placeholder="100"
                    onChange={handlePageSizeChange}
                  />
                  <span className="ms-2">/ page</span>
                </div>

              </div>
              <div className="table-responsive">

                {isHeaderFixed && (
                  <div
                    style={{
                      zIndex: 1000,
                      background: "#fff",
                      position: "fixed",
                      top: 0,
                    }}
                  >
                    <table>
                      <thead>
                        <tr>
                          {columns.map((column, index) => (
                            <th
                              key={index}
                              style={{
                                width: headerWidths[index],
                                textAlign: "center",
                                border: "2px solid #f0f0f0",
                                height: 65
                              }}
                            >
                              {column.title}
                            </th>
                          ))}
                        </tr>
                      </thead>
                    </table>
                  </div>
                )}
                <div ref={tableRef}>
                  <Table bordered
                    className="table datanew dataTable no-footer"
                    // rowSelection={rowSelection}
                    columns={columns}
                    dataSource={dataSource}
                    rowKey={(record) => record.id}
                    pagination={false}
                    onChange={handleTableChange}
                    scroll={{ y: "calc(100vh - 350px)" }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /product list */}

          {/* Product edit modal  */}
          <Modal
            title={<h4>プロパティ編集ダイアログ</h4>}
            open={edit_modal_visible}
            footer={false}
            onCancel={() => setEdit_modal_visible(false)}
            width={"90%"}
          >
            <div className="py-3 row">
              <div className="col-md-6">
                <div className="row mt-3 d-flex align-items-center">
                  <div className="col-md-2 text-end">Shipping Plan Name</div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      id="field-3"
                      value={name}
                      onChange={onEditModalInputChange("name")}
                      disabled
                      placeholder="Shipping Plan Name"
                    />
                  </div>
                </div>
                <div className="row mt-3 d-flex align-items-center">
                  <div className="col-md-2 text-end">Amazon Ship ID</div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      id="field-3"
                      placeholder="Amazon Ship ID"
                      value={fba_amazon_ship_id ?? ''}
                      onChange={onEditModalInputChange("fba_amazon_ship_id")}
                    />
                  </div>
                  <div className="col-md-2 text-end">Amazon Referencer ID</div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      id="field-3"
                      placeholder="Amazon Referencer ID"
                      value={fba_amazon_reference_id ?? ''}
                      onChange={onEditModalInputChange("fba_amazon_reference_id")}
                    />
                  </div>
                </div>
                <div className="row mt-3 d-flex align-items-center">
                  <div className="col-md-2 text-end">UGX追跡番号</div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      id="field-3"
                      placeholder="UGX追跡番号"
                      value={tracking_id ?? ''}
                      onChange={onEditModalInputChange("tracking_id")}
                    />
                  </div>
                  <div className="col-md-2 text-end">Fedex追跡番号</div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      id="field-3"
                      placeholder="Fedex追跡番号"
                      value={fedex_tracking_id ?? ''}
                      onChange={onEditModalInputChange("fedex_tracking_id")}
                    />
                  </div>
                </div>
                <div className="row mt-3 d-flex align-items-center">
                  <div className="col-md-2 text-end">UPS追跡番号</div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      id="field-3"
                      placeholder="UPS追跡番号"
                      value={ups_track_number ?? ''}
                      onChange={onEditModalInputChange("ups_track_number")}
                    />
                  </div>
                  <div className="col-md-2 text-end">ギフト券(%)</div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      id="field-3"
                      placeholder="ギフト券"
                      value={promotion_percent ?? ''}
                      onChange={onEditModalInputChange("promotion_percent")}
                    />
                  </div>
                </div>
                <div className="row mt-3 d-flex align-items-center">
                  <div className="col-md-2 text-end">仕入れ金額の<br />合計(¥)</div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      id="field-3"
                      disabled
                      placeholder="仕入れ金額の合計"
                      value={purchase_price ?? ''}
                      onChange={onEditModalInputChange("purchase_price")}
                    />
                  </div>
                  <div className="col-md-2 text-end">関税(¥)</div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      id="field-3"
                      placeholder="関税"
                      value={tariff_price ?? ''}
                      onChange={onEditModalInputChange("tariff_price")}
                    />
                  </div>
                </div>
                <div className="row mt-3 d-flex align-items-center">
                  <div className="col-md-2 text-end">国内送料(¥)</div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      id="field-3"
                      placeholder="国内送料"
                      value={ship_price ?? ''}
                      onChange={onEditModalInputChange("ship_price")}
                    />
                  </div>
                  <div className="col-md-2 text-end">国外送料(¥)</div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      id="field-3"
                      placeholder="国外送料"
                      value={internal_ship_price ?? ''}
                      onChange={onEditModalInputChange("internal_ship_price")}
                    />
                  </div>
                </div>
                <div className="row mt-3 d-flex align-items-center">
                  <div className="col-md-2 text-end">商品全体重量に<br />おける送料(¥)</div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      id="field-3"
                      placeholder="商品全体重量における送料"
                      value={weight_ship_price ?? ''}
                      onChange={onEditModalInputChange("weight_ship_price")}
                    />
                  </div>
                  <div className="col-md-2 text-end">梱包材の送料(¥)</div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      id="field-3"
                      placeholder="梱包材の送料"
                      value={package_ship_price ?? ''}
                      onChange={onEditModalInputChange("package_ship_price")}
                    />
                  </div>
                </div>
                <div className="row mt-3 d-flex align-items-center">
                  <div className="col-md-2 text-end">梱包経費(¥)</div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      id="field-3"
                      placeholder="梱包経費"
                      value={package_fee_price ?? ''}
                      onChange={onEditModalInputChange("package_fee_price")}
                    />
                  </div>
                  <div className="col-md-2 text-end">Amazon手数料($)</div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      id="field-3"
                      disabled
                      placeholder="Amazon手数料"
                      value={amazon_fee_price ?? ''}
                      onChange={onEditModalInputChange("amazon_fee_price")}
                    />
                  </div>
                </div>
                <div className="row mt-3 d-flex align-items-center">
                  <div className="col-md-2 text-end">Shipplan<br />梱包重量(kg)</div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      id="field-3"
                      placeholder="Shipplan 梱包重量"
                      value={package_weight ?? ''}
                      onChange={onEditModalInputChange("package_weight")}
                    />
                  </div>
                  <div className="col-md-2 text-end">販売開始日</div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      id="field-3"
                      placeholder="販売開始日"
                      value={dayjs((Number(created_date) > 3000) ? new Date(Number(created_date)) : created_date).format("YYYY-MM-DD HH:mm:ss")}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="row mt-3 d-flex align-items-center">
                  <div className="col-md-2 text-end">アカウント</div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      value={edit_shipinfo.AccountId ?? ''}
                      disabled />
                  </div>
                </div>
                <div className="row mt-3 d-flex align-items-center">
                  <div className="col-md-2 text-end">日付</div>
                  <div className="col-md-4">
                    <DatePicker
                      value={dayjs(edit_shipinfo.CreatedAt || new Date())}
                      format="YYYY-MM-DD HH:mm:ss"
                      showTime={true}
                      onChange={(date, dateString) => {
                        setEdit_shipinfo(prev => ({ ...prev, CreatedAt: dateString }));
                        let name = getShipPlanName(dateString, edit_shipinfo.PlanName);
                        setName(name);
                      }}
                    />
                  </div>
                  <div className="col-md-2 text-end">プラン名</div>
                  <div className="col-md-4">
                    <Input
                      value={edit_shipinfo.PlanName ?? ''}
                      onChange={(e) => {
                        setEdit_shipinfo(prev => ({ ...prev, PlanName: e.target.value }));
                        let name = getShipPlanName(edit_shipinfo.CreatedAt, e.target.value);
                        setName(name);
                      }} />
                  </div>
                </div>
                <div className="row mt-3 d-flex align-items-center">
                  <div className="col-md-2 text-end">国内集荷日</div>
                  <div className="col-md-4">
                    <DatePicker
                      value={edit_shipinfo.CollectionDate ? dayjs(edit_shipinfo.CollectionDate) : null}
                      format="YYYY-MM-DD"
                      onChange={(date, dateString) => {
                        let receiptdate = "";
                        if (date) {
                          let dtreceipt = date.add(7, 'day');
                          receiptdate = dtreceipt.format("YYYY-MM-DD");
                        }
                        setEdit_shipinfo(prev => ({ ...prev, CollectionDate: dateString, ReceiptDeadline: receiptdate }));
                      }}
                    />
                  </div>

                  <div className="col-md-2 text-end">受領期限<br />変更最終日</div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      value={edit_shipinfo.ReceiptDeadline ? edit_shipinfo.ReceiptDeadline.split(" ")[0] : ""}
                      className="form-control"
                      disabled
                    />
                  </div>
                </div>
                <div className="row mt-3 d-flex align-items-center">
                  <div className="col-md-2 text-end">FBA倉庫</div>
                  <div className="col-md-3">
                    <Input
                      value={edit_shipinfo.FBAStock ?? ''}
                      onChange={(e) => setEdit_shipinfo(prev => ({ ...prev, FBAStock: e.target.value }))}
                    />
                  </div>
                  <div className="col-md-1"></div>

                  <div className="col-md-2 text-end">納品手数料</div>
                  <div className="col-md-4">
                    <InputNumber
                      prefix="$"
                      value={edit_shipinfo.DeliveryFee ?? ''}
                      onChange={(value) => setEdit_shipinfo(prev => ({ ...prev, DeliveryFee: value }))}
                    />
                  </div>
                </div>
                <div className="row mt-3 d-flex align-items-center">
                  <div className="col-md-2 text-end">箱サイズ</div>
                  <div className="col-md-3">
                    <AutoComplete
                      value={edit_shipinfo.BoxSize ?? ''}
                      options={constants.BoxSizeOptions}
                      onSelect={(value, option) => {
                        if (option) {
                          setEdit_shipinfo(prev => ({ ...prev, width: option.width, height: option.height, length: option.length }));
                        }
                      }}
                      onChange={(value) => setEdit_shipinfo(prev => ({ ...prev, BoxSize: value }))}
                      style={{ width: 150 }}
                      filterOption={(inputValue, option) =>
                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      }
                    />
                  </div>
                  <div className="col-md-1"></div>
                  <div className="col-md-5">
                    <InputNumber style={{ width: 60, marginRight: 10 }}
                      value={edit_shipinfo.width ?? ''}
                      onChange={(value) => setEdit_shipinfo(prev => ({ ...prev, width: value }))}
                    /><span>╳</span>
                    <InputNumber style={{ width: 60, marginLeft: 10, marginRight: 10 }}
                      value={edit_shipinfo.height ?? ''}
                      onChange={(value) => setEdit_shipinfo(prev => ({ ...prev, height: value }))}
                    /><span>╳</span>
                    <InputNumber style={{ width: 60, marginLeft: 10, marginRight: 10 }}
                      value={edit_shipinfo.length ?? ''}
                      onChange={(value) => setEdit_shipinfo(prev => ({ ...prev, length: value }))}
                    /><span>cm</span>
                  </div>
                </div>
                <div className="row mt-3 d-flex align-items-center">
                  <div className="col-md-2 text-end">箱重量</div>
                  <div className="col-md-3">
                    <InputNumber suffix="Kg"
                      value={edit_shipinfo.BoxWeight ?? ''}
                      onChange={(value) => setEdit_shipinfo(prev => ({ ...prev, BoxWeight: value }))}
                    />
                  </div>
                  <div className="col-md-1"></div>

                  <div className="col-md-2 text-end">箱数</div>
                  <div className="col-md-1">
                    <InputNumber
                      value={edit_shipinfo.BoxNumber ?? ''}
                      onChange={(value) => setEdit_shipinfo(prev => ({ ...prev, BoxNumber: value }))}
                    />
                  </div>
                </div>
                <div className="row mt-3 d-flex align-items-center">
                  <div className="col-md-2 text-end">TSCA</div>
                  <div className="col-md-1">
                    <Checkbox
                      checked={edit_shipinfo.TSCA == 1}
                      onChange={(e) => setEdit_shipinfo(prev => ({ ...prev, TSCA: e.target.checked ? 1 : 0 }))}>
                    </Checkbox>
                  </div>

                  <div className="col-md-2 text-end">FDA</div>
                  <div className="col-md-1">
                    <Checkbox
                      checked={edit_shipinfo.FDA == 1}
                      onChange={(e) => setEdit_shipinfo(prev => ({ ...prev, FDA: e.target.checked ? 1 : 0 }))}>
                    </Checkbox>
                  </div>

                  <div className="col-md-2 text-end">時計</div>
                  <div className="col-md-1">
                    <Checkbox
                      checked={edit_shipinfo.Watch == 1}
                      onChange={(e) => setEdit_shipinfo(prev => ({ ...prev, Watch: e.target.checked ? 1 : 0 }))}>
                    </Checkbox>
                  </div>

                  <div className="col-md-2 text-end">木製</div>
                  <div className="col-md-1">
                    <Checkbox
                      checked={edit_shipinfo.Wood == 1}
                      onChange={(e) => setEdit_shipinfo(prev => ({ ...prev, Wood: e.target.checked ? 1 : 0 }))}>
                    </Checkbox>
                  </div>
                </div>
                <div className="row mt-3 d-flex align-items-center">
                  <div className="col-md-2 text-end">BLプレイヤー</div>
                  <div className="col-md-4">
                    <Checkbox
                      checked={edit_shipinfo.BLPlayer == 1}
                      onChange={(e) => setEdit_shipinfo(prev => ({ ...prev, BLPlayer: e.target.checked ? 1 : 0 }))}>
                    </Checkbox>
                  </div>

                  <div className="col-md-5 text-end">オーバーサイズ</div>
                  <div className="col-md-1">
                    <Checkbox
                      checked={edit_shipinfo.Oversized == 1}
                      onChange={(e) => setEdit_shipinfo(prev => ({ ...prev, Oversized: e.target.checked ? 1 : 0 }))}>
                    </Checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-cancel waves-effect"
                onClick={() => setEdit_modal_visible(false)}
              >
                閉じる
              </button>
              <button
                type="button"
                style={{ marginLeft: "10px" }}
                className="btn btn-primary waves-effect waves-light"
                onClick={handleUpdateProduct(editASINIndex)}
              >
                保存
              </button>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
});

export default DeliveryLog;
