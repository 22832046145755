
import React from "react";
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import { base_path } from "./environment.jsx";
import "../src/style/scss/main.scss";

import { Provider } from "react-redux";
import store from "./core/redux/store.jsx";
import AllRoutes from "./Router/router.jsx";
import ScrollToTopButton from "./core/components/scrollTop.jsx"
import ScrollToBottomButton from "./core/components/scrollDown.jsx";

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    //<React.StrictMode>
    <Provider store={store} >
      <BrowserRouter basename={base_path}>
        <AllRoutes />
        <ScrollToTopButton />
        <ScrollToBottomButton />
      </BrowserRouter>
    </Provider>
  //</React.StrictMode>
  );
} else {
  console.error("Element with id 'root' not found.");
}
