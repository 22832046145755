/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";

import { useSelector } from "react-redux";
import {
  Tabs,
  Card,
} from "antd";

import SellerTab from "./sellerTab";
import ExchangeTab from "./exchangeTab";
import CategoryTab from "./categoryTab";
import EmployeeTab from "./employeeTab";
import ContentTab from "./contentTab";
import AutoTab from "./autoTab";
import BanTab from "./banTab";
import PackageWayTab from "./packageWayTab";
import FnskuTab from "./fnskuTab";

const { TabPane } = Tabs;

const SettingsContainer = () => {
  const sellerRef = useRef();
  const exchangeRef = useRef();
  const categoryRef = useRef();
  const employeeRef = useRef();
  const contentRef = useRef();
  const autoRef = useRef();
  const banRef = useRef();
  const packageWayRef = useRef();
  const fnskuRef = useRef();

  const active_seller = useSelector((state) => state.active_seller);
  const [activeTab, setActiveTab] = useState(1);

  const handleChangeTab = (key) => {
    setActiveTab(key);
  }

  useEffect(() => {
    if (activeTab == 1) {
      if (sellerRef.current)
        sellerRef.current.initFunc();
    } else if (activeTab == 2) {
      if (exchangeRef.current)
        exchangeRef.current.initFunc();
    } else if (activeTab == 3) {
      if (categoryRef.current)
        categoryRef.current.initFunc();
    } else if (activeTab == 4) {
      if (employeeRef.current)
        employeeRef.current.initFunc();
    } else if (activeTab == 5) {
      if (contentRef.current)
        contentRef.current.initFunc();
    } else if (activeTab == 6) {
      if (autoRef.current)
        autoRef.current.initFunc();
    } else if (activeTab == 7) {
      if (banRef.current)
        banRef.current.initFunc();
    } else if (activeTab == 8) {
      if (packageWayRef.current)
        packageWayRef.current.initFunc();
    } else if (activeTab == 9) {
      if (fnskuRef.current)
        fnskuRef.current.initFunc();
    }
    
  }, [activeTab])

  useEffect(() => {
    document.title = "設定";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active_seller]);

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>設定</h4>
            </div>
          </div>
        </div>
        <div className="card table-list-card">
          <div className="card-body">
            {/* -------------------------------------------------------------- */}
            <Card style={{ width: "100%" }}>
              <Tabs defaultActiveKey="1" type="card" onChange={handleChangeTab}>
                <TabPane tab={<span>ストアアカウント</span>} key="1">
                  <SellerTab ref={sellerRef} />
                </TabPane>
                <TabPane tab={<span>自動設定</span>} key="6">
                  <AutoTab ref={autoRef} />
                </TabPane>
                <TabPane tab={<span>$ 為替レート</span>} key="2">
                  <ExchangeTab ref={exchangeRef} />
                </TabPane>
                <TabPane tab={<span>Category 表</span>} key="3">
                  <CategoryTab ref={categoryRef} />
                </TabPane>
                <TabPane tab={<span>発注者</span>} key="4">
                  <EmployeeTab ref={employeeRef} />
                </TabPane>
                <TabPane tab={<span>発注先</span>} key="5">
                  <ContentTab ref={contentRef} />
                </TabPane>
                <TabPane tab={<span>禁止商品</span>} key="7">
                  <BanTab ref={banRef} />
                </TabPane>
                <TabPane tab={<span>梱包方法</span>} key="8">
                  <PackageWayTab ref={packageWayRef} />
                </TabPane>
                <TabPane tab={<span>FNSKU</span>} key="9">
                  <FnskuTab ref={fnskuRef} />
                </TabPane>
              </Tabs>
            </Card>
            {/* -------------------------------------------------------------- */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsContainer;
